import React, { useEffect, useState } from 'react'
import Navbar from '../components/navbar'
import { useNavigate, useParams } from 'react-router-dom'
import axios from 'axios';
import { message } from 'antd'
import { v4 } from 'uuid';
import { imageDb } from "../firebase";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import "../css/contactus.css"



function Career() {
    const navigate = useNavigate()


    const [firstname, setfirstname] = useState();
    const [lastname, setlastname] = useState();
    const [District, setDistrict] = useState();
    const [Town, setTown] = useState();
    const [ShreetAddress, setShreetAddress] = useState();

    const [City, setCity] = useState();
    const [State, setState] = useState();
    const [PostalCode, setPostalCode] = useState();
    const [PhoneNumber, setPhoneNumber] = useState();
    const [Email, setEmail] = useState();



    const sendemail = async (e) => {
        e.preventDefault()



        const url = 'https://api.sendinblue.com/v3/smtp/email';
        const apiKey = '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'; // Replace with your actual API key






        const htmlContent = `
        <div style="  padding: 10px; background-color: #fff;">
    
        <h1 style=" margin: 0;">Customer Details - HIGH SPEED FIBRE BROADBAND</h1>
        <div style="padding-left: 20px;border: 1px solid gray;">
            <div style="display: flex;">
                <h3 style=" width: 200px;"> full name</h3>
                <h3>: ${firstname} ${lastname}</h3>
            </div>
           
            <div style="display: flex;">
                <h3 style=" width: 200px;"> District</h3>
                <h3>: ${District}</h3>
            </div>
            <div style="display: flex;">
                <h3 style="width: 200px;"> Town</h3>
                <h3>: ${Town}</h3>
            </div>
          
            <div style="display: flex;">
                <h3 style=" width: 200px;"> Brief description of their line of business.</h3>
                <h3>: ${ShreetAddress}</h3>
            </div>
           
            <div style="display: flex;">
                <h3 style="width: 200px;"> City</h3>
                <h3>: ${City}</h3>
            </div>
          
            <div style="display: flex;">
                <h3 style="width: 200px;"> State</h3>
                <h3>: ${State}</h3>
            </div>
          
            <div style="display: flex;">
                <h3 style="width: 200px;"> Digital Address</h3>
                <h3>: ${PostalCode}</h3>
            </div>
          
            <div style="display: flex;">
                <h3 style="width: 200px;"> contact number</h3>
                <h3>: ${PhoneNumber}</h3>
            </div>
            <div style="display: flex;">
                <h3 style="width: 200px;"> Email</h3>
                <h3>: ${Email}</h3>
            </div>
          
        </div>
    
    
       
    
    </div>
    
    `;





        const data = {
            sender: {
                name: 'info@datawarehousegh.com',
                email: 'info@datawarehousegh.com'
            },
            to: [
                {
                    email: "datawarehousegh@gmail.com",
                    name: "info@datawarehousegh.com"
                }
            ],
            subject: 'Career',
            htmlContent
        };

        const headers = {
            'Content-Type': 'application/json',
            'api-key': apiKey
        };

        await axios.post(url, data, { headers })
            .then(response => {



                const updatedProductJSON = JSON.stringify([]);
                alert("Your Imformation has been successfully sent, and we will promptly reach out to you as soon as possible to provide further updates and assistance")

                console.log("OTP has been sent to ")
                navigate("/")
            }

            )
            .catch(error => {

                console.log(error)
            }
            );



    }


    const [jobfor, setjobfor] = useState([])
    const [jobs, setjobs] = useState([])


    const { id } = useParams();
    const [FullName, setFullName] = useState("")
    const [DateOfBirth, setDateOfBirth] = useState("")
    const [Address, setAddress] = useState("")
    const [ContactDetails, setContactDetails] = useState("")
    const [NextOfKinNameandContactNumber, setNextOfKinNameandContactNumber] = useState("")
    const [SocailSecurityNumber, setSocailSecurityNumber] = useState("")
    const [IDNumber, setIDNumber] = useState("")
    const [EmergancyContactNumberAndName, setEmergancyContactNumberAndName] = useState("")
    const [email, setemail] = useState("")

    const [FacebookLink, setFacebookLink] = useState("")
    const [TwitterLink, setTwitterLink] = useState("")
    const [TiktiokLink, setTiktiokLink] = useState("")
    const [InstagramLink, setInstagramLink] = useState("")
    const [youtubeLink, setyoutubeLink] = useState("")
    const [PerformanceAppraisalRelatedNotes, setPerformanceAppraisalRelatedNotes] = useState("")

    const [btn, setbtn] = useState(true)

    const [Img, setImg] = useState()
    const [cv, setcv] = useState()
    const Navigate = useNavigate()

    useEffect(() => {




        var requestOptions = {
            method: 'GET',

            redirect: 'follow'
        };

        fetch("https://backend.datawarehousegh.com:1337/getjob", requestOptions)
            .then(response => response.json())
            .then(result => setjobs(result))
            .catch(error => console.log('error', error));







    }, [])

    useEffect(() => {


        if (id) {
            const matchedJob = jobs.find(job => job._id === id);
            if (matchedJob) {
                setjobfor(matchedJob);
            } else {
                setjobfor([]);
            }
        } else {
            setjobfor([])
        }





    },)


    const submitdata = async (e) => {

        await setbtn(false)
        e.preventDefault()


        const Carimageid2 = v4();
        const imgRef2 = ref(imageDb, `profiles/${Carimageid2}`)
        await uploadBytes(imgRef2, Img)
        const imgUrl = await getDownloadURL(imgRef2);


        console.log(imgUrl)


        const Carimageid = v4();
        const ex = cv.name.split('.')[1]
        const imgRef = ref(imageDb, `files/${Carimageid}.${ex}`)
        await uploadBytes(imgRef, cv)
        const cvUrl = await getDownloadURL(imgRef);


        console.log(cvUrl)




        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

        var urlencoded = new URLSearchParams();
        urlencoded.append("FullName", FullName);
        urlencoded.append("DateOfBirth", DateOfBirth);
        urlencoded.append("Address", Address);
        urlencoded.append("ContactDetails", ContactDetails);
        urlencoded.append("NextOfKinNameandContactNumber", NextOfKinNameandContactNumber);
        urlencoded.append("SocailSecurityNumber", SocailSecurityNumber);
        urlencoded.append("IDNumber", IDNumber);
        urlencoded.append("EmergancyContactNumberAndName", EmergancyContactNumberAndName);
        urlencoded.append("email", email);

        urlencoded.append("FacebookLink", FacebookLink);
        urlencoded.append("TwitterLink", TwitterLink);
        urlencoded.append("TiktiokLink", TiktiokLink);
        urlencoded.append("InstagramLink", InstagramLink);
        urlencoded.append("youtubeLink", youtubeLink);
        urlencoded.append("PerformanceAppraisalRelatedNotes", PerformanceAppraisalRelatedNotes);
        urlencoded.append("cv", cvUrl);
        urlencoded.append("profilepic", imgUrl);

        urlencoded.append("jobtitle", jobfor.jobtitle);

        urlencoded.append("shift", jobfor.shift);
        urlencoded.append("Discription", jobfor.Discription);
        urlencoded.append("Condition", jobfor.Condition);
        urlencoded.append("Location", jobfor.Location);


        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
        };

        await fetch("https://backend.datawarehousegh.com:1337/addstaffcareer", requestOptions)
            .then(response => response.json())
            .then(result => {


                setFullName("");
                setDateOfBirth("");
                setAddress("");
                setContactDetails("");
                setNextOfKinNameandContactNumber("");
                setSocailSecurityNumber("");
                setIDNumber("");
                setEmergancyContactNumberAndName("");
                setemail("");


                setFacebookLink("");
                setTwitterLink("");
                setTiktiokLink("");
                setInstagramLink("");
                setyoutubeLink("");
                setPerformanceAppraisalRelatedNotes("");

                Navigate("/career")



                setbtn(true)
                message.success("We have received your data and appreciate your prompt response. Rest assured, our team will review the information diligently, and we will make every effort to get back to you as soon as possible. Thank you for your patience.")


            })
            .catch(error => console.log('error', error));



    }
    return (

        <>
            <div style={{ marginTop: '110px' }}></div>

            <Navbar />
            <div className='mainpadding'>
                <h1 style={{ borderLeft: "5px solid #0587EF", textAlign: 'left', borderRadius: '5px', color: "rgb(70, 70, 70)", paddingLeft: "10px", alignSelf: "baseline" }}>
                    CAREER WITH US</h1>


                <div style={{ width: '90%', display: 'flex', justifyContent: 'flex-start', paddingLeft: '17px', textAlign: 'justify', color: 'gray', marginBottom: '50px' }}>

                    We are Data Warehouse Gh. One team with one big passion. Internet Connectivity, Digitalisation and Technology. We sell it, we deliver it, we install it, We Connect People, we support it, we fix it. And, most important of all, we bring a human touch to tech to make the magic happen!
                    <br /> <br /> As a company, we want to be proud of what customers say about the services they receive from us. Online, in-store or in their homes, we want to go the extra mile. And it’s all down to our people, - the team - capable and committed colleagues, learning together, growing together, making us amazing and celebrating the wins together.
                    <br /><br /> We know our people – the ones who sell, the ones who serve, the ones who support – are the magic ingredient in our success journey. They’re the ones who will make amazing things happen for our customers, time and time again. That’s why everything we’re doing is so our team can keep doing their thing, like introducing hybrid working for suitable roles so that our colleagues can get the best of both the virtual and in-person working experience. Put simply, we’re creating a clearer, simpler, faster place to work, where all our colleagues have the support they need to perform at their very best.

                </div>

                {jobfor.length === 0 && <>
                    <h2 style={{ borderRadius: '5px', paddingLeft: "15px", alignSelf: "baseline", margin: '0', color: 'gray', textAlign: 'left' }}>
                        Available Jobs</h2>

                    <div className='jobsdiv'>

                        {jobs.map((value) => {

                            return (<>
                                <div className='joblist'>
                                    <h3>job title  :   {value.jobtitle}</h3>
                                    <h4>Location   :   {value.Location}</h4>
                                    <h4>full or part :  {value.Condition}</h4>
                                    <h4>Shift :  {value.shift}</h4>
                                    <div> <h4>Discription</h4>
                                        <p> {value.Discription}</p>
                                    </div>
                                    <button className='formalbtn' onClick={() => {
                                        Navigate("/career/" + value._id)
                                    }}>Apply Now</button>

                                </div>

                            </>)
                        })}




                    </div>
                </>}


                {/* <form onSubmit={sendemail} className='chectoutform' style={{ paddingLeft: '17px' }}>



                    <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '30px' }}>
                        <div style={{ display: "flex", flexDirection: 'column', textAlign: 'left', width: '48%' }}>
                            <label>First Name*</label>
                            <input style={{marginBottom:'30px'}} className='chectoutforminput' required className='chectoutforminput' onChange={(e) => setfirstname(e.target.value)} />
                        </div>


                        <div style={{ display: "flex", flexDirection: 'column', textAlign: 'left', width: '48%' }}>
                            <label>Last Name*</label>
                            <input style={{marginBottom:'30px'}} className='chectoutforminput' required className='chectoutforminput' onChange={(e) => setlastname(e.target.value)} />
                        </div>


                    </div>



                    <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '30px' }}>
                        <div style={{ display: "flex", flexDirection: 'column', textAlign: 'left', width: '48%' }}>
                            <label>District</label>
                            <input style={{marginBottom:'30px'}} className='chectoutforminput' onChange={(e) => setDistrict(e.target.value)} />
                        </div>


                        <div style={{ display: "flex", flexDirection: 'column', textAlign: 'left', width: '48%' }}>
                            <label>Town</label>
                            <input style={{marginBottom:'30px'}} className='chectoutforminput' onChange={(e) => setTown(e.target.value)} />
                        </div>


                    </div>







                    <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '30px', width: "100%" }}>
                        <div style={{ display: "flex", flexDirection: 'column', textAlign: 'left', width: '30%' }}>
                            <label>City</label>
                            <input style={{marginBottom:'30px'}} className='chectoutforminput' onChange={(e) => setCity(e.target.value)} />
                        </div>


                        <div style={{ display: "flex", flexDirection: 'column', textAlign: 'left', width: '30%' }}>
                            <label>State</label>
                            <input style={{marginBottom:'30px'}} className='chectoutforminput' onChange={(e) => setState(e.target.value)} />
                        </div>


                        <div style={{ display: "flex", flexDirection: 'column', textAlign: 'left', width: '30%' }}>
                            <label>Digital Address</label>
                            <input style={{marginBottom:'30px'}} className='chectoutforminput' onChange={(e) => setPostalCode(e.target.value)} />
                        </div>

                    </div>

                    <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '30px' }}>
                        <div style={{ display: "flex", flexDirection: 'column', textAlign: 'left', width: '48%' }}>
                            <label>Phone Number*</label>
                            <input type='number' required className='chectoutforminput' onChange={(e) => setPhoneNumber(e.target.value)} />
                        </div>

                        <div style={{ display: "flex", flexDirection: 'column', textAlign: 'left', width: '48%' }}>
                            <label>Email*</label>
                            <input type='email' required className='chectoutforminput' onChange={(e) => setEmail(e.target.value)} />
                        </div>


                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '30px' }}>
                        <div style={{ display: "flex", flexDirection: 'column', textAlign: 'left', width: '100%' }}>
                            <label>Brief description of their line of business.</label>
                            <textarea className='chectoutforminput' style={{ height: '200px' }} onChange={(e) => setShreetAddress(e.target.value)} />
                        </div>





                    </div>

                    <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '30px', width: '100%' }}>
                        <div style={{ display: "flex", flexDirection: 'column', textAlign: 'left', width: '100%' }}>

                            <button className='chectoutforminput2' type='submit' >Sent</button>
                        </div>




                    </div>




                </form> */}



                {jobfor.length !== 0 && <>

                    <h2 style={{ borderRadius: '5px', paddingLeft: "15px", alignSelf: "baseline", margin: '0', color: 'gray', textAlign: 'left' }}>
                        {jobfor.jobtitle}</h2>


                    <div style={{ width: '90%', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', paddingLeft: '17px', textAlign: 'justify', color: 'gray', marginBottom: '50px' }}>
                        Applicants, please take note of the following criteria when applying to be the part of datawarehouse.

                        <div> <h4>Discription</h4>
                            <p style={{ margin: '0', marginBottom: '20px' }}> {jobfor.Discription}</p>
                        </div>
                        <li>     Location   :   {jobfor.Location}
                        </li>
                        <li>   {jobfor.Condition}</li>
                        <li>   Shift :  {jobfor.shift}</li>
                    </div>
                    <form onSubmit={submitdata} className='chectoutform' style={{textAlign:'left'}}>

                        <div>  <label>Full Name :</label><input style={{marginBottom:'30px'}} className='chectoutforminput' required value={FullName} onChange={(e) => setFullName(e.target.value)} /></div>
                        <div><label>Date Of Birth :</label><input style={{marginBottom:'30px'}} className='chectoutforminput' required value={DateOfBirth} onChange={(e) => setDateOfBirth(e.target.value)} /></div>
                        <div><label>email :</label><input style={{marginBottom:'30px'}} className='chectoutforminput' required value={email} onChange={(e) => setemail(e.target.value)} /></div>

                        <div><label>Address :</label><input style={{marginBottom:'30px'}} className='chectoutforminput' required value={Address} onChange={(e) => setAddress(e.target.value)} /></div>
                        <div><label>Contact Details :</label><input style={{marginBottom:'30px'}} className='chectoutforminput' required value={ContactDetails} onChange={(e) => setContactDetails(e.target.value)} /></div>
                        <div><label>Next Of Kin Name and Contact Number :</label><input style={{marginBottom:'30px'}} className='chectoutforminput' required value={NextOfKinNameandContactNumber} onChange={(e) => setNextOfKinNameandContactNumber(e.target.value)} /></div>
                        <div><label>Socail Security Number :</label><input style={{marginBottom:'30px'}} className='chectoutforminput' required value={SocailSecurityNumber} onChange={(e) => setSocailSecurityNumber(e.target.value)} /></div>
                        <div><label>ID Type :</label>

                            <select  style={{marginBottom:'30px'}} className='chectoutforminput' id="id" name="id">
                                <option disabled selected> select option</option>
                                <option value="volvo">Passport</option>
                                <option value="saab">Ecowas Card</option>
                                <option value="fiat">Voters Card</option>

                            </select>
                        </div>


                        <div><label>ID Number :</label><input style={{marginBottom:'30px'}} className='chectoutforminput' required value={IDNumber} onChange={(e) => setIDNumber(e.target.value)} /></div>
                        <div><label>Emergancy Contact Number And Name  :</label><input style={{marginBottom:'30px'}} className='chectoutforminput' required value={EmergancyContactNumberAndName} onChange={(e) => setEmergancyContactNumberAndName(e.target.value)} /></div>
                        <div><label>Profile Picture :</label><input style={{marginBottom:'30px'}} className='chectoutforminput' accept="image/*" onChange={(e) => setImg(e.target.files[0])} required type='file' /></div>
                        <div><label>Please upload your resume  :</label><input style={{marginBottom:'30px'}} className='chectoutforminput' onChange={(e) => setcv(e.target.files[0])} required type='file' /></div>



                        <h2>Socail Media Links</h2>
                        <div><label>Facebook Link :</label><input style={{marginBottom:'30px'}} className='chectoutforminput' required value={FacebookLink} onChange={(e) => setFacebookLink(e.target.value)} /></div>
                        <div><label>Twitter(X) Link :</label><input style={{marginBottom:'30px'}} className='chectoutforminput' required value={TwitterLink} onChange={(e) => setTwitterLink(e.target.value)} /></div>
                        <div><label>Tiktiok Link :</label><input style={{marginBottom:'30px'}} className='chectoutforminput' required value={TiktiokLink} onChange={(e) => setTiktiokLink(e.target.value)} /></div>
                        <div><label>Instagram Link :</label><input style={{marginBottom:'30px'}} className='chectoutforminput' required value={InstagramLink} onChange={(e) => setInstagramLink(e.target.value)} /></div>
                        <div><label>youtube Link :</label><input style={{marginBottom:'30px'}} className='chectoutforminput' required value={youtubeLink} onChange={(e) => setyoutubeLink(e.target.value)} /></div>

                        <h2>Why WE Should Select YOU?</h2>
                        <div><textarea className='textarea' required style={{ width: "100%", height: '100px' }} value={PerformanceAppraisalRelatedNotes} onChange={(e) => setPerformanceAppraisalRelatedNotes(e.target.value)} /></div>

                        {btn ? <>  <button className='formalbtn' type='submit'>Submit</button>
                        </> : <>
                            <button className='formalbtn' style={{ backgroundColor: "gray" }} onClick={(e) => {
                                e.preventDefault()
                                message.error("Please Wait")
                            }}>Please Wait</button>
                        </>}

                    </form>

                </>}


            </div>

        </>
    )
}

export default Career