import React from 'react'
import "../css/about.css"
import Navbar from "../components/navbar"
import { useNavigate } from 'react-router-dom';

import Carousel from "react-elastic-carousel";
import { BiWorld } from 'react-icons/bi';
import { ImUsers } from 'react-icons/im';






import userimage from "../images/userimage.png";
import homewhoweare from "../images/homewhoweare.png";

import AboutIcon1 from "../images/icon1.png";
import AboutIcon2 from "../images/icon2.png";
import AboutIcon3 from "../images/icon3.png";
import AboutIcon4 from "../images/icon4.png";
import AboutIcon5 from "../images/icon5.png";







const breakPoints2 = [

  { width: 1, itemsToShow: 1 },
  { width: 350, itemsToShow: 2 },
  { width: 500, itemsToShow: 3 },
  { width: 600, itemsToShow: 5 },
  { width: 950, itemsToShow: 8 },
];











function About() {
  const navigate = useNavigate();
  return (
    <div className='Maindiv'>

      <div style={{ marginTop: '110px' }}></div>
<Navbar/>


      <div className='aboutupper'>
        <div className='aboutupper1'></div>
        <div className='aboutupper2' > <h1>Data Warehouse Gh</h1> <p>Meet the Stars</p> </div>
      </div>




      <div clFassName='mainabout'>






        <div className='outstory' style={{ background: 'url(https://www.creatrixcampus.com/themes/custom/creatrixcampus/images/masonry_bg.png) no-repeat', backgroundPosition: '79% center', backgroundSize: '30%,20%' }}>


          <div className='ourstory2'>

            <div className='ourstoryp'>


              <h1 >About Us</h1>
              <p>
              Who we are! – Investing in people and connecting the nation. A company that connects people to people, people to things and things to things - Data Warehouse Gh is a trading name for DWH Ghana limited, an Afroeuro Capital Ghana company part of Bethwell Holdings International. Data Warehouse has been established to bring new opportunities to people across Ghana and beyond with a focus on bringing together award-winning connectivity and amazing entertainment under one roof from the major ISPs in Ghana to ensure we give our customers more choice, more freedom and smart internet solutions. Data Warehouse Gh’s aim is to make internet data very accessible, reliable, and affordable across Ghana. In July 2023, DWH was incorporated in Ghana as an Internet Data Brokerage (IDB) company. The company focus is the delivery of award-winning broadband and WiFi connectivity to homes as well as providing “connected value-added services”.
              </p>

              <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
                <div style={{ display: 'flex', width: '50%', justifyContent: 'center', color: "#0271EB", flexDirection: 'column' }}> <BiWorld style={{ width: '100%', height: "50px", display: 'flex' }} /> <h5>24</h5><h4>Countries served</h4></div>
                <div style={{ display: 'flex', width: '50%', justifyContent: 'center', color: "#0271EB", flexDirection: 'column' }}><ImUsers style={{ width: '100%', height: "50px", display: 'flex' }} /><h5>100+</h5><h4>Employees</h4></div>
              </div>

            </div>

            <div className='outstoryleft'>
              <div className='aboutusrightdiv'><h3>Empowering Ghana's Internet</h3> <p>Data Warehouse Gh connects people with entertainment, offering smart internet options for choice and access.</p></div>
              <div className='aboutusrightdiv'><h3>Nationwide Connectivity</h3> <p>DWH Ghana Limited envisions smooth interactions between people, data, and objects. Our IDB brings reliable, affordable internet nationwide.</p></div>
              <div className='aboutusrightdiv'><h3>Enhanced Connectivity</h3> <p>Partnering with ISPs, we offer unmatched internet and entertainment. Our IDB status ensures affordable, reliable connectivity throughout Ghana.</p></div>

            </div>
          </div>
        </div>



        <h2 className='valueaboutush1' style={{ marginLeft: '10%', }}>The values that shape who we are as a team</h2>
        <div className='valueaboutus'>


          <div>
            <div className='aboutboxes2' style={{ boxShadow: '0 0 1px #0271EB', textAlign: 'justify' }}>Part of Data Warehouse Gh’s vision is to create an inclusive, green and connected future for its customers and the communities. To this end, we will harness the core strengths of our partners and marry them with powerful technology, digital expertise, passionate people, and steadfast commitment to operate a green business creating a wider positive ripple effect in the fight against climate change.
              We’re committed to building a business that represents the diverse communities we serve, while creating an environment where our people genuinely feel they can bring their whole selves to work and where our customers “own” our mission</div>




            <div className='aboutboxes2s' style={{ boxShadow: '0 0 1px #0271EB', padding: '10px', textAlign: 'left', marginLeft: '250px', width: "150px" }}> <img loading="lazzy" src={AboutIcon1} width="50%" alt="icon" /> <h3 style={{ color: '#0271EB' }}>Empathetic</h3>  <p>People who are sensitive to other's needs and feelings both inside and outside the workplace.</p></div>



          </div>

          <div className='aboutboxes' style={{ boxShadow: '0 0 1px #0271EB', padding: '10px', textAlign: 'left', marginTop: '50px', width: "200px", height: "600px", flexDirection: 'column', justifyContent: "center" }}><img loading="lazzy" src={AboutIcon2} width="50%" alt="icon" /> <h3 style={{ color: '#0271EB' }}>Authentic</h3>  <p>Folks who are full of energy and enthusiasm, who practice what they preach, and have the courage to lead </p><br />____________________________<br /><img loading="lazzy" alt="icon" src={AboutIcon3} width="50%" /> <h3 style={{ color: '#0271EB' }}>Committed</h3>  <p>Professionals devoted to our values, vision, and the transformation of our clients</p></div>
          <div className='aboutboxes' style={{ boxShadow: '0 0 1px #0271EB', padding: '10px', textAlign: 'left', marginTop: '0px', width: "200px", height: "600px", flexDirection: 'column', justifyContent: "center" }}><img loading="lazzy" src={AboutIcon4} width="50%" alt="icon" /> <h3 style={{ color: '#0271EB' }}>Transparent</h3>  <p>Teams that are honest, respectful, and willing to learn from one another</p><br />____________________________<br /><img loading="lazzy" src={AboutIcon5} alt="icon" width="50%" /> <h3 style={{ color: '#0271EB' }}>Consultative</h3>  <p>Individuals guide, and cultivate relationships through a consultative approach</p></div>



        </div>




        {/* <div className='ourteam'>
          <h1 className='valueaboutush1'> Our Team</h1>
          <div className='color1'></div>
          <div className='color2'>



            <div className='boss'>
              <img loading="lazzy" className='userimagess' src={userimage} alt='bossimage' />
              <h1 >Saad Wahab Siddiqui</h1>
              <h3>Founder & CEO</h3>
            </div>

            <div className='employers'>

              <div className='employersdetails'>
                <img loading="lazzy" className='userimagess' src={userimage} alt='bossimage' />
                <h2 >Hadi</h2>
                <h4>Co-Founder and CTO</h4>
              </div>

              <div className='employersdetails'>
                <img loading="lazzy" className='userimagess' src={userimage} alt='bossimage' />
                <h2>Amal</h2>
                <h4>CMO</h4>
              </div>


              <div className='employersdetails'>
                <img loading="lazzy" className='userimagess' src={userimage} alt='bossimage' />
                <h2>Dr. Haresh</h2>
                <h4>Chief Health Officer</h4>
              </div>


              <div className='employersdetails'>
                <img loading="lazzy" className='userimagess' src={userimage} alt='bossimage' />
                <h2>Basalat</h2>
                <h4>Head of Operations</h4>
              </div>

              <div className='employersdetails'>
                <img loading="lazzy" className='userimagess' src={userimage} alt='bossimage' />
                <h2>Faryal</h2>
                <h4>Talent Acquisition Specialist</h4>
              </div>


              <div className='employersdetails'>
                <img loading="lazzy" className='userimagess' src={userimage} alt='bossimage' />
                <h2>Fasih</h2>
                <h4>Head of Sales</h4>
              </div>

              <div className='employersdetails'>
                <img loading="lazzy" className='userimagess' src={userimage} alt='bossimage' />
                <h2>Mustafa</h2>
                <h4>Partnerships Lead</h4>
              </div>


              <div className='employersdetails'>
                <img loading="lazzy" className='userimagess' src={userimage} alt='bossimage' />
                <h2>Areeba</h2>
                <h4>Marketing Specialist</h4>
              </div>

              <div className='employersdetails'>
                <img loading="lazzy" className='userimagess' src={userimage} alt='bossimage' />
                <h2>Amna</h2>
                <h4>Marketing Executive</h4>
              </div>




            </div>


          </div>

        </div> */}



        <div className='outstory'>


          <div className='ourstory2'>
            <img loading="lazzy" src={homewhoweare} alt="homewhoweare.png" width={"50%"} height={"80%"} />
           
           
            <div className='whowearep'>
              <h1 className='valueaboutush1'>CAREER WITH US</h1>
              <p>We are Data Warehouse Gh. One team with one big passion. Internet Connectivity, Digitalisation and Technology. We sell it, we deliver it, we install it, We Connect People, we support it, we fix it. And, most important of all, we bring a human touch to tech to make the magic happen!
                As a company, we want to be proud of what customers say about the services they receive from us. Online, in-store or in their homes, we want to go the extra mile. And it’s all down to our people, - the team - capable and committed colleagues, learning together, growing together, making us amazing and celebrating the wins together.
                We know our people – the ones who sell, the ones who serve, the ones who support – are the magic ingredient in our success journey. They’re the ones who will make amazing things happen for our customers, time and time again. That’s why everything we’re doing is so our team can keep doing their thing, like introducing hybrid working for suitable roles so that our colleagues can get the best of both the virtual and in-person working experience. Put simply, we’re creating a clearer, simpler, faster place to work, where all our colleagues have the support they need to perform at their very best.
                Contact us if you are interested in joining us. </p>



            </div>

          </div>
        </div>



        <div className='outstory'>


          <div className='ourstory2'>

            <div className='whowearep'>
              <h1 className='valueaboutush1'>OUR MISSION AND VISION</h1>
              <p>Data Warehouse Gh is on a mission to provide individuals, households, institutions and communities with reliable and affordable internet/Wi-Fi connectivity, smart devices & other value-added services across Ghana.
                <br/>
                Data Warehouse vision is to bring affordable internet to the doorstep of specially the unconnected communities and help reduce poverty and the deprivation of digital services to our communities. 
              </p>



            </div>


            <img loading="lazzy" src={"https://findmydoctor.pk/asset/about-us-2.jpg"} alt="homewhoweare.png" width={"50%"} height={"80%"} />


          </div>
        </div>


        <div className='forempoloyers'>
          <h1>We are Hiring!</h1>
          <button onClick={()=>navigate("/career")}>CONTACT US</button>
        </div>

        {/* <div className='innaws'>
          <h1>Data Warehouse Gh</h1>
          <h5>partners</h5>
          <Carousel className='carousel' breakPoints={breakPoints2} >
            <div className='carouselitem2'>  <img loading="lazzy" src={""} width="100%" height="100%" alt="" />   </div>
            <div className='carouselitem2'>   <img loading="lazzy" src={""} width="100%" height="100%" alt="" /> </div>
            <div className='carouselitem2'> <img loading="lazzy" src={""} width="100%" height="100%" alt="" /> </div>
            <div className='carouselitem2'> <img loading="lazzy" src={""} width="100%" height="100%" alt="" /> </div>
            <div className='carouselitem2'> <img loading="lazzy" src={""} width="100%" height="100%" alt="" /> </div>
            <div className='carouselitem2'> <img loading="lazzy" src={""} width="100%" height="100%" alt="" /> </div>
            <div className='carouselitem2'> <img loading="lazzy" src={""} width="100%" height="100%" alt="" /> </div>
            <div className='carouselitem2'> <img loading="lazzy" src={""} width="100%" height="100%" alt="" /> </div>
            <div className='carouselitem2'> <img loading="lazzy" src={""} width="100%" height="100%" alt="" /> </div>
            <div className='carouselitem2'> <img loading="lazzy" src={""} width="100%" height="100%" alt="" /> </div>
          </Carousel>
        </div> */}






      </div>






      <div className='newsletter'>
        <div className='nawsletterpra'>
          <h2>Subscribe to our newsletter</h2>
          <p>Signup for our weekly newsletter to get the latest news, update and amazing offers delivered directly to your inbox</p>
        </div>



        <div className='newslettersub'>
          <input placeholder='Please enter your email'/>
          <button>Subscribe</button>
        </div>
      </div>

    </div>



    //  </div >
  )
}

export default About