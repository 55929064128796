import React from 'react'

function favourite() {
  return (
    <div>
      
      <div style={{ marginTop: '110px' }}></div>


      
      favourite</div>
  )
}

export default favourite