import React from 'react'
import Navbar from "../../components/navbar"


function Churches() {
  return (
    <div>

      <div style={{ marginTop: '110px' }}></div>


      <Navbar />




      <div className='mainpadding'>
        <h1 style={{ borderLeft: "5px solid #0587EF", margin: '0', textAlign: 'left', borderRadius: '5px', color: "rgb(70, 70, 70)", paddingLeft: "10px", alignSelf: "baseline" }}>
          Internet @Public Places</h1>
          <div className='divwithimages'>
          <div>
        <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-start', paddingLeft: '17px', textAlign: 'justify', color: 'black', marginBottom: '50px' }}>
          Data Warehouse Gh Public Wi-Fi -nicknamed (Green Wifi) is coming soon to you’re a community and to a public place near you. Our goal is to provide reliable, efficient and value for money internet connection which can be confidently relied upon by individuals needing an internet connection while out and about. Soon, the GREEN WIFI will be common in restaurants, hotels, mosques, churches, and other public places.
        </div>


        <h2 style={{ borderRadius: '5px', width: '100%', paddingLeft: "15px", textAlign: 'left', margin: '0', color: 'black', alignSelf: "baseline" }}>
          Churches</h2>



        <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-start', paddingLeft: '17px', textAlign: 'justify', color: 'black', marginBottom: '50px' }}>
          Churches provide a space for members to connect, share ideas, and discuss topics relevant to their faith. Building an online church community for your congregation can be challenging but rewarding to the church's growth.
       <br/><br/>   To begin with, the Internet gives unparalleled possibilities to proclaim the saving message of Jesus Christ to humanity. It is capable of spreading religious information and teaching all over the world. In this way, your message as a pastor is being communicated to and received by many more people around the world.
       <br/><br/>     Furthermore, following successful examples from Asia and Buddhist TEMPLE ACADEMIES, Data Warehouse intends to partner churches using its GREEN WIFI technology to help establish the much Church academies across Ghana.
       <br/><br/>  Please contact as to find out more.
        </div>



        </div>
          <img alt='' src='/IANMGES/INTERNET-MOSQUE.jpg' />

        </div>

      </div>







    </div>
  )
}

export default Churches