import React from 'react'
import "../css/faq.css"
import { NavLink } from 'react-router-dom';
import Navbar from '../components/navbar';

function returnsrefunds() {
  return (
    <div>

      <div style={{ marginTop: '110px' }}></div>



<Navbar/>
      <div className='faqmaindiv'>
        <img src='https://images.93stores.com/2022/01/93stores-refund-policy.png' alt="profile" width={'40%'} height={"auto"} />

        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', textAlign: 'left', height: "auto" }} className='uperwaladin' >
          <h1 style={{ borderLeft: "5px solid blue", borderRadius: '5px', color: "rgb(70, 70, 70)", paddingLeft: "10px", alignSelf: "baseline", marginBottom: '0' }}>
            Returns & Refunds</h1>




        

          <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-start', paddingLeft: '17px', paddingRight: '20px', textAlign: 'justify', color: 'gray' }}>

            You can return something you bought from us if it doesn’t work properly, or you’ve changed your mind and don’t want it anymore.

         <br/>   Just make sure you send it back to us within the time limit:
         <br/>  Changed your mind – 3 days.
         <br/>  Faulty item – 7 days
         <br/>   Accessories – 7 days

         <br/>   We aim to refund you within 30days of receiving the item.

         <br/>    The item must be in its original form and in its original package and not tempered with in any shape of form. We will consider a refund and for items damaged by you at our exclusive discretion.

          </div>







        </div>









      </div>



      <div style={{ textAlign: 'left', padding: '60px' }}>

        <h1 style={{ borderLeft: "5px solid blue", borderRadius: '5px', color: "rgb(70, 70, 70)", paddingLeft: "10px", alignSelf: "baseline" }}>
          About Us</h1>
        <h3 style={{ borderRadius: '5px', paddingLeft: "15px", alignSelf: "baseline", margin: '0', color: 'gray' }}>
          Who we are – Investing in people and connecting the nation</h3>
        <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-start', paddingLeft: '30px', textAlign: 'justify', color: 'gray' }}>A company that connects people to people, people to things and things to things - Data Warehouse Gh is a trading
          name for DWH Ghana limited, an Afroeuro Capital Ghana company part of Bethwell Holdings International. Data
          Warehouse has been established to bring new opportunities to people across Ghana and beyond with a focus on
          bringing together award-winning connectivity and amazing entertainment under one roof from the major ISPs in
          Ghana to our customers giving them more choice, more freedom and smart internet solutions.</div>


        <h3 style={{ borderRadius: '5px', paddingLeft: "15px", alignSelf: "baseline", margin: '0', marginTop: '10px', color: 'gray' }}>
          Sustainability and Responsible Business</h3>
        <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-start', paddingLeft: '30px', textAlign: 'justify', color: 'gray' }}>
          Part of Data Warehouse Gh’s vision is to create an inclusive, green and connected future for its customers and the
          communities. To this end, we will harness the core strengths of our partners and marry them with powerful
          technology, digital expertise, passionate people, and steadfast commitment to operate a green business creating a
          wider positive ripple effect in the fight against climate change.<br />
          We’re committed to building a business that represents the diverse communities we serve, while creating an
          environment where our people genuinely feel they can bring their whole selves to work and where our customers
          “own” our mission.

        </div>

        <h3 style={{ borderRadius: '5px', paddingLeft: "15px", alignSelf: "baseline", margin: '0', marginTop: '10px', color: 'gray' }}>
          <NavLink to='/about' style={{ textDecoration: 'none', color: 'gray' }}>Read More</NavLink></h3>





      </div>










    </div>
  )
}

export default returnsrefunds