import React, { useState, useEffect } from 'react'
import Navbar from "../../components/navbar"
import "./css/mifi.css"
import { useNavigate } from 'react-router-dom';
import { BiArrowBack } from "react-icons/bi"
import Cookies from 'js-cookie';
import { Image } from 'antd';






const ProductImageGallery = ({ images }) => {
  const [selectedImage, setSelectedImage] = useState(images[0]);

  const handleImageClick = (image) => {
    setSelectedImage(image);
  };

  return (
    <div className='imagessss'>
      <Image src={selectedImage} alt="Product" width={"400px"} height={"400px"} />

      <Image.PreviewGroup
        preview={{
          onChange: (current, prev) => console.log(`current index: ${current}, prev index: ${prev}`),
        }}
      >
        {/* <Image width={200} src="https://gw.alipayobjects.com/zos/rmsportal/KDpgvguMpGfqaHPjicRK.svg" />
        <Image
          width={200} src="https://gw.alipayobjects.com/zos/antfincdn/aPkFc8Sj7n/method-draw-image.svg"
        /> */}
        <div className="small-images" style={{ display: 'flex', alignItems: 'center', justifyContent: "center", gap: "10px", flexWrap: 'wrap' }}>
        {images.map((image, index) => (
          <Image style={{ border: '1px solid gray' }}
            key={index}
            src={image}
            width={"100px"}
            height={"100px"}
            alt={`Product ${index}`}
            onClick={() => handleImageClick(image)}
            // className={selectedImage === image ? 'selected' : ''}
            className='hovar'
          />

        ))}
    </div>
      </Image.PreviewGroup >




  {/* <div className="small-images" style={{ display: 'flex', alignItems: 'center', justifyContent: "center", gap: "10px", flexWrap: 'wrap' }}>
    {images.map((image, index) => (
      <img style={{ border: '1px solid gray' }}
        key={index}
        src={image}
        width={"100px"}
        height={"100px"}
        alt={`Product ${index}`}
        onClick={() => handleImageClick(image)}
        // className={selectedImage === image ? 'selected' : ''}
        className='hovar'
      />
    ))}
  </div> */}
    </div >
  );
};

















function Mifis() {
  let navigate = useNavigate();
  const [showallplans, setshowallplans] = useState(false);
  const [showcheckout, setshowcheckout] = useState(true);
  const [productdetails, setproductdetails] = useState(1);
  const [Specifications, setSpecifications] = useState(false);
  const [selectedCell, setSelectedCell] = useState(null);

  const [selselectplanebox, setSelselectplanebox] = useState(false);

  const [productselected, setproductselected] = useState("");



  const [cookieproduct, setcookieproduct] = useState([]);


  useEffect(() => {



    // Check if the 'Product' cookie exists
    if (Cookies.get('Product')) {
      // The 'Product' cookie exists, so you can proceed to use its value
      const productCookie = Cookies.get('Product');
      try {
        const productData = JSON.parse(productCookie);
        setcookieproduct(productData);
      } catch (error) {
        console.error('Error parsing JSON from the "Product" cookie:', error);
      }
    } else {
      // The 'Product' cookie does not exist or is empty
      console.warn('The "Product" cookie is not set or is empty.');
    }




    // setcookieproduct(JSON.parse(Cookies.get('Product')));
  }, [])







  return (
    <div>

      <div style={{ marginTop: '110px' }}></div>


      <Navbar />



      <div className='mifimain'>


        <h1 style={{ borderLeft: "5px solid #0587EF", textAlign: 'left', borderRadius: '5px', color: "rgb(70, 70, 70)", paddingLeft: "10px", alignSelf: "baseline" }}>
          Internet & Broadband</h1>

        <h2 style={{ borderRadius: '5px', paddingLeft: "15px", alignSelf: "baseline", margin: '0', color: 'black' }}>
          MIFI</h2>

        <div style={{ width: '90%', display: 'flex', justifyContent: 'flex-start', paddingLeft: '17px', textAlign: 'justify', color: 'black', marginBottom: '50px' }}>
          Also known as mobile internet or MiFi. Mobile Broadband is the Wi-Fi connection you can take with you. Connect up to 10 devices with Mobile Wi-Fi.
        </div>

        {showcheckout === true && <>


          {/* 1 */}
          <div className='procarddd'>

            <div className='subprocard'>

              <div className='subprocardImg'>
                <Image src='/routers pictures/MT10/New folder/1.jpg' alt="profile" height={"100%"} width={"100%"} />
              </div>


              <div className='subprocarddescrib'>
                <h3 style={{ borderRadius: '5px', paddingLeft: "15px", alignSelf: "baseline", margin: '0', color: 'black' }}>
                  DW SMART MIFI – MT10</h3>

                <div style={{ width: '90%', display: 'flex', justifyContent: 'flex-start', paddingLeft: '17px', textAlign: 'justify', color: 'black' }}>
                  Discover the 4G MiFi: Reach 300Mbps* speeds without a landline. Work, stream, call, and browse anywhere with portable Wi-Fi. Stay connected on the go.  </div>
              </div>

            </div>



            <div className='subprocardfeature'>
              <h3 style={{ borderRadius: '5px', paddingLeft: "15px", alignSelf: "baseline", margin: '0', color: 'black' }}>
                Features</h3>

              <ul style={{ margin: '0', fontSize: "0.7em" }}>
                <li>Mobile Hotspot</li>
                <li>4G LTE</li>
                <li>Upto 15 users, 50m distance</li>
                <li>150mbps download speed</li>
                <li>3000mah Battery</li>
                <li>Supports USB interface charging.</li>
                <li>Multiband</li>
                <li>Global Operators Compatibility	</li>

              </ul>

              <h5 onClick={() => {
                setshowcheckout(false)
                setproductdetails(1)
              }} style={{ borderRadius: '5px', paddingLeft: "15px", alignSelf: "baseline", margin: '0', color: 'black' }}>
                View  Full Product's Detals</h5>
            </div>


            <div className='subprocardprice'>
              <h3 style={{ borderRadius: '5px', paddingLeft: "15px", alignSelf: "baseline", margin: '0', color: 'black' }}>
                Price</h3>

              <p style={{ width: '100%', fontWeight: 'bold', paddingLeft: "15px", display: 'flex', margin: '0', justifyContent: 'flex-start', textAlign: 'left', color: 'red' }}>
                GHc 729.00
              </p>
              <p style={{ width: '100%', paddingLeft: "15px", display: 'flex', margin: '0', justifyContent: 'flex-start', textAlign: 'left', color: 'black' }}>
                One-time Activation <br /> +<br /> 15GB of Data
              </p>

            </div>


            <div className='subprocardbutton'>
              <button onClick={() => {


                setSelselectplanebox(true)

                // setproductselected("DW SMART MIFI – MT10")

                setproductselected(
                  {
                    "productname": "DW SMART MIFI – MT10",
                    "productPrice": "699.00  (One-time Activation + 1st Month subscription)",
                    "productimg": "/routers pictures/MT10/New folder/1.jpg"
                  }
                )



              }}




              >ADD TO CART</button>
            </div>

          </div>






          {/* 2 */}
          <div className='procarddd'>

            <div className='subprocard'>

              <div className='subprocardImg'>
                <Image src='/routers pictures/MT20/New folder/1.jpg' alt="profile" height={"100%"} width={"100%"} />
              </div>


              <div className='subprocarddescrib'>
                <h3 style={{ borderRadius: '5px', paddingLeft: "15px", alignSelf: "baseline", margin: '0', color: 'black' }}>
                  DW SMART Mifi – MT20</h3>

                <div style={{ width: '90%', display: 'flex', justifyContent: 'flex-start', paddingLeft: '17px', textAlign: 'justify', color: 'black' }}>
                  Introducing 4G MiFi: Up to 300Mbps* speeds, no landline needed. Work, stream, and stay connected on the go with portable Wi-Fi.
                </div>
              </div>

            </div>



            <div className='subprocardfeature'>
              <h3 style={{ borderRadius: '5px', paddingLeft: "15px", alignSelf: "baseline", margin: '0', color: 'black' }}>
                Features</h3>

              <ul style={{ margin: '0', fontSize: "0.7em" }}>
                <li>Mobile Hotspot</li>
                <li>4G LTE</li>
                <li>Upto 10 users, 50m distance</li>
                <li>150mbps download speed</li>
                <li>2100mah Battery</li>
                <li>Supports USB interface charging.</li>
                <li>Multiband</li>
                <li>Global Operators Compatibility	</li>

              </ul>

              <h5 onClick={() => {
                setshowcheckout(false)
                setproductdetails(2)
              }} style={{ borderRadius: '5px', paddingLeft: "15px", alignSelf: "baseline", margin: '0', color: 'black' }}>
                View  Full Product's Detals</h5>

            </div>


            <div className='subprocardprice'>
              <h3 style={{ borderRadius: '5px', paddingLeft: "15px", alignSelf: "baseline", margin: '0', color: 'black' }}>
                Price</h3>

              <p style={{ width: '100%', fontWeight: 'bold', paddingLeft: "15px", display: 'flex', margin: '0', justifyContent: 'flex-start', textAlign: 'left', color: 'red' }}>
                GHc 699.00
              </p>
              <p style={{ width: '100%', paddingLeft: "15px", display: 'flex', margin: '0', justifyContent: 'flex-start', textAlign: 'left', color: 'black' }}>
                One-time Activation <br /> +<br /> 15GB of Data
              </p>

            </div>


            <div className='subprocardbutton'>
              <button onClick={() => {


                setSelselectplanebox(true)

                // setproductselected("DW SMART MIFI – MT10")

                setproductselected(
                  {
                    "productname": "DW SMART MIFI – MT20",
                    "productPrice": "750.00  (One-time Activation + 1st Month subscription)",
                    "productimg": "/routers pictures/MT20/New folder/1.jpg"
                  }
                )



              }}




              >ADD TO CART</button>
            </div>

          </div>







          {/* 3 */}
          <div className='procarddd'>

            <div className='subprocard'>

              <div className='subprocardImg'>
                <Image src='/routers pictures/MF982/New folder/1.jpg' alt="profile" height={"100%"} width={"100%"} />
              </div>


              <div className='subprocarddescrib'>
                <h3 style={{ borderRadius: '5px', paddingLeft: "15px", alignSelf: "baseline", margin: '0', color: 'black' }}>
                  DW SMART WIFI – MT25</h3>

                <div style={{ width: '90%', display: 'flex', justifyContent: 'flex-start', paddingLeft: '17px', textAlign: 'justify', color: 'black' }}>
                  Discover the 4G MiFi: No landline, speeds up to 300Mbps*. Work, stream, and stay connected anywhere with portable Wi-Fi.    </div>
              </div>

            </div>



            <div className='subprocardfeature'>
              <h3 style={{ borderRadius: '5px', paddingLeft: "15px", alignSelf: "baseline", margin: '0', color: 'black' }}>
                Features</h3>

              <ul style={{ margin: '0', fontSize: "0.7em" }}>
                <li>Mobile Hotspot</li>
                <li>4G LTE</li>
                <li>Upto 15 users, 50m distance</li>
                <li>150mbps download speed</li>
                <li>3000mah Battery</li>
                <li>Supports USB interface charging.</li>
                <li>Multiband</li>
                <li>Global Operators Compatibility	</li>

              </ul>

              <h5 onClick={() => {
                setshowcheckout(false)
                setproductdetails(3)
              }} style={{ borderRadius: '5px', paddingLeft: "15px", alignSelf: "baseline", margin: '0', color: 'black' }}>
                View  Full Product's Detals</h5>
            </div>


            <div className='subprocardprice'>
              <h3 style={{ borderRadius: '5px', paddingLeft: "15px", alignSelf: "baseline", margin: '0', color: 'black' }}>
                Price</h3>

              <p style={{ width: '100%', fontWeight: 'bold', paddingLeft: "15px", display: 'flex', margin: '0', justifyContent: 'flex-start', textAlign: 'left', color: 'red' }}>
                GHc 739.00
              </p>
              <p style={{ width: '100%', paddingLeft: "15px", display: 'flex', margin: '0', justifyContent: 'flex-start', textAlign: 'left', color: 'black' }}>
                One-time Activation <br /> +<br /> 15GB of Data
              </p>

            </div>


            <div className='subprocardbutton'>
              <button onClick={() => {


                setSelselectplanebox(true)

                // setproductselected("DW SMART MIFI – MT10")

                setproductselected(
                  {
                    "productname": "DW SMART MIFI – MT25",
                    "productPrice": "800.00  (One-time Activation + 1st Month subscription)",
                    "productimg": "/routers pictures/MF982/New folder/1.jpg"
                  }
                )



              }}




              >ADD TO CART</button>
            </div>

          </div>










          {/* 4 */}

          <div className='procarddd'>

            <div className='subprocard'>

              <div className='subprocardImg'>
                <Image src='/routers pictures/MT30/New folder/1.jpg' alt="profile" height={"100%"} width={"100%"} />
              </div>


              <div className='subprocarddescrib'>
                <h3 style={{ borderRadius: '5px', paddingLeft: "15px", alignSelf: "baseline", margin: '0', color: 'black' }}>
                  DW SMART WIFI – MT30</h3>

                <div style={{ width: '90%', display: 'flex', justifyContent: 'flex-start', paddingLeft: '17px', textAlign: 'justify', color: 'black' }}>
                  Meet the 4G MiFi: up to 300Mbps* speeds, no landline needed. Work, stream, call, or browse anywhere. Stay connected on the go with portable Wi-Fi.   </div>
              </div>

            </div>



            <div className='subprocardfeature'>
              <h3 style={{ borderRadius: '5px', paddingLeft: "15px", alignSelf: "baseline", margin: '0', color: 'black' }}>
                Features</h3>

              <ul style={{ margin: '0', fontSize: "0.7em" }}>
                <li>Mobile Hotspot</li>
                <li>4G LTE</li>
                <li>Upto 15 users, 50m distance</li>
                <li>Upto 300mbps</li>
                <li>4000mah Battery</li>
                <li>Supports USB interface charging.USB Type – C fast Charging	</li>
                <li>Multiband</li>
                <li>Global Operators Compatibility	</li>
                <li>1 Lan Port 	</li>

                <li>Global Operators Compatibility	</li>

              </ul>
              <h5 onClick={() => {
                setshowcheckout(false)
                setproductdetails(4)
              }} style={{ borderRadius: '5px', paddingLeft: "15px", alignSelf: "baseline", margin: '0', color: 'black' }}>
                View  Full Product's Detals</h5>
            </div>


            <div className='subprocardprice'>
              <h3 style={{ borderRadius: '5px', paddingLeft: "15px", alignSelf: "baseline", margin: '0', color: 'black' }}>
                Price</h3>

              <p style={{ width: '100%', fontWeight: 'bold', paddingLeft: "15px", display: 'flex', margin: '0', justifyContent: 'flex-start', textAlign: 'left', color: 'red' }}>
                GHc 759.00
              </p>
              <p style={{ width: '100%', paddingLeft: "15px", display: 'flex', margin: '0', justifyContent: 'flex-start', textAlign: 'left', color: 'black' }}>
                One-time Activation <br /> +<br /> 15GB of Data
              </p>

            </div>


            <div className='subprocardbutton'>
              <button onClick={() => {


                setSelselectplanebox(true)

                // setproductselected("DW SMART MIFI – MT10")

                setproductselected(
                  {
                    "productname": "DW SMART MIFI – MT30",
                    "productPrice": "850.00  (One-time Activation + 1st Month subscription)",
                    "productimg": "/routers pictures/MT30/New folder/1.jpg"
                  }
                )



              }}




              >ADD TO CART</button>
            </div>

          </div>





        </>}









        {showcheckout === false && <>

          <h3 onClick={() => setshowcheckout(true)} className='hovar' style={{ paddingLeft: "15px", width: 'fit-content', alignSelf: "baseline", marginTop: '0', color: 'black', display: 'flex', alignItems: 'center', gap: '5px' }}>
            <BiArrowBack style={{ fontWeight: 'bolder' }} />    Go Back</h3>
          {productdetails === 1 && <>

            <div className='productdetails'>
            <ProductImageGallery
                    images={[
                      '/routers pictures/MT10/New folder/1.jpg',
                     
                      '/routers pictures/MT10/New folder/2.jpg',
                    
                      '/routers pictures/MT10/New folder/5.jpg',
                      '/routers pictures/MT10/New folder/6.jpg',
                      '/routers pictures/MT10/New folder/7.jpg',
                      '/routers pictures/MT10/New folder/67.jpg',
                    
                      // Add more image URLs here
                    ]}
                  />
              <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', textAlign: 'left' }} className='prodetailsright'>



                <h2 style={{ paddingLeft: "15px", alignSelf: "baseline", marginTop: '0', color: 'black' }}>
                  DW SMART Mifi – MT10</h2>




                <div style={{ width: '90%', display: 'flex', justifyContent: 'flex-start', paddingLeft: '17px', textAlign: 'justify', color: 'black' }}>
                  <div style={{ width: '90%', display: 'flex', justifyContent: 'flex-start', paddingLeft: '17px', textAlign: 'justify', color: 'black', alignItems: 'center', marginTop: '20px' }}>
                    Rating:

                    <div class="rating">
                      <span class="star">&#9733;</span>
                      <span class="star">&#9733;</span>
                      <span class="star">&#9733;</span>
                      <span class="star">&#9733;</span>
                      <span class="star">&#9733;</span>
                    </div>


                  </div>
                </div>
                <h5 style={{ width: '100%', display: 'flex', marginBottom: '0', justifyContent: 'flex-start', paddingLeft: '17px', textAlign: 'justify', color: 'black' }}>
                  Get it now from  <p style={{ fontWeight: "bolder", padding: "0 1px", color: 'red' }}> GHc 729.00 </p> (One-time Activation + 1st Month subscription)
                </h5>





                {Specifications === true && <>
                  <div style={{ display: 'flex', gap: '30px' }}>  <h3 onClick={() => setSpecifications(false)} className='hovar' style={{ borderRadius: '5px', paddingLeft: "0px", alignSelf: "baseline", margin: '0', marginTop: '40px', color: 'black' }}>
                    Features Highlight</h3>

                    <h3 style={{ borderRadius: '5px', borderBottom: '5px solid #0587EF', paddingLeft: "0px", alignSelf: "baseline", margin: '0', marginTop: '40px', color: 'black' }}>
                      Specifications</h3></div></>}




                {Specifications === false && <>
                  <div style={{ display: 'flex', gap: '30px' }}>  <h3 style={{ borderRadius: '5px', borderBottom: '5px solid #0587EF', paddingLeft: "0px", alignSelf: "baseline", margin: '0', marginTop: '40px', color: 'black' }}>
                    Features Highlight</h3>

                    <h3 onClick={() => setSpecifications(true)} className='hovar' style={{ borderRadius: '5px', paddingLeft: "0px", alignSelf: "baseline", margin: '0', marginTop: '40px', color: 'black' }}>
                      Specifications</h3></div></>}


                {Specifications === false && <>
                  <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-start', paddingLeft: '0px', textAlign: 'justify', color: 'black' }}>
                    Our 4G Mifi offers the best connectivity solution for Mobile broadband, which means it's fast & you don't need a landline. With speeds of up to 300Mbps* (Device Theoretical Max) the 4G Mifi is capable of delivering the ultimate mobile broadband experience. Whether it's working on the go, streaming, video calls or just browsing the web 4G Mobile Broadband is made for those who love it all - all at the same time! A portable Wi-Fi connection you can charge up and take with you. Wherever. Whenever.
                  </div></>}

                {Specifications === true && <>
                  <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-start', paddingLeft: '0px', textAlign: 'justify', color: 'black' }}>

                    <ul>
                      <li>Mobile Hotspot</li>
                      <li>4G LTE</li>
                      <li>Upto 15 users, 50m distance</li>
                      <li>150mbps download speed</li>
                      <li>Supports USB interface charging</li>
                      <li>Multiband</li>
                      <li>Global Operators Compatibility</li>
                      <li>Smart Security Management</li>

                    </ul>
                  </div></>}




                <button onClick={() => {


                  setSelselectplanebox(true)

                  // setproductselected("DW SMART MIFI – MT10")

                  setproductselected(
                    {
                      "productname": "DW SMART MIFI – MT10",
                      "productPrice": "699.00  (One-time Activation + 1st Month subscription)",
                      "productimg": "/routers pictures/MT10/New folder/1.jpg"
                    }
                  )



                }}




                  style={{ width: '100%', height: '50px', fontSize: '2rem', fontWeight: "bold", backgroundColor: "#0587EF", color: 'white', borderRadius: '5px' }}>Order Now </button>

              </div>


            </div>




            <div className='productdetails3'>


              <h3 style={{ borderRadius: '5px', backgroundColor: '#0587EF', padding: "5px", alignSelf: "baseline", margin: '0', marginTop: '40px', color: 'White' }}>
                NETWORKS                       </h3>

              <div style={{ width: '90%', display: 'flex', justifyContent: 'flex-start', textAlign: 'justify', color: 'black' }}>
                Available on.
              </div>


              <table className='data-plans-table'>

                <tbody>

                  <tr style={{display:'flex'}} >

                    <td style={{display:'flex',width:'33%',alignItems:'center',justifyContent:'center'}}> <img alt='' src='/LOGO (2).jpg' width={"40px"}/> MTN </td>
                    <td style={{display:'flex',width:'33%',alignItems:'center',justifyContent:'center'}}> <img alt='' src='/LOGO (1).jpg' width={"40px"}/>AT Ghana</td>
                    <td style={{display:'flex',width:'33%',alignItems:'center',justifyContent:'center'}}> <img alt='' src='/LOGO (3).jpg' width={"40px"}/>Telecel</td>

                  </tr>

                </tbody>
              </table>





              <h3 style={{ borderRadius: '5px', backgroundColor: '#0587EF', padding: "5px", alignSelf: "baseline", margin: '0', marginTop: '40px', color: 'White' }}>
                No fluff. Just the good stuff.</h3>

              <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-start', textAlign: 'justify', color: 'black' }}>
                All plans from Data Warehouse Gh come with great features as standard. We provide coverage that you can trust in your area. We cover 98% of the Ghana’s population. We provide competitively generous internet data up to unlimited subject to fair usage & open data policies.
              </div>



                <h3 style={{ borderRadius: '5px', backgroundColor: '#0587EF', padding: "5px", alignSelf: "baseline", margin: '0', marginTop: '40px', color: 'White' }}>
                 Monthly Data Plans                        </h3>
                {/* <div style={{ width: '90%', display: 'flex', justifyContent: 'flex-start', textAlign: 'justify', color: 'black' }}>
                  Contract Duration (1-12) months.
                </div> */}


                <table className='data-plans-table'>
                  <thead>
                    <tr>
                      <th>Data Plan</th>
                      <th>Price</th>
                     
                    </tr>
                  </thead>
                  <tbody>

                    <tr >
                      <td>10.5GB Data</td>
                      <td>GHc 75/-</td>
                     

                    </tr>


                    <tr >
                      <td>12.5GB Data</td>
                      <td>GHc 85/-</td>
                     
                    </tr>


                    <tr >
                      <td>15GB Data</td>
                      <td>GHc 100/-</td>
                     
                     
                    </tr>

                    {showallplans === true && <>

                      <tr >
                        <td>21GB Data</td>
                        <td>GHc 140/-</td>
                      </tr>

                      <tr >
                        <td>35GB Data</td>
                        <td>GHc 185/-</td>
                      </tr>


                      <tr >
                        <td>45GB Data</td>
                        <td>GHc 210/-</td>
                      </tr>


                      <tr >
                        <td>75GB Data</td>
                        <td>GHc 210/-</td>
                      </tr>

                      <tr >
                        <td>100GB Data</td>
                        <td>GHc 320/-</td>
                      </tr>
                      <tr >
                        <td>150GB Data</td>
                        <td>GHc 450/-</td>
                      </tr>
                      <tr >
                        <td>180GB Data</td>
                        <td>GHc 540/-</td>
                      </tr>


                    </>}

                  </tbody>
                </table>

              {showallplans === false && <>


                <h5 className='readmore' onClick={() => { setshowallplans(true) }} style={{ margin: "0", width: '100%', display: 'flex', justifyContent: 'end', textAlign: 'justify', color: 'black' }}>
                  Read More.
                </h5>

              </>}
              {showallplans === true && <>

                <h5 className='readmore' onClick={() => { setshowallplans(false) }} style={{ margin: "0", width: '100%', display: 'flex', justifyContent: 'end', textAlign: 'justify', color: 'black' }}>
                  Read Less.
                </h5>
              </>}
              <div className='hovar' onClick={()=>navigate('/dataplanspdf')} style={{ width: '100%', display: 'flex', justifyContent: 'flex-start', paddingLeft: '17px', textAlign: 'justify', color: 'black', marginBottom: '50px' }}>
          CLICK HERE to see Data Plans PDF
        </div>



            </div>


          </>}
          



          {productdetails === 2 && <>

            <div className='productdetails'>
            <ProductImageGallery
                    images={[
                      '/routers pictures/MT20/New folder/1.jpg',
                     
                      '/routers pictures/MT20/New folder/2.jpg',
                    
                      '/routers pictures/MT20/New folder/3.jpg',
                      '/routers pictures/MT20/New folder/5.jpg',
                     
                    
                      // Add more image URLs here
                    ]}
                  />
              <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', textAlign: 'left' }} className='prodetailsright'>



                <h2 style={{ paddingLeft: "15px", alignSelf: "baseline", marginTop: '0', color: 'black' }}>
                  DW SMART Mifi – MT20</h2>




                <div style={{ width: '90%', display: 'flex', justifyContent: 'flex-start', paddingLeft: '17px', textAlign: 'justify', color: 'black' }}>
                  <div style={{ width: '90%', display: 'flex', justifyContent: 'flex-start', paddingLeft: '17px', textAlign: 'justify', color: 'black', alignItems: 'center', marginTop: '20px' }}>
                    Rating:

                    <div class="rating">
                      <span class="star">&#9733;</span>
                      <span class="star">&#9733;</span>
                      <span class="star">&#9733;</span>
                      <span class="star">&#9733;</span>
                      <span class="star">&#9733;</span>
                    </div>


                  </div>
                </div>
                <h5 style={{ width: '100%', display: 'flex', marginBottom: '0', justifyContent: 'flex-start', paddingLeft: '17px', textAlign: 'justify', color: 'black' }}>
                  Get it now from  <p style={{ fontWeight: "bolder", padding: "0 1px", color: 'red' }}> GHc 699.00 </p> (One-time Activation + 1st Month subscription)
                </h5>





                {Specifications === true && <>
                  <div style={{ display: 'flex', gap: '30px' }}>  <h3 onClick={() => setSpecifications(false)} className='hovar' style={{ borderRadius: '5px', paddingLeft: "0px", alignSelf: "baseline", margin: '0', marginTop: '40px', color: 'black' }}>
                    Features Highlight</h3>

                    <h3 style={{ borderRadius: '5px', borderBottom: '5px solid #0587EF', paddingLeft: "0px", alignSelf: "baseline", margin: '0', marginTop: '40px', color: 'black' }}>
                      Specifications</h3></div></>}




                {Specifications === false && <>
                  <div style={{ display: 'flex', gap: '30px' }}>  <h3 style={{ borderRadius: '5px', borderBottom: '5px solid #0587EF', paddingLeft: "0px", alignSelf: "baseline", margin: '0', marginTop: '40px', color: 'black' }}>
                    Features Highlight</h3>

                    <h3 onClick={() => setSpecifications(true)} className='hovar' style={{ borderRadius: '5px', paddingLeft: "0px", alignSelf: "baseline", margin: '0', marginTop: '40px', color: 'black' }}>
                      Specifications</h3></div></>}


                {Specifications === false && <>
                  <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-start', paddingLeft: '0px', textAlign: 'justify', color: 'black' }}>
                    Unleash the potential of high-speed 4G MiFi – your portable gateway to seamless mobile broadband. With speeds reaching up to 300Mbps* (Device Theoretical Max), enjoy uninterrupted streaming, video calls, and browsing on the go. No landline needed – just charge and carry the power of connectivity with you, anywhere, anytime. Elevate your mobile experience today.   </div></>}

                {Specifications === true && <>
                  <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-start', paddingLeft: '0px', textAlign: 'justify', color: 'black' }}>

                    <ul>
                      <li>Mobile Hotspot</li>
                      <li>4G LTE</li>
                      <li>Upto 10 users, 50m distance</li>
                      <li>150mbps download speed</li>
                      <li>2100mah Battery</li>
                      <li>Supports USB interface charging</li>
                      <li>Multiband</li>
                      <li>Global Operators Compatibility</li>
                      <li>Smart Security Management</li>

                    </ul>
                  </div></>}




                <button onClick={() => {


                  setSelselectplanebox(true)

                  // setproductselected("DW SMART MIFI – MT10")

                  setproductselected(
                    {
                      "productname": "DW SMART MIFI – MT20",
                      "productPrice": "750.00  (One-time Activation + 1st Month subscription)",
                      "productimg": "/routers pictures/MT20/New folder/1.jpg"
                    }
                  )



                }}




                  style={{ width: '100%', height: '50px', fontSize: '2rem', fontWeight: "bold", backgroundColor: "#0587EF", color: 'white', borderRadius: '5px' }}>Order Now </button>

              </div>


            </div>




            <div className='productdetails3'>


              <h3 style={{ borderRadius: '5px', backgroundColor: '#0587EF', padding: "5px", alignSelf: "baseline", margin: '0', marginTop: '40px', color: 'White' }}>
                NETWORKS                       </h3>

              <div style={{ width: '90%', display: 'flex', justifyContent: 'flex-start', textAlign: 'justify', color: 'black' }}>
                Available on.
              </div>


              <table className='data-plans-table'>

                <tbody>

                   <tr style={{display:'flex'}} >

                    <td style={{display:'flex',width:'33%',alignItems:'center',justifyContent:'center'}}> <img alt='' src='/LOGO (2).jpg' width={"40px"}/> MTN </td>
                    <td style={{display:'flex',width:'33%',alignItems:'center',justifyContent:'center'}}> <img alt='' src='/LOGO (1).jpg' width={"40px"}/>AT Ghana</td>
                    <td style={{display:'flex',width:'33%',alignItems:'center',justifyContent:'center'}}> <img alt='' src='/LOGO (3).jpg' width={"40px"}/>Telecel</td>

                  </tr>

                </tbody>
              </table>





              <h3 style={{ borderRadius: '5px', backgroundColor: '#0587EF', padding: "5px", alignSelf: "baseline", margin: '0', marginTop: '40px', color: 'White' }}>
                No fluff. Just the good stuff.</h3>

              <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-start', textAlign: 'justify', color: 'black' }}>
                All plans from Data Warehouse Gh come with great features as standard. We provide coverage that you can trust in your area. We cover 98% of the Ghana’s population. We provide competitively generous internet data up to unlimited subject to fair usage & open data policies.
              </div>



                <h3 style={{ borderRadius: '5px', backgroundColor: '#0587EF', padding: "5px", alignSelf: "baseline", margin: '0', marginTop: '40px', color: 'White' }}>
                 Monthly Data Plans                        </h3>
                {/* <div style={{ width: '90%', display: 'flex', justifyContent: 'flex-start', textAlign: 'justify', color: 'black' }}>
                  Contract Duration (1-12) months.
                </div> */}


                <table className='data-plans-table'>
                  <thead>
                    <tr>
                      <th>Data Plan</th>
                      <th>Price</th>
                     
                    </tr>
                  </thead>
                  <tbody>

                    <tr >
                      <td>10.5GB Data</td>
                      <td>GHc 75/-</td>
                     

                    </tr>


                    <tr >
                      <td>12.5GB Data</td>
                      <td>GHc 85/-</td>
                     
                    </tr>


                    <tr >
                      <td>15GB Data</td>
                      <td>GHc 100/-</td>
                     
                     
                    </tr>

                    {showallplans === true && <>

                      <tr >
                        <td>21GB Data</td>
                        <td>GHc 140/-</td>
                      </tr>

                      <tr >
                        <td>35GB Data</td>
                        <td>GHc 185/-</td>
                      </tr>


                      <tr >
                        <td>45GB Data</td>
                        <td>GHc 210/-</td>
                      </tr>


                      <tr >
                        <td>75GB Data</td>
                        <td>GHc 210/-</td>
                      </tr>

                      <tr >
                        <td>100GB Data</td>
                        <td>GHc 320/-</td>
                      </tr>
                      <tr >
                        <td>150GB Data</td>
                        <td>GHc 450/-</td>
                      </tr>
                      <tr >
                        <td>180GB Data</td>
                        <td>GHc 540/-</td>
                      </tr>


                    </>}

                  </tbody>
                </table>

              {showallplans === false && <>


                <h5 className='readmore' onClick={() => { setshowallplans(true) }} style={{ margin: "0", width: '100%', display: 'flex', justifyContent: 'end', textAlign: 'justify', color: 'black' }}>
                  Read More.
                </h5>

              </>}
              {showallplans === true && <>

                <h5 className='readmore' onClick={() => { setshowallplans(false) }} style={{ margin: "0", width: '100%', display: 'flex', justifyContent: 'end', textAlign: 'justify', color: 'black' }}>
                  Read Less.
                </h5>
              </>}
              <div className='hovar' onClick={()=>navigate('/dataplanspdf')} style={{ width: '100%', display: 'flex', justifyContent: 'flex-start', paddingLeft: '17px', textAlign: 'justify', color: 'black', marginBottom: '50px' }}>
          CLICK HERE to see Data Plans PDF
        </div>









            </div>


          </>}



          {productdetails === 3 && <>

            <div className='productdetails'>
            <ProductImageGallery
                    images={[
                      '/routers pictures/MF982/New folder/1.jpg',
                     
                      '/routers pictures/MF982/New folder/3.jpg',
                    
                      '/routers pictures/MF982/New folder/4.jpg',
                      '/routers pictures/MF982/New folder/5.jpg',
                      '/routers pictures/MF982/New folder/6.jpg',
                    
                      // Add more image URLs here
                    ]}
                  />
              <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', textAlign: 'left' }} className='prodetailsright'>



                <h2 style={{ paddingLeft: "15px", alignSelf: "baseline", marginTop: '0', color: 'black' }}>
                  DW SMART WIFI – MT25</h2>


                <div style={{ width: '90%', display: 'flex', justifyContent: 'flex-start', paddingLeft: '17px', textAlign: 'justify', color: 'black' }}>
                  <div style={{ width: '90%', display: 'flex', justifyContent: 'flex-start', paddingLeft: '17px', textAlign: 'justify', color: 'black', alignItems: 'center', marginTop: '20px' }}>
                    Rating:

                    <div class="rating">
                      <span class="star">&#9733;</span>
                      <span class="star">&#9733;</span>
                      <span class="star">&#9733;</span>
                      <span class="star">&#9733;</span>
                      <span class="star">&#9733;</span>
                    </div>


                  </div>
                </div>
                <h5 style={{ width: '100%', display: 'flex', marginBottom: '0', justifyContent: 'flex-start', paddingLeft: '17px', textAlign: 'justify', color: 'black' }}>
                  Get it now from  <p style={{ fontWeight: "bolder", padding: "0 1px", color: 'red' }}> GHc 739.00 </p> (One-time Activation + 1st Month subscription)
                </h5>





                {Specifications === true && <>
                  <div style={{ display: 'flex', gap: '30px' }}>  <h3 onClick={() => setSpecifications(false)} className='hovar' style={{ borderRadius: '5px', paddingLeft: "0px", alignSelf: "baseline", margin: '0', marginTop: '40px', color: 'black' }}>
                    Features Highlight</h3>

                    <h3 style={{ borderRadius: '5px', borderBottom: '5px solid #0587EF', paddingLeft: "0px", alignSelf: "baseline", margin: '0', marginTop: '40px', color: 'black' }}>
                      Specifications</h3></div></>}




                {Specifications === false && <>
                  <div style={{ display: 'flex', gap: '30px' }}>  <h3 style={{ borderRadius: '5px', borderBottom: '5px solid #0587EF', paddingLeft: "0px", alignSelf: "baseline", margin: '0', marginTop: '40px', color: 'black' }}>
                    Features Highlight</h3>

                    <h3 onClick={() => setSpecifications(true)} className='hovar' style={{ borderRadius: '5px', paddingLeft: "0px", alignSelf: "baseline", margin: '0', marginTop: '40px', color: 'black' }}>
                      Specifications</h3></div></>}


                {Specifications === false && <>
                  <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-start', paddingLeft: '0px', textAlign: 'justify', color: 'black' }}>
                    Experience the pinnacle of mobile broadband with our 4G MiFi. Speeds up to 300Mbps* (Device Theoretical Max) redefine on-the-go connectivity. No landline required – just charge, and you're ready to stream, video call, and browse wherever you are. Elevate your mobile experience today.  </div></>}

                {Specifications === true && <>
                  <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-start', paddingLeft: '0px', textAlign: 'justify', color: 'black' }}>

                    <ul>
                      <li>Mobile Hotspot</li>
                      <li>4G LTE</li>
                      <li>Upto 15 users, 50m distance</li>
                      <li>150mbps download speed</li>
                      <li>3000mah Battery</li>
                      <li>Supports USB interface charging</li>
                      <li>Multiband</li>
                      <li>Global Operators Compatibility</li>
                      <li>Smart Security Management</li>

                    </ul>
                  </div></>}




                <button onClick={() => {


                  setSelselectplanebox(true)

                  // setproductselected("DW SMART MIFI – MT10")

                  setproductselected(
                    {
                      "productname": "DW SMART MIFI – MT25",
                      "productPrice": "800.00  (One-time Activation + 1st Month subscription)",
                      "productimg": "/routers pictures/MF982/New folder/1.jpg"
                    }
                  )



                }}




                  style={{ width: '100%', height: '50px', fontSize: '2rem', fontWeight: "bold", backgroundColor: "#0587EF", color: 'white', borderRadius: '5px' }}>Order Now </button>

              </div>


            </div>




            <div className='productdetails3'>


              <h3 style={{ borderRadius: '5px', backgroundColor: '#0587EF', padding: "5px", alignSelf: "baseline", margin: '0', marginTop: '40px', color: 'White' }}>
                NETWORKS                       </h3>

              <div style={{ width: '90%', display: 'flex', justifyContent: 'flex-start', textAlign: 'justify', color: 'black' }}>
                Available on.
              </div>


              <table className='data-plans-table'>

                <tbody>

                   <tr style={{display:'flex'}} >

                    <td style={{display:'flex',width:'33%',alignItems:'center',justifyContent:'center'}}> <img alt='' src='/LOGO (2).jpg' width={"40px"}/> MTN </td>
                    <td style={{display:'flex',width:'33%',alignItems:'center',justifyContent:'center'}}> <img alt='' src='/LOGO (1).jpg' width={"40px"}/>AT Ghana</td>
                    <td style={{display:'flex',width:'33%',alignItems:'center',justifyContent:'center'}}> <img alt='' src='/LOGO (3).jpg' width={"40px"}/>Telecel</td>

                  </tr>

                </tbody>
              </table>





              <h3 style={{ borderRadius: '5px', backgroundColor: '#0587EF', padding: "5px", alignSelf: "baseline", margin: '0', marginTop: '40px', color: 'White' }}>
                No fluff. Just the good stuff.</h3>

              <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-start', textAlign: 'justify', color: 'black' }}>
                All plans from Data Warehouse Gh come with great features as standard. We provide coverage that you can trust in your area. We cover 98% of the Ghana’s population. We provide competitively generous internet data up to unlimited subject to fair usage & open data policies.
              </div>



                <h3 style={{ borderRadius: '5px', backgroundColor: '#0587EF', padding: "5px", alignSelf: "baseline", margin: '0', marginTop: '40px', color: 'White' }}>
                 Monthly Data Plans                        </h3>
                {/* <div style={{ width: '90%', display: 'flex', justifyContent: 'flex-start', textAlign: 'justify', color: 'black' }}>
                  Contract Duration (1-12) months.
                </div> */}


                <table className='data-plans-table'>
                  <thead>
                    <tr>
                      <th>Data Plan</th>
                      <th>Price</th>
                     
                    </tr>
                  </thead>
                  <tbody>

                    <tr >
                      <td>10.5GB Data</td>
                      <td>GHc 75/-</td>
                     

                    </tr>


                    <tr >
                      <td>12.5GB Data</td>
                      <td>GHc 85/-</td>
                     
                    </tr>


                    <tr >
                      <td>15GB Data</td>
                      <td>GHc 100/-</td>
                     
                     
                    </tr>

                    {showallplans === true && <>

                      <tr >
                        <td>21GB Data</td>
                        <td>GHc 140/-</td>
                      </tr>

                      <tr >
                        <td>35GB Data</td>
                        <td>GHc 185/-</td>
                      </tr>


                      <tr >
                        <td>45GB Data</td>
                        <td>GHc 210/-</td>
                      </tr>


                      <tr >
                        <td>75GB Data</td>
                        <td>GHc 210/-</td>
                      </tr>

                      <tr >
                        <td>100GB Data</td>
                        <td>GHc 320/-</td>
                      </tr>
                      <tr >
                        <td>150GB Data</td>
                        <td>GHc 450/-</td>
                      </tr>
                      <tr >
                        <td>180GB Data</td>
                        <td>GHc 540/-</td>
                      </tr>


                    </>}

                  </tbody>
                </table>

              {showallplans === false && <>


                <h5 className='readmore' onClick={() => { setshowallplans(true) }} style={{ margin: "0", width: '100%', display: 'flex', justifyContent: 'end', textAlign: 'justify', color: 'black' }}>
                  Read More.
                </h5>

              </>}
              {showallplans === true && <>

                <h5 className='readmore' onClick={() => { setshowallplans(false) }} style={{ margin: "0", width: '100%', display: 'flex', justifyContent: 'end', textAlign: 'justify', color: 'black' }}>
                  Read Less.
                </h5>
              </>}

              <div className='hovar' onClick={()=>navigate('/dataplanspdf')} style={{ width: '100%', display: 'flex', justifyContent: 'flex-start', paddingLeft: '17px', textAlign: 'justify', color: 'black', marginBottom: '50px' }}>
          CLICK HERE to see Data Plans PDF
        </div>


            </div>


          </>}






          {productdetails === 4 && <>

            <div className='productdetails'>
            <ProductImageGallery
                    images={[
                      '/routers pictures/MT30/New folder/1.jpg',
                     
                      '/routers pictures/MT30/New folder/2.jpg',
                    
                      '/routers pictures/MT30/New folder/3.jpg',
                      '/routers pictures/MT30/New folder/4.png',
                     
                      '/routers pictures/MT30/New folder/5.jpg',
                      '/routers pictures/MT30/New folder/6.jpg',
                     
                     

                    
                      // Add more image URLs here
                    ]}
                  />
              <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', textAlign: 'left' }} className='prodetailsright'>



                <h2 style={{ paddingLeft: "15px", alignSelf: "baseline", marginTop: '0', color: 'black' }}>
                  DW SMART WIFI – MT30</h2>


                <div style={{ width: '90%', display: 'flex', justifyContent: 'flex-start', paddingLeft: '17px', textAlign: 'justify', color: 'black' }}>
                  <div style={{ width: '90%', display: 'flex', justifyContent: 'flex-start', paddingLeft: '17px', textAlign: 'justify', color: 'black', alignItems: 'center', marginTop: '20px' }}>
                    Rating:

                    <div class="rating">
                      <span class="star">&#9733;</span>
                      <span class="star">&#9733;</span>
                      <span class="star">&#9733;</span>
                      <span class="star">&#9733;</span>
                      <span class="star">&#9733;</span>
                    </div>


                  </div>
                </div>
                <h5 style={{ width: '100%', display: 'flex', marginBottom: '0', justifyContent: 'flex-start', paddingLeft: '17px', textAlign: 'justify', color: 'black' }}>
                  Get it now from  <p style={{ fontWeight: "bolder", padding: "0 1px", color: 'red' }}> GHc 759.00 </p> (One-time Activation + 1st Month subscription)
                </h5>





                {Specifications === true && <>
                  <div style={{ display: 'flex', gap: '30px' }}>  <h3 onClick={() => setSpecifications(false)} className='hovar' style={{ borderRadius: '5px', paddingLeft: "0px", alignSelf: "baseline", margin: '0', marginTop: '40px', color: 'black' }}>
                    Features Highlight</h3>

                    <h3 style={{ borderRadius: '5px', borderBottom: '5px solid #0587EF', paddingLeft: "0px", alignSelf: "baseline", margin: '0', marginTop: '40px', color: 'black' }}>
                      Specifications</h3></div></>}




                {Specifications === false && <>
                  <div style={{ display: 'flex', gap: '30px' }}>  <h3 style={{ borderRadius: '5px', borderBottom: '5px solid #0587EF', paddingLeft: "0px", alignSelf: "baseline", margin: '0', marginTop: '40px', color: 'black' }}>
                    Features Highlight</h3>

                    <h3 onClick={() => setSpecifications(true)} className='hovar' style={{ borderRadius: '5px', paddingLeft: "0px", alignSelf: "baseline", margin: '0', marginTop: '40px', color: 'black' }}>
                      Specifications</h3></div></>}


                {Specifications === false && <>
                  <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-start', paddingLeft: '0px', textAlign: 'justify', color: 'black' }}>
                    Introducing the ultimate solution for mobile broadband – our 4G MiFi. With speeds up to 300Mbps* (Device Theoretical Max), experience unmatched connectivity without the need for a landline. Whether you're working remotely, streaming content, making video calls, or simply browsing, our portable Wi-Fi has you covered. Charge it up and stay connected wherever you go, whenever you want.   </div></>}

                {Specifications === true && <>
                  <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-start', paddingLeft: '0px', textAlign: 'justify', color: 'black' }}>

                    <ul>
                      <li>Mobile Hotspot</li>
                      <li>4G LTE</li>
                      <li>Upto 15 users, 50m distance</li>
                      <li>150mbps download speed</li>
                      <li>4000mah Battery</li>
                      <li>Supports USB interface charging</li>
                      <li>Multiband</li>
                      <li>Global Operators Compatibility</li>
                      <li>Smart Security Management</li>
                      <li>1 Lan Port </li>
                      <li>USB Type – C fast Charging</li>
                      <li>Upto 300mbps</li>

                    </ul>
                  </div></>}




                <button onClick={() => {


                  setSelselectplanebox(true)

                  // setproductselected("DW SMART MIFI – MT10")

                  setproductselected(
                    {
                      "productname": "DW SMART MIFI – MT30",
                      "productPrice": "850.00  (One-time Activation + 1st Month subscription)",
                      "productimg": "/routers pictures/MT30/New folder/1.jpg"
                    }
                  )



                }}




                  style={{ width: '100%', height: '50px', fontSize: '2rem', fontWeight: "bold", backgroundColor: "#0587EF", color: 'white', borderRadius: '5px' }}>Order Now </button>

              </div>


            </div>




            <div className='productdetails3'>


              <h3 style={{ borderRadius: '5px', backgroundColor: '#0587EF', padding: "5px", alignSelf: "baseline", margin: '0', marginTop: '40px', color: 'White' }}>
                NETWORKS                       </h3>

              <div style={{ width: '90%', display: 'flex', justifyContent: 'flex-start', textAlign: 'justify', color: 'black' }}>
                Available on.
              </div>


              <table className='data-plans-table'>

                <tbody>

                   <tr style={{display:'flex'}} >

                    <td style={{display:'flex',width:'33%',alignItems:'center',justifyContent:'center'}}> <img alt='' src='/LOGO (2).jpg' width={"40px"}/> MTN </td>
                    <td style={{display:'flex',width:'33%',alignItems:'center',justifyContent:'center'}}> <img alt='' src='/LOGO (1).jpg' width={"40px"}/>AT Ghana</td>
                    <td style={{display:'flex',width:'33%',alignItems:'center',justifyContent:'center'}}> <img alt='' src='/LOGO (3).jpg' width={"40px"}/>Telecel</td>

                  </tr>

                </tbody>
              </table>





              <h3 style={{ borderRadius: '5px', backgroundColor: '#0587EF', padding: "5px", alignSelf: "baseline", margin: '0', marginTop: '40px', color: 'White' }}>
                No fluff. Just the good stuff.</h3>

              <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-start', textAlign: 'justify', color: 'black' }}>
                All plans from Data Warehouse Gh come with great features as standard. We provide coverage that you can trust in your area. We cover 98% of the Ghana’s population. We provide competitively generous internet data up to unlimited subject to fair usage & open data policies.
              </div>



                <h3 style={{ borderRadius: '5px', backgroundColor: '#0587EF', padding: "5px", alignSelf: "baseline", margin: '0', marginTop: '40px', color: 'White' }}>
                 Monthly Data Plans                        </h3>
                {/* <div style={{ width: '90%', display: 'flex', justifyContent: 'flex-start', textAlign: 'justify', color: 'black' }}>
                  Contract Duration (1-12) months.
                </div> */}


                <table className='data-plans-table'>
                  <thead>
                    <tr>
                      <th>Data Plan</th>
                      <th>Price</th>
                     
                    </tr>
                  </thead>
                  <tbody>

                    <tr >
                      <td>10.5GB Data</td>
                      <td>GHc 75/-</td>
                     

                    </tr>


                    <tr >
                      <td>12.5GB Data</td>
                      <td>GHc 85/-</td>
                     
                    </tr>


                    <tr >
                      <td>15GB Data</td>
                      <td>GHc 100/-</td>
                     
                     
                    </tr>

                    {showallplans === true && <>

                      <tr >
                        <td>21GB Data</td>
                        <td>GHc 140/-</td>
                      </tr>

                      <tr >
                        <td>35GB Data</td>
                        <td>GHc 185/-</td>
                      </tr>


                      <tr >
                        <td>45GB Data</td>
                        <td>GHc 210/-</td>
                      </tr>


                      <tr >
                        <td>75GB Data</td>
                        <td>GHc 210/-</td>
                      </tr>

                      <tr >
                        <td>100GB Data</td>
                        <td>GHc 320/-</td>
                      </tr>
                      <tr >
                        <td>150GB Data</td>
                        <td>GHc 450/-</td>
                      </tr>
                      <tr >
                        <td>180GB Data</td>
                        <td>GHc 540/-</td>
                      </tr>


                    </>}

                  </tbody>
                </table>

              {showallplans === false && <>


                <h5 className='readmore' onClick={() => { setshowallplans(true) }} style={{ margin: "0", width: '100%', display: 'flex', justifyContent: 'end', textAlign: 'justify', color: 'black' }}>
                  Read More.
                </h5>

              </>}
              {showallplans === true && <>

                <h5 className='readmore' onClick={() => { setshowallplans(false) }} style={{ margin: "0", width: '100%', display: 'flex', justifyContent: 'end', textAlign: 'justify', color: 'black' }}>
                  Read Less.
                </h5>
              </>}
              <div className='hovar' onClick={()=>navigate('/dataplanspdf')} style={{ width: '100%', display: 'flex', justifyContent: 'flex-start', paddingLeft: '17px', textAlign: 'justify', color: 'black', marginBottom: '50px' }}>
          CLICK HERE to see Data Plans PDF
        </div>


            </div>


          </>}

















        </>}





      </div>










      {selselectplanebox === true && <>

        <div className='mifimifiselectplanbagmain'>
          <div className='mifiselectplanbag' onClick={() => setSelselectplanebox(false)}></div>
          <div className='mifiselectplanbag2'>

            <h3 style={{ borderRadius: '5px', backgroundColor: '#0587EF', padding: "5px", alignSelf: "baseline", margin: '0', color: 'White' }}>
              Kindly select a data plan to proceed further.                     </h3>
              <div style={{ width: '90%', display: 'flex', justifyContent: 'flex-start', textAlign: 'justify', color: 'black' }}>
                Contract Duration one month.
              </div>


              <table className='data-plans-table'>
                <thead>
                  <tr>
                    
                    <th>Data</th>
                    <th>Price</th>
                  
                   
                  </tr>
                </thead>
                <tbody>

                  <tr >
                    <th>10.5GB Data</th>
                    <td
                      className={selectedCell === '10.5GB Data for 1 month in GHc75' ? 'selected-cell' : ''}
                      onClick={async () => {
                        await setSelectedCell('10.5GB Data for 1 month in GHc75');

                        await setproductselected({ ...productselected, selectedCell })

                      }

                      }
                    >GHc75</td>
                   

                  </tr>


                  <tr >
                    <th>12.5GB Data</th>
                    <td
                      className={selectedCell === '12.5GB Data for 1 month in GHc85' ? 'selected-cell' : ''}
                      onClick={() => setSelectedCell('12.5GB Data for 1 month in GHc85')}
                    >GHc85</td>
                 
                  </tr>


                  <tr >
                    <th>15GB Data</th>
                    <td
                      className={selectedCell === '15GB Data for 1 month in GHc100' ? 'selected-cell' : ''}
                      onClick={() => setSelectedCell('15GB Data for 1 month in GHc100')}
                    >GHc100</td>
                   
                  </tr>

                  {showallplans === true && <>

                    <tr >
                      <th>21GB Data</th>
                      <td
                        className={selectedCell === '21GB Data for 1 month in GHc140' ? 'selected-cell' : ''}
                        onClick={() => setSelectedCell('21GB Data for 1 month in GHc140')}
                      >GHc140</td>
                     
                    </tr>

                    <tr >
                      <th>35GB Data</th>
                      <td
                        className={selectedCell === '35GB Data for 1 month in GHc185' ? 'selected-cell' : ''}
                        onClick={() => setSelectedCell('35GB Data for 1 month in GHc185')}
                      >GHc185</td>
                     
                    </tr>


                    <tr >
                      <th>45GB Data</th>
                      <td
                        className={selectedCell === '45GB Data for 1 month in GHc210' ? 'selected-cell' : ''}
                        onClick={() => setSelectedCell('45GB Data for 1 month in GHc210')}
                      >GHc210</td>
                     
                    </tr>


                    <tr >
                      <th>75GB Data</th>
                      <td
                        className={selectedCell === '75GB Data for 1 month in GHc275' ? 'selected-cell' : ''}
                        onClick={() => setSelectedCell('75GB Data for 1 month in GHc275')}
                      >GHc275</td>
                     
                    </tr>

                    <tr >
                      <th>100GB Data</th>
                      <td
                        className={selectedCell === '100GB Data for 1 month in GHc320' ? 'selected-cell' : ''}
                        onClick={() => setSelectedCell('100GB Data for 1 month in GHc320')}
                      >GHc320</td>
                    
                    </tr>

                    <tr >
                      <th>150GB Data</th>
                      <td
                        className={selectedCell === '150GB Data for 1 month in GHc450' ? 'selected-cell' : ''}
                        onClick={() => setSelectedCell('150GB Data for 1 month in GHc450')}
                      >GHc450</td>
                    
                    </tr>

                    <tr >
                      <th>180GB Data</th>
                      <td
                        className={selectedCell === '180GB Data for 1 month in GHc540' ? 'selected-cell' : ''}
                        onClick={() => setSelectedCell('180GB Data for 1 month in GHc540')}
                      >GHc540</td>
                    
                    </tr>



                  </>}

                </tbody>
              </table>

            {showallplans === false && <>


              <h5 className='readmore' onClick={() => { setshowallplans(true) }} style={{ margin: "0", width: '100%', display: 'flex', justifyContent: 'end', textAlign: 'justify', color: 'black' }}>
                Read More.
              </h5>

            </>}
            {showallplans === true && <>

              <h5 className='readmore' onClick={() => { setshowallplans(false) }} style={{ margin: "0", width: '100%', display: 'flex', justifyContent: 'end', textAlign: 'justify', color: 'black' }}>
                Read Less.
              </h5>
            </>}



            <div className='buttoonn'>
              <button onClick={() => {

                setSelselectplanebox(false)
                setSelectedCell(null)

              }}>Cancel</button>
              <button
                onClick={async (e) => {

                  // await  console.log(productselected.productname);
                 
                 
                 
                 
                  const newProduct = {
                    "productname": productselected.productname,
                    "productprice": productselected.productPrice,
                    "productimg": productselected.productimg,
                    "dataplane": selectedCell
                  };

                  


                  const newupdateddata = [...cookieproduct, newProduct]

                 

                  // await console.log(newProduct)


                  const updatedProductJSON = JSON.stringify(newupdateddata);

                  await Cookies.set('Product', updatedProductJSON, { expires: 7 });

                  await navigate("/cart")

                }}


              >Continue</button>
            </div>



          </div>



        </div>

      </>}
    </div>
  )
}

export default Mifis






