import React from 'react'
import Navbar from "../../components/navbar"
import Comingsoon from '../../components/comingsoon'

function TabletswithInternet() {
  return (
    <div>
      
    <div style={{ marginTop: '110px' }}></div>


<Navbar/>
    
    
    
    
  <Comingsoon/></div>
  )
}

export default TabletswithInternet