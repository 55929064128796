import React from 'react'
import "../css/faq.css"
import { NavLink } from 'react-router-dom';
import Navbar from '../components/navbar';

function privacypolicy() {
  return (
    <div>

      <div style={{ marginTop: '110px' }}></div>





<Navbar/>

      <div className='mainpadding' style={{textAlign:'left'}}>



        <h1 style={{ borderLeft: "5px solid blue", borderRadius: '5px', color: "rgb(70, 70, 70)", paddingLeft: "10px", alignSelf: "baseline",margin:'0' }}>
          Privacy Policy</h1>



        <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-start', paddingLeft: '30px', textAlign: 'justify', color: 'gray' }}>
          Your information and your privacy are important — to you and to us. This policy explains how we use your information and how we keep it safe. Most importantly, it explains the choices you can make at any time about how your information is used.

        </div>









        <h3 style={{ borderRadius: '5px', paddingLeft: "15px", alignSelf: "baseline", margin: '0',marginTop:'30px', color: 'gray',marginTop:'50px' }}> When this Policy applies</h3> 
        <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-start', paddingLeft: '30px', textAlign: 'justify', color: 'gray' }}>
        This Privacy Policy (“Policy”) covers the information generated when you use or subscribe to DATA WAREHOUSE GH products, services, apps, websites, or networks to which this policy is linked. In the policy, we call them “Products or Services” for short. They include smart devices, voice, data, video, entertainment, advertising, internet and other products, services, and apps.

        <br/>   This Policy applies to you and anyone who uses our Products or Services under your account, except where we identify for you that separate privacy policies or terms and conditions apply. You are responsible for making sure all users under your account understand and agree to this Policy.

        <br/>    Here are special circumstances where this Policy may not apply, or may apply in addition to other policies:

        <br/>   Some of our Products or Services may be covered by their own privacy policies or additional privacy terms and conditions.
        <br/>   Some of our affiliates have their own privacy policies that apply to data they collect from products, services and apps they provide. Any data collected subject to this Policy that is shared with those affiliates will still be protected consistent with this Policy.
        <br/>   Some areas both inside and outside of the United States - for example California and the European Union - require us to adopt different policy terms and commitments in accordance with local laws.
        <br/>   In certain cases, when you’re using your DATA WAREHOUSE GH Products or Services, other companies may be collecting information, so that your information may be covered by this Policy and other privacy policies at the same time. Here are some examples: if you purchase one of our Products or Services from a retailer; if you use our services to connect to a social networking service or another company’s Wi-Fi network; or if you provide your information to another company through a co-branded website, app or service controlled by the other company. In those cases, any information you provide to those companies may be subject to just their policy, or subject to both their policy and ours.
        <br/>   If you are a DATA WAREHOUSE GH business customer, we may have written Product or Service agreements that contain specific provisions about confidentiality, security or handling of information. When one of those agreements differs from or conflicts with this Policy, the terms of those agreements will apply instead.
        </div>
        <h3 style={{ borderRadius: '5px', paddingLeft: "15px", alignSelf: "baseline", margin: '0',marginTop:'30px', color: 'gray' }}> The information we collect</h3> 
        <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-start', paddingLeft: '30px', textAlign: 'justify', color: 'gray' }}>
        We collect information about you and how you’re using our Products or Services along with information about your devices and equipment. This may include performance information, along with web browsing, location, and TV viewing information.
        </div>
        <h3 style={{ borderRadius: '5px', paddingLeft: "15px", alignSelf: "baseline", margin: '0',marginTop:'30px', color: 'gray' }}>How we collect your information</h3> 
        <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-start', paddingLeft: '30px', textAlign: 'justify', color: 'gray' }}>
        We collect your information in 3 ways:

        <br/>    You give it to us when you make a purchase, set up an account or otherwise directly communicate with us.
        <br/>   We automatically get it when you use, or your device uses, our Products or Services. For example, we use network tools to collect information like call and text records and the web browsing information we describe in this Policy.
        <br/>   We get it from outside sources like credit reports, marketing mailing lists, and commercially available geographic and demographic information, along with other available information, such as public posts to social networking sites.
        <br/>   How we use your information
        <br/>  We use your information to power our services and to improve your experiences. We use your information to provide, support, improve, protect, analyse, and bill for our products, service and network; to communicate with you about your service, products or apps; to market our services; to detect and avoid fraud; for advertising; and for research purposes.
        </div>
        <h3 style={{ borderRadius: '5px', paddingLeft: "15px", alignSelf: "baseline", margin: '0',marginTop:'30px', color: 'gray' }}> How we share your information</h3> 
        <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-start', paddingLeft: '30px', textAlign: 'justify', color: 'gray' }}>
        We share it with your permission.
        <br/>   We share it across DATA WAREHOUSE GH companies.
        <br/>   We share it with non-DATA WAREHOUSE GH companies or entities as explained in this Policy. For more details about how, your information may be shared for advertising and marketing see Privacy Choices and Controls.
        </div>
        <h3 style={{ borderRadius: '5px', paddingLeft: "15px", alignSelf: "baseline", margin: '0',marginTop:'30px', color: 'gray' }}>Your Privacy Choices and Controls</h3> 
        <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-start', paddingLeft: '30px', textAlign: 'justify', color: 'gray' }}>
        You can Manage Your Privacy Choices about how we contact you and how we use or share your information. You also have choices about how certain third parties and advertisers use your information, including how we use and share your information for advertising, marketing, and analytics. Click “Learn more” for more detail.
        </div>
        <h3 style={{ borderRadius: '5px', paddingLeft: "15px", alignSelf: "baseline", margin: '0',marginTop:'30px', color: 'gray' }}> Security</h3> 
        <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-start', paddingLeft: '30px', textAlign: 'justify', color: 'gray' }}>
        We work hard to safeguard your data using a range of technological and organizational security controls.

        <br/>    We maintain and protect the security of computer storage and network equipment, and we use security procedures that require employees to authenticate themselves to access sensitive data. We also limit access to personal information only to those with jobs requiring such access. We require callers and online users to authenticate themselves before providing account information.

        <br/>    No security measures are perfect, however. We can’t guarantee that your information will never be disclosed in a manner inconsistent with this Policy. If a breach were to occur, we will notify you as required by applicable law.

        <br/>    Data storage, transfer, retention, and accuracy

        <br/>   We take steps to ensure that data is processed according to this Policy and to the requirements of applicable law of your country and of the additional countries where the data is subsequently processed.
        </div>
        <h3 style={{ borderRadius: '5px', paddingLeft: "15px", alignSelf: "baseline", margin: '0',marginTop:'30px', color: 'gray' }}> Other privacy information</h3> 
        <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-start', paddingLeft: '30px', textAlign: 'justify', color: 'gray' }}>
        Changes in ownership or to the Policy
        <br/>    Information about our customers and users, including information that identifies you personally, may be shared and transferred as part of any merger, acquisition, sale of company assets or transition of service to another provider. This also applies in the unlikely event of an insolvency, bankruptcy, or receivership.

        <br/>    We may update this Policy as necessary to reflect changes we make and to satisfy legal requirements. We’ll post a prominent notice of material changes on our websites. We’ll give you reasonable notice before any material changes take effect.
        </div>
        <h3 style={{ borderRadius: '5px', paddingLeft: "15px", alignSelf: "baseline", margin: '0',marginTop:'30px', color: 'gray' }}> Information specific to Children</h3> 
        <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-start', paddingLeft: '30px', textAlign: 'justify', color: 'gray' }}>
        We don’t knowingly collect personally identifying information from anyone under the age of 13 unless we first get permission from the child's parent or legal guardian. Unless we have parental consent, we will not contact a child under the age of 13 for marketing purposes. Information about our collection, use or sharing of, and your choices and controls with respect to such information, is found in Learn more.
        </div>
        <h3 style={{ borderRadius: '5px', paddingLeft: "15px", alignSelf: "baseline", margin: '0',marginTop:'30px', color: 'gray' }}>Customer Proprietary Network Information (CPNI)</h3> 
        <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-start', paddingLeft: '30px', textAlign: 'justify', color: 'gray' }}>
        CPNI is information about your telecommunications and VoIP (internet phone) services from us, including what plans you subscribe to, how you use these services and billing information. (Your telephone number, name and address are not considered CPNI.) We use CPNI internally and share it outside DATA WAREHOUSE GH only when conforming with applicable regulations and orders of the Federal Communications Commission.
        </div>
        <h3 style={{ borderRadius: '5px', paddingLeft: "15px", alignSelf: "baseline", margin: '0',marginTop:'30px', color: 'gray' }}> How to contact us about this policy</h3> 
        <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-start', paddingLeft: '30px', textAlign: 'justify', color: 'gray' }}>
        Contact us at either of these addresses for any questions about this Policy.

        <br/>       E-mail us at privacypolicy@datawarehousegh.com For questions not related to privacy click on the "Contact Us" link at the bottom of any www.datawarehousegh.com page. You also can access your online account from the upper right-hand corner of our home page at Data Warehouse GH.com.gh for additional service options.

        <br/>     You also have the option of filing a complaint with the FTC Bureau of Consumer Protection, using an online form. Other rights and remedies also may be available to you under federal or other applicable laws.

        <br/>     If you’re a satellite TV subscriber, you also have certain rights under Section 338(i) of the Federal Communications Act.

    <br/>    Customer service contact numbers can be found at www.datawarehousegh.com.


        </div>





































        <h1 style={{ borderLeft: "5px solid blue", borderRadius: '5px', color: "rgb(70, 70, 70)", paddingLeft: "10px", alignSelf: "baseline" }}>
          About Us</h1>
        <h3 style={{ borderRadius: '5px', paddingLeft: "15px", alignSelf: "baseline", margin: '0',marginTop:'30px', color: 'gray' }}>
          Who we are – Investing in people and connecting the nation</h3>
        <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-start', paddingLeft: '30px', textAlign: 'justify', color: 'gray' }}>A company that connects people to people, people to things and things to things - Data Warehouse Gh is a trading
          name for DWH Ghana limited, an Afroeuro Capital Ghana company part of Bethwell Holdings International. Data
          Warehouse has been established to bring new opportunities to people across Ghana and beyond with a focus on
          bringing together award-winning connectivity and amazing entertainment under one roof from the major ISPs in
          Ghana to our customers giving them more choice, more freedom and smart internet solutions.</div>


        <h3 style={{ borderRadius: '5px', paddingLeft: "15px", alignSelf: "baseline", margin: '0', marginTop: '10px', color: 'gray' }}>
          Sustainability and Responsible Business</h3>
        <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-start', paddingLeft: '30px', textAlign: 'justify', color: 'gray' }}>
          Part of Data Warehouse Gh’s vision is to create an inclusive, green and connected future for its customers and the
          communities. To this end, we will harness the core strengths of our partners and marry them with powerful
          technology, digital expertise, passionate people, and steadfast commitment to operate a green business creating a
          wider positive ripple effect in the fight against climate change.<br />
          We’re committed to building a business that represents the diverse communities we serve, while creating an
          environment where our people genuinely feel they can bring their whole selves to work and where our customers
          “own” our mission.

        </div>

        <h3 style={{ borderRadius: '5px', paddingLeft: "15px", alignSelf: "baseline", margin: '0', marginTop: '10px', color: 'gray' }}>
          <NavLink to='/about' style={{ textDecoration: 'none', color: 'gray' }}>Read More</NavLink></h3>





      </div>










    </div>
  )
}
export default privacypolicy