import React from 'react'
import Navbar from '../components/navbar'

function Dataplanspdf() {
    return (<>
        <div style={{ marginTop: '110px' }}></div>
  
      <Navbar/>
  
      
  
      <iframe
          title="PDF Viewer"
          src="DW NETWORK PRICE LIST.pdf" // Replace with the path or URL of your PDF file
          width="100%"
           height="1000vh" // Adjust the height as needed
        ></iframe>
      </>
   )
  }
  
export default Dataplanspdf