import React from 'react'
import Navbar from "../../components/navbar"


function CommercialPublicPlaces() {
  return (
    <div>

      <div style={{ marginTop: '110px' }}></div>


      <Navbar />


      <div className='mainpadding'>
        <h1 style={{ borderLeft: "5px solid #0587EF", margin: '0', textAlign: 'left', borderRadius: '5px', color: "rgb(70, 70, 70)", paddingLeft: "10px", alignSelf: "baseline" }}>
          Internet @Public Places</h1>
          <div className='divwithimages'>
          <div>
        <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-start', paddingLeft: '17px', textAlign: 'justify', color: 'black', marginBottom: '50px' }}>
          Data Warehouse Gh Public Wi-Fi -nicknamed (Green Wifi) is coming soon to you’re a community and to a public place near you. Our goal is to provide reliable, efficient and value for money internet connection which can be confidently relied upon by individuals needing an internet connection while out and about. Soon, the GREEN WIFI will be common in restaurants, hotels, mosques, churches, and other public places.
        </div>


        <h2 style={{ borderRadius: '5px', width: '100%', paddingLeft: "15px", textAlign: 'left', margin: '0', color: 'black', alignSelf: "baseline" }}>
          Commercial Public Places</h2>



        <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-start', paddingLeft: '17px', textAlign: 'justify', color: 'black', marginBottom: '50px' }}>
          Data Warehouse’s GREEN WIFI provides and makes available fast, reliable and valued for money internet at public place which includes, but are not limited to, stores, banks, eating establishments, bars, hotels, in buses, transit terminals, theatres and auditoriums, enclosed sports arenas, convention centres, museums, galleries, hospitals and other health care facilities of any kind (including clinics, ...). We are actively looking to partner owners of these facility for deploy our state-of-the-art technology in the above place. We also are inviting the public to get involve – if you know of any public places within your community which might benefit from our GREEN WIFI, please contact us.
        </div>


        </div>
          <img alt='' src='/IANMGES/InternetATPublicplaces.jpg' />

        </div>



      </div>






    </div>
  )
}

export default CommercialPublicPlaces