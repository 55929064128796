import React from 'react'
import Navbar from "../../components/navbar"


function Mosques() {
  return (
    <div>

      <div style={{ marginTop: '110px' }}></div>


      <Navbar />




      <div className='mainpadding'>
        <h1 style={{ borderLeft: "5px solid #0587EF", margin: '0', textAlign: 'left', borderRadius: '5px', color: "rgb(70, 70, 70)", paddingLeft: "10px", alignSelf: "baseline" }}>
          Internet @Public Places</h1>
          <div className='divwithimages'>
          <div>
        <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-start', paddingLeft: '17px', textAlign: 'justify', color: 'black', marginBottom: '50px' }}>
          Data Warehouse Gh Public Wi-Fi -nicknamed (Green Wifi) is coming soon to you’re a community and to a public place near you. Our goal is to provide reliable, efficient and value for money internet connection which can be confidently relied upon by individuals needing an internet connection while out and about. Soon, the GREEN WIFI will be common in restaurants, hotels, mosques, churches, and other public places.
        </div>


        <h2 style={{ borderRadius: '5px', width: '100%', paddingLeft: "15px", textAlign: 'left', margin: '0', color: 'black', alignSelf: "baseline" }}>
          Mosques</h2>



        <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-start', paddingLeft: '17px', textAlign: 'justify', color: 'black', marginBottom: '50px' }}>

          Mosques provide a space for members to connect, share ideas, and discuss topics relevant to their faith. Building an online community can be very rewarding to the spread of Islam.
     <br/>  <br/>   To begin with, the Internet gives unparalleled possibilities to spread the message of Allah and his message for humanity.
     <br/><br/>      Please contact us for more information.
        </div>


        </div>
          <img alt='' src='/IANMGES/INTERNET-MOSQUE.jpg' />

        </div>


      </div>




    </div>
  )
}

export default Mosques