import React, { useState, useEffect } from 'react'
import "./../css/cart.css"
import Navbar from "../components/navbar"
import Cookies from 'js-cookie';
import axios from 'axios';
import { BsTrash } from 'react-icons/bs';

function Cart() {

  const [showcheckout, setshowcheckout] = useState(false);




  const [product, setproduct] = useState([]);
 


  const [product2, setproduct2] = useState([]);





  const [firstname, setfirstname] = useState();
  const [lastname, setlastname] = useState();
  const [District, setDistrict] = useState();
  const [Town, setTown] = useState();
  const [ShreetAddress, setShreetAddress] = useState();
  const [EcowasCardnumber, setEcowasCardnumber] = useState();
  const [City, setCity] = useState();
  const [State, setState] = useState();
  const [PostalCode, setPostalCode] = useState();
  const [PhoneNumber, setPhoneNumber] = useState();
  const [Email, setEmail] = useState();


  const [imstudent, setimstudent] = useState(false);
  const [Universityname, setUniversityname] = useState();
  const [idnumber, setidnumber] = useState();
  const [studentEmail, setstudentEmail] = useState();










  useEffect(() => {



    console.log(product)
    setproduct2(JSON.parse(Cookies.get('Product') || '[]'))


  }, [])








   const sendemail = async (e) => {
    e.preventDefault()



    const url = 'https://api.sendinblue.com/v3/smtp/email';
    const apiKey = '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'; // Replace with your actual API key






    const htmlContent = `
    <div style="  padding: 10px; background-color: #fff;">

    <h1 style=" margin: 0;">Customer Details</h1>
    <div style="padding-left: 20px;border: 1px solid gray;">
        <div style="display: flex; white-space: pre;">
            <h3 style=" width: 200px;"> full name</h3>
            <h3>: ${firstname} ${lastname}</h3>
        </div>

        ${imstudent ? `
        <div style="display: flex;">
          <h3 style="width: 200px;">He is Student</h3>
         
        </div>

        <div style="display: flex;">
          <h3 style="width: 200px;">University Name</h3>
          <h3>: ${Universityname}</h3>
        </div>

        <div style="display: flex;">
          <h3 style="width: 200px;">Student ID CARD Number</h3>
          <h3>: ${idnumber}</h3>
        </div>

        <div style="display: flex; white-space: pre;">
          <h3 style="width: 200px;">Student Email</h3>
          <h3>: ${studentEmail}</h3>
        </div>
      ` : ''}
       
        <div style="display: flex; white-space: pre;">
            <h3 style=" width: 200px;"> District</h3>
            <h3>: ${District}</h3>
        </div>
        <div style="display: flex;">
            <h3 style="width: 200px;"> Town</h3>
            <h3>: ${Town}</h3>
        </div>
      
        <div style="display: flex;">
            <h3 style=" width: 200px;">Address</h3>
            <h3>: ${ShreetAddress}</h3>
        </div>
        <div style="display: flex;">
            <h3 style="width: 200px;"> Ecowas Card number</h3>
            <h3>: ${EcowasCardnumber}</h3>
        </div>
        <div style="display: flex;">
            <h3 style="width: 200px;"> City</h3>
            <h3>: ${City}</h3>
        </div>
      
        <div style="display: flex;">
            <h3 style="width: 200px;"> State</h3>
            <h3>: ${State}</h3>
        </div>
      
        <div style="display: flex;">
            <h3 style="width: 200px;"> Digital Address</h3>
            <h3>: ${PostalCode}</h3>
        </div>
      
        <div style="display: flex;">
            <h3 style="width: 200px;"> contact number</h3>
            <h3>: ${PhoneNumber}</h3>
        </div>
        <div style="display: flex;">
            <h3 style="width: 200px;"> Email</h3>
            <h3>: ${Email}</h3>
        </div>
      
    </div>


    <h1>Product Details</h1>

    ${product2.map(product => `

    <div style="width: 100%;">

    <div style="border:1px solid gray;width: 100%;padding:5px;margin-bottom: 10px;height: fit-content;">
            <h2>
                ${product.productname} </h2>

            <div style="display: flex;">
                <div style="display: flex;align-items: center;">
                    Rating:

                    <div class="rating">
                        <span class="star">★</span>
                        <span class="star">★</span>
                        <span class="star">★</span>
                        <span class="star">☆</span>
                        <span class="star">☆</span>
                    </div>


                </div>
            </div>


            <p>

            </p>
            <h4>
                Price</h4>
            ${product.productprice}



            <div style="background-color:red;padding:10px;display:flex;justify-content: center;color:white;font-weight:bold">
                ${product.dataplane}
            </div>

        </div>
    </div>`
    ).join('')}

</div>

`;

const htmlContent2 = `
<p><strong>Order Confirmation</strong></p>
<p>Thanks for your order. One of our customer service professionals will be in touch with you shortly. Please keep an email on your e-mail. You will receive a call back from one of professional customer service professionals shortly. Kindly call our customer online on <a href="tel:0534629229">053 4629 229</a> if in an unlikely event, you have not heard from within 24hrs. You can view the status of your order or make changes to it by calling our customer service line on <a href="tel:0534629229">0534629229</a> or our officials live on <a href="http://www.datawarehousegh.com">www.datawarehousegh.com</a>.</p>

<h1>Order Summery</h1>

${product2.map(product => `

<div style="width: 100%;">

<div style="border:1px solid gray;width: 100%;padding:5px;margin-bottom: 10px;height: fit-content;">
        <h2>
            ${product.productname} </h2>

        <div style="display: flex;">
            <div style="display: flex;align-items: center;">
                Rating:

                <div class="rating">
                    <span class="star">★</span>
                    <span class="star">★</span>
                    <span class="star">★</span>
                    <span class="star">☆</span>
                    <span class="star">☆</span>
                </div>


            </div>
        </div>


        <p>

        </p>
        <h4>
            Price</h4>
        ${product.productprice}



        <div style="background-color:red;padding:10px;display:flex;justify-content: center;color:white;font-weight:bold">
            ${product.dataplane}
        </div>

    </div>
</div>`
).join('')}

<p>Thank you</p>
<p>Yours sincerely,<br>Data Warehouse Gh</p>

<p>Join our refer and friend and win scheme – make money by talking to friends. Visit <a href="http://www.datawarehousegh.com/promos">www.datawarehousegh.com/promos</a></p>



`;

console.log(Email)

    const data = {
      sender: {
        name: 'Data Warehouse',
        email: 'newonlineorder@datawarehousegh.com'
      },
      to: [
        {
          email: "datawarehousegh@gmail.com",
          // email: "aliahmed.samoo.1@gmail.com",
          name: "datawarehousegh"
        }
      ],
      subject: 'New Order',
      htmlContent: htmlContent
    };

    const headers = {
      'Content-Type': 'application/json',
      'api-key': apiKey
    };

    const data2 = {
      sender: {
        name: 'Data Warehouse',
        email: 'info@datawarehousegh.com'
      },
      to: [
        {
          email: Email,
          name: lastname
        }
      ],
      subject: 'Order Confirmation',
      htmlContent: htmlContent2
    };
   
   
   
   
    await axios.post(url, data2, { headers })
    .then(response => {



     
      console.log("email sent ")
    }

    )
    .catch(error => {

      console.log(error)
    }
    );

    await axios.post(url, data, { headers })
      .then(response => {



        const updatedProductJSON = JSON.stringify([]);
        alert("Your order has been successfully placed, and we will promptly reach out to you as soon as possible to provide further updates and assistance")
        Cookies.set('Product', updatedProductJSON, { expires: 7 });
        setshowcheckout(false);
        setproduct2(JSON.parse(Cookies.get('Product') || '[]'))
        console.log("OTP has been sent to ")
      }

      )
      .catch(error => {

        console.log(error)
      }
      );



  }




  const removeItem = async(indexToRemove) => {
    // Create a copy of the array
    const updatedItems = [...product2];

    // Use splice() method to remove the item at the specified index
   await updatedItems.splice(indexToRemove, 1);

    // Alternatively, you can use filter() method
    // const filteredItems = updatedItems.filter((item, index) => index !== indexToRemove);
    
    
    const updatedProductJSON = JSON.stringify(updatedItems);

    await Cookies.set('Product', updatedProductJSON, { expires: 7 });


    // Update state with the new array
   await setproduct2(updatedItems);
  };







  return (


    <>
      <div style={{ marginTop: '110px' }}></div>
      <Navbar />

      <div className='maincart'>

        {product2.length === 0 && <>

          <img src='https://assets-v2.lottiefiles.com/a/cbbb0d80-1185-11ee-bb81-1f8a0ee065ae/kGZag9os6n.gif' alt='' width={"30%"} />
          <h1 style={{ color: 'black' }}>Your Cart is empty </h1>
        </>}


        {product2.length !== 0 && <>
          {showcheckout === false && <>

            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', textAlign: 'left', width: '100%' }} className='prodetailsright'>
              <h3 style={{ borderLeft: "5px solid #0587EF", borderRadius: '5px', color: "rgb(70, 70, 70)", paddingLeft: "10px", alignSelf: "baseline" ,marginBottom:'50px' }}>
                Your Order Summary</h3>






              {product2.map((data,index) =>
                <>
                  <div className='productdtailscart'>


                    <div style={{ display: 'flex' }}>
                      <img className='procartimag' src={data.productimg} alt=''  />
                      <div className='divproooo'>
                        <h4 style={{ paddingLeft: "15px", alignSelf: "baseline", margin: '0', color: 'black' }}>
                          {data.productname}</h4>
                        <div style={{ display: 'flex', justifyContent: 'flex-start', paddingLeft: '17px', textAlign: 'justify', color: 'black', alignItems: 'center', marginTop: '20px' }}>



                          Rating:

                          <div class="rating">
                            <span class="star">&#9733;</span>
                            <span class="star">&#9733;</span>
                            <span class="star">&#9733;</span>
                            <span class="star">&#9733;</span>
                            <span class="star">&#9733;</span>
                          </div>


                        </div>
                      </div>
                    
                     
                    
                    </div>
                    <h5 style={{ display: 'flex',flexDirection:'column', marginBottom: '0', justifyContent: 'flex-start', padding: '0px', position: 'sticky', top: '0', textAlign: 'justify', color: 'black' }}>
                      GHc {data.productprice}
                   
                      
                
                      </h5>
                      {/* <div className='removeicon' onClick={() => removeItem(index)}><BsTrash style={{color:'red',width:'50px',height:'50px'}}/><h5 style={{margin:'0'}}>Remove this Item</h5></div> */}
                 
                  </div>
                  <div className='redcartdataplan'>{data.dataplane}
                  </div>
                  <div className='removeicon' onClick={() => removeItem(index)}><BsTrash style={{color:'red',width:'50px',height:'50px'}}/><h5 style={{margin:'0'}}>Remove this Item</h5></div>
                 

                 
                 
                  
                 
               
               
                 
                </>
              )}

              <button className='checkoutbutton' onClick={() => setshowcheckout(true)}>Check Out</button>

            </div>
          </>}



        </>}


















        {showcheckout === true && <>
          <form onSubmit={sendemail} className='chectoutform'>
            <h1 style={{ display: "flex", alignItems: "baseline", borderLeft: "5px solid #0587EF", borderRadius: '5px', color: "rgb(70, 70, 70)", paddingLeft: "10px", alignSelf: "baseline", marginBottom: '0' }}>
              Check out</h1>
            <div style={{ width: '90%', display: 'flex', justifyContent: 'flex-start', paddingLeft: '17px', textAlign: 'justify', color: 'black' }}>
              To expedite the processing of your order, we kindly ask you to provide comprehensive and precise details. This will ensure a smooth and efficient transaction.
            </div>



            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '30px' }}>
              <div style={{ display: "flex", flexDirection: 'column', textAlign: 'left', width: '48%' }}>
                <label>First Name*</label>
                <input required className='chectoutforminput' onChange={(e) => setfirstname(e.target.value)} />
              </div>


              <div style={{ display: "flex", flexDirection: 'column', textAlign: 'left', width: '48%' }}>
                <label>Last Name*</label>
                 <input required className='chectoutforminput' onChange={(e) => setlastname(e.target.value)} />
              </div>


            </div>



            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '30px' }}>
              <div style={{ display: "flex", flexDirection: 'column', textAlign: 'left', width: '48%' }}>
                <label>District</label>
                <input className='chectoutforminput' onChange={(e) => setDistrict(e.target.value)} />
              </div>


              <div style={{ display: "flex", flexDirection: 'column', textAlign: 'left', width: '48%' }}>
                <label>Town</label>
                <input className='chectoutforminput' onChange={(e) => setTown(e.target.value)} />
              </div>


            </div>



            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '30px' }}>
              <div style={{ display: "flex", flexDirection: 'column', textAlign: 'left', width: '48%' }}>
                <label>Street Address*</label>
                <input required className='chectoutforminput' onChange={(e) => setShreetAddress(e.target.value)} />
              </div>


              <div style={{ display: "flex", flexDirection: 'column', textAlign: 'left', width: '48%' }}>
                <label>Ecowas Card number*</label>
                <input className='chectoutforminput' onChange={(e) => setEcowasCardnumber(e.target.value)} />
              </div>


            </div>



            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '30px', width: "100%" }}>
              <div style={{ display: "flex", flexDirection: 'column', textAlign: 'left', width: '30%' }}>
                <label>City</label>
                <input className='chectoutforminput' onChange={(e) => setCity(e.target.value)} />
              </div>


              <div style={{ display: "flex", flexDirection: 'column', textAlign: 'left', width: '30%' }}>
                <label>State</label>
                <input className='chectoutforminput' onChange={(e) => setState(e.target.value)} />
              </div>


              <div style={{ display: "flex", flexDirection: 'column', textAlign: 'left', width: '30%' }}>
                <label>Digital Address</label>
                <input className='chectoutforminput' onChange={(e) => setPostalCode(e.target.value)} />
              </div>

            </div>

            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '30px' }}>
              <div style={{ display: "flex", flexDirection: 'column', textAlign: 'left', width: '48%' }}>
                <label>Phone Number*</label>
                <input type='number' required className='chectoutforminput' onChange={(e) => setPhoneNumber(e.target.value)} />
              </div>

              <div style={{ display: "flex", flexDirection: 'column', textAlign: 'left', width: '48%' }}>
                <label>Email*</label>
                <input type='email' required className='chectoutforminput' onChange={(e) => setEmail(e.target.value)} />
              </div>


            </div>

            <div style={{ display: 'flex',justifyContent:'flex-start', marginTop: '30px' }}>
             
               
                <input type='checkbox' style={{width:'40px',}} onChange={(e) => setimstudent(e.target.checked)} />
                <label>I am Student</label>
              

             


            </div>

{imstudent === true &&<>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '30px', width: "100%" }}>
              <div style={{ display: "flex", flexDirection: 'column', textAlign: 'left', width: '30%' }}>
                <label>University Name*</label>
                <input required className='chectoutforminput' onChange={(e) => setUniversityname(e.target.value)} />
              </div>


              <div style={{ display: "flex", flexDirection: 'column', textAlign: 'left', width: '30%' }}>
                <label>Student ID Number*</label>
                <input required className='chectoutforminput' onChange={(e) => setidnumber(e.target.value)} />
              </div>


              <div style={{ display: "flex", flexDirection: 'column', textAlign: 'left', width: '30%' }}>
                <label>Student Email*</label>
                <input required className='chectoutforminput' onChange={(e) => setstudentEmail(e.target.value)} />
              </div>

            </div>
            </>}

            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '30px', width: '100%' }}>
              <div style={{ display: "flex", flexDirection: 'column', textAlign: 'left', width: '100%' }}>

                <button className='chectoutforminput2' type='submit' >Place Order</button>
              </div>




            </div>


          </form>



        </>}



      </div></>
  )
}

export default Cart