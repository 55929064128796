import React, { useState, useEffect } from 'react'
import Navbar from "../../components/navbar"
import "./css/mifi.css"
import { useNavigate } from 'react-router-dom';
import { BiArrowBack } from "react-icons/bi"
import Cookies from 'js-cookie';
import { Image } from 'antd';
import "../../css/product.css"














const ProductImageGallery = ({ images }) => {
  const [selectedImage, setSelectedImage] = useState(images[0]);

  const handleImageClick = (image) => {
    setSelectedImage(image);
  };

  return (
    <div className='imagessss'>
      <Image src={selectedImage} alt="Product" width={"400px"} height={"400px"} />

      <Image.PreviewGroup
        preview={{
          onChange: (current, prev) => console.log(`current index: ${current}, prev index: ${prev}`),
        }}
      >
        {/* <Image width={200} src="https://gw.alipayobjects.com/zos/rmsportal/KDpgvguMpGfqaHPjicRK.svg" />
        <Image
          width={200} src="https://gw.alipayobjects.com/zos/antfincdn/aPkFc8Sj7n/method-draw-image.svg"
        /> */}
        <div className="small-images" style={{ display: 'flex', alignItems: 'center', justifyContent: "center", gap: "10px", flexWrap: 'wrap' }}>
          {images.map((image, index) => (
            <Image style={{ border: '1px solid gray' }}
              key={index}
              src={image}
              width={"100px"}
              height={"100px"}
              alt={`Product ${index}`}
              onClick={() => handleImageClick(image)}
              // className={selectedImage === image ? 'selected' : ''}
              className='hovar'
            />

          ))}
        </div>
      </Image.PreviewGroup >




      {/* <div className="small-images" style={{ display: 'flex', alignItems: 'center', justifyContent: "center", gap: "10px", flexWrap: 'wrap' }}>
    {images.map((image, index) => (
      <img style={{ border: '1px solid gray' }}
        key={index}
        src={image}
        width={"100px"}
        height={"100px"}
        alt={`Product ${index}`}
        onClick={() => handleImageClick(image)}
        // className={selectedImage === image ? 'selected' : ''}
        className='hovar'
      />
    ))}
  </div> */}
    </div >
  );
};























function FastPlugConnect() {

  let navigate = useNavigate();
  const [showallplans, setshowallplans] = useState(false);
  const [showcheckout, setshowcheckout] = useState(true);
  const [productdetails, setproductdetails] = useState(1);
  const [Specifications, setSpecifications] = useState(false);
  const [selectedCell, setSelectedCell] = useState(null);

  const [selselectplanebox, setSelselectplanebox] = useState(false);
  const [selselectplanebox2, setSelselectplanebox2] = useState(false);

  const [productselected, setproductselected] = useState("");


  const [routertype, setroutertype] = useState(false);




  const [cookieproduct, setcookieproduct] = useState([]);


  useEffect(() => {



    // Check if the 'Product' cookie exists
    if (Cookies.get('Product')) {
      // The 'Product' cookie exists, so you can proceed to use its value
      const productCookie = Cookies.get('Product');
      try {
        const productData = JSON.parse(productCookie);
        setcookieproduct(productData);
      } catch (error) {
        console.error('Error parsing JSON from the "Product" cookie:', error);
      }
    } else {
      // The 'Product' cookie does not exist or is empty
      console.warn('The "Product" cookie is not set or is empty.');
    }




    // setcookieproduct(JSON.parse(Cookies.get('Product')));
  }, [])




  return (
    <div>

      <div style={{ marginTop: '110px' }}></div>


      <Navbar />






      <div className='mifimain'>


        <h1 style={{ borderLeft: "5px solid #0587EF", textAlign: 'left', borderRadius: '5px', color: "rgb(70, 70, 70)", paddingLeft: "10px", alignSelf: "baseline" }}>
          Internet & Broadband</h1>

        <h2 style={{ borderRadius: '5px', paddingLeft: "15px", alignSelf: "baseline", margin: '0', color: 'black' }}>
          FAST PLUG & CONNECT.   </h2>

        <div style={{ width: '90%', display: 'flex', justifyContent: 'flex-start', paddingLeft: '17px', textAlign: 'justify', color: 'black', marginBottom: '50px' }}>
          Data Warehouse 4G Routers with SIM Slot Unlocked, 300Mbps Dual Band 4G Mobile Wi-Fi Router, No Configuration Required, 4G LTE SIM Card Router, Connectivity up to 32 Devices.
        </div>

        <div style={{ width: '90%', display: 'flex', justifyContent: 'flex-start', paddingLeft: '17px', textAlign: 'justify', color: 'black', marginBottom: '50px' }}>
          WiFi Anywhere: Enjoy online smooth HD video streaming, video call, files download on your smartphone, tablet, and laptop with this 4G wireless router
        </div>



        <div className='selectrouterbox'>
          {routertype === true && <>
            <div className='selectrouterboxleft' onClick={() => {
              setroutertype(false)
              setshowcheckout(true)
            }}>DW FastNet(Battery Back-up)</div>
            <div className='selectrouterboxright'>DW FastNet(No Battery)</div>
          </>}
          {routertype === false && <>

            <div className='selectrouterboxright'>DW FastNet(Battery Back-up)</div>
            <div className='selectrouterboxleft' onClick={() => {
              setroutertype(true)
              setshowcheckout(true)
            }}>DW FastNet(No Battery)</div>
          </>}
        </div>




        {routertype === true && <>
          {/* DW FastNet(No Battery)*/}
          {showcheckout === true && <>



            <div className='procarddd2' onClick={() => {
              setshowcheckout(false)
              setproductdetails(2)
            }}>

              <div className='subprocard'>

                {/* <div className='subprocardImg'>

                 

                </div> */}


                <div className='subprocarddescrib'>
                  <h3 style={{ borderRadius: '5px', paddingLeft: "15px", alignSelf: "baseline", margin: '0', color: 'black' }}>
                    DW FastNet(No Battery)  </h3>

                  <div style={{ width: '90%', display: 'flex', justifyContent: 'flex-start', paddingLeft: '17px', textAlign: 'justify', color: 'black' }}>
                    Data Warehouse 4G Routers with SIM Slot Unlocked, 300Mbps Dual Band 4G Mobile Wi-Fi Router, No Configuration Required, 4G LTE SIM Card Router, Connectivity up to 32 Devices.  </div>
                </div>
                <img src='/routers pictures/AX6 pro/New folder/2.jpg' alt="profile" height={"300px"} width={"300px"} />

              </div>

              <div className='subprocarddescrib2'>
                <h3 style={{ borderRadius: '5px', paddingLeft: "15px", alignSelf: "baseline", margin: '0', color: 'black' }}>
                  4G Smart Home Internet – The Fastest Way to get your home connected.  </h3>

                <div style={{ width: '90%', display: 'flex', justifyContent: 'flex-start', paddingLeft: '17px', textAlign: 'justify', color: 'black' }}>
                  Our Smart 4G Home Broadband is perfect for your day-to-day and you don’t have to wait to get up to speed. With our Smart Home Internet Solutions, you just plug in and connect .  </div>
              </div>

              <div className='subprocardfeature'>
                <h3 style={{ borderRadius: '5px', paddingLeft: "15px", alignSelf: "baseline", margin: '0', color: 'black' }}>
                  Features</h3>

                <ul style={{ margin: '0', fontSize: "0.7em" }}>
                  <li> Great for everyday home use</li>
                  <li>No landline. No engineer. No waiting </li>
                  <li>Nationwide delivery </li>
                  <li>Plug and play set up</li>
                  <li>Connect up to 32 devices</li>
                  <li>Monthly Subscription plans to suit you</li>

                  <li>Lan/Wan Ports</li>
                  <li>No Configuration required </li>
                  <li>Truly 4G Super Speed</li>


                </ul>


              </div>




            </div>




            <h2 style={{ borderRadius: '5px', paddingLeft: "15px", alignSelf: "baseline", margin: '0', color: 'black' }}>
              Buy Now  </h2>



            <div className='divtext'>
              Feel free to explore our range of products and select the one that best suits your needs. Keep in mind that our prices are flexible and tailored to the specific data packages you choose, ensuring you get the most value out of your purchase.
            </div>


            <div className='routercardbox'>


              {/* 6 */}
              <div className='routercard' onClick={() => {


                setSelselectplanebox(true)

                // setproductselected("DW SMART MIFI – MT10")

                setproductselected(
                  {
                    "productname": "DW FastNet - 21",
                    "productPrice": "929.00/-  One-time Activation+ 1st Month subscription on DW Light1 Package with inclusive 21GB data",
                    "productimg": "/routers pictures/AX6 pro/New folder/2.jpg"
                  }
                )



              }}>
                <h2 style={{ margin: '0', color: 'black' }}>
                  DW FastNet - 21  </h2>

                <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-start', textAlign: 'justify', color: 'black' }}>
                  <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-start', textAlign: 'justify', color: 'black', alignItems: 'center', marginBottom: '20px' }}>
                    Rating:

                    <div class="rating">
                      <span class="star">&#9733;</span>
                      <span class="star">&#9733;</span>
                      <span class="star">&#9733;</span>
                      <span class="star">&#9733;</span>
                      <span class="star">&#9733;</span>
                    </div>


                  </div>
                </div>


                <p style={{ width: '100%', fontWeight: 'bold', display: 'flex', margin: '0', justifyContent: 'flex-start', textAlign: 'left', color: 'red' }}>

                  <h4 style={{ borderRadius: '5px', alignSelf: "baseline", marginRight: '5px', color: 'black' }}>
                    Price</h4>
                  Ghc 929.00/-
                </p>
                <p style={{ width: '100%', display: 'flex', margin: '0', justifyContent: 'flex-start', textAlign: 'left', color: 'black' }}>
                  One-time Activation <br /> +<br /> 1st Month subscription on DW Light1 Package with inclusive 21GB data
                </p>



                <div style={{ backgroundColor: '#044ff3', padding: "5px", color: 'white', display: 'flex', justifyContent: 'center' }}>Click to Buy this Product</div>
              </div>




              {/* 1 */}
              <div className='routercard' onClick={() => {


                setSelselectplanebox(true)

                // setproductselected("DW SMART MIFI – MT10")

                setproductselected(
                  {
                    "productname": "DW FastNet - 35",
                    "productPrice": "979.00/-  One-time Activation+ 1st Month subscription on DW Light1 Package with inclusive 35GB data",
                    "productimg": "/routers pictures/AX6 pro/New folder/2.jpg"
                  }
                )



              }}>
                <h2 style={{ margin: '0', color: 'black' }}>
                  DW FastNet - 35 </h2>

                <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-start', textAlign: 'justify', color: 'black' }}>
                  <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-start', textAlign: 'justify', color: 'black', alignItems: 'center', marginBottom: '20px' }}>
                    Rating:

                    <div class="rating">
                      <span class="star">&#9733;</span>
                      <span class="star">&#9733;</span>
                      <span class="star">&#9733;</span>
                      <span class="star">&#9733;</span>
                      <span class="star">&#9733;</span>
                    </div>


                  </div>
                </div>


                <p style={{ width: '100%', fontWeight: 'bold', display: 'flex', margin: '0', justifyContent: 'flex-start', textAlign: 'left', color: 'red' }}>

                  <h4 style={{ borderRadius: '5px', alignSelf: "baseline", marginRight: '5px', color: 'black' }}>
                    Price</h4>
                  Ghc 979.00/-
                </p>
                <p style={{ width: '100%', display: 'flex', margin: '0', justifyContent: 'flex-start', textAlign: 'left', color: 'black' }}>
                  One-time Activation <br /> +<br /> 1st Month subscription on DW Light1 Package with inclusive 35GB data
                </p>



                <div style={{ backgroundColor: '#044ff3', padding: "5px", color: 'white', display: 'flex', justifyContent: 'center' }}>Click to Buy this Product</div>
              </div>

              {/* 2 */}

              <div className='routercard' onClick={() => {


                setSelselectplanebox(true)

                // setproductselected("DW SMART MIFI – MT10")

                setproductselected(
                  {
                    "productname": "DW FastNet - 45 ",
                    "productPrice": "999.00/-  One-time Activation+ 1st Month subscription on DW Light1 Package with inclusive 45GB data",
                    "productimg": "/routers pictures/AX6 pro/New folder/2.jpg"
                  }
                )



              }}>
                <h2 style={{ margin: '0', color: 'black' }}>
                  DW FastNet - 45   </h2>

                <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-start', textAlign: 'justify', color: 'black' }}>
                  <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-start', textAlign: 'justify', color: 'black', alignItems: 'center', marginBottom: '20px' }}>
                    Rating:

                    <div class="rating">
                      <span class="star">&#9733;</span>
                      <span class="star">&#9733;</span>
                      <span class="star">&#9733;</span>
                      <span class="star">&#9733;</span>
                      <span class="star">&#9733;</span>
                    </div>


                  </div>
                </div>


                <p style={{ width: '100%', fontWeight: 'bold', display: 'flex', margin: '0', justifyContent: 'flex-start', textAlign: 'left', color: 'red' }}>

                  <h4 style={{ borderRadius: '5px', alignSelf: "baseline", marginRight: '5px', color: 'black' }}>
                    Price</h4>
                  Ghc 999.00/-
                </p>
                <p style={{ width: '100%', display: 'flex', margin: '0', justifyContent: 'flex-start', textAlign: 'left', color: 'black' }}>
                  One-time Activation <br /> +<br /> 1st Month subscription on DW Light1 Package with inclusive 45GB data
                </p>



                <div style={{ backgroundColor: '#044ff3', padding: "5px", color: 'white', display: 'flex', justifyContent: 'center' }}>Click to Buy this Product</div>
              </div>


              {/* 3 */}
              <div className='routercard' onClick={() => {


                setSelselectplanebox(true)

                // setproductselected("DW SMART MIFI – MT10")

                setproductselected(
                  {
                    "productname": "DW FastNet - 75",
                    "productPrice": "1,069.00/-  One-time Activation+ 1st Month subscription on DW Light1 Package with inclusive 75GB data",
                    "productimg": "/routers pictures/AX6 pro/New folder/2.jpg"
                  }
                )



              }}>
                <h2 style={{ margin: '0', color: 'black' }}>
                  DW FastNet - 75 </h2>

                <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-start', textAlign: 'justify', color: 'black' }}>
                  <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-start', textAlign: 'justify', color: 'black', alignItems: 'center', marginBottom: '20px' }}>
                    Rating:

                    <div class="rating">
                      <span class="star">&#9733;</span>
                      <span class="star">&#9733;</span>
                      <span class="star">&#9733;</span>
                      <span class="star">&#9733;</span>
                      <span class="star">&#9733;</span>
                    </div>


                  </div>
                </div>


                <p style={{ width: '100%', fontWeight: 'bold', display: 'flex', margin: '0', justifyContent: 'flex-start', textAlign: 'left', color: 'red' }}>

                  <h4 style={{ borderRadius: '5px', alignSelf: "baseline", marginRight: '5px', color: 'black' }}>
                    Price</h4>
                  Ghc 1,069.00/-
                </p>
                <p style={{ width: '100%', display: 'flex', margin: '0', justifyContent: 'flex-start', textAlign: 'left', color: 'black' }}>
                  One-time Activation <br /> +<br /> 1st Month subscription on DW Light1 Package with inclusive 75GB data
                </p>



                <div style={{ backgroundColor: '#044ff3', padding: "5px", color: 'white', display: 'flex', justifyContent: 'center' }}>Click to Buy this Product</div>
              </div>


              {/* 4 */}
              <div className='routercard' onClick={() => {


                setSelselectplanebox(true)

                // setproductselected("DW SMART MIFI – MT10")

                setproductselected(
                  {
                    "productname": "DW FastNet - 100",
                    "productPrice": "1,109.00/-  One-time Activation+ 1st Month subscription on DW Light1 Package with inclusive 100GB data",
                    "productimg": "/routers pictures/AX6 pro/New folder/2.jpg"
                  }
                )



              }}>
                <h2 style={{ margin: '0', color: 'black' }}>
                  DW FastNet - 100  </h2>

                <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-start', textAlign: 'justify', color: 'black' }}>
                  <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-start', textAlign: 'justify', color: 'black', alignItems: 'center', marginBottom: '20px' }}>
                    Rating:

                    <div class="rating">
                      <span class="star">&#9733;</span>
                      <span class="star">&#9733;</span>
                      <span class="star">&#9733;</span>
                      <span class="star">&#9733;</span>
                      <span class="star">&#9733;</span>
                    </div>


                  </div>
                </div>


                <p style={{ width: '100%', fontWeight: 'bold', display: 'flex', margin: '0', justifyContent: 'flex-start', textAlign: 'left', color: 'red' }}>

                  <h4 style={{ borderRadius: '5px', alignSelf: "baseline", marginRight: '5px', color: 'black' }}>
                    Price</h4>
                  Ghc 1,109.00/-
                </p>
                <p style={{ width: '100%', display: 'flex', margin: '0', justifyContent: 'flex-start', textAlign: 'left', color: 'black' }}>
                  One-time Activation <br /> +<br /> 1st Month subscription on DW Light1 Package with inclusive 100GB data
                </p>



                <div style={{ backgroundColor: '#044ff3', padding: "5px", color: 'white', display: 'flex', justifyContent: 'center' }}>Click to Buy this Product</div>
              </div>

              {/* 5 */}
              <div className='routercard' onClick={() => {


                setSelselectplanebox(true)

                // setproductselected("DW SMART MIFI – MT10")

                setproductselected(
                  {
                    "productname": "DW FastNet - 180",
                    "productPrice": "1,329.00/-  One-time Activation+ 1st Month subscription on DW Light1 Package with inclusive 180GB data",
                    "productimg": "/routers pictures/AX6 pro/New folder/2.jpg"
                  }
                )



              }}>
                <h2 style={{ margin: '0', color: 'black' }}>
                  DW FastNet - 180  </h2>

                <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-start', textAlign: 'justify', color: 'black' }}>
                  <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-start', textAlign: 'justify', color: 'black', alignItems: 'center', marginBottom: '20px' }}>
                    Rating:

                    <div class="rating">
                      <span class="star">&#9733;</span>
                      <span class="star">&#9733;</span>
                      <span class="star">&#9733;</span>
                      <span class="star">&#9733;</span>
                      <span class="star">&#9733;</span>
                    </div>


                  </div>
                </div>


                <p style={{ width: '100%', fontWeight: 'bold', display: 'flex', margin: '0', justifyContent: 'flex-start', textAlign: 'left', color: 'red' }}>

                  <h4 style={{ borderRadius: '5px', alignSelf: "baseline", marginRight: '5px', color: 'black' }}>
                    Price</h4>
                  Ghc 1,329.00/-
                </p>
                <p style={{ width: '100%', display: 'flex', margin: '0', justifyContent: 'flex-start', textAlign: 'left', color: 'black' }}>
                  One-time Activation <br /> +<br /> 1st Month subscription on DW Light1 Package with inclusive 180GB data
                </p>



                <div style={{ backgroundColor: '#044ff3', padding: "5px", color: 'white', display: 'flex', justifyContent: 'center' }}>Click to Buy this Product</div>
              </div>






            </div>














          </>}

          {showcheckout === false && <>

            <h3 onClick={() => setshowcheckout(true)} className='hovar' style={{ paddingLeft: "15px", width: 'fit-content', alignSelf: "baseline", marginTop: '0', color: 'black', display: 'flex', alignItems: 'center', gap: '5px' }}>
              <BiArrowBack style={{ fontWeight: 'bolder' }} />    Go Back</h3>



            {productdetails === 2 && <>

              <div className='productdetails'>
                {/* <img src='https://www.three.co.uk/content/dam/assets/devices/huawei/25760/25760_dlp.png' alt="profile" width={'300px'} height={"auto"} /> */}



                <ProductImageGallery
                  images={[
                    '/routers pictures/AX6 pro/New folder/1.jpg',

                    '/routers pictures/AX6 pro/New folder/2.jpg',

                    '/routers pictures/AX6 pro/New folder/4.jpg',

                    '/routers pictures/AX6 pro/New folder/8.jpg',
                    // Add more image URLs here
                  ]}
                />


                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', textAlign: 'left' }} className='prodetailsright'>



                  <h2 style={{ paddingLeft: "15px", alignSelf: "baseline", marginTop: '0', color: 'black' }}>
                    DW FastNet(No Battery)</h2>




                  <div style={{ width: '90%', display: 'flex', justifyContent: 'flex-start', paddingLeft: '17px', textAlign: 'justify', color: 'black' }}>
                    <div style={{ width: '90%', display: 'flex', justifyContent: 'flex-start', paddingLeft: '17px', textAlign: 'justify', color: 'black', alignItems: 'center', marginTop: '20px' }}>
                      Rating:

                      <div class="rating">
                        <span class="star">&#9733;</span>
                        <span class="star">&#9733;</span>
                        <span class="star">&#9733;</span>
                        <span class="star">&#9733;</span>
                        <span class="star">&#9733;</span>
                      </div>


                    </div>
                  </div>

                  <h5 style={{ width: '100%', display: 'flex', marginTop: '0', justifyContent: 'flex-start', paddingLeft: '17px', textAlign: 'justify', color: 'black' }}>
                    (Activation Cost + 1st month subscription on DW Light1 Package with inclusive data)
                  </h5>





                  {Specifications === true && <>
                    <div style={{ display: 'flex', gap: '30px' }}>  <h3 onClick={() => setSpecifications(false)} className='hovar' style={{ borderRadius: '5px', paddingLeft: "0px", alignSelf: "baseline", margin: '0', marginTop: '40px', color: 'black' }}>
                      Features Highlight</h3>

                      <h3 style={{ borderRadius: '5px', borderBottom: '5px solid #0587EF', paddingLeft: "0px", alignSelf: "baseline", margin: '0', marginTop: '40px', color: 'black' }}>
                        Specifications</h3></div></>}




                  {Specifications === false && <>
                    <div style={{ display: 'flex', gap: '30px' }}>  <h3 style={{ borderRadius: '5px', borderBottom: '5px solid #0587EF', paddingLeft: "0px", alignSelf: "baseline", margin: '0', marginTop: '40px', color: 'black' }}>
                      Features Highlight</h3>

                      <h3 onClick={() => setSpecifications(true)} className='hovar' style={{ borderRadius: '5px', paddingLeft: "0px", alignSelf: "baseline", margin: '0', marginTop: '40px', color: 'black' }}>
                        Specifications</h3></div></>}


                  {Specifications === false && <>
                    <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-start', paddingLeft: '0px', textAlign: 'justify', color: 'black' }}>

                      Data Warehouse 4G Routers with SIM Slot Unlocked, 300Mbps Dual Band 4G Mobile Wi-Fi Router, No Configuration Required, 4G LTE SIM Card Router, Connectivity up to 32 Devices.
                      <br />  WiFi Anywhere: Enjoy online smooth HD video streaming, video call, files download on your smartphone, tablet, and laptop with this 4G wireless router


                    </div></>}

                  {Specifications === true && <>
                    <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-start', paddingLeft: '0px', textAlign: 'justify', color: 'black' }}>

                      <ul>
                        <li>Great for everyday use</li>
                        <li>No landline. No engineer. No waiting </li>
                        <li>Nationwide delivery </li>
                        <li>Plug and play set up</li>
                        <li>Connect up to 32 devices</li>
                        <li>Monthly Subscription plans to suit you</li>
                        <li>Lan/Wan Ports</li>
                        <li>No Configuration required </li>
                        <li>Truly 4G Super Speed	</li>

                      </ul>
                    </div></>}




                  <button onClick={() => {


                    setSelselectplanebox(true)
                    setSelselectplanebox2(true)
                    // setproductselected("DW SMART MIFI – MT10")





                  }}




                    style={{ width: '100%', height: '50px', fontSize: '2rem', fontWeight: "bold", backgroundColor: "#0587EF", color: 'white', borderRadius: '5px' }}>Order Now </button>

                </div>


              </div>




              <div className='productdetails3'>







                <h3 style={{ borderRadius: '5px', backgroundColor: '#0587EF', padding: "5px", alignSelf: "baseline", margin: '0', marginTop: '40px', color: 'White' }}>
                  4G Smart Home Internet – The Fastest Way to get your home connected.</h3>

                <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-start', textAlign: 'justify', color: 'black' }}>
                  Our Smart 4G Home Broadband is perfect for your day-to-day and you don’t have to wait to get up to speed. With our Smart Home Internet Solutions, you just plug in and connect .
                </div>


                <h3 style={{ borderRadius: '5px', backgroundColor: '#0587EF', padding: "5px", alignSelf: "baseline", margin: '0', marginTop: '40px', color: 'White' }}>
                  Highlights.</h3>

                <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-start', textAlign: 'justify', color: 'black' }}>
                  Our Smart Home Internet Solutions offers the best connectivity solution for Home broadband, which means it's fast & you don't need a landline. With speeds of up to 300Mbps* (Device Theoretical Max) you get the ultimate 4G Smart Home broadband experience. Whether it's working from home streaming, video calls or just browsing the web our 4G Smart Home Broadband is made for those who love it all - all at the same time! A battery backup 4G router which guarantees hours of continues internet even when power goes off.
                </div>


                <h3 style={{ alignSelf: "baseline", marginTop: '0', color: 'black' }}>
                  What’s the best broadband speed for my home?</h3>
                <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-start', textAlign: 'justify', color: 'black' }}>
                  Depending on how you use your internet, you’ll find what you need for one of our 4G or 5
                </div>




                <h3 style={{ alignSelf: "baseline", marginTop: '0', color: 'black' }}>
                  Light users</h3>
                <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-start', textAlign: 'justify', color: 'black' }}>

                  If you need day-to-day speedy internet for general browsing, social scrolling and video chatting, then our 4G Smart Home Solution would be perfect for you.
                </div>



                <h3 style={{ alignSelf: "baseline", marginTop: '0', color: 'black' }}>
                  Students</h3>
                <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-start', textAlign: 'justify', color: 'black' }}>
                  Our 4G Smart Broadband solution will be perfect for room-mates. It will lower your internet cost by at least 40%. It is ideal for keeping on top of your daily studying, researching, scrolling, socialising, and light streaming and gaming. Plus, it’s great value, too. Check out our Mobile Broadband Solutions.

                </div>

                <h3 style={{ alignSelf: "baseline", marginTop: '0', color: 'black' }}>
                  Families</h3>
                <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-start', textAlign: 'justify', color: 'black' }}>
                  Depending on how many you’ve got in your home, and what your needs are, our Smart Home Internet with a bespoke data plan would just be what you need. For busy households with a lot of streaming and working, we’d recommend Our Smart Home Internet Solution for you. Call us now

                </div>

                <h3 style={{ alignSelf: "baseline", marginTop: '0', color: 'black' }}>
                  Gamers</h3>
                <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-start', textAlign: 'justify', color: 'black' }}>
                  If you’re looking for smooth, seamless, lower lag gaming, then look no further than our 4G Smart Internet Solutions. It’s for serious gamers who seriously game.

                </div>

                <h3 style={{ alignSelf: "baseline", marginTop: '0', color: 'black' }}>
                  Film lovers</h3>
                <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-start', textAlign: 'justify', color: 'black' }}>
                  Big into Netflix? Our 4G Smart broadband is what you need speeds reach an average of 150Mbps. So you can stream or download in seconds.
                </div>



                <h3 style={{ borderRadius: '5px', backgroundColor: '#0587EF', padding: "5px", alignSelf: "baseline", margin: '0', marginTop: '40px', color: 'White' }}>
                  Monthly Data Plans                        </h3>
                {/* <div style={{ width: '90%', display: 'flex', justifyContent: 'flex-start', textAlign: 'justify', color: 'black' }}>
                  Contract Duration (1-12) months.
                </div> */}


                <table className='data-plans-table'>
                  <thead>
                    <tr>
                      <th>Data Plan</th>
                      <th>Price</th>

                    </tr>
                  </thead>
                  <tbody>

                    <tr >
                      <td>10.5GB Data</td>
                      <td>GHc 75/-</td>


                    </tr>


                    <tr >
                      <td>12.5GB Data</td>
                      <td>GHc 85/-</td>

                    </tr>


                    <tr >
                      <td>15GB Data</td>
                      <td>GHc 100/-</td>


                    </tr>

                    {showallplans === true && <>

                      <tr >
                        <td>21GB Data</td>
                        <td>GHc 140/-</td>
                      </tr>

                      <tr >
                        <td>35GB Data</td>
                        <td>GHc 185/-</td>
                      </tr>


                      <tr >
                        <td>45GB Data</td>
                        <td>GHc 210/-</td>
                      </tr>


                      <tr >
                        <td>75GB Data</td>
                        <td>GHc 210/-</td>
                      </tr>

                      <tr >
                        <td>100GB Data</td>
                        <td>GHc 320/-</td>
                      </tr>
                      <tr >
                        <td>150GB Data</td>
                        <td>GHc 450/-</td>
                      </tr>
                      <tr >
                        <td>180GB Data</td>
                        <td>GHc 540/-</td>
                      </tr>


                    </>}

                  </tbody>
                </table>

                {showallplans === false && <>


                  <h5 className='readmore' onClick={() => { setshowallplans(true) }} style={{ margin: "0", width: '100%', display: 'flex', justifyContent: 'end', textAlign: 'justify', color: 'black' }}>
                    Read More.
                  </h5>

                </>}
                {showallplans === true && <>

                  <h5 className='readmore' onClick={() => { setshowallplans(false) }} style={{ margin: "0", width: '100%', display: 'flex', justifyContent: 'end', textAlign: 'justify', color: 'black' }}>
                    Read Less.
                  </h5>
                </>}




              </div>


            </>}
            <div className='hovar' onClick={() => navigate('/dataplanspdf')} style={{ width: '100%', display: 'flex', justifyContent: 'flex-start', paddingLeft: '17px', textAlign: 'justify', color: 'black', marginBottom: '50px' }}>
              CLICK HERE to see Data Plans PDF
            </div>







          </>}

        </>}




        {routertype === false && <>
          {/* DW FastNet(Battery Back-up) */}




          {showcheckout === true && <>



            <div className='procarddd2' onClick={() => {
              setshowcheckout(false)
              setproductdetails(1)
            }}>

              <div className='subprocard'>

                {/* <div className='subprocardImg'>
                  <img src='https://w7.pngwing.com/pngs/892/710/png-transparent-huawei-e5577cs-321-mifi-mobile-phones-mobile-case-electronics-3g-mobile-phones-thumbnail.png' alt="profile" height={"100%"} width={"100%"} />
                </div> */}


                <div className='subprocarddescrib'>
                  <h3 style={{ borderRadius: '5px', paddingLeft: "15px", alignSelf: "baseline", margin: '0', color: 'black' }}>
                    DW FastNet(Battery Back-up)  </h3>

                  <div style={{ width: '90%', display: 'flex', justifyContent: 'flex-start', paddingLeft: '17px', textAlign: 'justify', color: 'black' }}>
                    Data Warehouse 4G Routers with SIM Slot Unlocked, 300Mbps Dual Band 4G Mobile Wi-Fi Router, No Configuration Required, 4G LTE SIM Card Router, Connectivity up to 32 Devices.  </div>
                </div>
                <img src='/routers pictures/AX9 pro/New folder/1.jpg' alt="profile" height={"300px"} width={"300px"} />

              </div>

              <div className='subprocarddescrib2'>
                <h3 style={{ borderRadius: '5px', paddingLeft: "15px", alignSelf: "baseline", margin: '0', color: 'black' }}>
                  4G Smart Home Internet – The Fastest Way to get your home connected.  </h3>

                <div style={{ width: '90%', display: 'flex', justifyContent: 'flex-start', paddingLeft: '17px', textAlign: 'justify', color: 'black' }}>
                  Our Smart 4G Home Broadband is perfect for your day-to-day and you don’t have to wait to get up to speed. With our Smart Home Internet Solutions, you just plug in and connect .  </div>
              </div>

              <div className='subprocardfeature'>
                <h3 style={{ borderRadius: '5px', paddingLeft: "15px", alignSelf: "baseline", margin: '0', color: 'black' }}>
                  Features</h3>

                <ul style={{ margin: '0', fontSize: "0.7em" }}>
                  <li> Great for everyday home use</li>
                  <li>No landline. No engineer. No waiting </li>
                  <li>Nationwide delivery </li>
                  <li>Plug and play set up</li>
                  <li>Connect up to 32 devices</li>
                  <li>Monthly Subscription plans to suit you</li>
                  <li>5000mah Battery Backup to last you up to 48hrs in case of light off</li>
                  <li>Lan/Wan Ports</li>
                  <li>No Configuration required </li>
                  <li>Truly 4G Super Speed</li>


                </ul>


              </div>




            </div>




            <h2 style={{ borderRadius: '5px', paddingLeft: "15px", alignSelf: "baseline", margin: '0', color: 'black' }}>
              Buy Now  </h2>
            <div className='divtext'>
              Feel free to explore our range of products and select the one that best suits your needs. Keep in mind that our prices are flexible and tailored to the specific data packages you choose, ensuring you get the most value out of your purchase.  </div>


            <div className='routercardbox'>


              {/* 6 */}
              <div className='routercard' onClick={() => {


                setSelselectplanebox(true)

                // setproductselected("DW SMART MIFI – MT10")

                setproductselected(
                  {
                    "productname": "DW FastNet(Battery Back-up) 21",
                    "productPrice": "1099.00/-  One-time Activation+ 1st Month subscription on DW Light1 Package with inclusive 21GB data",
                    "productimg": "/routers pictures/AX9 pro/New folder/1.jpg"
                  }
                )



              }}>
                <h2 style={{ margin: '0', color: 'black' }}>
                  DW FastNet(Battery Back-up) 21  </h2>

                <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-start', textAlign: 'justify', color: 'black' }}>
                  <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-start', textAlign: 'justify', color: 'black', alignItems: 'center', marginBottom: '20px' }}>
                    Rating:

                    <div class="rating">
                      <span class="star">&#9733;</span>
                      <span class="star">&#9733;</span>
                      <span class="star">&#9733;</span>
                      <span class="star">&#9733;</span>
                      <span class="star">&#9733;</span>
                    </div>


                  </div>
                </div>


                <p style={{ width: '100%', fontWeight: 'bold', display: 'flex', margin: '0', justifyContent: 'flex-start', textAlign: 'left', color: 'red' }}>

                  <h4 style={{ borderRadius: '5px', alignSelf: "baseline", marginRight: '5px', color: 'black' }}>
                    Price</h4>
                  Ghc 1099.00/-
                </p>
                <p style={{ width: '100%', display: 'flex', margin: '0', justifyContent: 'flex-start', textAlign: 'left', color: 'black' }}>
                  One-time Activation <br /> +<br /> 1st Month subscription on DW Light1 Package with inclusive 45GB data
                </p>



                <div style={{ backgroundColor: '#044ff3', padding: "5px", color: 'white', display: 'flex', justifyContent: 'center' }}>Click to Buy this Product</div>
              </div>




              {/* 1 */}
              <div className='routercard' onClick={() => {


                setSelselectplanebox(true)

                // setproductselected("DW SMART MIFI – MT10")

                setproductselected(
                  {
                    "productname": "DW FastNet(Battery Back-up) 35",
                    "productPrice": "1139.00/-  One-time Activation+ 1st Month subscription on DW Light1 Package with inclusive 35GB data",
                    "productimg": "/routers pictures/AX9 pro/New folder/1.jpg"
                  }
                )



              }}>
                <h2 style={{ margin: '0', color: 'black' }}>
                  DW FastNet(Battery Back-up) 35 </h2>

                <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-start', textAlign: 'justify', color: 'black' }}>
                  <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-start', textAlign: 'justify', color: 'black', alignItems: 'center', marginBottom: '20px' }}>
                    Rating:

                    <div class="rating">
                      <span class="star">&#9733;</span>
                      <span class="star">&#9733;</span>
                      <span class="star">&#9733;</span>
                      <span class="star">&#9733;</span>
                      <span class="star">&#9733;</span>
                    </div>


                  </div>
                </div>


                <p style={{ width: '100%', fontWeight: 'bold', display: 'flex', margin: '0', justifyContent: 'flex-start', textAlign: 'left', color: 'red' }}>

                  <h4 style={{ borderRadius: '5px', alignSelf: "baseline", marginRight: '5px', color: 'black' }}>
                    Price</h4>
                  Ghc 1139.00/-
                </p>
                <p style={{ width: '100%', display: 'flex', margin: '0', justifyContent: 'flex-start', textAlign: 'left', color: 'black' }}>
                  One-time Activation <br /> +<br /> 1st Month subscription on DW Light1 Package with inclusive 70GB data
                </p>



                <div style={{ backgroundColor: '#044ff3', padding: "5px", color: 'white', display: 'flex', justifyContent: 'center' }}>Click to Buy this Product</div>
              </div>

              {/* 2 */}

              <div className='routercard' onClick={() => {


                setSelselectplanebox(true)

                // setproductselected("DW SMART MIFI – MT10")

                setproductselected(
                  {
                    "productname": "DW FastNet(Battery Back-up) 45",
                    "productPrice": "1169.00/-  One-time Activation+ 1st Month subscription on DW Light1 Package with inclusive 45GB data",
                    "productimg": "/routers pictures/AX9 pro/New folder/1.jpg"
                  }
                )



              }}>
                <h2 style={{ margin: '0', color: 'black' }}>
                  DW FastNet(Battery Back-up) 45  </h2>

                <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-start', textAlign: 'justify', color: 'black' }}>
                  <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-start', textAlign: 'justify', color: 'black', alignItems: 'center', marginBottom: '20px' }}>
                    Rating:

                    <div class="rating">
                      <span class="star">&#9733;</span>
                      <span class="star">&#9733;</span>
                      <span class="star">&#9733;</span>
                      <span class="star">&#9733;</span>
                      <span class="star">&#9733;</span>
                    </div>


                  </div>
                </div>


                <p style={{ width: '100%', fontWeight: 'bold', display: 'flex', margin: '0', justifyContent: 'flex-start', textAlign: 'left', color: 'red' }}>

                  <h4 style={{ borderRadius: '5px', alignSelf: "baseline", marginRight: '5px', color: 'black' }}>
                    Price</h4>
                  Ghc 1169.00/-
                </p>
                <p style={{ width: '100%', display: 'flex', margin: '0', justifyContent: 'flex-start', textAlign: 'left', color: 'black' }}>
                  One-time Activation <br /> +<br /> 1st Month subscription on DW Light1 Package with inclusive 100GB data
                </p>



                <div style={{ backgroundColor: '#044ff3', padding: "5px", color: 'white', display: 'flex', justifyContent: 'center' }}>Click to Buy this Product</div>
              </div>


              {/* 3 */}
              <div className='routercard' onClick={() => {


                setSelselectplanebox(true)

                // setproductselected("DW SMART MIFI – MT10")

                setproductselected(
                  {
                    "productname": "DW FastNet(Battery Back-up) 75",
                    "productPrice": "1229.00/-  One-time Activation+ 1st Month subscription on DW Light1 Package with inclusive 75GB data",
                    "productimg": "/routers pictures/AX9 pro/New folder/1.jpg"
                  }
                )



              }}>
                <h2 style={{ margin: '0', color: 'black' }}>
                  DW FastNet(Battery Back-up) 75 </h2>

                <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-start', textAlign: 'justify', color: 'black' }}>
                  <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-start', textAlign: 'justify', color: 'black', alignItems: 'center', marginBottom: '20px' }}>
                    Rating:

                    <div class="rating">
                      <span class="star">&#9733;</span>
                      <span class="star">&#9733;</span>
                      <span class="star">&#9733;</span>
                      <span class="star">&#9733;</span>
                      <span class="star">&#9733;</span>
                    </div>


                  </div>
                </div>


                <p style={{ width: '100%', fontWeight: 'bold', display: 'flex', margin: '0', justifyContent: 'flex-start', textAlign: 'left', color: 'red' }}>

                  <h4 style={{ borderRadius: '5px', alignSelf: "baseline", marginRight: '5px', color: 'black' }}>
                    Price</h4>
                  Ghc 1229.00/-
                </p>
                <p style={{ width: '100%', display: 'flex', margin: '0', justifyContent: 'flex-start', textAlign: 'left', color: 'black' }}>
                  One-time Activation <br /> +<br /> 1st Month subscription on DW Light1 Package with inclusive 125GB data
                </p>



                <div style={{ backgroundColor: '#044ff3', padding: "5px", color: 'white', display: 'flex', justifyContent: 'center' }}>Click to Buy this Product</div>
              </div>


              {/* 4 */}
              <div className='routercard' onClick={() => {


                setSelselectplanebox(true)

                // setproductselected("DW SMART MIFI – MT10")

                setproductselected(
                  {
                    "productname": "DW FastNet(Battery Back-up) 100",
                    "productPrice": "1279.00/-  One-time Activation+ 1st Month subscription on DW Light1 Package with inclusive 100GB data",
                    "productimg": "/routers pictures/AX9 pro/New folder/1.jpg"
                  }
                )



              }}>
                <h2 style={{ margin: '0', color: 'black' }}>
                  DW FastNet(Battery Back-up) 100  </h2>

                <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-start', textAlign: 'justify', color: 'black' }}>
                  <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-start', textAlign: 'justify', color: 'black', alignItems: 'center', marginBottom: '20px' }}>
                    Rating:

                    <div class="rating">
                      <span class="star">&#9733;</span>
                      <span class="star">&#9733;</span>
                      <span class="star">&#9733;</span>
                      <span class="star">&#9733;</span>
                      <span class="star">&#9733;</span>
                    </div>


                  </div>
                </div>


                <p style={{ width: '100%', fontWeight: 'bold', display: 'flex', margin: '0', justifyContent: 'flex-start', textAlign: 'left', color: 'red' }}>

                  <h4 style={{ borderRadius: '5px', alignSelf: "baseline", marginRight: '5px', color: 'black' }}>
                    Price</h4>
                  Ghc 1279.00/-
                </p>
                <p style={{ width: '100%', display: 'flex', margin: '0', justifyContent: 'flex-start', textAlign: 'left', color: 'black' }}>
                  One-time Activation <br /> +<br /> 1st Month subscription on DW Light1 Package with inclusive 155GB data
                </p>



                <div style={{ backgroundColor: '#044ff3', padding: "5px", color: 'white', display: 'flex', justifyContent: 'center' }}>Click to Buy this Product</div>
              </div>

              {/* 5 */}
              <div className='routercard' onClick={() => {


                setSelselectplanebox(true)

                // setproductselected("DW SMART MIFI – MT10")

                setproductselected(
                  {
                    "productname": "DW FastNet(Battery Back-up) 180",
                    "productPrice": "1499.00/-  One-time Activation+ 1st Month subscription on DW Light1 Package with inclusive 180GB data",
                    "productimg": "/routers pictures/AX9 pro/New folder/1.jpg"
                  }
                )



              }}>
                <h2 style={{ margin: '0', color: 'black' }}>
                  DW FastNet(Battery Back-up) 180  </h2>

                <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-start', textAlign: 'justify', color: 'black' }}>
                  <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-start', textAlign: 'justify', color: 'black', alignItems: 'center', marginBottom: '20px' }}>
                    Rating:

                    <div class="rating">
                      <span class="star">&#9733;</span>
                      <span class="star">&#9733;</span>
                      <span class="star">&#9733;</span>
                      <span class="star">&#9733;</span>
                      <span class="star">&#9733;</span>
                    </div>


                  </div>
                </div>


                <p style={{ width: '100%', fontWeight: 'bold', display: 'flex', margin: '0', justifyContent: 'flex-start', textAlign: 'left', color: 'red' }}>

                  <h4 style={{ borderRadius: '5px', alignSelf: "baseline", marginRight: '5px', color: 'black' }}>
                    Price</h4>
                  Ghc 1599.00/-
                </p>
                <p style={{ width: '100%', display: 'flex', margin: '0', justifyContent: 'flex-start', textAlign: 'left', color: 'black' }}>
                  One-time Activation <br /> +<br /> 1st Month subscription on DW Light1 Package with inclusive 185GB data
                </p>



                <div style={{ backgroundColor: '#044ff3', padding: "5px", color: 'white', display: 'flex', justifyContent: 'center' }}>Click to Buy this Product</div>
              </div>






            </div>














          </>}

          {showcheckout === false && <>

            <h3 onClick={() => setshowcheckout(true)} className='hovar' style={{ paddingLeft: "15px", width: 'fit-content', alignSelf: "baseline", marginTop: '0', color: 'black', display: 'flex', alignItems: 'center', gap: '5px' }}>
              <BiArrowBack style={{ fontWeight: 'bolder' }} />    Go Back</h3>



            {productdetails === 1 && <>

              <div className='productdetails'>
                <ProductImageGallery
                  images={[
                    '/routers pictures/AX9 pro/New folder/1.jpg',

                    '/routers pictures/AX9 pro/New folder/3.jpg',

                    '/routers pictures/AX9 pro/New folder/4.jpg',
                    '/routers pictures/AX9 pro/New folder/5.jpg',
                    '/routers pictures/AX9 pro/New folder/6.jpg',
                    '/routers pictures/AX9 pro/New folder/7.jpg',
                    '/routers pictures/AX9 pro/New folder/8.jpg',
                    // Add more image URLs here
                  ]}
                />
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', textAlign: 'left' }} className='prodetailsright'>



                  <h2 style={{ paddingLeft: "15px", alignSelf: "baseline", marginTop: '0', color: 'black' }}>
                    DW FastNet(Battery Back-up).</h2>




                  <div style={{ width: '90%', display: 'flex', justifyContent: 'flex-start', paddingLeft: '17px', textAlign: 'justify', color: 'black' }}>
                    <div style={{ width: '90%', display: 'flex', justifyContent: 'flex-start', paddingLeft: '17px', textAlign: 'justify', color: 'black', alignItems: 'center', marginTop: '20px' }}>
                      Rating:

                      <div class="rating">
                        <span class="star">&#9733;</span>
                        <span class="star">&#9733;</span>
                        <span class="star">&#9733;</span>
                        <span class="star">&#9733;</span>
                        <span class="star">&#9733;</span>
                      </div>


                    </div>
                  </div>

                  <h5 style={{ width: '100%', display: 'flex', marginTop: '0', justifyContent: 'flex-start', paddingLeft: '17px', textAlign: 'justify', color: 'black' }}>
                    (Activation Cost + 1st month subscription on DW Light1 Package with inclusive data)
                  </h5>





                  {Specifications === true && <>
                    <div style={{ display: 'flex', gap: '30px' }}>  <h3 onClick={() => setSpecifications(false)} className='hovar' style={{ borderRadius: '5px', paddingLeft: "0px", alignSelf: "baseline", margin: '0', marginTop: '40px', color: 'black' }}>
                      Features Highlight</h3>

                      <h3 style={{ borderRadius: '5px', borderBottom: '5px solid #0587EF', paddingLeft: "0px", alignSelf: "baseline", margin: '0', marginTop: '40px', color: 'black' }}>
                        Specifications</h3></div></>}




                  {Specifications === false && <>
                    <div style={{ display: 'flex', gap: '30px' }}>  <h3 style={{ borderRadius: '5px', borderBottom: '5px solid #0587EF', paddingLeft: "0px", alignSelf: "baseline", margin: '0', marginTop: '40px', color: 'black' }}>
                      Features Highlight</h3>

                      <h3 onClick={() => setSpecifications(true)} className='hovar' style={{ borderRadius: '5px', paddingLeft: "0px", alignSelf: "baseline", margin: '0', marginTop: '40px', color: 'black' }}>
                        Specifications</h3></div></>}


                  {Specifications === false && <>
                    <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-start', paddingLeft: '0px', textAlign: 'justify', color: 'black' }}>

                      Data Warehouse 4G Routers with SIM Slot Unlocked, 300Mbps Dual Band 4G Mobile Wi-Fi Router, No Configuration Required, 4G LTE SIM Card Router, Connectivity up to 32 Devices.
                      <br />  WiFi Anywhere: Enjoy online smooth HD video streaming, video call, files download on your smartphone, tablet, and laptop with this 4G wireless router


                    </div></>}

                  {Specifications === true && <>
                    <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-start', paddingLeft: '0px', textAlign: 'justify', color: 'black' }}>

                      <ul>
                        <li>Great for everyday use</li>
                        <li>No landline. No engineer. No waiting </li>
                        <li>Nationwide delivery </li>
                        <li>Plug and play set up</li>
                        <li>Connect up to 32 devices</li>
                        <li>Monthly Subscription plans to suit you</li>
                        <li>5000mah Battery Backup to last you up to 48hrs in case of light off</li>
                        <li>Lan/Wan Ports</li>
                        <li>No Configuration required </li>
                        <li>Truly 4G Super Speed	</li>

                      </ul>
                    </div></>}




                  <button onClick={() => {


                    setSelselectplanebox(true)
                    setSelselectplanebox2(true)
                    // setproductselected("DW SMART MIFI – MT10")

                    setproductselected([
                      {
                        "productname": "DW SMART MIFI – MT10",
                        "productPrice": "650.00",
                        "productimg": ""
                      }
                    ])



                  }}




                    style={{ width: '100%', height: '50px', fontSize: '2rem', fontWeight: "bold", backgroundColor: "#0587EF", color: 'white', borderRadius: '5px' }}>Order Now </button>

                </div>


              </div>




              <div className='productdetails3'>







                <h3 style={{ borderRadius: '5px', backgroundColor: '#0587EF', padding: "5px", alignSelf: "baseline", margin: '0', marginTop: '40px', color: 'White' }}>
                  4G Smart Home Internet – The Fastest Way to get your home connected.</h3>

                <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-start', textAlign: 'justify', color: 'black' }}>
                  Our Smart 4G Home Broadband is perfect for your day-to-day and you don’t have to wait to get up to speed. With our Smart Home Internet Solutions, you just plug in and connect .
                </div>


                <h3 style={{ borderRadius: '5px', backgroundColor: '#0587EF', padding: "5px", alignSelf: "baseline", margin: '0', marginTop: '40px', color: 'White' }}>
                  Highlights.</h3>

                <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-start', textAlign: 'justify', color: 'black' }}>
                  Our Smart Home Internet Solutions offers the best connectivity solution for Home broadband, which means it's fast & you don't need a landline. With speeds of up to 300Mbps* (Device Theoretical Max) you get the ultimate 4G Smart Home broadband experience. Whether it's working from home streaming, video calls or just browsing the web our 4G Smart Home Broadband is made for those who love it all - all at the same time! A battery backup 4G router which guarantees hours of continues internet even when power goes off.
                </div>


                <h3 style={{ alignSelf: "baseline", marginTop: '0', color: 'black' }}>
                  What’s the best broadband speed for my home?</h3>
                <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-start', textAlign: 'justify', color: 'black' }}>
                  Depending on how you use your internet, you’ll find what you need for one of our 4G or 5
                </div>




                <h3 style={{ alignSelf: "baseline", marginTop: '0', color: 'black' }}>
                  Light users</h3>
                <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-start', textAlign: 'justify', color: 'black' }}>

                  If you need day-to-day speedy internet for general browsing, social scrolling and video chatting, then our 4G Smart Home Solution would be perfect for you.
                </div>



                <h3 style={{ alignSelf: "baseline", marginTop: '0', color: 'black' }}>
                  Students</h3>
                <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-start', textAlign: 'justify', color: 'black' }}>
                  Our 4G Smart Broadband solution will be perfect for room-mates. It will lower your internet cost by at least 40%. It is ideal for keeping on top of your daily studying, researching, scrolling, socialising, and light streaming and gaming. Plus, it’s great value, too. Check out our Mobile Broadband Solutions.

                </div>

                <h3 style={{ alignSelf: "baseline", marginTop: '0', color: 'black' }}>
                  Families</h3>
                <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-start', textAlign: 'justify', color: 'black' }}>
                  Depending on how many you’ve got in your home, and what your needs are, our Smart Home Internet with a bespoke data plan would just be what you need. For busy households with a lot of streaming and working, we’d recommend Our Smart Home Internet Solution for you. Call us now

                </div>

                <h3 style={{ alignSelf: "baseline", marginTop: '0', color: 'black' }}>
                  Gamers</h3>
                <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-start', textAlign: 'justify', color: 'black' }}>
                  If you’re looking for smooth, seamless, lower lag gaming, then look no further than our 4G Smart Internet Solutions. It’s for serious gamers who seriously game.

                </div>

                <h3 style={{ alignSelf: "baseline", marginTop: '0', color: 'black' }}>
                  Film lovers</h3>
                <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-start', textAlign: 'justify', color: 'black' }}>
                  Big into Netflix? Our 4G Smart broadband is what you need speeds reach an average of 150Mbps. So you can stream or download in seconds.
                </div>



                <h3 style={{ borderRadius: '5px', backgroundColor: '#0587EF', padding: "5px", alignSelf: "baseline", margin: '0', marginTop: '40px', color: 'White' }}>
                  Monthly Data Plans                        </h3>
                {/* <div style={{ width: '90%', display: 'flex', justifyContent: 'flex-start', textAlign: 'justify', color: 'black' }}>
                  Contract Duration (1-12) months.
                </div> */}


                <table className='data-plans-table'>
                  <thead>
                    <tr>
                      <th>Data Plan</th>
                      <th>Price</th>

                    </tr>
                  </thead>
                  <tbody>

                    <tr >
                      <td>10.5GB Data</td>
                      <td>GHc 75/-</td>


                    </tr>


                    <tr >
                      <td>12.5GB Data</td>
                      <td>GHc 85/-</td>

                    </tr>


                    <tr >
                      <td>15GB Data</td>
                      <td>GHc 100/-</td>


                    </tr>

                    {showallplans === true && <>

                      <tr >
                        <td>21GB Data</td>
                        <td>GHc 140/-</td>
                      </tr>

                      <tr >
                        <td>35GB Data</td>
                        <td>GHc 185/-</td>
                      </tr>


                      <tr >
                        <td>45GB Data</td>
                        <td>GHc 210/-</td>
                      </tr>


                      <tr >
                        <td>75GB Data</td>
                        <td>GHc 210/-</td>
                      </tr>

                      <tr >
                        <td>100GB Data</td>
                        <td>GHc 320/-</td>
                      </tr>
                      <tr >
                        <td>150GB Data</td>
                        <td>GHc 450/-</td>
                      </tr>
                      <tr >
                        <td>180GB Data</td>
                        <td>GHc 540/-</td>
                      </tr>


                    </>}

                  </tbody>
                </table>
                {showallplans === false && <>


                  <h5 className='readmore' onClick={() => { setshowallplans(true) }} style={{ margin: "0", width: '100%', display: 'flex', justifyContent: 'end', textAlign: 'justify', color: 'black' }}>
                    Read More.
                  </h5>

                </>}
                {showallplans === true && <>

                  <h5 className='readmore' onClick={() => { setshowallplans(false) }} style={{ margin: "0", width: '100%', display: 'flex', justifyContent: 'end', textAlign: 'justify', color: 'black' }}>
                    Read Less.
                  </h5>
                </>}




              </div>


            </>}
            <div className='hovar' onClick={() => navigate('/dataplanspdf')} style={{ width: '100%', display: 'flex', justifyContent: 'flex-start', paddingLeft: '17px', textAlign: 'justify', color: 'black', marginBottom: '50px' }}>
              CLICK HERE to see Data Plans PDF
            </div>







          </>}

        </>}














      </div>





      {selselectplanebox === true && <>

        <div className='mifimifiselectplanbagmain'>
          <div className='mifiselectplanbag' onClick={() => setSelselectplanebox(false)}></div>
          <div className='mifiselectplanbag2'>



            {selselectplanebox2 === true && <>

              <h3 style={{ borderRadius: '5px', backgroundColor: '#0587EF', padding: "5px", alignSelf: "baseline", margin: '0', color: 'White' }}>
                Kindly select product with initial data plan to proceed further.                     </h3>
              <div style={{ width: '90%', display: 'flex', justifyContent: 'flex-start', textAlign: 'justify', color: 'black' }}>
                Feel free to explore our range of products and select the one that best suits your needs. Keep in mind that our prices are flexible and tailored to the specific data packages you choose, ensuring you get the most value out of your purchase.
              </div>
              {routertype === false && <>
                {/* DW FastNet(Battery Back-up). */}
                <div className='routercardbox' style={{ padding: '0' }}>


                  {/* 6 */}
                  <div className='routercard' onClick={() => {


                     setSelselectplanebox2(false)

                    // setproductselected("DW SMART MIFI – MT10")

                    setproductselected(
                      {
                        "productname": "DW FastNet(Battery Back-up) 21",
                        "productPrice": "1099.00/-  One-time Activation+ 1st Month subscription on DW Light1 Package with inclusive 21GB data",
                        "productimg": "/routers pictures/AX9 pro/New folder/1.jpg"
                      }
                    )



                  }}>
                    <h2 style={{ margin: '0', color: 'black' }}>
                      DW FastNet(Battery Back-up) 21  </h2>

                    <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-start', textAlign: 'justify', color: 'black' }}>
                      <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-start', textAlign: 'justify', color: 'black', alignItems: 'center', marginBottom: '20px' }}>
                        Rating:

                        <div class="rating">
                          <span class="star">&#9733;</span>
                          <span class="star">&#9733;</span>
                          <span class="star">&#9733;</span>
                          <span class="star">&#9733;</span>
                          <span class="star">&#9733;</span>
                        </div>


                      </div>
                    </div>


                    <p style={{ width: '100%', fontWeight: 'bold', display: 'flex', margin: '0', justifyContent: 'flex-start', textAlign: 'left', color: 'red' }}>

                      <h4 style={{ borderRadius: '5px', alignSelf: "baseline", marginRight: '5px', color: 'black' }}>
                        Price</h4>
                      Ghc 1099.00/-
                    </p>
                    <p style={{ width: '100%', display: 'flex', margin: '0', justifyContent: 'flex-start', textAlign: 'left', color: 'black' }}>
                      One-time Activation <br /> +<br /> 1st Month subscription on DW Light1 Package with inclusive 45GB data
                    </p>



                    <div style={{ backgroundColor: '#044ff3', padding: "5px", color: 'white', display: 'flex', justifyContent: 'center' }}>Click to Buy this Product</div>
                  </div>




                  {/* 1 */}
                  <div className='routercard' onClick={() => {


                     setSelselectplanebox2(false)

                    // setproductselected("DW SMART MIFI – MT10")

                    setproductselected(
                      {
                        "productname": "DW FastNet(Battery Back-up) 35",
                        "productPrice": "1139.00/-  One-time Activation+ 1st Month subscription on DW Light1 Package with inclusive 35GB data",
                        "productimg": "/routers pictures/AX9 pro/New folder/1.jpg"
                      }
                    )



                  }}>
                    <h2 style={{ margin: '0', color: 'black' }}>
                      DW FastNet(Battery Back-up) 35 </h2>

                    <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-start', textAlign: 'justify', color: 'black' }}>
                      <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-start', textAlign: 'justify', color: 'black', alignItems: 'center', marginBottom: '20px' }}>
                        Rating:

                        <div class="rating">
                          <span class="star">&#9733;</span>
                          <span class="star">&#9733;</span>
                          <span class="star">&#9733;</span>
                          <span class="star">&#9733;</span>
                          <span class="star">&#9733;</span>
                        </div>


                      </div>
                    </div>


                    <p style={{ width: '100%', fontWeight: 'bold', display: 'flex', margin: '0', justifyContent: 'flex-start', textAlign: 'left', color: 'red' }}>

                      <h4 style={{ borderRadius: '5px', alignSelf: "baseline", marginRight: '5px', color: 'black' }}>
                        Price</h4>
                      Ghc 1139.00/-
                    </p>
                    <p style={{ width: '100%', display: 'flex', margin: '0', justifyContent: 'flex-start', textAlign: 'left', color: 'black' }}>
                      One-time Activation <br /> +<br /> 1st Month subscription on DW Light1 Package with inclusive 70GB data
                    </p>



                    <div style={{ backgroundColor: '#044ff3', padding: "5px", color: 'white', display: 'flex', justifyContent: 'center' }}>Click to Buy this Product</div>
                  </div>

                  {/* 2 */}

                  <div className='routercard' onClick={() => {


                     setSelselectplanebox2(false)

                    // setproductselected("DW SMART MIFI – MT10")

                    setproductselected(
                      {
                        "productname": "DW FastNet(Battery Back-up) 45",
                        "productPrice": "1169.00/-  One-time Activation+ 1st Month subscription on DW Light1 Package with inclusive 45GB data",
                        "productimg": "/routers pictures/AX9 pro/New folder/1.jpg"
                      }
                    )



                  }}>
                    <h2 style={{ margin: '0', color: 'black' }}>
                      DW FastNet(Battery Back-up) 45  </h2>

                    <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-start', textAlign: 'justify', color: 'black' }}>
                      <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-start', textAlign: 'justify', color: 'black', alignItems: 'center', marginBottom: '20px' }}>
                        Rating:

                        <div class="rating">
                          <span class="star">&#9733;</span>
                          <span class="star">&#9733;</span>
                          <span class="star">&#9733;</span>
                          <span class="star">&#9733;</span>
                          <span class="star">&#9733;</span>
                        </div>


                      </div>
                    </div>


                    <p style={{ width: '100%', fontWeight: 'bold', display: 'flex', margin: '0', justifyContent: 'flex-start', textAlign: 'left', color: 'red' }}>

                      <h4 style={{ borderRadius: '5px', alignSelf: "baseline", marginRight: '5px', color: 'black' }}>
                        Price</h4>
                      Ghc 1169.00/-
                    </p>
                    <p style={{ width: '100%', display: 'flex', margin: '0', justifyContent: 'flex-start', textAlign: 'left', color: 'black' }}>
                      One-time Activation <br /> +<br /> 1st Month subscription on DW Light1 Package with inclusive 100GB data
                    </p>



                    <div style={{ backgroundColor: '#044ff3', padding: "5px", color: 'white', display: 'flex', justifyContent: 'center' }}>Click to Buy this Product</div>
                  </div>


                  {/* 3 */}
                  <div className='routercard' onClick={() => {


                     setSelselectplanebox2(false)

                    // setproductselected("DW SMART MIFI – MT10")

                    setproductselected(
                      {
                        "productname": "DW FastNet(Battery Back-up) 75",
                        "productPrice": "1229.00/-  One-time Activation+ 1st Month subscription on DW Light1 Package with inclusive 75GB data",
                        "productimg": "/routers pictures/AX9 pro/New folder/1.jpg"
                      }
                    )



                  }}>
                    <h2 style={{ margin: '0', color: 'black' }}>
                      DW FastNet(Battery Back-up) 75 </h2>

                    <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-start', textAlign: 'justify', color: 'black' }}>
                      <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-start', textAlign: 'justify', color: 'black', alignItems: 'center', marginBottom: '20px' }}>
                        Rating:

                        <div class="rating">
                          <span class="star">&#9733;</span>
                          <span class="star">&#9733;</span>
                          <span class="star">&#9733;</span>
                          <span class="star">&#9733;</span>
                          <span class="star">&#9733;</span>
                        </div>


                      </div>
                    </div>


                    <p style={{ width: '100%', fontWeight: 'bold', display: 'flex', margin: '0', justifyContent: 'flex-start', textAlign: 'left', color: 'red' }}>

                      <h4 style={{ borderRadius: '5px', alignSelf: "baseline", marginRight: '5px', color: 'black' }}>
                        Price</h4>
                      Ghc 1229.00/-
                    </p>
                    <p style={{ width: '100%', display: 'flex', margin: '0', justifyContent: 'flex-start', textAlign: 'left', color: 'black' }}>
                      One-time Activation <br /> +<br /> 1st Month subscription on DW Light1 Package with inclusive 125GB data
                    </p>



                    <div style={{ backgroundColor: '#044ff3', padding: "5px", color: 'white', display: 'flex', justifyContent: 'center' }}>Click to Buy this Product</div>
                  </div>


                  {/* 4 */}
                  <div className='routercard' onClick={() => {


                     setSelselectplanebox2(false)

                    // setproductselected("DW SMART MIFI – MT10")

                    setproductselected(
                      {
                        "productname": "DW FastNet(Battery Back-up) 100",
                        "productPrice": "1279.00/-  One-time Activation+ 1st Month subscription on DW Light1 Package with inclusive 100GB data",
                        "productimg": "/routers pictures/AX9 pro/New folder/1.jpg"
                      }
                    )



                  }}>
                    <h2 style={{ margin: '0', color: 'black' }}>
                      DW FastNet(Battery Back-up) 100  </h2>

                    <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-start', textAlign: 'justify', color: 'black' }}>
                      <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-start', textAlign: 'justify', color: 'black', alignItems: 'center', marginBottom: '20px' }}>
                        Rating:

                        <div class="rating">
                          <span class="star">&#9733;</span>
                          <span class="star">&#9733;</span>
                          <span class="star">&#9733;</span>
                          <span class="star">&#9733;</span>
                          <span class="star">&#9733;</span>
                        </div>


                      </div>
                    </div>


                    <p style={{ width: '100%', fontWeight: 'bold', display: 'flex', margin: '0', justifyContent: 'flex-start', textAlign: 'left', color: 'red' }}>

                      <h4 style={{ borderRadius: '5px', alignSelf: "baseline", marginRight: '5px', color: 'black' }}>
                        Price</h4>
                      Ghc 1279.00/-
                    </p>
                    <p style={{ width: '100%', display: 'flex', margin: '0', justifyContent: 'flex-start', textAlign: 'left', color: 'black' }}>
                      One-time Activation <br /> +<br /> 1st Month subscription on DW Light1 Package with inclusive 155GB data
                    </p>



                    <div style={{ backgroundColor: '#044ff3', padding: "5px", color: 'white', display: 'flex', justifyContent: 'center' }}>Click to Buy this Product</div>
                  </div>

                  {/* 5 */}
                  <div className='routercard' onClick={() => {


                     setSelselectplanebox2(false)

                    // setproductselected("DW SMART MIFI – MT10")

                    setproductselected(
                      {
                        "productname": "DW FastNet(Battery Back-up) 180",
                        "productPrice": "1499.00/-  One-time Activation+ 1st Month subscription on DW Light1 Package with inclusive 180GB data",
                        "productimg": "/routers pictures/AX9 pro/New folder/1.jpg"
                      }
                    )



                  }}>
                    <h2 style={{ margin: '0', color: 'black' }}>
                      DW FastNet(Battery Back-up) 180  </h2>

                    <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-start', textAlign: 'justify', color: 'black' }}>
                      <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-start', textAlign: 'justify', color: 'black', alignItems: 'center', marginBottom: '20px' }}>
                        Rating:

                        <div class="rating">
                          <span class="star">&#9733;</span>
                          <span class="star">&#9733;</span>
                          <span class="star">&#9733;</span>
                          <span class="star">&#9733;</span>
                          <span class="star">&#9733;</span>
                        </div>


                      </div>
                    </div>


                    <p style={{ width: '100%', fontWeight: 'bold', display: 'flex', margin: '0', justifyContent: 'flex-start', textAlign: 'left', color: 'red' }}>

                      <h4 style={{ borderRadius: '5px', alignSelf: "baseline", marginRight: '5px', color: 'black' }}>
                        Price</h4>
                      Ghc 1599.00/-
                    </p>
                    <p style={{ width: '100%', display: 'flex', margin: '0', justifyContent: 'flex-start', textAlign: 'left', color: 'black' }}>
                      One-time Activation <br /> +<br /> 1st Month subscription on DW Light1 Package with inclusive 185GB data
                    </p>



                    <div style={{ backgroundColor: '#044ff3', padding: "5px", color: 'white', display: 'flex', justifyContent: 'center' }}>Click to Buy this Product</div>
                  </div>




                </div>
              </>}
              {routertype === true && <>
                {/* DW FastNet(No Battery) */}
                <div className='routercardbox' style={{ padding: '0' }}>


                  {/* 6 */}
                  <div className='routercard' onClick={() => {


                    setSelselectplanebox2(false)

                    // setproductselected("DW SMART MIFI – MT10")

                    setproductselected(
                      {
                        "productname": "DW FastNet - 21",
                        "productPrice": "929.00/-  One-time Activation+ 1st Month subscription on DW Light1 Package with inclusive 21GB data",
                        "productimg": "/routers pictures/AX6 pro/New folder/2.jpg"
                      }
                    )



                  }}>
                    <h2 style={{ margin: '0', color: 'black' }}>
                      DW FastNet - 21  </h2>

                    <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-start', textAlign: 'justify', color: 'black' }}>
                      <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-start', textAlign: 'justify', color: 'black', alignItems: 'center', marginBottom: '20px' }}>
                        Rating:

                        <div class="rating">
                          <span class="star">&#9733;</span>
                          <span class="star">&#9733;</span>
                          <span class="star">&#9733;</span>
                          <span class="star">&#9733;</span>
                          <span class="star">&#9733;</span>
                        </div>


                      </div>
                    </div>


                    <p style={{ width: '100%', fontWeight: 'bold', display: 'flex', margin: '0', justifyContent: 'flex-start', textAlign: 'left', color: 'red' }}>

                      <h4 style={{ borderRadius: '5px', alignSelf: "baseline", marginRight: '5px', color: 'black' }}>
                        Price</h4>
                      Ghc 929.00/-
                    </p>
                    <p style={{ width: '100%', display: 'flex', margin: '0', justifyContent: 'flex-start', textAlign: 'left', color: 'black' }}>
                      One-time Activation <br /> +<br /> 1st Month subscription on DW Light1 Package with inclusive 21GB data
                    </p>



                    <div style={{ backgroundColor: '#044ff3', padding: "5px", color: 'white', display: 'flex', justifyContent: 'center' }}>Click to Buy this Product</div>
                  </div>




                  {/* 1 */}
                  <div className='routercard' onClick={() => {


                    setSelselectplanebox2(false)

                    // setproductselected("DW SMART MIFI – MT10")

                    setproductselected(
                      {
                        "productname": "DW FastNet - 35",
                        "productPrice": "979.00/-  One-time Activation+ 1st Month subscription on DW Light1 Package with inclusive 35GB data",
                        "productimg": "/routers pictures/AX6 pro/New folder/2.jpg"
                      }
                    )



                  }}>
                    <h2 style={{ margin: '0', color: 'black' }}>
                      DW FastNet - 35 </h2>

                    <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-start', textAlign: 'justify', color: 'black' }}>
                      <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-start', textAlign: 'justify', color: 'black', alignItems: 'center', marginBottom: '20px' }}>
                        Rating:

                        <div class="rating">
                          <span class="star">&#9733;</span>
                          <span class="star">&#9733;</span>
                          <span class="star">&#9733;</span>
                          <span class="star">&#9733;</span>
                          <span class="star">&#9733;</span>
                        </div>


                      </div>
                    </div>


                    <p style={{ width: '100%', fontWeight: 'bold', display: 'flex', margin: '0', justifyContent: 'flex-start', textAlign: 'left', color: 'red' }}>

                      <h4 style={{ borderRadius: '5px', alignSelf: "baseline", marginRight: '5px', color: 'black' }}>
                        Price</h4>
                      Ghc 979.00/-
                    </p>
                    <p style={{ width: '100%', display: 'flex', margin: '0', justifyContent: 'flex-start', textAlign: 'left', color: 'black' }}>
                      One-time Activation <br /> +<br /> 1st Month subscription on DW Light1 Package with inclusive 35GB data
                    </p>



                    <div style={{ backgroundColor: '#044ff3', padding: "5px", color: 'white', display: 'flex', justifyContent: 'center' }}>Click to Buy this Product</div>
                  </div>

                  {/* 2 */}

                  <div className='routercard' onClick={() => {


                    setSelselectplanebox2(false)

                    // setproductselected("DW SMART MIFI – MT10")

                    setproductselected(
                      {
                        "productname": "DW FastNet - 45 ",
                        "productPrice": "999.00/-  One-time Activation+ 1st Month subscription on DW Light1 Package with inclusive 45GB data",
                        "productimg": "/routers pictures/AX6 pro/New folder/2.jpg"
                      }
                    )



                  }}>
                    <h2 style={{ margin: '0', color: 'black' }}>
                      DW FastNet - 45   </h2>

                    <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-start', textAlign: 'justify', color: 'black' }}>
                      <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-start', textAlign: 'justify', color: 'black', alignItems: 'center', marginBottom: '20px' }}>
                        Rating:

                        <div class="rating">
                          <span class="star">&#9733;</span>
                          <span class="star">&#9733;</span>
                          <span class="star">&#9733;</span>
                          <span class="star">&#9733;</span>
                          <span class="star">&#9733;</span>
                        </div>


                      </div>
                    </div>


                    <p style={{ width: '100%', fontWeight: 'bold', display: 'flex', margin: '0', justifyContent: 'flex-start', textAlign: 'left', color: 'red' }}>

                      <h4 style={{ borderRadius: '5px', alignSelf: "baseline", marginRight: '5px', color: 'black' }}>
                        Price</h4>
                      Ghc 999.00/-
                    </p>
                    <p style={{ width: '100%', display: 'flex', margin: '0', justifyContent: 'flex-start', textAlign: 'left', color: 'black' }}>
                      One-time Activation <br /> +<br /> 1st Month subscription on DW Light1 Package with inclusive 45GB data
                    </p>



                    <div style={{ backgroundColor: '#044ff3', padding: "5px", color: 'white', display: 'flex', justifyContent: 'center' }}>Click to Buy this Product</div>
                  </div>


                  {/* 3 */}
                  <div className='routercard' onClick={() => {


                    setSelselectplanebox2(false)

                    // setproductselected("DW SMART MIFI – MT10")

                    setproductselected(
                      {
                        "productname": "DW FastNet - 75",
                        "productPrice": "1,069.00/-  One-time Activation+ 1st Month subscription on DW Light1 Package with inclusive 75GB data",
                        "productimg": "/routers pictures/AX6 pro/New folder/2.jpg"
                      }
                    )



                  }}>
                    <h2 style={{ margin: '0', color: 'black' }}>
                      DW FastNet - 75 </h2>

                    <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-start', textAlign: 'justify', color: 'black' }}>
                      <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-start', textAlign: 'justify', color: 'black', alignItems: 'center', marginBottom: '20px' }}>
                        Rating:

                        <div class="rating">
                          <span class="star">&#9733;</span>
                          <span class="star">&#9733;</span>
                          <span class="star">&#9733;</span>
                          <span class="star">&#9733;</span>
                          <span class="star">&#9733;</span>
                        </div>


                      </div>
                    </div>


                    <p style={{ width: '100%', fontWeight: 'bold', display: 'flex', margin: '0', justifyContent: 'flex-start', textAlign: 'left', color: 'red' }}>

                      <h4 style={{ borderRadius: '5px', alignSelf: "baseline", marginRight: '5px', color: 'black' }}>
                        Price</h4>
                      Ghc 1,069.00/-
                    </p>
                    <p style={{ width: '100%', display: 'flex', margin: '0', justifyContent: 'flex-start', textAlign: 'left', color: 'black' }}>
                      One-time Activation <br /> +<br /> 1st Month subscription on DW Light1 Package with inclusive 75GB data
                    </p>



                    <div style={{ backgroundColor: '#044ff3', padding: "5px", color: 'white', display: 'flex', justifyContent: 'center' }}>Click to Buy this Product</div>
                  </div>


                  {/* 4 */}
                  <div className='routercard' onClick={() => {


                    setSelselectplanebox2(false)

                    // setproductselected("DW SMART MIFI – MT10")

                    setproductselected(
                      {
                        "productname": "DW FastNet - 100",
                        "productPrice": "1,109.00/-  One-time Activation+ 1st Month subscription on DW Light1 Package with inclusive 100GB data",
                        "productimg": "/routers pictures/AX6 pro/New folder/2.jpg"
                      }
                    )



                  }}>
                    <h2 style={{ margin: '0', color: 'black' }}>
                      DW FastNet - 100  </h2>

                    <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-start', textAlign: 'justify', color: 'black' }}>
                      <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-start', textAlign: 'justify', color: 'black', alignItems: 'center', marginBottom: '20px' }}>
                        Rating:

                        <div class="rating">
                          <span class="star">&#9733;</span>
                          <span class="star">&#9733;</span>
                          <span class="star">&#9733;</span>
                          <span class="star">&#9733;</span>
                          <span class="star">&#9733;</span>
                        </div>


                      </div>
                    </div>


                    <p style={{ width: '100%', fontWeight: 'bold', display: 'flex', margin: '0', justifyContent: 'flex-start', textAlign: 'left', color: 'red' }}>

                      <h4 style={{ borderRadius: '5px', alignSelf: "baseline", marginRight: '5px', color: 'black' }}>
                        Price</h4>
                      Ghc 1,109.00/-
                    </p>
                    <p style={{ width: '100%', display: 'flex', margin: '0', justifyContent: 'flex-start', textAlign: 'left', color: 'black' }}>
                      One-time Activation <br /> +<br /> 1st Month subscription on DW Light1 Package with inclusive 100GB data
                    </p>



                    <div style={{ backgroundColor: '#044ff3', padding: "5px", color: 'white', display: 'flex', justifyContent: 'center' }}>Click to Buy this Product</div>
                  </div>

                  {/* 5 */}
                  <div className='routercard' onClick={() => {


                    setSelselectplanebox2(false)

                    // setproductselected("DW SMART MIFI – MT10")

                    setproductselected(
                      {
                        "productname": "DW FastNet - 180",
                        "productPrice": "1,329.00/-  One-time Activation+ 1st Month subscription on DW Light1 Package with inclusive 180GB data",
                        "productimg": "/routers pictures/AX6 pro/New folder/2.jpg"
                      }
                    )



                  }}>
                    <h2 style={{ margin: '0', color: 'black' }}>
                      DW FastNet - 180  </h2>

                    <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-start', textAlign: 'justify', color: 'black' }}>
                      <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-start', textAlign: 'justify', color: 'black', alignItems: 'center', marginBottom: '20px' }}>
                        Rating:

                        <div class="rating">
                          <span class="star">&#9733;</span>
                          <span class="star">&#9733;</span>
                          <span class="star">&#9733;</span>
                          <span class="star">&#9733;</span>
                          <span class="star">&#9733;</span>
                        </div>


                      </div>
                    </div>


                    <p style={{ width: '100%', fontWeight: 'bold', display: 'flex', margin: '0', justifyContent: 'flex-start', textAlign: 'left', color: 'red' }}>

                      <h4 style={{ borderRadius: '5px', alignSelf: "baseline", marginRight: '5px', color: 'black' }}>
                        Price</h4>
                      Ghc 1,329.00/-
                    </p>
                    <p style={{ width: '100%', display: 'flex', margin: '0', justifyContent: 'flex-start', textAlign: 'left', color: 'black' }}>
                      One-time Activation <br /> +<br /> 1st Month subscription on DW Light1 Package with inclusive 180GB data
                    </p>



                    <div style={{ backgroundColor: '#044ff3', padding: "5px", color: 'white', display: 'flex', justifyContent: 'center' }}>Click to Buy this Product</div>
                  </div>





                </div>
              </>}

              <div className='buttoonn'>
                <button onClick={() => {

                  setSelselectplanebox(false)
                  setSelectedCell(null)

                }}>Cancel</button>
                {/* <button
                  // onClick={async (e) => {

                  //   await console.log(cookieproduct);
                  //   const newProduct = {
                  //     "productname": productselected.productname,
                  //     "productprice": productselected.productPrice,
                  //     "productimg": productselected.productimg,
                  //     "dataplane": selectedCell
                  //   };




                  //   const newupdateddata = [...cookieproduct, newProduct]



                  //   await console.log(cookieproduct)


                  //   const updatedProductJSON = JSON.stringify(newupdateddata);

                  //   await Cookies.set('Product', updatedProductJSON, { expires: 7 });

                  //   await navigate("/cart")

                  // }}


                >Continue</button> */}
              </div>


            </>}
            {selselectplanebox2 === false && <>

              <h3 style={{ borderRadius: '5px', backgroundColor: '#0587EF', padding: "5px", alignSelf: "baseline", margin: '0', color: 'White' }}>
                Kindly select a data plan to proceed further.                     </h3>
              <div style={{ width: '90%', display: 'flex', justifyContent: 'flex-start', textAlign: 'justify', color: 'black' }}>
                Contract Duration one month.
              </div>


              <table className='data-plans-table'>
                <thead>
                  <tr>

                    <th>Data</th>
                    <th>Price</th>


                  </tr>
                </thead>
                <tbody>

                  <tr >
                    <th>10.5GB Data</th>
                    <td
                      className={selectedCell === '10.5GB Data for 1 month in GHc75' ? 'selected-cell' : ''}
                      onClick={async () => {
                        await setSelectedCell('10.5GB Data for 1 month in GHc75');

                        await setproductselected({ ...productselected, selectedCell })

                      }

                      }
                    >GHc75</td>


                  </tr>


                  <tr >
                    <th>12.5GB Data</th>
                    <td
                      className={selectedCell === '12.5GB Data for 1 month in GHc85' ? 'selected-cell' : ''}
                      onClick={() => setSelectedCell('12.5GB Data for 1 month in GHc85')}
                    >GHc85</td>

                  </tr>


                  <tr >
                    <th>15GB Data</th>
                    <td
                      className={selectedCell === '15GB Data for 1 month in GHc100' ? 'selected-cell' : ''}
                      onClick={() => setSelectedCell('15GB Data for 1 month in GHc100')}
                    >GHc100</td>

                  </tr>

                  {showallplans === true && <>

                    <tr >
                      <th>21GB Data</th>
                      <td
                        className={selectedCell === '21GB Data for 1 month in GHc140' ? 'selected-cell' : ''}
                        onClick={() => setSelectedCell('21GB Data for 1 month in GHc140')}
                      >GHc140</td>

                    </tr>

                    <tr >
                      <th>35GB Data</th>
                      <td
                        className={selectedCell === '35GB Data for 1 month in GHc185' ? 'selected-cell' : ''}
                        onClick={() => setSelectedCell('35GB Data for 1 month in GHc185')}
                      >GHc185</td>

                    </tr>


                    <tr >
                      <th>45GB Data</th>
                      <td
                        className={selectedCell === '45GB Data for 1 month in GHc210' ? 'selected-cell' : ''}
                        onClick={() => setSelectedCell('45GB Data for 1 month in GHc210')}
                      >GHc210</td>

                    </tr>


                    <tr >
                      <th>75GB Data</th>
                      <td
                        className={selectedCell === '75GB Data for 1 month in GHc275' ? 'selected-cell' : ''}
                        onClick={() => setSelectedCell('75GB Data for 1 month in GHc275')}
                      >GHc275</td>

                    </tr>

                    <tr >
                      <th>100GB Data</th>
                      <td
                        className={selectedCell === '100GB Data for 1 month in GHc320' ? 'selected-cell' : ''}
                        onClick={() => setSelectedCell('100GB Data for 1 month in GHc320')}
                      >GHc320</td>

                    </tr>

                    <tr >
                      <th>150GB Data</th>
                      <td
                        className={selectedCell === '150GB Data for 1 month in GHc450' ? 'selected-cell' : ''}
                        onClick={() => setSelectedCell('150GB Data for 1 month in GHc450')}
                      >GHc450</td>

                    </tr>

                    <tr >
                      <th>180GB Data</th>
                      <td
                        className={selectedCell === '180GB Data for 1 month in GHc540' ? 'selected-cell' : ''}
                        onClick={() => setSelectedCell('180GB Data for 1 month in GHc540')}
                      >GHc540</td>

                    </tr>



                  </>}

                </tbody>
              </table>

              {showallplans === false && <>


                <h5 className='readmore' onClick={() => { setshowallplans(true) }} style={{ margin: "0", width: '100%', display: 'flex', justifyContent: 'end', textAlign: 'justify', color: 'black' }}>
                  Read More.
                </h5>

              </>}
              {showallplans === true && <>

                <h5 className='readmore' onClick={() => { setshowallplans(false) }} style={{ margin: "0", width: '100%', display: 'flex', justifyContent: 'end', textAlign: 'justify', color: 'black' }}>
                  Read Less.
                </h5>
              </>}



              <div className='buttoonn'>
                <button onClick={() => {

                  setSelselectplanebox(false)
                  setSelectedCell(null)

                }}>Cancel</button>
                <button
                  onClick={async (e) => {

                    await console.log(cookieproduct);
                    const newProduct = {
                      "productname": productselected.productname,
                      "productprice": productselected.productPrice,
                      "productimg": productselected.productimg,
                      "dataplane": selectedCell
                    };




                    const newupdateddata = [...cookieproduct, newProduct]



                    await console.log(cookieproduct)


                    const updatedProductJSON = JSON.stringify(newupdateddata);

                    await Cookies.set('Product', updatedProductJSON, { expires: 7 });

                    await navigate("/cart")

                  }}


                >Continue</button>
              </div>
            </>}


          </div>



        </div>

      </>}













    </div>
  )
}

export default FastPlugConnect