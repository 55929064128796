import React from 'react'
import { NavLink } from 'react-router-dom';
import Navbar from '../components/navbar';

function devices() {
  return (
    <div>

      <div style={{ marginTop: '110px' }}></div>

      <Navbar />



      <div className='mainpadding'>



        <h1 style={{ borderLeft: "5px solid #0587EF", textAlign: 'left', borderRadius: '5px', color: "rgb(70, 70, 70)", paddingLeft: "10px", alignSelf: "baseline" }}>
          Devices with internet</h1>

          <div className='divwithimages'>
          <div>
        <div style={{ width: '90%', display: 'flex', justifyContent: 'flex-start', paddingLeft: '17px', textAlign: 'justify', color: 'gray', marginBottom: '50px' }}>
          We provide on a subscription basis only, smart devices with internet connectivity. Our devices include tablets, laptops, smart TV’s and Android TV boxes.
        </div>
        <div style={{ width: '90%', display: 'flex', justifyContent: 'flex-start', paddingLeft: '17px', textAlign: 'justify', color: 'gray', marginBottom: '50px' }}>
          Customers can select devices bundled with connectivity and a desired term. There is even better news if you are a government worker or an approved corporate worker, we can work with you for more generous terms. At Data Warehouse GH, we “talk devices” and our customers comes first. It even gets better! All our Tablets, Phones, Laptops and Smart Tv’s comes fully insured, which means in an unlikely event of an insurable incident occurring, you be entitled to another device.
        </div>

        </div>
          <img alt='' src='/IANMGES/device-with-internet.png' />

        </div>

        <img src='https://thumbs.dreamstime.com/b/computer-laptop-tablet-phone-connection-29214561.jpg' alt='' width={"100%"} />


        <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-start', paddingLeft: '0px', textAlign: 'justify', color: 'gray' }}>


          <ul>
            <li>Smart TV’s with Internet (Coming Soon)</li>
            <li> Tablets with Internet (Coming Soon)</li>
            <li>   Laptops with Internet (Coming Soon)</li>
            <li>  Android Box with Internet (Coming Soon)</li>

          </ul>


        </div>


      </div>




    </div>
  )
}

export default devices