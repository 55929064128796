import React from 'react'
import Navbar from '../components/navbar'
import { Card } from 'antd';
import { useNavigate } from 'react-router-dom';

const { Meta } = Card;


function Dshop() {
    const navigate = useNavigate();
    return (



        <div>

            <div style={{ marginTop: '110px' }}></div>
            <Navbar />

            <div className='mainpadding'>
                <h1 style={{ borderLeft: "5px solid #0587EF", textAlign: 'left', borderRadius: '5px', color: "rgb(70, 70, 70)", paddingLeft: "10px", alignSelf: "baseline" }}>
                    D-Shop</h1>


                <div className='dshopmaindiv'>



                    <Card
                        onClick={() => navigate("/Internet&Broadband/FastPlugConnect")}
                        hoverable
                        style={{ width: 240 }}
                        cover={<img alt="example" src="/routers pictures/AX9 pro/New folder/1.jpg" />}
                    >
                        <Meta title="DW VOOM Connect" description="Upgrade with this 4G router." />
                    </Card>


                    <Card
                        onClick={() => navigate("/Internet&Broadband/FastPlugConnect")}
                        hoverable
                        style={{ width: 240 }}
                        cover={<img alt="example" src="/routers pictures/AX6 pro/New folder/2.jpg" />}
                    >
                        <Meta title="DW GO-Connect" description="Maximize connectivity with this 4G router." />
                    </Card>


                    <Card
                        onClick={() => navigate("/Internet&Broadband/Dongles")}
                        hoverable
                        style={{ width: 240 }}
                        cover={<img alt="example" src="/routers pictures/U90/New folder/4.jpg" />}
                    >
                        <Meta title="DW U90" description="Elevate your connectivity with this dongle." />
                    </Card>

                    <Card
                        onClick={() => navigate("/Internet&Broadband/Mifis")}
                        hoverable
                        style={{ width: 240 }}
                        cover={<img alt="example" src="/routers pictures/MT10/New folder/6.jpg" />}
                    >
                        <Meta title="DW SMART MIFI – MT10" description="Boost your connectivity with this MiFi." />
                    </Card>

                    <Card
                        onClick={() => navigate("/Internet&Broadband/Mifis")}
                        hoverable
                        style={{ width: 240 }}
                        cover={<img alt="example" src="/routers pictures/MT20/New folder/1.jpg" />}
                    >
                        <Meta title="DW SMART Mifi – MT20" description="Elevate your connectivity with this 4G MiFi." />
                    </Card>

                    <Card
                        onClick={() => navigate("/Internet&Broadband/Mifis")}
                        hoverable
                        style={{ width: 240 }}
                        cover={<img alt="example" src="/routers pictures/MF982/New folder/4.jpg" />}
                    >
                        <Meta title="DW SMART WIFI – MT25" description="Upgrade your connectivity with 4G MiFi." />
                    </Card>

                    <Card
                        onClick={() => navigate("/Internet&Broadband/Mifis")}
                        hoverable
                        style={{ width: 240 }}
                        cover={<img alt="example" src="/routers pictures/MT30/New folder/1.jpg" />}
                    >
                        <Meta title="DW SMART WIFI – MT30" description="Enhance your connectivity with 4G MiFi device." />
                    </Card>



                    <Card
                        onClick={() => navigate("/akwaabaconnect")}
                        hoverable
                        style={{ width: 240 }}
                        cover={<img alt="example" src="/routers pictures/MT30/New folder/3333333.jpg" />}
                    >
                        <Meta title="AKWAABA CONNECT" description="Enhance your connectivity with akwaaba connect" />
                    </Card>



                  


                 




                    


                   

                   













                </div>



            </div>

        </div>
    )
}

export default Dshop