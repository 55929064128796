import React, { useState, useEffect } from 'react'
import Navbar from "../../components/navbar"
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

function GroupConnect() {



  let navigate = useNavigate();






  const [firstname, setfirstname] = useState();
  const [lastname, setlastname] = useState();
  const [District, setDistrict] = useState();
  const [Town, setTown] = useState();
  const [ShreetAddress, setShreetAddress] = useState();

  const [City, setCity] = useState();
  const [State, setState] = useState();
  const [PostalCode, setPostalCode] = useState();
  const [PhoneNumber, setPhoneNumber] = useState();
  const [Email, setEmail] = useState();







  const sendemail = async (e) => {
    e.preventDefault()



    const url = 'https://api.sendinblue.com/v3/smtp/email';
    const apiKey = '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'; // Replace with your actual API key






    const htmlContent = `
    <div style="  padding: 10px; background-color: #fff;">

    <h1 style=" margin: 0;">Customer Details - HIGH SPEED FIBRE BROADBAND</h1>
    <div style="padding-left: 20px;border: 1px solid gray;">
        <div style="display: flex;">
            <h3 style=" width: 200px;"> full name</h3>
            <h3>: ${firstname} ${lastname}</h3>
        </div>
       
        <div style="display: flex;">
            <h3 style=" width: 200px;"> District</h3>
            <h3>: ${District}</h3>
        </div>
        <div style="display: flex;">
            <h3 style="width: 200px;"> Town</h3>
            <h3>: ${Town}</h3>
        </div>
      
        <div style="display: flex;">
            <h3 style=" width: 200px;">Address</h3>
            <h3>: ${ShreetAddress}</h3>
        </div>
       
        <div style="display: flex;">
            <h3 style="width: 200px;"> City</h3>
            <h3>: ${City}</h3>
        </div>
      
        <div style="display: flex;">
            <h3 style="width: 200px;"> State</h3>
            <h3>: ${State}</h3>
        </div>
      
        <div style="display: flex;">
            <h3 style="width: 200px;"> Digital Address</h3>
            <h3>: ${PostalCode}</h3>
        </div>
      
        <div style="display: flex;">
            <h3 style="width: 200px;"> contact number</h3>
            <h3>: ${PhoneNumber}</h3>
        </div>
        <div style="display: flex;">
            <h3 style="width: 200px;"> Email</h3>
            <h3>: ${Email}</h3>
        </div>
      
    </div>


   

</div>

`;





    const data = {
      sender: {
        name: 'Data Warehouse Gh',
        email: 'info@datawarehousegh.com'
      },
      to: [
        {
          email: "datawarehousegh@gmail.com",
          name: "Data Warehouse Gh"
        }
      ],
      subject: 'Group Connect',
      htmlContent
    };

    const headers = {
      'Content-Type': 'application/json',
      'api-key': apiKey
    };

    await axios.post(url, data, { headers })
      .then(response => {



        const updatedProductJSON = JSON.stringify([]);
        alert("Your Imformation has been successfully sent, and we will promptly reach out to you as soon as possible to provide further updates and assistance")

        console.log("OTP has been sent to ")
        navigate("/")
      }

      )
      .catch(error => {

        console.log(error)
      }
      );



  }




  return (
    <div>

      <div style={{ marginTop: '110px' }}></div>


      <Navbar />


      <div className='mainpadding'>
        <h1 style={{ borderLeft: "5px solid #0587EF", margin: '0', textAlign: 'left', borderRadius: '5px', color: "rgb(70, 70, 70)", paddingLeft: "10px", alignSelf: "baseline" }}>
          GROUP CONNECT</h1>
        <div className='divwithimages'>
          <div style={{textAlign:'justify',display:'flex',}}>
            <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-start', paddingLeft: '17px', textAlign: 'justify', color: 'black', marginBottom: '50px' }}>
              We know students works in groups, study in groups, network, and share ideas and even group buy. At Data Warehouse Gh, we are passionate about people and believe sharing the benefits a good deal is a win win for us all.
              <br />  Group Connect is simple – Subscribe to qualifying Data Warehouse services with you friends, group members, classmates, study group makes and Bingo – you all get special group discount. What more, they do not need to be on the same campus or the same academic institution to qualify and benefit.


            </div>

          </div>
          <img alt='' src='/IANMGES/JPEG-GROUP-CONNECT.jpg' />

        </div>
        <h2 style={{ borderRadius: '5px', width: '100%', paddingLeft: "15px", textAlign: 'left', margin: '0', color: 'black', alignSelf: "baseline" }}>
          Contact our sales team now!</h2>


















        <form onSubmit={sendemail} className='chectoutform' style={{ paddingLeft: '17px' }}>



          <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '30px' }}>
            <div style={{ display: "flex", flexDirection: 'column', textAlign: 'left', width: '48%' }}>
              <label>First Name*</label>
              <input required className='chectoutforminput' onChange={(e) => setfirstname(e.target.value)} />
            </div>


            <div style={{ display: "flex", flexDirection: 'column', textAlign: 'left', width: '48%' }}>
              <label>Last Name*</label>
              <input required className='chectoutforminput' onChange={(e) => setlastname(e.target.value)} />
            </div>


          </div>



          <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '30px' }}>
            <div style={{ display: "flex", flexDirection: 'column', textAlign: 'left', width: '48%' }}>
              <label>District</label>
              <input className='chectoutforminput' onChange={(e) => setDistrict(e.target.value)} />
            </div>


            <div style={{ display: "flex", flexDirection: 'column', textAlign: 'left', width: '48%' }}>
              <label>Town</label>
              <input className='chectoutforminput' onChange={(e) => setTown(e.target.value)} />
            </div>


          </div>



          <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '30px' }}>
            <div style={{ display: "flex", flexDirection: 'column', textAlign: 'left', width: '100%' }}>
              <label>Street Address*</label>
              <input required className='chectoutforminput' onChange={(e) => setShreetAddress(e.target.value)} />
            </div>





          </div>



          <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '30px', width: "100%" }}>
            <div style={{ display: "flex", flexDirection: 'column', textAlign: 'left', width: '30%' }}>
              <label>City</label>
              <input className='chectoutforminput' onChange={(e) => setCity(e.target.value)} />
            </div>


            <div style={{ display: "flex", flexDirection: 'column', textAlign: 'left', width: '30%' }}>
              <label>State</label>
              <input className='chectoutforminput' onChange={(e) => setState(e.target.value)} />
            </div>


            <div style={{ display: "flex", flexDirection: 'column', textAlign: 'left', width: '30%' }}>
              <label>Digital Address</label>
              <input className='chectoutforminput' onChange={(e) => setPostalCode(e.target.value)} />
            </div>

          </div>

          <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '30px' }}>
            <div style={{ display: "flex", flexDirection: 'column', textAlign: 'left', width: '48%' }}>
              <label>Phone Number*</label>
              <input type='number' required className='chectoutforminput' onChange={(e) => setPhoneNumber(e.target.value)} />
            </div>

            <div style={{ display: "flex", flexDirection: 'column', textAlign: 'left', width: '48%' }}>
              <label>Email*</label>
              <input type='email' required className='chectoutforminput' onChange={(e) => setEmail(e.target.value)} />
            </div>


          </div>

          <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '30px', width: '100%' }}>
            <div style={{ display: "flex", flexDirection: 'column', textAlign: 'left', width: '100%' }}>

              <button className='chectoutforminput2' type='submit' >proceed</button>
            </div>




          </div>


        </form>
















      </div>




    </div>
  )
}

export default GroupConnect