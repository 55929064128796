import React from 'react'
import Navbar from '../components/navbar'

function Studentplans() {
  return (<>
      <div style={{ marginTop: '110px' }}></div>

    <Navbar/>

    

    <iframe
        title="PDF Viewer"
        src="DATA WAREHOUSE STUDENTS  PRICING TABLE -1a.pdf" // Replace with the path or URL of your PDF file
        width="100%"
         height="1000vh" // Adjust the height as needed
      ></iframe>
    </>
 )
}

export default Studentplans