import React from 'react'
import Navbar from "../../components/navbar"


function Hostels() {
  return (
    <div>

      <div style={{ marginTop: '110px' }}></div>


      <Navbar />



      <div className='mainpadding'>
        <h1 style={{ borderLeft: "5px solid #0587EF", margin: '0', textAlign: 'left', borderRadius: '5px', color: "rgb(70, 70, 70)", paddingLeft: "10px", alignSelf: "baseline" }}>
          Internet @Public Places</h1>

          <div className='divwithimages'>
          <div>

        <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-start', paddingLeft: '17px', textAlign: 'justify', color: 'black', marginBottom: '50px' }}>
          Data Warehouse Gh Public Wi-Fi -nicknamed (Green Wifi) is coming soon to you’re a community and to a public place near you. Our goal is to provide reliable, efficient and value for money internet connection which can be confidently relied upon by individuals needing an internet connection while out and about. Soon, the GREEN WIFI will be common in restaurants, hotels, mosques, churches, and other public places.
        </div>


        <h2 style={{ borderRadius: '5px', width: '100%', paddingLeft: "15px", textAlign: 'left', margin: '0', color: 'black', alignSelf: "baseline" }}>
          Hostels</h2>



        <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-start', paddingLeft: '17px', textAlign: 'justify', color: 'black', marginBottom: '50px' }}>
          Why do hostels need Wi-Fi?
        <br/>  In today’s world and especially post covid-19 global environment, hybrid and online learning and delivery of lectures is now common stream. Students needs fast, reliable and value for money connectivity and access to internet to stay on top of their academic work. Data Warehouse GREEN WIFI infrastructure in hostels project is to enhance the accessibility of internet for academic purposes and to browse exclusive online resource (licensed online journals) of the University for students/faculty members and staffs.
        </div>
        </div>
          <img alt='' src='/IANMGES/hostel-connect.png' />

        </div>

        <h2 style={{ borderRadius: '5px', width: '100%', paddingLeft: "15px", textAlign: 'left', margin: '0', color: 'black', alignSelf: "baseline" }}>
        Benefits of GREEN Wi-Fi Solutions for Hostels</h2>



        <div style={{ width: '100%', display: 'flex',flexDirection:'column', justifyContent: 'flex-start', paddingLeft: '17px', textAlign: 'justify', color: 'black', marginBottom: '50px' }}>
         <li>Easier Collaboration. ...</li>
         <li>Learning Beyond the Classroom Walls. ...</li>
         <li>Personalized Instruction. ...</li>
         <li>Accelerating Project Completion. ...</li>
         <li>Cost Savings – cost less than 50% of what students currently spend.</li>
         <li>Making Education More Interactive. ...</li>
         <li>Increased Engagement.</li>
        

<br/>

<br/>

         We are actively looking out for hostel owners to partner – so we can together bring the much needed reliable, fast, and cost saving, value for money internet connectivity to students. 

         </div>





      </div>




    </div>
  )
}

export default Hostels