import React, { useState, useEffect } from 'react'
import Navbar from "../../components/navbar"
import axios from 'axios';
import { useNavigate } from 'react-router-dom';


function HostelsWifi() {





  let navigate = useNavigate();






  const [firstname, setfirstname] = useState();
  const [lastname, setlastname] = useState();
  const [District, setDistrict] = useState();
  const [Town, setTown] = useState();
  const [ShreetAddress, setShreetAddress] = useState();

  const [City, setCity] = useState();
  const [State, setState] = useState();
  const [PostalCode, setPostalCode] = useState();
  const [PhoneNumber, setPhoneNumber] = useState();
  const [Email, setEmail] = useState();







   const sendemail = async (e) => {
    e.preventDefault()



    const url = 'https://api.sendinblue.com/v3/smtp/email';
    const apiKey = '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'; // Replace with your actual API key






    const htmlContent = `
    <div style="  padding: 10px; background-color: #fff;">

    <h1 style=" margin: 0;">Customer Details - HIGH SPEED FIBRE BROADBAND</h1>
    <div style="padding-left: 20px;border: 1px solid gray;">
        <div style="display: flex;">
            <h3 style=" width: 200px;"> full name</h3>
            <h3>: ${firstname} ${lastname}</h3>
        </div>
       
        <div style="display: flex;">
            <h3 style=" width: 200px;"> District</h3>
            <h3>: ${District}</h3>
        </div>
        <div style="display: flex;">
            <h3 style="width: 200px;"> Town</h3>
            <h3>: ${Town}</h3>
        </div>
      
        <div style="display: flex;">
            <h3 style=" width: 200px;">Address</h3>
            <h3>: ${ShreetAddress}</h3>
        </div>
       
        <div style="display: flex;">
            <h3 style="width: 200px;"> City</h3>
            <h3>: ${City}</h3>
        </div>
      
        <div style="display: flex;">
            <h3 style="width: 200px;"> State</h3>
            <h3>: ${State}</h3>
        </div>
      
        <div style="display: flex;">
            <h3 style="width: 200px;"> Digital Address</h3>
            <h3>: ${PostalCode}</h3>
        </div>
      
        <div style="display: flex;">
            <h3 style="width: 200px;"> contact number</h3>
            <h3>: ${PhoneNumber}</h3>
        </div>
        <div style="display: flex;">
            <h3 style="width: 200px;"> Email</h3>
            <h3>: ${Email}</h3>
        </div>
      
    </div>


   

</div>

`;





    const data = {
      sender: {
        name: 'Data Warehouse Gh',
        email: 'info@datawarehousegh.com'
      },
      to: [
        {
          email: "datawarehousegh@gmail.com",
          name: "Data Warehouse Gh"
        }
      ],
      subject: 'Hostel Wifi',
      htmlContent
    };

    const headers = {
      'Content-Type': 'application/json',
      'api-key': apiKey
    };

    await axios.post(url, data, { headers })
      .then(response => {



        const updatedProductJSON = JSON.stringify([]);
        alert("Your Imformation has been successfully sent, and we will promptly reach out to you as soon as possible to provide further updates and assistance")

        console.log("OTP has been sent to ")
        navigate("/")
      }

      )
      .catch(error => {

        console.log(error)
      }
      );



  }


















  return (
    <div>

      <div style={{ marginTop: '110px' }}></div>


      <Navbar />



      <div className='mainpadding'>
        <h1 style={{ borderLeft: "5px solid #0587EF", margin: '0', textAlign: 'left', borderRadius: '5px', color: "rgb(70, 70, 70)", paddingLeft: "10px", alignSelf: "baseline" }}>
          HOSTEL CONNECT</h1>

        <h2 style={{ borderRadius: '5px', width: '100%', paddingLeft: "15px", textAlign: 'left', margin: '0', color: 'black', alignSelf: "baseline" }}>
          We make connectivity easy.</h2>
        <h4 style={{ borderRadius: '5px', width: '100%', paddingLeft: "15px", textAlign: 'left', margin: '0', color: 'black', alignSelf: "baseline" }}>
          Connectivity solutions for off-campus student housing</h4>

        <div style={{ width: '100%', display: 'flex',flexDirection:'column', justifyContent: 'flex-start', paddingLeft: '17px', textAlign: 'justify', color: 'black', marginBottom: '50px' }}>
          We see the big picture. The key to attracting students to your hostel property is giving them an experience they can’t get anywhere else. As a partner who shares your passion for resident happiness and success, we take care of helping your residents stay connected so you can stay focused on what you do best.
          <br /><br />  Our Hostel Connect network and plan is designed from the ground up, with students, by students and for students and customized to your property and built to handle student resident connectivity demands.
          <li>  Hostel Connect guaranteed continues access to the internet.</li>
          <li>Around-the-clock network monitoring, onsite and phone support</li>
          <li>Personal area networking (PAN) for like-home streaming, casting, device-sharing and more.</li>


        </div>


        <h2 style={{ borderRadius: '5px', width: '100%', paddingLeft: "15px", textAlign: 'left', margin: '0', color: 'black', alignSelf: "baseline" }}>
          Connect with a partner you can count on.</h2>




        <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-start', paddingLeft: '17px', textAlign: 'justify', color: 'black', marginBottom: '50px' }}>

          Find out what Data Warehouse can do for you. Give us a call at +233 534 629 299, email us at  info@datawarehousegh.com, or fill out the form below to provide further updates and assistance.


        </div>













        <form onSubmit={sendemail} className='chectoutform' style={{ paddingLeft: '17px' }}>



          <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '30px' }}>
            <div style={{ display: "flex", flexDirection: 'column', textAlign: 'left', width: '48%' }}>
              <label>First Name*</label>
             <input required className='chectoutforminput' onChange={(e) => setfirstname(e.target.value)} />
            </div>


            <div style={{ display: "flex", flexDirection: 'column', textAlign: 'left', width: '48%' }}>
              <label>Last Name*</label>
               <input required className='chectoutforminput' onChange={(e) => setlastname(e.target.value)} />
            </div>


          </div>



          <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '30px' }}>
            <div style={{ display: "flex", flexDirection: 'column', textAlign: 'left', width: '48%' }}>
              <label>District</label>
              <input className='chectoutforminput' onChange={(e) => setDistrict(e.target.value)} />
            </div>


            <div style={{ display: "flex", flexDirection: 'column', textAlign: 'left', width: '48%' }}>
              <label>Town</label>
              <input className='chectoutforminput' onChange={(e) => setTown(e.target.value)} />
            </div>


          </div>



          <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '30px' }}>
            <div style={{ display: "flex", flexDirection: 'column', textAlign: 'left', width: '100%' }}>
              <label>Street Address*</label>
             <input required className='chectoutforminput' onChange={(e) => setShreetAddress(e.target.value)} />
            </div>





          </div>



          <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '30px', width: "100%" }}>
            <div style={{ display: "flex", flexDirection: 'column', textAlign: 'left', width: '30%' }}>
              <label>City*</label>
              <input className='chectoutforminput' onChange={(e) => setCity(e.target.value)} />
            </div>


            <div style={{ display: "flex", flexDirection: 'column', textAlign: 'left', width: '30%' }}>
              <label>State*</label>
              <input className='chectoutforminput' onChange={(e) => setState(e.target.value)} />
            </div>


            <div style={{ display: "flex", flexDirection: 'column', textAlign: 'left', width: '30%' }}>
              <label>Digital Address*</label>
              <input className='chectoutforminput' onChange={(e) => setPostalCode(e.target.value)} />
            </div>

          </div>

          <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '30px' }}>
            <div style={{ display: "flex", flexDirection: 'column', textAlign: 'left', width: '48%' }}>
              <label>Phone Number*</label>
              <input type='number' required className='chectoutforminput' onChange={(e) => setPhoneNumber(e.target.value)} />
            </div>

            <div style={{ display: "flex", flexDirection: 'column', textAlign: 'left', width: '48%' }}>
              <label>Email*</label>
                <input type='email' required className='chectoutforminput' onChange={(e) => setEmail(e.target.value)} />
            </div>


          </div>

          <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '30px', width: '100%' }}>
            <div style={{ display: "flex", flexDirection: 'column', textAlign: 'left', width: '100%' }}>

              <button className='chectoutforminput2'  type='submit' >Sent</button>
            </div>




          </div>


        </form>
















      </div>











    </div>
  )
}

export default HostelsWifi