import React, { useState } from 'react'
import { NavLink } from 'react-router-dom';
import "../css/product.css"
import { Collapse } from 'antd';
import Navbar from '../components/navbar';



const { Panel } = Collapse;
// const { Meta } = Card;


function Internetdroadband() {
  const [showallplans, setshowallplans] = useState(false);
  const [showcheckout, setshowcheckout] = useState(true);




  return (
    <div >









      <div style={{ marginTop: '110px' }}></div>







<Navbar/>

      {/* <nav className="navbar2">
        <ul className="nav-list2mob">
          <li className="nav-item2"><NavLink exact to="/Internet&Broadband" activeClassName="active">Internet & Broadband</NavLink></li>
          <li className="nav-item2"><NavLink to="/Devices" activeClassName="active">Devices</NavLink></li>
          <li className="nav-item2"><NavLink to="/Deals&Promos" activeClassName="active">Deals & Promos</NavLink></li>


          <li className="nav-item2"><NavLink to="/Student" activeClassName="active">Student</NavLink></li>
        </ul>

        <ul className="nav-list2mob">
          <li className="nav-item2"><NavLink to="/Workers" activeClassName="active">Workers (Coming Soon)</NavLink></li>
          <li className="nav-item2"><NavLink to="/Support" activeClassName="active">Support</NavLink></li>

          <li className="nav-item2"><NavLink to="/Business" activeClassName="active">Business (Coming Soon)</NavLink></li>
        </ul>
      </nav> */}












      <div className='mainpro'>


        <h1 style={{ borderLeft: "5px solid #0587EF", borderRadius: '5px', color: "rgb(70, 70, 70)", paddingLeft: "10px", alignSelf: "baseline" }}>
          Internet & Broadband</h1>


        <div className='productmainpro'>
          {/* <div className='productmainleft'>











            <Collapse style={{ width: '100%', color: '#rgb(240, 230, 230)', backgroundColor: "#rgb(240, 230, 230)", marginBottom: '50px' }} accordion>




              <Panel style={{ color: '#rgb(240, 230, 230)' }} header={<><div>ALL</div></>} key="0">
                <div style={{ width: '90%', display: 'flex', flexWrap: 'wrap', justifyContent: 'center', gap: '10px' }}>

                  <div style={{ display: "flex", flexDirection: 'column', justifyContent: 'flex-start', padding: '0 20px', alignItems: 'baseline', width: '100%' }}>

                    <NavLink style={{ textDecoration: 'none', color: 'gray' }} >All Products</NavLink>


                  </div>

                </div>


              </Panel>


              <Panel style={{ color: '#rgb(240, 230, 230)' }} header={<><div>Home & Small Business</div></>} key="1">
                <div style={{ width: '90%', display: 'flex', flexWrap: 'wrap', justifyContent: 'center', gap: '10px' }}>


                  <div style={{ display: "flex", flexDirection: 'column', justifyContent: 'flex-start', padding: '0 20px', alignItems: 'baseline', width: '100%' }}>

                    <NavLink style={{ textDecoration: 'none', color: 'gray' }} >Fast Plug & Connect</NavLink>
                    <NavLink style={{ textDecoration: 'none', color: 'gray' }} >High Speed Fiber Broadband</NavLink>
                    <NavLink style={{ textDecoration: 'none', color: 'gray' }} >Cloud Solutions</NavLink>
                    <NavLink style={{ textDecoration: 'none', color: 'gray' }} >Radio Radios</NavLink>

                  </div>

                </div>


              </Panel>





              <Panel style={{ color: '#rgb(240, 230, 230)' }} header={<><div>Internet On The Go</div></>} key="2">
                <div style={{ width: '90%', display: 'flex', flexWrap: 'wrap', justifyContent: 'center', gap: '10px' }}>


                  <div style={{ display: "flex", flexDirection: 'column', justifyContent: 'flex-start', padding: '0 20px', alignItems: 'baseline', width: '100%' }}>

                    <NavLink style={{ textDecoration: 'none', color: 'gray' }} >Mifis</NavLink>
                    <NavLink style={{ textDecoration: 'none', color: 'gray' }} >Dongles</NavLink>

                  </div>
                </div>


              </Panel>





              <Panel style={{ color: '#rgb(240, 230, 230)' }} header={<><div>Internet @ Public Places</div></>} key="3">
                <div style={{ width: '90%', display: 'flex', flexWrap: 'wrap', justifyContent: 'center', gap: '10px' }}>


                  <div style={{ display: "flex", flexDirection: 'column', justifyContent: 'flex-start', padding: '0 20px', alignItems: 'baseline', width: '100%' }}>

                    <NavLink style={{ textDecoration: 'none', color: 'gray' }} >Commercial Public Places</NavLink>
                    <NavLink style={{ textDecoration: 'none', color: 'gray' }} >Hostels</NavLink>
                    <NavLink style={{ textDecoration: 'none', color: 'gray' }} >Churc2hes</NavLink>
                    <NavLink style={{ textDecoration: 'none', color: 'gray' }} >Mosques</NavLink>

                  </div>
                </div>


              </Panel>

              <Panel style={{ color: '#rgb(240, 230, 230)' }} header={<><div>community WI-FI Zone</div></>} key="4">
                <div style={{ width: '90%', display: 'flex', flexWrap: 'wrap', justifyContent: 'center', gap: '10px' }}>


                  <div style={{ display: "flex", flexDirection: 'column', justifyContent: 'flex-start', padding: '0 20px', alignItems: 'baseline', width: '100%' }}>

                    <NavLink style={{ textDecoration: 'none', color: 'gray' }} >community WI-FI Zone</NavLink>


                  </div>

                </div>



              </Panel>






            </Collapse>













          </div> */}




          {/* <div className='productmainright'>


            {showcheckout === true && <>



              <div className='procarddd'>

                <div className='subprocard'>

                  <div className='subprocardImg'>
                    <img src='https://w7.pngwing.com/pngs/892/710/png-transparent-huawei-e5577cs-321-mifi-mobile-phones-mobile-case-electronics-3g-mobile-phones-thumbnail.png' alt="profile" height={"100%"} width={"100%"} />
                  </div>


                  <div className='subprocarddescrib'>
                    <h3 style={{ borderRadius: '5px', paddingLeft: "15px", alignSelf: "baseline", margin: '0', color: 'gray' }}>
                      4g LTE Advanced Mobile Wifi</h3>

                    <div style={{ width: '90%', display: 'flex', justifyContent: 'flex-start', paddingLeft: '17px', textAlign: 'justify', color: 'gray' }}>
                      Also known as mobile internet or MiFi. Mobile Broadband is the Wi-Fi connection you can take with you. Connect up to 10 devices with Mobile Wi-Fi.
                    </div>
                  </div>

                </div>



                <div className='subprocardfeature'>
                  <h3 style={{ borderRadius: '5px', paddingLeft: "15px", alignSelf: "baseline", margin: '0', color: 'gray' }}>
                    Features</h3>

                  <ul style={{ margin: '0',fontSize:"0.7em" }}>
                    <li>Mobile Hotspot</li>
                    <li>4G LTE</li>
                    <li>Upto 10 users, 50m distance</li>
                    <li>150mbps download speed</li>
                     <li>2100mah Battery</li>
                
                     
                  
                    
                  </ul>

                  <h5 onClick={()=>setshowcheckout(false)} style={{ borderRadius: '5px', paddingLeft: "15px", alignSelf: "baseline", margin: '0', color: 'gray' }}>
                    View Plans Full Detals</h5>
                </div>


                <div className='subprocardprice'>
                  <h3 style={{ borderRadius: '5px', paddingLeft: "15px", alignSelf: "baseline", margin: '0', color: 'gray' }}>
                    Price</h3>

                  <p style={{ width: '100%', fontWeight: 'bold', paddingLeft: "15px", display: 'flex', margin: '0', justifyContent: 'flex-start', textAlign: 'left', color: 'red' }}>
                    GHc 650.00
                  </p>
                  <p style={{ width: '100%', paddingLeft: "15px", display: 'flex', margin: '0', justifyContent: 'flex-start', textAlign: 'left', color: 'gray' }}>
                    One-time Activation <br /> +<br /> 1st Month subscription
                  </p>

                </div>


                <div className='subprocardbutton'>
                  <button>ADD TO CART</button>
                </div>

              </div>




              <div className='procarddd'>

                <div className='subprocard'>

                  <div className='subprocardImg'>
                    <img src='/images/indexupercardright.jpg' alt="profile" height={"100%"} width={"100%"} />
                  </div>


                  <div className='subprocarddescrib'>
                    <h3 style={{ borderRadius: '5px', paddingLeft: "15px", alignSelf: "baseline", margin: '0', color: 'gray' }}>
                      4g LTE Advanced Mobile Wifi</h3>

                    <div style={{ width: '90%', display: 'flex', justifyContent: 'flex-start', paddingLeft: '17px', textAlign: 'justify', color: 'gray' }}>
                      Also known as mobile internet or MiFi. Mobile Broadband is the Wi-Fi connection you can take with you. Connect up to 10 devices with Mobile Wi-Fi.
                    </div>
                  </div>

                </div>



                <div className='subprocardfeature'>
                  <h3 style={{ borderRadius: '5px', paddingLeft: "15px", alignSelf: "baseline", margin: '0', color: 'gray' }}>
                    Features</h3>

                 <ul style={{ margin: '0',fontSize:"0.6em" }}>
                    <li>Mobile Hotspot</li>
                    <li>4G LTE</li>
                    <li>Upto 10 users, 50m distance</li>
                    <li>150mbps download speed</li>
                     <li>2100mah Battery</li>
                     <li>Supports USB interface charging.</li>
                     <li>Multiband</li>
                     <li>Global Operators Compatibility	</li>
                    
                  </ul>
                </div>


                <div className='subprocardprice'>
                  <h3 style={{ borderRadius: '5px', paddingLeft: "15px", alignSelf: "baseline", margin: '0', color: 'gray' }}>
                    Price</h3>

                  <p style={{ width: '100%', fontWeight: 'bold', paddingLeft: "15px", display: 'flex', margin: '0', justifyContent: 'flex-start', textAlign: 'left', color: 'red' }}>
                    GHc 650.00
                  </p>
                  <p style={{ width: '100%', paddingLeft: "15px", display: 'flex', margin: '0', justifyContent: 'flex-start', textAlign: 'left', color: 'gray' }}>
                    One-time Activation <br /> +<br /> 1st Month subscription
                  </p>

                </div>


                <div className='subprocardbutton'>
                  <button>ADD TO CART</button>
                </div>

              </div>









              <div className='procarddd'>

                <div className='subprocard'>

                  <div className='subprocardImg'>
                    <img src='/images/indexupercardright.jpg' alt="profile" height={"100%"} width={"100%"} />
                  </div>


                  <div className='subprocarddescrib'>
                    <h3 style={{ borderRadius: '5px', paddingLeft: "15px", alignSelf: "baseline", margin: '0', color: 'gray' }}>
                      4g LTE Advanced Mobile Wifi</h3>

                    <div style={{ width: '90%', display: 'flex', justifyContent: 'flex-start', paddingLeft: '17px', textAlign: 'justify', color: 'gray' }}>
                      Also known as mobile internet or MiFi. Mobile Broadband is the Wi-Fi connection you can take with you. Connect up to 10 devices with Mobile Wi-Fi.
                    </div>
                  </div>

                </div>



                <div className='subprocardfeature'>
                  <h3 style={{ borderRadius: '5px', paddingLeft: "15px", alignSelf: "baseline", margin: '0', color: 'gray' }}>
                    Features</h3>

                 <ul style={{ margin: '0',fontSize:"0.6em" }}>
                    <li>Mobile Hotspot</li>
                    <li>4G LTE</li>
                    <li>Upto 10 users, 50m distance</li>
                    <li>150mbps download speed</li>
                     <li>2100mah Battery</li>
                     <li>Supports USB interface charging.</li>
                     <li>Multiband</li>
                     <li>Global Operators Compatibility	</li>
                    
                  </ul>
                </div>


                <div className='subprocardprice'>
                  <h3 style={{ borderRadius: '5px', paddingLeft: "15px", alignSelf: "baseline", margin: '0', color: 'gray' }}>
                    Price</h3>

                  <p style={{ width: '100%', fontWeight: 'bold', paddingLeft: "15px", display: 'flex', margin: '0', justifyContent: 'flex-start', textAlign: 'left', color: 'red' }}>
                    GHc 650.00
                  </p>
                  <p style={{ width: '100%', paddingLeft: "15px", display: 'flex', margin: '0', justifyContent: 'flex-start', textAlign: 'left', color: 'gray' }}>
                    One-time Activation <br /> +<br /> 1st Month subscription
                  </p>

                </div>


                <div className='subprocardbutton'>
                  <button>ADD TO CART</button>
                </div>

              </div>












              <div className='procarddd'>

                <div className='subprocard'>

                  <div className='subprocardImg'>
                    <img src='/images/indexupercardright.jpg' alt="profile" height={"100%"} width={"100%"} />
                  </div>


                  <div className='subprocarddescrib'>
                    <h3 style={{ borderRadius: '5px', paddingLeft: "15px", alignSelf: "baseline", margin: '0', color: 'gray' }}>
                      4g LTE Advanced Mobile Wifi</h3>

                    <div style={{ width: '90%', display: 'flex', justifyContent: 'flex-start', paddingLeft: '17px', textAlign: 'justify', color: 'gray' }}>
                      Also known as mobile internet or MiFi. Mobile Broadband is the Wi-Fi connection you can take with you. Connect up to 10 devices with Mobile Wi-Fi.
                    </div>
                  </div>

                </div>



                <div className='subprocardfeature'>
                  <h3 style={{ borderRadius: '5px', paddingLeft: "15px", alignSelf: "baseline", margin: '0', color: 'gray' }}>
                    Features</h3>

                 <ul style={{ margin: '0',fontSize:"0.6em" }}>
                    <li>Mobile Hotspot</li>
                    <li>4G LTE</li>
                    <li>Upto 10 users, 50m distance</li>
                    <li>150mbps download speed</li>
                     <li>2100mah Battery</li>
                     <li>Supports USB interface charging.</li>
                     <li>Multiband</li>
                     <li>Global Operators Compatibility	</li>
                    
                  </ul>
                </div>


                <div className='subprocardprice'>
                  <h3 style={{ borderRadius: '5px', paddingLeft: "15px", alignSelf: "baseline", margin: '0', color: 'gray' }}>
                    Price</h3>

                  <p style={{ width: '100%', fontWeight: 'bold', paddingLeft: "15px", display: 'flex', margin: '0', justifyContent: 'flex-start', textAlign: 'left', color: 'red' }}>
                    GHc 650.00
                  </p>
                  <p style={{ width: '100%', paddingLeft: "15px", display: 'flex', margin: '0', justifyContent: 'flex-start', textAlign: 'left', color: 'gray' }}>
                    One-time Activation <br /> +<br /> 1st Month subscription
                  </p>

                </div>


                <div className='subprocardbutton'>
                  <button>ADD TO CART</button>
                </div>

              </div>















              <div className='procarddd'>

                <div className='subprocard'>

                  <div className='subprocardImg'>
                    <img src='/images/indexupercardright.jpg' alt="profile" height={"100%"} width={"100%"} />
                  </div>


                  <div className='subprocarddescrib'>
                    <h3 style={{ borderRadius: '5px', paddingLeft: "15px", alignSelf: "baseline", margin: '0', color: 'gray' }}>
                      4g LTE Advanced Mobile Wifi</h3>

                    <div style={{ width: '90%', display: 'flex', justifyContent: 'flex-start', paddingLeft: '17px', textAlign: 'justify', color: 'gray' }}>
                      Also known as mobile internet or MiFi. Mobile Broadband is the Wi-Fi connection you can take with you. Connect up to 10 devices with Mobile Wi-Fi.
                    </div>
                  </div>

                </div>



                <div className='subprocardfeature'>
                  <h3 style={{ borderRadius: '5px', paddingLeft: "15px", alignSelf: "baseline", margin: '0', color: 'gray' }}>
                    Features</h3>

                 <ul style={{ margin: '0',fontSize:"0.6em" }}>
                    <li>Mobile Hotspot</li>
                    <li>4G LTE</li>
                    <li>Upto 10 users, 50m distance</li>
                    <li>150mbps download speed</li>
                     <li>2100mah Battery</li>
                     <li>Supports USB interface charging.</li>
                     <li>Multiband</li>
                     <li>Global Operators Compatibility	</li>
                    
                  </ul>
                </div>


                <div className='subprocardprice'>
                  <h3 style={{ borderRadius: '5px', paddingLeft: "15px", alignSelf: "baseline", margin: '0', color: 'gray' }}>
                    Price</h3>

                  <p style={{ width: '100%', fontWeight: 'bold', paddingLeft: "15px", display: 'flex', margin: '0', justifyContent: 'flex-start', textAlign: 'left', color: 'red' }}>
                    GHc 650.00
                  </p>
                  <p style={{ width: '100%', paddingLeft: "15px", display: 'flex', margin: '0', justifyContent: 'flex-start', textAlign: 'left', color: 'gray' }}>
                    One-time Activation <br /> +<br /> 1st Month subscription
                  </p>

                </div>


                <div className='subprocardbutton'>
                  <button>ADD TO CART</button>
                </div>

              </div>














            </>}









            {showcheckout === false && <>
              <div className='productdetails'>
                <img src='https://www.three.co.uk/content/dam/assets/devices/huawei/25760/25760_dlp.png' alt="profile" width={'300px'} height={"auto"} />

                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', textAlign: 'left' }} className='prodetailsright'>
                  <h1 style={{ borderLeft: "5px solid #0587EF", borderRadius: '5px', color: "rgb(70, 70, 70)", paddingLeft: "10px", alignSelf: "baseline", marginBottom: '0' }}>
                    Internet on the Go</h1>


                  <h2 style={{ paddingLeft: "15px", alignSelf: "baseline", marginTop: '0', color: 'gray' }}>
                    4g LTE Advanced Mobile Wifi</h2>


                  <h3 style={{ borderRadius: '5px', paddingLeft: "15px", alignSelf: "baseline", margin: '0', color: 'gray' }}>
                    Mobile Broadband</h3>

                  <div style={{ width: '90%', display: 'flex', justifyContent: 'flex-start', paddingLeft: '17px', textAlign: 'justify', color: 'gray' }}>
                    Also known as mobile internet or MiFi. Mobile Broadband is the Wi-Fi connection you can take with you. Connect up to 10 devices with Mobile Wi-Fi.
                  </div>

                  <h3 style={{ borderRadius: '5px', paddingLeft: "15px", alignSelf: "baseline", margin: '0', marginTop: '40px', color: 'gray' }}>
                    Features Highlight</h3>

                  <div style={{ width: '90%', display: 'flex', justifyContent: 'flex-start', paddingLeft: '17px', textAlign: 'justify', color: 'gray' }}>
                    Our 4G Mifi offers the best connectivity solution for Mobile broadband, which means it's fast & you don't need a landline. With speeds of up to 300Mbps* (Device Theoretical Max) the 4G Mifi is capable of delivering the ultimate mobile broadband experience. Whether it's working on the go, streaming, video calls or just browsing the web 4G Mobile Broadband is made for those who love it all - all at the same time! A portable Wi-Fi connection you can charge up and take with you. Wherever. Whenever.
                  </div>


                  <div style={{ width: '90%', display: 'flex', justifyContent: 'flex-start', paddingLeft: '17px', textAlign: 'justify', color: 'gray', alignItems: 'center', marginTop: '20px' }}>
                    Rating:

                    <div class="rating">
                      <span class="star">&#9733;</span>
                      <span class="star">&#9733;</span>
                      <span class="star">&#9733;</span>
                      <span class="star">&#9733;</span>
                      <span class="star">&#9733;</span>
                    </div>


                  </div>

                  <h5 style={{ width: '100%', display: 'flex', marginBottom: '0', justifyContent: 'flex-start', paddingLeft: '17px', textAlign: 'justify', color: 'gray' }}>
                    Get it now from GHc 650.00 (One-time Activation + 1st Month subscription)
                  </h5>
                  <button onClick={() => setshowcheckout(true)} style={{ width: '100%', height: '50px', fontSize: '2rem', fontWeight: "bold", backgroundColor: "#0587EF", color: 'white', borderRadius: '5px' }}>Order Now </button>

                </div>


              </div>




              <div className='productdetails3'>


                <h3 style={{ borderRadius: '5px', backgroundColor: '#0587EF', padding: "5px", alignSelf: "baseline", margin: '0', marginTop: '40px', color: 'White' }}>
                  NETWORKS                       </h3>

                <div style={{ width: '90%', display: 'flex', justifyContent: 'flex-start', textAlign: 'justify', color: 'gray' }}>
                  Available on.
                </div>


                <table className='data-plans-table'>

                  <tbody>

                    <tr >

                      <td>MTN </td>
                      <td>AT Ghana</td>
                      <td>Telecel</td>

                    </tr>

                  </tbody>
                </table>





                <h3 style={{ borderRadius: '5px', backgroundColor: '#0587EF', padding: "5px", alignSelf: "baseline", margin: '0', marginTop: '40px', color: 'White' }}>
                  No fluff. Just the good stuff.</h3>

                <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-start', textAlign: 'justify', color: 'gray' }}>
                  All plans from Data Warehouse Gh come with great features as standard. We provide coverage that you can trust in your area. We cover 98% of the Ghana’s population. We provide competitively generous internet data up to unlimited subject to fair usage & open data policies.
                </div>



                <h3 style={{ borderRadius: '5px', backgroundColor: '#0587EF', padding: "5px", alignSelf: "baseline", margin: '0', marginTop: '40px', color: 'White' }}>
                  Data Plans                        </h3>
                <div style={{ width: '90%', display: 'flex', justifyContent: 'flex-start', textAlign: 'justify', color: 'gray' }}>
                  Contract Duration (1-12) months.
                </div>


                <table className='data-plans-table'>
                  <thead>
                    <tr>
                      <th></th>
                      <th>12 months</th>
                      <th>6 months</th>
                      <th>3 months</th>
                      <th>1 month</th>
                   
                    </tr>
                  </thead>
                  <tbody>

                    <tr >
                      <th>10GB Data</th>
                      <td>GHc90</td>
                      <td>GHc100</td>
                      <td>GHc110</td>
                      <td>GHc120</td>

                    </tr>


                    <tr >
                      <th>15GB Data</th>
                      <td>GHc120</td>
                      <td>GHc130</td>
                      <td>GHc140</td>
                      <td>GHc150</td>
                   
                    </tr>


                    <tr >
                      <th>20GB Data</th>
                      <td>GHc140</td>
                      <td>GHc145</td>
                      <td>GHc155</td>
                      <td>GHc160</td>
                   
                    </tr>

                    {showallplans === true && <>

                      <tr >
                        <th>35GB Data</th>
                        <td>GHc170</td>
                        <td>GHc185</td>
                        <td>GHc200</td>
                        <td>GHc220</td>
                     
                      </tr>

                      <tr >
                        <th>50GB Data</th>
                        <td>GHc200</td>
                        <td>GHc215</td>
                        <td>GHc230</td>
                        <td>GHc250</td>
                     
                      </tr>


                      <tr >
                        <th>75GB Data</th>
                        <td>GHc250</td>
                        <td>GHc260</td>
                        <td>GHc275</td>
                        <td>GHc290</td>
                     
                      </tr>


                      <tr >
                        <th>100GB Data</th>
                        <td>GHc270</td>
                        <td>GHc280</td>
                        <td>GHc290</td>
                        <td>GHc300</td>
                     
                      </tr>

                      <tr >
                        <th>220GB Data</th>
                        <td>GHc450</td>
                        <td>GHc465</td>
                        <td>GHc480</td>
                        <td>GHc499</td>
                     
                      </tr>


                    </>}

                  </tbody>
                </table>

                {showallplans === false && <>


                  <h5 className='readmore' onClick={() => { setshowallplans(true) }} style={{ margin: "0", width: '100%', display: 'flex', justifyContent: 'end', textAlign: 'justify', color: 'gray' }}>
                    Read More.
                  </h5>

                </>}
                {showallplans === true && <>

                  <h5 className='readmore' onClick={() => { setshowallplans(false) }} style={{ margin: "0", width: '100%', display: 'flex', justifyContent: 'end', textAlign: 'justify', color: 'gray' }}>
                    Read Less.
                  </h5>
                </>}







                <h3 style={{ borderRadius: '5px', backgroundColor: '#0587EF', padding: "5px", alignSelf: "baseline", margin: '0', marginTop: '40px', color: 'White' }}>
                  Specifications:                       </h3>



                <table className='data-plans-table'>

                  <tbody>

                    <tr >

                      <td>Mobile Hotspot</td>
                      <td>4G LTE</td>
                      <td>Upto 10 users, 50m distance</td>

                    </tr>

                    <tr >

                      <td>150mbps download speed</td>
                      <td>2100mah Battery</td>
                      <td>Supports USB interface charging.</td>

                    </tr>


                    <tr >

                      <td>Multiband</td>
                      <td>Global Operators Compatibility</td>
                      <td>Smart Security Management</td>
                   
                    </tr>





                  </tbody>
                </table>









              </div>




            </>}

          </div> */}







        </div>






























        <h1 style={{ borderLeft: "5px solid #0587EF'", borderRadius: '5px', color: "rgb(70, 70, 70)", paddingLeft: "10px", alignSelf: "baseline" }}>
          About Us</h1>
        <h3 style={{ borderRadius: '5px', paddingLeft: "15px", alignSelf: "baseline", margin: '0', color: 'gray' }}>
          Who we are – Investing in people and connecting the nation</h3>
        <div style={{ width: '90%', display: 'flex', justifyContent: 'flex-start', paddingLeft: '30px', textAlign: 'justify', color: 'gray' }}>A company that connects people to people, people to things and things to things - Data Warehouse Gh is a trading
          name for DWH Ghana limited, an Afroeuro Capital Ghana company part of Bethwell Holdings International. Data
          Warehouse has been established to bring new opportunities to people across Ghana and beyond with a focus on
          bringing together award-winning connectivity and amazing entertainment under one roof from the major ISPs in
          Ghana to our customers giving them more choice, more freedom and smart internet solutions.</div>


        <h3 style={{ borderRadius: '5px', paddingLeft: "15px", alignSelf: "baseline", margin: '0', marginTop: '10px', color: 'gray' }}>
          Sustainability and Responsible Business</h3>
        <div style={{ width: '90%', display: 'flex', justifyContent: 'flex-start', paddingLeft: '30px', textAlign: 'justify', color: 'gray' }}>
          Part of Data Warehouse Gh’s vision is to create an inclusive, green and connected future for its customers and the
          communities. To this end, we will harness the core strengths of our partners and marry them with powerful
          technology, digital expertise, passionate people, and steadfast commitment to operate a green business creating a
          wider positive ripple effect in the fight against climate change.<br />
          We’re committed to building a business that represents the diverse communities we serve, while creating an
          environment where our people genuinely feel they can bring their whole selves to work and where our customers
          “own” our mission.

        </div>

        <h3 style={{ borderRadius: '5px', paddingLeft: "15px", alignSelf: "baseline", margin: '0', marginTop: '10px', color: 'gray' }}>
          <NavLink to='/about' style={{ textDecoration: 'none', color: 'gray' }}>Read More</NavLink></h3>



















      </div>


    </div>
  )
}

export default Internetdroadband