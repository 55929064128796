import React from 'react'
import "../css/index.css"
import { Carousel } from 'antd';
import { AiOutlineArrowDown } from 'react-icons/ai';
import { NavLink } from 'react-router-dom';
import Navbar from '../components/navbar';
import { useNavigate } from 'react-router-dom';


function Index() {

  let navigate = useNavigate();




  return (
    <>

      <div style={{ marginTop: '110px' }}></div>








      <Navbar />


      {/* <nav className="navbar2">
        <ul className="nav-list2mob">
          <li className="nav-item2"><NavLink exact to="/Internet&Broadband" activeClassName="active">Internet & Broadband</NavLink></li>
          <li className="nav-item2"><NavLink to="/Devices" activeClassName="active">Devices</NavLink></li>
          <li className="nav-item2"><NavLink to="/Deals&Promos" activeClassName="active">Deals & Promos</NavLink></li>


          <li className="nav-item2"><NavLink to="/Student" activeClassName="active">Student</NavLink></li>
        </ul>

        <ul className="nav-list2mob">
          <li className="nav-item2"><NavLink to="/Workers" activeClassName="active">Workers (Coming Soon)</NavLink></li>
          <li className="nav-item2"><NavLink to="/Support" activeClassName="active">Support</NavLink></li>

          <li className="nav-item2"><NavLink to="/Business" activeClassName="active">Business (Coming Soon)</NavLink></li>
        </ul>
      </nav> */}










      <Carousel autoplay>

        <div >
          <img alt='' src='/banners[1]/v2.jpg' width={"100%"} />
        </div>
        <div >
          <img alt='' src='/banners[1]/b (2).jpg' width={"100%"} />
        </div>
        <div >
          <img alt='' src='/banners[1]/b (3).jpg' width={"100%"} />
        </div>
        <div >
          <img alt='' src='/banners[1]/b (4).jpg' width={"100%"} />
        </div>
        <div >
          <img alt='' src='/banners[1]/b (1).jpg' width={"100%"} />
        </div>



      </Carousel>



      <div className='indexmaindiv'>




        <div className='indexupercard'>
          <div className='indexupercardleftright' style={{ backgroundImage: "url(/images/indexupercardleft.jpg)" }}>
            <h4 style={{ margin: '0' }} >Unbeatable Price</h4>
            <h2 style={{ margin: '' }}>Fast Fiber Broadband</h2>
            <h4 style={{ margin: '0' }}>UPTO 50% OFF</h4>
            <h3 style={{ marginBottom: '0', textDecoration: 'underline' }} onClick={() => navigate('/Internet&Broadband/HighSpeedFiberBroadband')}>Explore Items</h3>
          </div>
          <div className='indexupercardleftright' style={{ backgroundImage: "url(/images/indexupercardright.jpg)" }}>
            <h4 style={{ margin: '0' }} >Stay Connected</h4>
            <h2 style={{ margin: '' }}>On-the-Go Internet</h2>
            <h4 style={{ margin: '0' }}>UPTO 50% OFF</h4>
            <h3 style={{ marginBottom: '0', textDecoration: 'underline' }} onClick={() => navigate('/Internet&Broadband/Mifis')}>Explore Items</h3>
          </div>
        </div>












        {/* <h1 style={{ borderLeft: "5px solid #0587EF", borderRadius: '5px', color: "#0587EF", paddingLeft: "10px", alignSelf: "baseline" }}>
          New Arrival</h1>

        <div className='indexoffersuper'>
          <div className='indexofferscards'>
            <img src='https://w7.pngwing.com/pngs/892/710/png-transparent-huawei-e5577cs-321-mifi-mobile-phones-mobile-case-electronics-3g-mobile-phones-thumbnail.png' alt='ads' loading='lazzy' />
            <div>  <h3> 4g LTE Advanced Mobile Wifi


            </h3>
              <div style={{ width: '90%', display: 'flex', justifyContent:"space-evenly", paddingLeft: '17px', textAlign: 'justify', color: 'gray', alignItems: 'center', marginTop: '5px' }}>
                Rating:

                <div class="rating">
                  <span class="star">&#9733;</span>
                  <span class="star">&#9733;</span>
                  <span class="star">&#9733;</span>
                  <span class="star">&#9733;</span>
                  <span class="star">&#9733;</span>
                </div>

                <button style={{ marginLeft: '0' }}>GHc 180</button>

              </div>



            </div>

          </div>
          <div className='indexofferscards'>
            <img src='https://infoguidenigeria.com/wp-content/uploads/2022/09/mifi-device.png' alt='ads' loading='lazzy' />
            <div>    <h3>Next-Gen Smart Devices</h3>
              <div style={{ width: '90%', display: 'flex', justifyContent: "space-evenly", paddingLeft: '17px', textAlign: 'justify', color: 'gray', alignItems: 'center', marginTop: '5px' }}>
                Rating:

                <div class="rating">
                  <span class="star">&#9733;</span>
                  <span class="star">&#9733;</span>
                  <span class="star">&#9733;</span>
                  <span class="star">&#9733;</span>
                  <span class="star">&#9733;</span>
                </div>

                <button style={{ marginLeft: '0%' }}>GHc 180</button>

              </div>
            </div>
          </div>
          <div className='indexofferscards'>
            <img src='https://5.imimg.com/data5/AL/XA/GLADMIN-5831336/st-p12-4g-mifi-cpe.png' alt='ads' loading='lazzy' />
            <div>    <h3>ST P12 4G MIFI CPE
            </h3>
              <div style={{ width: '90%', display: 'flex', justifyContent: "space-evenly", paddingLeft: '17px', textAlign: 'justify', color: 'gray', alignItems: 'center', marginTop: '5px' }}>
                Rating:

                <div class="rating">
                  <span class="star">&#9733;</span>
                  <span class="star">&#9733;</span>
                  <span class="star">&#9733;</span>
                  <span class="star">&#9733;</span>
                  <span class="star">&#9733;</span>
                </div>

                <button style={{ marginLeft: '0%' }}>GHc 180</button>

              </div>
            </div>
          </div>
          <div className='indexofferscards'>
            <img src='https://w7.pngwing.com/pngs/892/710/png-transparent-huawei-e5577cs-321-mifi-mobile-phones-mobile-case-electronics-3g-mobile-phones-thumbnail.png' alt='ads' loading='lazzy' />
            <div>  <h3> 4g LTE Advanced Mobile Wifi</h3>
              <div style={{ width: '90%', display: 'flex', justifyContent: "space-evenly", paddingLeft: '17px', textAlign: 'justify', color: 'gray', alignItems: 'center', marginTop: '5px' }}>
                Rating:

                <div class="rating">
                  <span class="star">&#9733;</span>
                  <span class="star">&#9733;</span>
                  <span class="star">&#9733;</span>
                  <span class="star">&#9733;</span>
                  <span class="star">&#9733;</span>
                </div>

                <button style={{ marginLeft: '0%' }}>GHc 180</button>

              </div>
            </div>
          </div>


        </div> */}






        <h1 style={{ borderLeft: "5px solid #0587EF", borderRadius: '5px', color: "#0587EF", paddingLeft: "10px", alignSelf: "baseline" }}>
          Big Saving Zone</h1>

        <div className='indexBigSavingZoneuper'>
          <div className='indexBigSavingZonecards' style={{ backgroundImage: "url(/images/BigSavingZoneuper1.png)" }}>
            <h2 style={{ margin: '0' }}>Fast Plug </h2> <h2 style={{ marginTop: '0' }}>& Connect </h2>
            <h4 style={{ margin: '0' }}> Experience high-speed </h4><h4 style={{ marginTop: '0' }}> internet like never before.</h4>
            <h3 style={{ margin: '0' }}>UPTO 50% OFF</h3>
            <div>  <AiOutlineArrowDown />
              <div className='hovar' onClick={() => navigate('/Internet&Broadband/FastPlugConnect')} style={{ border: '1px solid white', borderRadius: '4px', padding: '5px 10px 5px 10px' }}>
                Shop Now
              </div>
            </div>
          </div>


          <div className='indexBigSavingZonecards' style={{ backgroundImage: "url(/images/BigSavingZoneuper2.png)" }}>
            <h2 style={{ margin: '0' }}>Dongle. </h2> <h2 style={{ marginTop: '0' }}>DW U90</h2>
            <h4 style={{ margin: '0' }}> Don't miss out on special </h4><h4 style={{ marginTop: '0' }}> deals.</h4>
            <h3 style={{ margin: '0' }}>UPTO 40% OFF</h3>
            <div>  <AiOutlineArrowDown />
              <div className='hovar' onClick={() => navigate('/Internet&Broadband/Dongles')} style={{ border: '1px solid white', borderRadius: '4px', padding: '5px 10px 5px 10px' }}>
                Shop Now
              </div>
            </div>
          </div>


          <div className='indexBigSavingZonecards' style={{ backgroundImage: "url(/images/BigSavingZoneuper3.png)" }}>
            <h2 style={{ margin: '0' }}>Bundle  </h2> <h2 style={{ marginTop: '0' }}>& Save</h2>
            <h4 style={{ margin: '0' }}> Maximize your savings </h4><h4 style={{ marginTop: '0' }}> with our exclusive bundles.</h4>
            {/* <h3 style={{ margin: '0' }}>UPTO 50% OFF</h3> */}
            <div>  <AiOutlineArrowDown />
              <div style={{ border: '1px solid white', borderRadius: '4px', padding: '5px 10px 5px 10px' }}>
                Coming Soon
              </div>
            </div>
          </div>

        </div>






        <div className='indexbannerauper'>
          <div className='indexbannercards' style={{ backgroundImage: "url(/images/indexbannercardsleftt.png)" }}>
            <h2 style={{ margin: '0' }}>High-Speed Fiber, </h2> <h2 style={{ marginTop: '0' }}>Price</h2>
            <h4 style={{ margin: '0' }}> Supercharge your online </h4><h4 style={{ margin: '0' }}> experience without  </h4><h4 style={{ marginTop: '0' }}> breaking the bank.</h4>
            <h3 style={{ margin: '0' }}>UPTO 60% OFF</h3>
            <div>  <AiOutlineArrowDown />
              <div className='hovar' onClick={() => navigate('/Internet&Broadband/HighSpeedFiberBroadband')} style={{ border: '1px solid black', borderRadius: '4px', padding: '5px 10px 5px 10px' }}>
                Shop Now
              </div>
            </div>
          </div>


          <div className='indexbannercards' style={{ backgroundImage: "url(/images/indexbannercardsleft.png)" }}>
            <h2 style={{ margin: '0' }}>Power-Up  </h2> <h2 style={{ marginTop: '0' }}>with Bundles</h2>
            <h4 style={{ margin: '0' }}> Combine your internet  </h4><h4 style={{ margin: '0' }}> subscription with </h4><h4 style={{ margin: '0' }}> our state-of- </h4><h4 style={{ margin: '0' }}> the-art devices for </h4><h4 style={{ marginTop: '0' }}> maximum savings.</h4>
            {/* <h3 style={{ margin: '0' }}>UPTO 60% OFF</h3> */}
            <div>  <AiOutlineArrowDown />
              <div style={{ border: '1px solid black', borderRadius: '4px', padding: '5px 10px 5px 10px' }}>
                Coming Soon
              </div>
            </div>
          </div>


        </div>





        <div className='indexflashdeals'>

          <div className='indexflashdealsdiv' style={{ backgroundImage: "url(/images/indexflashdealsleft.png)" }}>
            <h1>Business Solutions</h1>
            <p style={{ textAlign: 'justify' }}>
              Data Warehouse Business solutions provides immediate access to internet everywhere in Ghana for businesses and NGOs. We have a basket of solutions to meet the need of most small to media size enterprises in particular and also provide value for money internet solutions for all their staff.
            </p>

            <button className='indexflashdealsdivbutton' onClick={() => navigate('/Business')}> <h3>Explore More</h3></button>
          </div>
          <div className='indexflashdealsdiv2' style={{ backgroundImage: "url(/images/indexflashdealsright.png)" }}>

          </div>
        </div>






        {/* <h1 style={{ paddingLeft: "10px", color: "#0587EF", alignSelf: "baseline" }}>
          Internet Solutions</h1>
        <h3 style={{ paddingLeft: "10px", alignSelf: "baseline", marginTop: '0', color: 'gray' }}>Explore Our Internet Options</h3>
 */}




        {/* <h2 style={{ borderLeft: "5px solid #0587EF", borderRadius: '5px', color: "#0587EF", paddingLeft: "10px", alignSelf: "baseline" }}>
          Fiber Broadband</h2>
        <div className='indexoffersuper'>
          <div className='indexofferscards'>
            <img src='https://w7.pngwing.com/pngs/892/710/png-transparent-huawei-e5577cs-321-mifi-mobile-phones-mobile-case-electronics-3g-mobile-phones-thumbnail.png' alt='ads' loading='lazzy' />
            <div>  <h3> 4g LTE Advanced Mobile Wifi


            </h3>
              <div style={{ width: '90%', display: 'flex', justifyContent: "space-evenly", paddingLeft: '17px', textAlign: 'justify', color: 'gray', alignItems: 'center', marginTop: '5px' }}>
                Rating:

                <div class="rating">
                  <span class="star">&#9733;</span>
                  <span class="star">&#9733;</span>
                  <span class="star">&#9733;</span>
                  <span class="star">&#9733;</span>
                  <span class="star">&#9733;</span>
                </div>

                <button style={{ marginLeft: '0' }}>GHc 180</button>

              </div>



            </div>

          </div>
          <div className='indexofferscards'>
            <img src='https://infoguidenigeria.com/wp-content/uploads/2022/09/mifi-device.png' alt='ads' loading='lazzy' />
            <div>    <h3>Next-Gen Smart Devices</h3>
              <div style={{ width: '90%', display: 'flex', justifyContent: "space-evenly", paddingLeft: '17px', textAlign: 'justify', color: 'gray', alignItems: 'center', marginTop: '5px' }}>
                Rating:

                <div class="rating">
                  <span class="star">&#9733;</span>
                  <span class="star">&#9733;</span>
                  <span class="star">&#9733;</span>
                  <span class="star">&#9733;</span>
                  <span class="star">&#9733;</span>
                </div>

                <button style={{ marginLeft: '0%' }}>GHc 180</button>

              </div>
            </div>
          </div>
          <div className='indexofferscards'>
            <img src='https://5.imimg.com/data5/AL/XA/GLADMIN-5831336/st-p12-4g-mifi-cpe.png' alt='ads' loading='lazzy' />
            <div>    <h3>ST P12 4G MIFI CPE
            </h3>
              <div style={{ width: '90%', display: 'flex', justifyContent: "space-evenly", paddingLeft: '17px', textAlign: 'justify', color: 'gray', alignItems: 'center', marginTop: '5px' }}>
                Rating:

                <div class="rating">
                  <span class="star">&#9733;</span>
                  <span class="star">&#9733;</span>
                  <span class="star">&#9733;</span>
                  <span class="star">&#9733;</span>
                  <span class="star">&#9733;</span>
                </div>

                <button style={{ marginLeft: '0%' }}>GHc 180</button>

              </div>
            </div>
          </div>
          <div className='indexofferscards'>
            <img src='https://w7.pngwing.com/pngs/892/710/png-transparent-huawei-e5577cs-321-mifi-mobile-phones-mobile-case-electronics-3g-mobile-phones-thumbnail.png' alt='ads' loading='lazzy' />
            <div>  <h3> 4g LTE Advanced Mobile Wifi</h3>
              <div style={{ width: '90%', display: 'flex', justifyContent: "space-evenly", paddingLeft: '17px', textAlign: 'justify', color: 'gray', alignItems: 'center', marginTop: '5px' }}>
                Rating:

                <div class="rating">
                  <span class="star">&#9733;</span>
                  <span class="star">&#9733;</span>
                  <span class="star">&#9733;</span>
                  <span class="star">&#9733;</span>
                  <span class="star">&#9733;</span>
                </div>

                <button style={{ marginLeft: '0%' }}>GHc 180</button>

              </div>
            </div>
          </div>


        </div>
 */}





        {/* 

        <h2 style={{ borderLeft: "5px solid #0587EF", borderRadius: '5px', color: "#0587EF", paddingLeft: "10px", alignSelf: "baseline" }}>
          Mobile Connectivity</h2>
        <div className='indexoffersuper'>
          <div className='indexofferscards'>
            <img src='https://w7.pngwing.com/pngs/892/710/png-transparent-huawei-e5577cs-321-mifi-mobile-phones-mobile-case-electronics-3g-mobile-phones-thumbnail.png' alt='ads' loading='lazzy' />
            <div>  <h3> 4g LTE Advanced Mobile Wifi


            </h3>
              <div style={{ width: '90%', display: 'flex', justifyContent: "space-evenly", paddingLeft: '17px', textAlign: 'justify', color: 'gray', alignItems: 'center', marginTop: '5px' }}>
                Rating:

                <div class="rating">
                  <span class="star">&#9733;</span>
                  <span class="star">&#9733;</span>
                  <span class="star">&#9733;</span>
                  <span class="star">&#9733;</span>
                  <span class="star">&#9733;</span>
                </div>

                <button style={{ marginLeft: '0' }}>GHc 180</button>

              </div>



            </div>

          </div>
          <div className='indexofferscards'>
            <img src='https://infoguidenigeria.com/wp-content/uploads/2022/09/mifi-device.png' alt='ads' loading='lazzy' />
            <div>    <h3>Next-Gen Smart Devices</h3>
              <div style={{ width: '90%', display: 'flex', justifyContent: "space-evenly", paddingLeft: '17px', textAlign: 'justify', color: 'gray', alignItems: 'center', marginTop: '5px' }}>
                Rating:

                <div class="rating">
                  <span class="star">&#9733;</span>
                  <span class="star">&#9733;</span>
                  <span class="star">&#9733;</span>
                  <span class="star">&#9733;</span>
                  <span class="star">&#9733;</span>
                </div>

                <button style={{ marginLeft: '0%' }}>GHc 180</button>

              </div>
            </div>
          </div>
          <div className='indexofferscards'>
            <img src='https://5.imimg.com/data5/AL/XA/GLADMIN-5831336/st-p12-4g-mifi-cpe.png' alt='ads' loading='lazzy' />
            <div>    <h3>ST P12 4G MIFI CPE
            </h3>
              <div style={{ width: '90%', display: 'flex', justifyContent: "space-evenly", paddingLeft: '17px', textAlign: 'justify', color: 'gray', alignItems: 'center', marginTop: '5px' }}>
                Rating:

                <div class="rating">
                  <span class="star">&#9733;</span>
                  <span class="star">&#9733;</span>
                  <span class="star">&#9733;</span>
                  <span class="star">&#9733;</span>
                  <span class="star">&#9733;</span>
                </div>

                <button style={{ marginLeft: '0%' }}>GHc 180</button>

              </div>
            </div>
          </div>
          <div className='indexofferscards'>
            <img src='https://w7.pngwing.com/pngs/892/710/png-transparent-huawei-e5577cs-321-mifi-mobile-phones-mobile-case-electronics-3g-mobile-phones-thumbnail.png' alt='ads' loading='lazzy' />
            <div>  <h3> 4g LTE Advanced Mobile Wifi</h3>
              <div style={{ width: '90%', display: 'flex', justifyContent: "space-evenly", paddingLeft: '17px', textAlign: 'justify', color: 'gray', alignItems: 'center', marginTop: '5px' }}>
                Rating:

                <div class="rating">
                  <span class="star">&#9733;</span>
                  <span class="star">&#9733;</span>
                  <span class="star">&#9733;</span>
                  <span class="star">&#9733;</span>
                  <span class="star">&#9733;</span>
                </div>

                <button style={{ marginLeft: '0%' }}>GHc 180</button>

              </div>
            </div>
          </div>


        </div>

 */}












        <div className='indexbanner'>
          <img className='hovar' src='bg3.jpg' alt='ads' loading='lazzy' width={"100%"} onClick={() => navigate('Internet&Broadband/Mifis')} />

        </div>






        {/* <h1 style={{ borderLeft: "5px solid #0587EF", borderRadius: '5px', color: "#0587EF", paddingLeft: "10px", alignSelf: "baseline" }}>
          Limited-Time Promotions</h1>
          <div className='indexoffersuper'>
          <div className='indexofferscards'>
            <img src='https://i.pinimg.com/1200x/cb/2a/4b/cb2a4b10a4fb2d34d9e83bc350f9509f.jpg' alt='ads' loading='lazzy' />

          </div>
          <div className='indexofferscards'>
            <img src='https://www.frip.in/wp-content/uploads/Internet-Broadband-Promotion-Flyer-12.jpg' alt='ads' loading='lazzy' />

          </div>
          <div className='indexofferscards'>
            <img src='https://img.freepik.com/premium-psd/internet-broadband-promotion-flyer_174473-1.jpg' alt='ads' loading='lazzy' />

          </div>
          <div className='indexofferscards'>
            <img src='https://png.pngtree.com/png-clipart/20200727/original/pngtree-internet-broadband-promotion-flyer-png-image_5351679.jpg' alt='ads' loading='lazzy' />

          </div>


        </div>

 */}






        <h1 style={{ borderLeft: "5px solid #0587EF", borderRadius: '5px', color: "#0587EF", paddingLeft: "10px", alignSelf: "baseline" }}>
          Feedback</h1>

        <div className='indexfeedback'>
          <div className='indexfeedbackcard'>


            <div class="feedback-card">
              <img src='https://static.vecteezy.com/system/resources/thumbnails/009/734/564/small/default-avatar-profile-icon-of-social-media-user-vector.jpg' alt="profile" />
              <h2>Philip A. </h2>
              <div class="rating">
                <span class="star">&#9733;</span>
                <span class="star">&#9733;</span>
                <span class="star">&#9733;</span>
                <span class="star">&#9733;</span>
                <span class="star">&#9733;</span>
              </div>
              <p>I had a minor issue with my MiFi router, and the customer service team went above and beyond to assist me. They were quick to respond and resolved my problem within hours. The data packages they offer are diverse, catering to various needs. Overall, a fantastic experience.</p>
            </div>



          </div>


          <div className='indexfeedbackcard'>


            <div class="feedback-card">
              <img src='https://static.vecteezy.com/system/resources/thumbnails/009/734/564/small/default-avatar-profile-icon-of-social-media-user-vector.jpg' alt="profile" />
              <h2>Alison D</h2>
              <div class="rating">
                <span class="star">&#9733;</span>
                <span class="star">&#9733;</span>
                <span class="star">&#9733;</span>
                <span class="star">&#9733;</span>
                <span class="star">&#9733;</span>
              </div>
              <p>I recently purchased this MiFi router along with one of their data packages, and I couldn't be happier. The router is incredibly compact and easy to use. It provides a fast and reliable internet connection wherever I go. The data package is competitively priced and offers excellent coverage. Highly recommended!</p>
            </div>



          </div>


          <div className='indexfeedbackcard'>

            <div class="feedback-card">
              <img src='https://static.vecteezy.com/system/resources/thumbnails/009/734/564/small/default-avatar-profile-icon-of-social-media-user-vector.jpg' alt="profile" />
              <h2>Joe W.</h2>
              <div class="rating">
                <span class="star">&#9733;</span>
                <span class="star">&#9733;</span>
                <span class="star">&#9733;</span>
                <span class="star">&#9733;</span>
                <span class="star">&#9733;</span>
              </div>
              <p>I travel frequently for work, and this MiFi router has become an essential part of my travel gear. It's small, lightweight, and the battery life is impressive. The data package options are flexible, and I've never had connectivity issues. The only reason for not giving it five stars is the price, but the quality justifies the cost.</p>
            </div>




          </div>




        </div>



        <div className='indexpartners'>
          {/* <div className='indexpartnerscard' style={{ backgroundImage: "url(/partner1.jpg)" }} ></div>
          <div className='indexpartnerscard' style={{ backgroundImage: "url(/partner2.jpg)" }} ></div>
          <div className='indexpartnerscard' style={{ backgroundImage: "url(/partner3.jpg)" }} ></div> */}
          <img alt=' ' src='/partner1.jpg' />
          <img alt=' ' src='/partner3.jpg' />
          <img alt=' ' src='/partner2.jpg' />

        </div>





      </div></>
  )
}

export default Index