import React from 'react'
import Navbar from "../../components/navbar"


function SwitchingNetworks() {
  return (
    <div>

      <div style={{ marginTop: '110px' }}></div>


      <Navbar />

      <div className='mainpadding'>
        <h1 style={{ borderLeft: "5px solid #0587EF", margin: '0', textAlign: 'left', borderRadius: '5px', color: "rgb(70, 70, 70)", paddingLeft: "10px", alignSelf: "baseline" }}>
          Switching Network</h1>




        <h2 style={{ borderRadius: '5px', paddingLeft: "15px", alignSelf: "baseline", margin: '0', color: 'black' }}>
          ‘Switch & Earn’</h2>



        <div style={{ width: '90%', display: 'flex', justifyContent: 'flex-start', paddingLeft: '17px', textAlign: 'justify', color: 'black', marginBottom: '50px' }}>
          You could save a great deal of money by switching to Data Warehouse Gh platform. At Data Warehouse, we adopt “a whole of market” approach, when you take a broadband plan with us, you could potentially save up to 40 percent on your data cost – money you can spend on other pressing needs and in the current hash economy climate where every pocket is being hit very hard – that is no brainer.
       <br/><br/><br/>   Switch to Data Warehouse Gh to stay in touch with loved ones, stream content, and surf the web. We cover covers more than 98% of the Ghana’s population.
        </div>





      </div>



    </div>
  )
}

export default SwitchingNetworks