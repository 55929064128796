import React, { useState } from 'react'
import Navbar from "../../components/navbar"
import axios from 'axios';
import { useNavigate } from 'react-router-dom';



function Checkifyoucanupgrade() {


  let navigate = useNavigate();


  const [firstname, setname] = useState();
  const [subject, setsubject] = useState();

  const [PhoneNumber, setPhoneNumber] = useState();
  const [Email, setEmail] = useState();
  const [Messageee, setmessagess] = useState();






   const sendemail = async (e) => {
    e.preventDefault()



    const url = 'https://api.sendinblue.com/v3/smtp/email';
    const apiKey = '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'; // Replace with your actual API key






    const htmlContent = `
    <div style="  padding: 10px; background-color: #fff;">

    <h1 style=" margin: 0;">Customer Details - Check if you can upgrade!</h1>
    <div style="padding-left: 20px;border: 1px solid gray;">
        <div style="display: flex;">
            <h3 style=" width: 200px;"> full name</h3>
            <h3>: ${firstname}</h3>
        </div>
       
       
       
     
      
      
      
        <div style="display: flex;">
            <h3 style="width: 200px;"> contact number</h3>
            <h3>: ${PhoneNumber}</h3>
        </div>
        <div style="display: flex;">
            <h3 style="width: 200px;"> Email</h3>
            <h3>: ${Email}</h3>
        </div>

          <div style="display: flex;">
            <h3 style="width: 200px;"> Subject </h3>
            <p>: ${subject}</p>
        </div>

        <div style="display: flex;">
        <h3 style="width: 200px;"> Message</h3>
        <p>: ${Messageee}</p>
    </div>
      
    </div>


   

</div>

`;





    const data = {
      sender: {
        name: 'Data Warehouse Gh',
        email: 'info@datawarehousegh.com'
      },
      to: [
        {
          email: "datawarehousegh@gmail.com",
          name: "Data Warehouse Gh"
        }
      ],
      subject: 'Check if you can upgrade!',
      htmlContent
    };

    const headers = {
      'Content-Type': 'application/json',
      'api-key': apiKey
    };

    await axios.post(url, data, { headers })
      .then(response => {



        const updatedProductJSON = JSON.stringify([]);
        alert("Your Imformation has been successfully sent, and we will promptly reach out to you as soon as possible to provide further updates and assistance")

        console.log("OTP has been sent to ")
        navigate("/")
      }

      )
      .catch(error => {

        console.log(error)
      }
      );



  }

















  return (
    <div>

      <div style={{ marginTop: '110px' }}></div>


      <Navbar />


      <div className='mainpadding' style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
        <h1 style={{ borderLeft: "5px solid #0587EF", margin: '0', textAlign: 'left', borderRadius: '5px', color: "rgb(70, 70, 70)", paddingLeft: "10px", alignSelf: "baseline" }}>
          Check if you can upgrade!</h1>




        <h2 style={{ borderRadius: '5px', paddingLeft: "15px", alignSelf: "baseline", margin: '0', color: 'black' }}>
          Yes, we can help!</h2>




        <form onSubmit={sendemail} className='contactusform' style={{ width: '90%' }}>

          <h5 style={{ margin: '0' }}>Get in touch</h5>
          <h1 style={{ margin: '0' }}>Contact our sales team</h1>



          <div className='formforcontact'>






            <div className='divforinput'>

              <div className='divforinput2'>
                <label for="name"><h5 style={{ margin: '0' }} >Name *</h5></label>
                <input required className='inputcontact' type={'text'} placeholder='Enter Your Name' onChange={(e) => setname(e.target.value)} />
              </div>
              <div className='divforinput2'>
                <label for="name"><h5 style={{ margin: '0' }}>Subject *</h5></label>
                <input required className='inputcontact' type={'text'} placeholder='Enter Subject' onChange={(e) => setsubject(e.target.value)} />
              </div>


            </div>

            <div className='divforinput'>

              <div className='divforinput2'>
                <label for="name"><h5 style={{ margin: '0' }}>Email *</h5></label>
                <input required className='inputcontact' type={'Email'} placeholder='Enter your Email' onChange={(e) => setEmail(e.target.value)} />
              </div>
              <div className='divforinput2'>
                <label for="name"><h5 style={{ margin: '0' }}>Phone *</h5></label>
                <input required className='inputcontact' type={'number'} placeholder='Enter your Phone' onChange={(e) => setPhoneNumber(e.target.value)} />
              </div>


            </div>









            <label for="name"><h5 style={{ margin: '0' }}>Message *</h5></label>
            <textarea required className='inputcontact2' placeholder='Enter your Message' onChange={(e) => setmessagess(e.target.value)} />

            <button className='buttoncontact'  type='submit' >Send Message</button>


          </div>









        </form>




      </div>






    </div>
  )
}

export default Checkifyoucanupgrade