import React from 'react'
import { useNavigate } from 'react-router-dom';
import Navbar from '../components/navbar';
import { Collapse } from 'antd';



const { Panel } = Collapse;

function Student() {
  let navigate = useNavigate();
  return (
    <div>


      <div style={{ marginTop: '110px' }}></div>


      <Navbar />


      <div className='mainpadding'>
        <h1 style={{ borderLeft: "5px solid #0587EF", margin: '0', textAlign: 'left', borderRadius: '5px', color: "rgb(70, 70, 70)", paddingLeft: "10px", alignSelf: "baseline" }}>
          STUDENTS EXCLUSIVE</h1>

        <h2 style={{ borderRadius: '5px', width: '100%', paddingLeft: "15px", textAlign: 'left', margin: '0', color: 'gray', alignSelf: "baseline" }}>
          DATA WAREHOUSE GH STUDENT DISCOUNT</h2>

        <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-start', paddingLeft: '17px', textAlign: 'justify', color: 'gray', marginBottom: '50px' }}>
          Calling all savvy students! Data Warehouse GH is your go-to destination for unbeatable deals and exclusive discounts on everything internet data. With our exciting student offer, you can enjoy exceptional savings on greatly discounted lock- free mifi and dongles, flexible pay-monthly contracts, and devices deals, plus receive a valuable Data Warehouse GH gift card as a bonus. Whether you're in need of the latest mifi or looking for value for your money on data, this limited-time offer provides you with incredible value. Don't miss your chance to save big and receive a Data Warehouse GH gift card when you shop for the best data and device deals at Data Warehouse GH.

        </div>


        <h2 style={{ borderRadius: '5px', width: '100%', paddingLeft: "15px", textAlign: 'left', margin: '0', color: 'gray', alignSelf: "baseline" }}>
          Student Discount at Data Warehouse GH</h2>




        <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-start', paddingLeft: '17px', textAlign: 'justify', color: 'gray', marginBottom: '50px' }}>
          Students can now benefit from exclusive discounts and deals. Verify your student status to get your Data Warehouse GH student offer.
        </div>
<img alt=' ' src='/IANMGES/STUDENT-DISCOUNT-BANNER.png' width={"100%"}/>


        <h4 style={{ borderRadius: '5px', width: '100%', paddingLeft: "15px", textAlign: 'left', margin: '0', color: 'gray', alignSelf: "baseline" }}>
          Students get up to GHc100 Gift Card when you sign up for one of the Great Students deals from Data Warehouse GH - CLICK HERE</h4>

        <h4 style={{ borderRadius: '5px', width: '100%', paddingLeft: "15px", textAlign: 'left', margin: '0', color: 'gray', alignSelf: "baseline" }}>
          Students get up to GHc100 Gift Card when you purchase a MIFI from Data Warehouse GH  CLICK HERE</h4>
          <div className='hovar' onClick={()=>navigate('/studentplans')} style={{ width: '100%', display: 'flex', justifyContent: 'flex-start', paddingLeft: '17px', textAlign: 'justify', color: 'gray', marginBottom: '50px' }}>
          CLICK HERE to see Students Data Plans
        </div>



        <h2 style={{ borderRadius: '5px', width: '100%', paddingLeft: "15px", textAlign: 'left', margin: '0', color: 'gray', alignSelf: "baseline",marginTop:'50px' }}>
          Student Discount FAQs</h2>

        <Collapse style={{ margin: "15px", width: '100%', color: '#rgb(240, 230, 230)', backgroundColor: "#rgb(240, 230, 230)", marginBottom: '50px',textAlign:'left' }} accordion>




          <Panel style={{ color: '#rgb(240, 230, 230)' }} header={<><div>Who is eligible for the Data Warehouse GH student discount?</div></>} key="0">
            <div style={{ width: '100%', display: 'flex', flexWrap: 'wrap', justifyContent: 'center', gap: '10px' }}>

              <div style={{ display: "flex", flexDirection: 'column', justifyContent: 'flex-start', padding: '0 20px', alignItems: 'baseline', width: '100%' }}>

              Anyone over the age of 15 and in full-time or part-time education — including university, Teacher Training college, Vocational Schools, SHS and other specialised institutions — qualifies for this offer.
              </div>

            </div>


          </Panel>


          <Panel style={{ color: '#rgb(240, 230, 230)' }} header={<><div>How do I redeem my Data Warehouse GH student discount online?</div></>} key="1">
            <div style={{ width: '100%', display: 'flex', flexWrap: 'wrap', justifyContent: 'center', gap: '10px' }}>


              <div style={{ display: "flex", flexDirection: 'column', justifyContent: 'flex-start', padding: '0 20px', alignItems: 'baseline', width: '100%' }}>
              Once you’ve verified your student status and made your purchase with Data Warehouse GH, you can submit the details for verification to us by completing the online Gift Card Claim form to get your gift card.
                </div>

            </div>


          </Panel>





          <Panel style={{ color: '#rgb(240, 230, 230)' }} header={<><div>What should I use to verify my student status?</div></>} key="2">
            <div style={{ width: '100%', display: 'flex', flexWrap: 'wrap', justifyContent: 'center', gap: '10px' }}>


              <div style={{ display: "flex", flexDirection: 'column', justifyContent: 'flex-start', padding: '0 20px', alignItems: 'baseline', width: '100%' }}>

              To prove your student status, you will have to use your student’s ID or the National Union of Ghana Students Card (NUGS) a course or classmate number.
              </div>
            </div>


          </Panel>





          <Panel style={{ color: '#rgb(240, 230, 230)' }} header={<><div>I'm having trouble redeeming my Data Warehouse GH student discount. Who shall I contact?</div></>} key="3">
            <div style={{ width: '100%', display: 'flex', flexWrap: 'wrap', justifyContent: 'center', gap: '10px' }}>


              <div style={{ display: "flex", flexDirection: 'column', justifyContent: 'flex-start', padding: '0 20px', alignItems: 'baseline', width: '100%' }}>

              Please, email  support.students@datawarehousegh.com or click here. You will need to include in the email your details and relevant information associated with the offer or discount.
                 </div>
            </div>


          </Panel>

          <Panel style={{ color: '#rgb(240, 230, 230)' }} header={<><div>Can I claim my Data Warehouse GH student discount in conjunction with other voucher codes or offers?</div></>} key="4">
            <div style={{ width: '100%', display: 'flex', flexWrap: 'wrap', justifyContent: 'center', gap: '10px' }}>


              <div style={{ display: "flex", flexDirection: 'column', justifyContent: 'flex-start', padding: '0 20px', alignItems: 'baseline', width: '100%' }}>

              This offer cannot be combined with any other cashback schemes or voucher codes.
                  </div>

            </div>



          </Panel>

          


        </Collapse>




      </div>




    </div>
  )
}

export default Student