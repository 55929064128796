import React from 'react'
import Navbar from "../../components/navbar"


function Delivery() {
  return (
    <div>
      
    <div style={{ marginTop: '110px' }}></div>


<Navbar/>
    
    
<div className='mainpadding'>
        <h1 style={{ borderLeft: "5px solid #0587EF", margin: '0', textAlign: 'left', borderRadius: '5px', color: "rgb(70, 70, 70)", paddingLeft: "10px", alignSelf: "baseline" }}>
        Delivery</h1>




        <h2 style={{ borderRadius: '5px', paddingLeft: "15px", alignSelf: "baseline", margin: '0', color: 'black' }}>
        Flexible delivery options</h2>



        <div style={{ width: '90%', display: 'flex',flexDirection:'column', justifyContent: 'flex-start', paddingLeft: '17px', textAlign: 'justify', color: 'black', marginBottom: '50px' }}>
        Our delivery service is one of the most flexible around, because we understand everyone’s lives are different.  We offer delivery 7 days a week, to fit right in with your busy life. And that’s not all:
          <br/><br/><br/> 
          
          
          <li>We give you notice in advance and gives you regular updates of the delivery window, so you don’t have to waste time waiting.</li> 
          <li>We let you track the progress of your items independently</li>
          <li>Need more info? You’ll find lots of delivery FAQs <a href={'/faq'}>Click Here</a></li>
        
          </div>
        </div>
    
  
  
  
  
  
  </div>
  )
}

export default Delivery