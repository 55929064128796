import React, { useState, useEffect } from 'react'
import "../css/index.css"
import Cookies from 'js-cookie';
import { NavLink, useNavigate } from 'react-router-dom';
import { AiOutlineShoppingCart } from 'react-icons/ai';


import { FaBars } from 'react-icons/fa';
import { GrFormClose } from 'react-icons/gr';




function Navbar() {
  let navigate = useNavigate();

  const [product2, setproduct2] = useState([]);

  const [Mobnav, setmobnav] = useState(false);

  const [openMobnav, setopenmobnav] = useState(0);






  useEffect(() => {
    const intervalId = setInterval(() => {
      setproduct2(JSON.parse(Cookies.get('Product') || '[]'));
    }, 100); // 1000 milliseconds = 1 second

    // Clean up the interval when the component unmounts
    return () => {
      clearInterval(intervalId);
    };
  }, []);




  return (
    <>
      <nav className="navbar2">
        <ul className="nav-list2">
          <div style={{ display: 'flex' }}>
            <li className="nav-item2"><NavLink exact to="/Internet&Broadband" activeClassName="active" onClick={(e) => e.preventDefault()}>Internet & Broadband </NavLink>
              <div className="nabdropdown"  >

                <div className='nabdropdown2' >

                  <div className='insidedrop'>
                    <h3 style={{ borderRadius: '5px', paddingLeft: "15px", alignSelf: "baseline", margin: '0', color: 'black' }}>
                      Home & Small Business</h3>
                    <ul style={{ width: '90%', justifyContent: 'flex-start', paddingLeft: '20px', textAlign: 'justify', color: 'black', marginLeft: '25px' }}>
                      <li onClick={() => { navigate("/Internet&Broadband/FastPlugConnect") }} > Fast Plug & Connect </li>
                      <li onClick={() => { navigate("/Internet&Broadband/HighSpeedFiberBroadband") }} >High Speed Fiber Broadband</li>
                      <li onClick={() => { navigate("/Internet&Broadband/CloudSolutions") }} >Cloud Solutions</li>
                      <li onClick={() => { navigate("/Internet&Broadband/RadioSolutions") }} >Radio Solutions </li>
                    </ul>


                    <h3 style={{ borderRadius: '5px', paddingLeft: "15px", alignSelf: "baseline", margin: '0', color: 'black' }}>
                      Internet On the Go</h3>
                    <ul style={{ width: '90%', justifyContent: 'flex-start', paddingLeft: '20px', textAlign: 'justify', color: 'black', marginLeft: '25px' }}>
                      <li onClick={() => { navigate("/Internet&Broadband/Mifis") }} >Mifis</li>
                      <li onClick={() => { navigate("/Internet&Broadband/Dongles") }} >Dongles</li>

                    </ul>



                    <h3 style={{ borderRadius: '5px', paddingLeft: "15px", alignSelf: "baseline", margin: '0', color: 'black' }}>
                      Internet @ Public Places</h3>
                    <ul style={{ width: '90%', justifyContent: 'flex-start', paddingLeft: '20px', textAlign: 'justify', color: 'black', marginLeft: '25px' }}>
                      <li onClick={() => { navigate("/Internet&Broadband/CommercialPublicPlaces") }} >Commercial Public Places </li>
                      <li onClick={() => { navigate("/Internet&Broadband/Hostels") }} >Hostels</li>
                      <li onClick={() => { navigate("/Internet&Broadband/Churches") }} >Churches </li>
                      <li onClick={() => { navigate("/Internet&Broadband/Mosques") }} >Mosques </li>
                    </ul>


                    <h3 onClick={() => { navigate("/Internet&Broadband/CommunityWiFiZones") }} className='droplink' style={{ borderRadius: '5px', paddingLeft: "15px", alignSelf: "baseline", margin: '0', color: 'black' }}>
                      Community Wi-Fi Zones</h3>



                  </div>

                  <img src='/internet and broadband.jpg' alt='' width={"400px"} />

                </div>

              </div>
            </li>
            <li className="nav-item2"><NavLink to="/Devices" activeClassName="active" onClick={(e) => e.preventDefault()}>Devices</NavLink>


              <div className='nabdropdown'>

                <div className='nabdropdown2' >

                  <ul className='insidedrop'>


                    <li onClick={() => { navigate("/Devices") }}> <h3 className='droplink' style={{ borderRadius: '5px', paddingLeft: "15px", alignSelf: "baseline", color: 'black' }}>
                      Devices with internet </h3></li>
                    <li onClick={() => { navigate("/Devices/SmartTvswithInternet") }}> <h3 className='droplink' style={{ borderRadius: '5px', paddingLeft: "15px", alignSelf: "baseline", color: 'black' }}>
                      Smart Tv’s with Internet </h3></li>

                    <li onClick={() => { navigate("/Devices/TabletswithInternet") }}>   <h3 className='droplink' style={{ borderRadius: '5px', paddingLeft: "15px", alignSelf: "baseline", color: 'black' }}>
                      Tablets with Internet  </h3></li>
                    <li onClick={() => { navigate("/Devices/LaptopswithInternet") }}>   <h3 className='droplink' style={{ borderRadius: '5px', paddingLeft: "15px", alignSelf: "baseline", color: 'black' }}>
                      Laptops with Internet  </h3></li>
                    <li onClick={() => { navigate("/Devices/AndroidBoxwithInternet") }}>   <h3 className='droplink' style={{ borderRadius: '5px', paddingLeft: "15px", alignSelf: "baseline", color: 'black' }}>
                      Android Box with Internet  </h3></li>



                  </ul>

                  <img src='/devices.jpg' alt='' width={"500px"} />

                </div>

              </div>

            </li>

            <li className="nav-item2"><NavLink to="/Deals&Promos" activeClassName="active" onClick={(e) => e.preventDefault()}>Deals & Promos</NavLink>

              <div className='nabdropdown'>

                <div className='nabdropdown2' >

                  <ul className='insidedrop'>


                    <li onClick={() => { navigate("/Deals&Promos/DealoftheWeek") }} > <h3 className='droplink' style={{ borderRadius: '5px', paddingLeft: "15px", alignSelf: "baseline", color: 'black' }}>
                      Deal of the Week </h3></li>

                    <li onClick={() => { navigate("/Deals&Promos/DiscountsCoupons") }} >   <h3 className='droplink' style={{ borderRadius: '5px', paddingLeft: "15px", alignSelf: "baseline", color: 'black' }}>
                      Discounts & Coupons  </h3></li>
                    <li onClick={() => { navigate("/Deals&Promos/FreeDataPromos") }} >   <h3 className='droplink' style={{ borderRadius: '5px', paddingLeft: "15px", alignSelf: "baseline", color: 'black' }}>
                      Free Data Promos </h3></li>
                    <li onClick={() => { navigate("/Deals&Promos/CashBackDeals ") }} >   <h3 className='droplink' style={{ borderRadius: '5px', paddingLeft: "15px", alignSelf: "baseline", color: 'black' }}>
                      Cash Back Deals  </h3></li>
                    <li onClick={() => { navigate("/Deals&Promos/LoyaltySchemes") }} >   <h3 className='droplink' style={{ borderRadius: '5px', paddingLeft: "15px", alignSelf: "baseline", color: 'black' }}>
                      Loyalty Schemes </h3></li>
                    <li onClick={() => { navigate("/Deals&Promos/Connectandwin") }} >   <h3 className='droplink' style={{ borderRadius: '5px', paddingLeft: "15px", alignSelf: "baseline", color: 'black' }}>
                      Connect and win </h3></li>


                  </ul>

                  <img src='/deals.jpg' alt='' width={"500px"} />

                </div>

              </div>


            </li>
            <li className="nav-item2"><NavLink to={"/Student"} activeClassName="active" onClick={(e) => e.preventDefault()}>Student</NavLink>


              <div className='nabdropdown'>

                <div className='nabdropdown2' >

                  <ul className='insidedrop'>

                    <li onClick={() => { navigate("/Student") }} > <h3 className='droplink' style={{ borderRadius: '5px', paddingLeft: "15px", alignSelf: "baseline", color: 'black' }}>
                      Student Exclusive  </h3></li>
                    <li onClick={() => { navigate("/Student/CampusConnect") }} > <h3 className='droplink' style={{ borderRadius: '5px', paddingLeft: "15px", alignSelf: "baseline", color: 'black' }}>
                      Campus Connect  </h3></li>

                    <li onClick={() => { navigate("/Student/HostelsWifi") }} >   <h3 className='droplink' style={{ borderRadius: '5px', paddingLeft: "15px", alignSelf: "baseline", color: 'black' }}>
                      Hostels Wifi  </h3></li>
                    <li onClick={() => { navigate("/Student/GroupConnect") }} >   <h3 className='droplink' style={{ borderRadius: '5px', paddingLeft: "15px", alignSelf: "baseline", color: 'black' }}>
                      Group Connect </h3></li>



                  </ul>

                  <img src='/student.jpg' alt='' width={"500px"} />

                </div>

              </div>

            </li>
            <li className="nav-item2"><NavLink to="/Workers" activeClassName="active">Workers (Coming Soon)</NavLink>

              <div className='nabdropdown'>

                <div className='nabdropdown2' >

                  <ul className='insidedrop'>


                    <li> <h3 className='droplink' style={{ borderRadius: '5px', paddingLeft: "15px", alignSelf: "baseline", color: 'black' }}>
                      Coming Soon  </h3></li>



                  </ul>

                  <img src='/workers.jpg' alt='' width={"500px"} />

                </div>

              </div>


            </li>
            <li className="nav-item2"><NavLink to="/Support" activeClassName="active" onClick={(e) => e.preventDefault()}>Support</NavLink>

              <div className='nabdropdown'>

                <div className='nabdropdown2' >

                  <ul className='insidedrop'>


                    <li onClick={() => { navigate("/Support/SwitchingNetworks") }} > <h3 className='droplink' style={{ borderRadius: '5px', paddingLeft: "15px", alignSelf: "baseline", color: 'black' }}>
                      Switching Networks </h3></li>
                    <li onClick={() => { navigate("/Support/StoreLocation") }} > <h3 className='droplink' style={{ borderRadius: '5px', paddingLeft: "15px", alignSelf: "baseline", color: 'black' }}>
                      Store Location </h3></li>
                    <li onClick={() => { navigate("/Support/Checkifyoucanupgrade") }} > <h3 className='droplink' style={{ borderRadius: '5px', paddingLeft: "15px", alignSelf: "baseline", color: 'black' }}>
                      Check if you can upgrade  </h3></li>
                    <li onClick={() => { navigate("/Support/Delivery") }} > <h3 className='droplink' style={{ borderRadius: '5px', paddingLeft: "15px", alignSelf: "baseline", color: 'black' }}>
                      Delivery  </h3></li>
                    <li onClick={() => { navigate("/Support/Contactastore") }} > <h3 className='droplink' style={{ borderRadius: '5px', paddingLeft: "15px", alignSelf: "baseline", color: 'black' }}>
                      Contact a store  </h3></li>
                    <li onClick={() => { navigate("/Support/GetSupport") }} > <h3 className='droplink' style={{ borderRadius: '5px', paddingLeft: "15px", alignSelf: "baseline", color: 'black' }}>
                      Get Support  </h3></li>
                    <li onClick={() => { navigate("/Support/InsuranceInformation") }} > <h3 className='droplink' style={{ borderRadius: '5px', paddingLeft: "15px", alignSelf: "baseline", color: 'black' }}>
                      Insurance Information  </h3></li>




                  </ul>

                  <img src='/support.jpg' alt='' width={"500px"} />

                </div>

              </div>


            </li>

            <li className="nav-item2"><NavLink to="/Business" activeClassName="active" onClick={(e) => e.preventDefault()}>Business Solutions</NavLink>

              <div className='nabdropdown'>

                <div className='nabdropdown2' >

                  <ul className='insidedrop'>


                    <li onClick={() => { navigate("/Business") }} > <h3 className='droplink' style={{ borderRadius: '5px', paddingLeft: "15px", alignSelf: "baseline", color: 'black' }}>
                      Business Solutions  </h3></li>




                  </ul>

                  <img src='/business solution.jpg' alt='' width={"500px"} />

                </div>

              </div>

            </li>
          </div>
          <div style={{ display: 'flex', alignSelf: 'flex-end', justifySelf: 'end' }}>
            {/* <li className="nav-item"><NavLink to="/favourite" activeClassName="active"> <AiFillHeart style={{width:'40px',height:'40px'}} /> </NavLink></li> */}
            <li className="nav-item"><NavLink to="/cart" activeClassName="active">
              <AiOutlineShoppingCart style={{ width: '30px', height: '30px' }} />

              {product2.length !== 0 && <>

                <div className='cartcout'>{product2.length}</div>
              </>}

            </NavLink></li>
          </div>


        </ul>




      </nav>

      <div className='mobbbbnav'>
        <nav className="navbar">
          <ul className="nav-listmob">

            <a href="/" >   <img src='/trans2.png' alt='ads' loading='lazzy' width={"200px"} /></a>

            <div style={{ display: 'flex' }}>
              {Mobnav === false && <>      <li className="nav-item"><NavLink activeClassName="active" onClick={() => setmobnav(true)}> <FaBars /> </NavLink></li></>}


              {Mobnav === true && <>    <li className="nav-item"><NavLink activeClassName="active" onClick={() => setmobnav(false)}> <GrFormClose /> </NavLink></li></>}


            </div>



            {Mobnav === true && <>
              <div className='mobnav'>



                <li className="nav-item"><NavLink exact to="/" activeClassName="active" onClick={() => setmobnav(false)}>Home</NavLink></li>



                <li className="nav-item"><NavLink exact to="/Internet&Broadband" activeClassName="active" onClick={(e) => {
                  e.preventDefault()
                  setopenmobnav(1)
                }}>Internet & Broadband </NavLink>

                  {openMobnav === 1 && <>
                    <div className='nabdropdown2' style={{ justifyContent: 'flex-start' }} >

                      <div className='insidedrop'>
                        <h3 style={{ borderRadius: '5px', paddingLeft: "15px", alignSelf: "baseline", margin: '0', color: 'black' }}>
                          Home & Small Business</h3>
                        <ul style={{ width: '90%', justifyContent: 'flex-start', paddingLeft: '20px', textAlign: 'justify', color: 'black', marginLeft: '25px' }}>
                          <li onClick={() => { navigate("/Internet&Broadband/FastPlugConnect") }} > Fast Plug & Connect </li>
                          <li onClick={() => { navigate("/Internet&Broadband/HighSpeedFiberBroadband") }} >High Speed Fiber Broadband</li>
                          <li onClick={() => { navigate("/Internet&Broadband/CloudSolutions") }} >Cloud Solutions</li>
                          <li onClick={() => { navigate("/Internet&Broadband/RadioSolutions") }} >Radio Solutions </li>
                        </ul>


                        <h3 style={{ borderRadius: '5px', paddingLeft: "15px", alignSelf: "baseline", margin: '0', color: 'black' }}>
                          Internet On the Go</h3>
                        <ul style={{ width: '90%', justifyContent: 'flex-start', paddingLeft: '20px', textAlign: 'justify', color: 'black', marginLeft: '25px' }}>
                          <li onClick={() => { navigate("/Internet&Broadband/Mifis") }} >Mifis</li>
                          <li onClick={() => { navigate("/Internet&Broadband/Dongles") }} >Dongles</li>

                        </ul>



                        <h3 style={{ borderRadius: '5px', paddingLeft: "15px", alignSelf: "baseline", margin: '0', color: 'black' }}>
                          Internet @ Public Places</h3>
                        <ul style={{ width: '90%', justifyContent: 'flex-start', paddingLeft: '20px', textAlign: 'justify', color: 'black', marginLeft: '25px' }}>
                          <li onClick={() => { navigate("/Internet&Broadband/CommercialPublicPlaces") }} >Commercial Public Places </li>
                          <li onClick={() => { navigate("/Internet&Broadband/Hostels") }} >Hostels</li>
                          <li onClick={() => { navigate("/Internet&Broadband/Churches") }} >Churches </li>
                          <li onClick={() => { navigate("/Internet&Broadband/Mosques") }} >Mosques </li>
                        </ul>


                        <h3 onClick={() => { navigate("/Internet&Broadband/CommunityWiFiZones") }} className='droplink' style={{ borderRadius: '5px', paddingLeft: "15px", alignSelf: "baseline", margin: '0', color: 'black' }}>
                          Community Wi-Fi Zones</h3>



                      </div>


                    </div>
                  </>}

                </li>




                <li className="nav-item"><NavLink to="/Devices" activeClassName="active" onClick={(e) => {
                  e.preventDefault()
                  setopenmobnav(2)
                }
                }>Devices</NavLink>



                  {openMobnav === 2 && <>
                    <div className='nabdropdown2' style={{ justifyContent: 'flex-start' }} >

                      <ul className='insidedrop'>


                        <li onClick={() => { navigate("/Devices") }}> <h3 className='droplink' style={{ borderRadius: '5px', paddingLeft: "15px", alignSelf: "baseline", color: 'black' }}>
                          Devices with internet </h3></li>
                        <li onClick={() => { navigate("/Devices/SmartTvswithInternet") }}> <h3 className='droplink' style={{ borderRadius: '5px', paddingLeft: "15px", alignSelf: "baseline", color: 'black' }}>
                          Smart Tv’s with Internet </h3></li>

                        <li onClick={() => { navigate("/Devices/TabletswithInternet") }}>   <h3 className='droplink' style={{ borderRadius: '5px', paddingLeft: "15px", alignSelf: "baseline", color: 'black' }}>
                          Tablets with Internet  </h3></li>
                        <li onClick={() => { navigate("/Devices/LaptopswithInternet") }}>   <h3 className='droplink' style={{ borderRadius: '5px', paddingLeft: "15px", alignSelf: "baseline", color: 'black' }}>
                          Laptops with Internet  </h3></li>
                        <li onClick={() => { navigate("/Devices/AndroidBoxwithInternet") }}>   <h3 className='droplink' style={{ borderRadius: '5px', paddingLeft: "15px", alignSelf: "baseline", color: 'black' }}>
                          Android Box with Internet  </h3></li>

                      </ul>


                    </div>

                  </>}

                </li>

                <li className="nav-item"><NavLink to="/Deals&Promos" activeClassName="active" onClick={(e) => {
                  e.preventDefault()
                  setopenmobnav(3)
                }
                }>Deals & Promos</NavLink>


                  {openMobnav === 3 && <>
                    <div className='nabdropdown2' style={{ justifyContent: 'flex-start' }}>

                      <ul className='insidedrop'>


                        <li onClick={() => { navigate("/Deals&Promos/DealoftheWeek") }} > <h3 className='droplink' style={{ borderRadius: '5px', paddingLeft: "15px", alignSelf: "baseline", color: 'black' }}>
                          Deal of the Week </h3></li>

                        <li onClick={() => { navigate("/Deals&Promos/DiscountsCoupons") }} >   <h3 className='droplink' style={{ borderRadius: '5px', paddingLeft: "15px", alignSelf: "baseline", color: 'black' }}>
                          Discounts & Coupons  </h3></li>
                        <li onClick={() => { navigate("/Deals&Promos/FreeDataPromos") }} >   <h3 className='droplink' style={{ borderRadius: '5px', paddingLeft: "15px", alignSelf: "baseline", color: 'black' }}>
                          Free Data Promos </h3></li>
                        <li onClick={() => { navigate("/Deals&Promos/CashBackDeals ") }} >   <h3 className='droplink' style={{ borderRadius: '5px', paddingLeft: "15px", alignSelf: "baseline", color: 'black' }}>
                          Cash Back Deals  </h3></li>
                        <li onClick={() => { navigate("/Deals&Promos/LoyaltySchemes") }} >   <h3 className='droplink' style={{ borderRadius: '5px', paddingLeft: "15px", alignSelf: "baseline", color: 'black' }}>
                          Loyalty Schemes </h3></li>
                        <li onClick={() => { navigate("/Deals&Promos/Connectandwin") }} >   <h3 className='droplink' style={{ borderRadius: '5px', paddingLeft: "15px", alignSelf: "baseline", color: 'black' }}>
                          Connect and win </h3></li>


                      </ul>



                    </div>
                  </>}

                </li>
                <li className="nav-item"><NavLink to={"/Student"} activeClassName="active" onClick={(e) => {
                  e.preventDefault()
                  setopenmobnav(4)
                }
                }>Student</NavLink>



                  {openMobnav === 4 && <>
                    <div className='nabdropdown2' style={{ justifyContent: 'flex-start' }} >

                      <ul className='insidedrop'>

                        <li onClick={() => { navigate("/Student") }} > <h3 className='droplink' style={{ borderRadius: '5px', paddingLeft: "15px", alignSelf: "baseline", color: 'black' }}>
                          Student Exclusive  </h3></li>
                        <li onClick={() => { navigate("/Student/CampusConnect") }} > <h3 className='droplink' style={{ borderRadius: '5px', paddingLeft: "15px", alignSelf: "baseline", color: 'black' }}>
                          Campus Connect  </h3></li>

                        <li onClick={() => { navigate("/Student/HostelsWifi") }} >   <h3 className='droplink' style={{ borderRadius: '5px', paddingLeft: "15px", alignSelf: "baseline", color: 'black' }}>
                          Hostels Wifi  </h3></li>
                        <li onClick={() => { navigate("/Student/GroupConnect") }} >   <h3 className='droplink' style={{ borderRadius: '5px', paddingLeft: "15px", alignSelf: "baseline", color: 'black' }}>
                          Group Connect </h3></li>



                      </ul>



                    </div>
                  </>}
                </li>
                <li className="nav-item"><NavLink to="/Workers" activeClassName="active" onClick={(e) => {
                  e.preventDefault()
                  setopenmobnav(5)
                }

                }>Workers (Coming Soon)</NavLink>


                  {openMobnav === 5 && <>
                    <div className='nabdropdown2' style={{ justifyContent: 'flex-start' }}>

                      <ul className='insidedrop'>


                        <li> <h3 className='droplink' style={{ borderRadius: '5px', paddingLeft: "15px", alignSelf: "baseline", color: 'black' }}>
                          Coming Soon  </h3></li>



                      </ul>


                    </div>
                  </>}

                </li>
                <li className="nav-item"><NavLink to="/Support" activeClassName="active" onClick={(e) => {
                  e.preventDefault()
                  setopenmobnav(6)
                }

                }>Support</NavLink>


                  {openMobnav === 6 && <>
                    <div className='nabdropdown2' style={{ justifyContent: 'flex-start' }}>

                      <ul className='insidedrop'>


                        <li onClick={() => { navigate("/Support/SwitchingNetworks") }} > <h3 className='droplink' style={{ borderRadius: '5px', paddingLeft: "15px", alignSelf: "baseline", color: 'black' }}>
                          Switching Networks </h3></li>
                        <li onClick={() => { navigate("/Support/StoreLocation") }} > <h3 className='droplink' style={{ borderRadius: '5px', paddingLeft: "15px", alignSelf: "baseline", color: 'black' }}>
                          Store Location </h3></li>
                        <li onClick={() => { navigate("/Support/Checkifyoucanupgrade") }} > <h3 className='droplink' style={{ borderRadius: '5px', paddingLeft: "15px", alignSelf: "baseline", color: 'black' }}>
                          Check if you can upgrade  </h3></li>
                        <li onClick={() => { navigate("/Support/Delivery") }} > <h3 className='droplink' style={{ borderRadius: '5px', paddingLeft: "15px", alignSelf: "baseline", color: 'black' }}>
                          Delivery  </h3></li>
                        <li onClick={() => { navigate("/Support/Contactastore") }} > <h3 className='droplink' style={{ borderRadius: '5px', paddingLeft: "15px", alignSelf: "baseline", color: 'black' }}>
                          Contact a store  </h3></li>
                        <li onClick={() => { navigate("/Support/GetSupport") }} > <h3 className='droplink' style={{ borderRadius: '5px', paddingLeft: "15px", alignSelf: "baseline", color: 'black' }}>
                          Get Support  </h3></li>
                        <li onClick={() => { navigate("/Support/InsuranceInformation") }} > <h3 className='droplink' style={{ borderRadius: '5px', paddingLeft: "15px", alignSelf: "baseline", color: 'black' }}>
                          Insurance Information  </h3></li>




                      </ul>



                    </div>
                  </>}

                </li>

                <li className="nav-item"><NavLink to="/Business" activeClassName="active" onClick={(e) => {
                  e.preventDefault()
                  setopenmobnav(7)
                }

                }>Business Solutions</NavLink>


                  {openMobnav === 7 && <>
                    <div className='nabdropdown2' style={{ justifyContent: 'flex-start' }}>

                      <ul className='insidedrop'>


                        <li onClick={() => { navigate("/Business") }} > <h3 className='droplink' style={{ borderRadius: '5px', paddingLeft: "15px", alignSelf: "baseline", color: 'black' }}>
                          Business Solutions  </h3></li>




                      </ul>




                    </div>
                  </>}
                </li>

                <li className="nav-item"><NavLink to="/Dshop" activeClassName="active" onClick={() => setmobnav(false)}>D-Shop</NavLink></li>


                <li className="nav-item"><NavLink to="/about" activeClassName="active" onClick={() => setmobnav(false)}>About</NavLink></li>
                <li className="nav-item"><NavLink to="/contact" activeClassName="active" onClick={() => setmobnav(false)}>Contact Us</NavLink></li>


                <li className="nav-item"><NavLink to="/cart" activeClassName="active" onClick={() => setmobnav(false)}><AiOutlineShoppingCart style={{ width: '40px', height: '40px' }} /></NavLink></li>






              </div>
            </>}
          </ul>
        </nav>
      </div>


    </>

  )
}

export default Navbar