import React, { useState } from 'react'
import Navbar from "../../components/navbar"
import Comingsoon from '../../components/comingsoon'





function CommunityWiFiZones() {









  return (
    <div>

      <div style={{ marginTop: '110px' }}></div>


      <Navbar />

<div className='mainpadding'>
      <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-start', paddingLeft: '0px', textAlign: 'justify', color: 'black' }}>
                   
      Here you will find the list of all areas and communities where Data Warehouse Green Wifis currently available.   
                    </div>



                    </div>





    
      <Comingsoon/>
  







    </div>
  )
}

export default CommunityWiFiZones