import React, { useEffect, useState } from 'react'
import Navbar from "../../components/navbar"
import Comingsoon from '../../components/comingsoon'
import { Image } from 'antd';
import { Collapse } from 'antd';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';


const { Panel } = Collapse;


function SmartTvswithInternet() {
  let navigate = useNavigate();
  const [showcheckout, setshowcheckout] = useState(true);


  const [selselectplanebox, setSelselectplanebox] = useState(false);

  const [mode, setmode] = useState("1");

  const brands = [
    { name: 'Kuai', logo: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR21aLpCVcCBAZTu7PIswjdhtRggydAhJ51yw&s' },
    { name: 'Moove', logo: 'https://www.businesslist.com.ng/img/ng/a/1575922624-59-moove-tv-limited.png' },
    { name: 'Sony', logo: 'https://logos-world.net/wp-content/uploads/2020/04/Sony-Logo.png' },
    { name: 'TLC', logo: 'https://iconape.com/wp-content/png_logo_vector/tlc-logo.png' },
    { name: 'Samsung', logo: 'https://upload.wikimedia.org/wikipedia/commons/thumb/2/24/Samsung_Logo.svg/1280px-Samsung_Logo.svg.png' },
    { name: 'Toshiba', logo: 'https://e7.pngegg.com/pngimages/845/385/png-clipart-laptop-toshiba-logo-company-electronics-laptop-electronics-company.png' },
    { name: 'Hisense', logo: 'https://upload.wikimedia.org/wikipedia/commons/thumb/5/54/Hisense_Logo.svg/1280px-Hisense_Logo.svg.png' },
    { name: 'Philip', logo: 'https://via.placeholder.com/150?text=Philip' },
    { name: 'Nasco', logo: 'https://via.placeholder.com/150?text=Nasco' },
    { name: 'Panasonic', logo: 'https://upload.wikimedia.org/wikipedia/commons/thumb/0/05/Panasonic_Logo.svg/1280px-Panasonic_Logo.svg.png' },
    { name: 'JVC', logo: 'https://upload.wikimedia.org/wikipedia/commons/thumb/7/7a/JVC_logo.svg/1280px-JVC_logo.svg.png' },
    { name: 'LG', logo: 'https://upload.wikimedia.org/wikipedia/commons/thumb/1/19/LG_Logo.svg/1280px-LG_Logo.svg.png' },
    { name: 'Sharp', logo: 'https://upload.wikimedia.org/wikipedia/commons/thumb/0/0f/Sharp_Logo.svg/1280px-Sharp_Logo.svg.png' }
  ];

  const [cookieproduct, setcookieproduct] = useState([]);


  const [activeBrand, setActiveBrand] = useState(null);

  const handleCellClick = (brandName) => {
    setActiveBrand(brandName);
  };



  const [tvframe, settvframe] = useState("");
  const [size, setsize] = useState("");
  const [option, setoption] = useState("");

  const [selectedOption, setSelectedOption] = useState('');

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };


  const tvData = [
    { size: '32in', was: 3040, now: 2889 },
    { size: '43in', was: 5280, now: 4799 },
    { size: '50in', was: null, now: null },
    { size: '55in', was: 8448, now: 7680 },
    { size: '65in', was: null, now: null },
    { size: '70in', was: 10389, now: 9449 },
    { size: '75in', was: null, now: null },
    { size: '85in', was: 21474, now: 19520 },
    { size: '100in', was: null, now: null }
  ];


  const [selectedSize, setSelectedSize] = useState('');
  const [priceDetails, setPriceDetails] = useState({ was: '', now: '' });


  const handleSizeChange = (event) => {
    const selected = event.target.value;
    setSelectedSize(selected);

    const selectedTV = tvData.find(tv => tv.size === selected);
    if (selectedTV) {
      setPriceDetails({
        was: selectedTV.was !== null ? selectedTV.was.toLocaleString() : '-',
        now: selectedTV.now !== null ? selectedTV.now.toLocaleString() : '-'
      });
    }
  };


  useEffect(() => {



    // Check if the 'Product' cookie exists
    if (Cookies.get('Product')) {
      // The 'Product' cookie exists, so you can proceed to use its value
      const productCookie = Cookies.get('Product');
      try {
        const productData = JSON.parse(productCookie);
        setcookieproduct(productData);
      } catch (error) {
        console.error('Error parsing JSON from the "Product" cookie:', error);
      }
    } else {
      // The 'Product' cookie does not exist or is empty
      console.warn('The "Product" cookie is not set or is empty.');
    }




    // setcookieproduct(JSON.parse(Cookies.get('Product')));
  }, [])




  return (


    <div>


      <div style={{ marginTop: '110px' }}></div>


      <Navbar />




      <div className='mifimain'>


        <h1 style={{ borderLeft: "5px solid #0587EF", textAlign: 'left', borderRadius: '5px', color: "rgb(70, 70, 70)", paddingLeft: "10px", alignSelf: "baseline" }}>
          Smart Tv’s with Internet</h1>



        <div style={{ width: '90%', display: 'flex', justifyContent: 'flex-start', paddingLeft: '17px', textAlign: 'justify', color: 'black', marginBottom: '50px' }}>
          Want to take your home viewing experience up a level (or three)? Then a smart TV might be exactly what you need!
          Imagine a slick flat-screen TV. Now, imagine it with seamless internet connectivity, powerful processors, high definition and a user-friendly interface - and you’ve got a smart TV.
          Smart TVs are more like smartphones or tablets, offering a whole host of exciting online features for viewers to choose from at the touch of a button. Pretty exciting.
          But what is a smart TV exactly? How do they work and what are the best smart TV apps, games and features? Keep reading, as we reveal all.

        </div>

        <h4 style={{ borderRadius: '5px', paddingLeft: "15px", alignSelf: "baseline", margin: '0', color: 'black' }}>
          What is a smart TV and what does the term mean?
        </h4>

        <div style={{ width: '90%', display: 'flex', justifyContent: 'flex-start', paddingLeft: '17px', textAlign: 'justify', color: 'black', marginBottom: '50px' }}>
          Combining traditional functionality with streaming capabilities, a smart TV is a flat screen television that is equipped with integrated internet connectivity and a built-in operating system.  This allows the smart TV to effortlessly stream content, play music, browse social media and so much more.


        </div>

        {showcheckout === true && <>

          {/* 1 */}
          <div className='procarddd'>

            <div className='subprocard'>

              <div className='subprocardImg'>
                <Image src='/routers pictures/MT10/New folder/1.jpg' alt="profile" height={"100%"} width={"100%"} />
              </div>


              <div className='subprocarddescrib'>
                <h3 style={{ borderRadius: '5px', paddingLeft: "15px", alignSelf: "baseline", margin: '0', color: 'black' }}>
                  Frameless Smart TVs </h3>

                <div style={{ width: '90%', display: 'flex', justifyContent: 'flex-start', paddingLeft: '17px', textAlign: 'justify', color: 'black' }}>
                  A frameless design enhances your viewing experience by eliminating the bezel, allowing you to focus solely on the screen. The ultra-slim border creates a seamless, immersive display that blends effortlessly into your space, offering a more engaging and cinematic experience.
                </div>
              </div>

            </div>


            <div className='subprocardfeature'>
              <h3 style={{ borderRadius: '5px', paddingLeft: "15px", alignSelf: "baseline", margin: '0', color: 'black' }}>
                Features</h3>

              <ul style={{ margin: '0', fontSize: "0.7em" }}>
                <li>4K Ultra HD Resolution</li>

                <li>HDR (High Dynamic Range) Support</li>
                <li>LED or OLED Screen Technology</li>

                <li>Multiple HDMI and USB Ports</li>

                <li>Screen Mirroring and Casting</li>
                <li>High Refresh Rate (e.g., 120Hz)</li>

                <li>Customizable Picture and Sound Settings</li>
                <li>Built-in Speakers with Surround Sound</li>
                <li>Energy-Efficient Design</li>

              </ul>


            </div>

            <div className='subprocardprice'>
              <h3 style={{ borderRadius: '5px', paddingLeft: "15px", alignSelf: "baseline", margin: '0', color: 'black' }}>
                Price</h3>

              <p style={{ width: '100%', fontWeight: 'bold', paddingLeft: "15px", display: 'flex', margin: '0', justifyContent: 'flex-start', textAlign: 'left', color: 'red' }}>
                was  <span style={{ textDecoration: 'line-through' }}> &nbsp; GHc 729.00</span>
              </p>
              <p style={{ width: '100%', paddingLeft: "15px", display: 'flex', margin: '0', justifyContent: 'flex-start', textAlign: 'left', color: 'black' }}>
                NOW  <span style={{}}> &nbsp;GHc 729.00</span>
              </p>

            </div>


            <div className='subprocardbutton'>
              <button
                onClick={() => {


                  setSelselectplanebox(true)

                  // setproductselected("DW SMART MIFI – MT10")

                  settvframe(
                    "Frameless"
                  )



                }}




              >ADD TO CART</button>
            </div>

          </div>


          {/* 2 */}
          <div className='procarddd'>

            <div className='subprocard'>

              <div className='subprocardImg'>
                <Image src='/routers pictures/MT10/New folder/1.jpg' alt="profile" height={"100%"} width={"100%"} />
              </div>


              <div className='subprocarddescrib'>
                <h3 style={{ borderRadius: '5px', paddingLeft: "15px", alignSelf: "baseline", margin: '0', color: 'black' }}>
                  Frame Smart TVs </h3>

                <div style={{ width: '90%', display: 'flex', justifyContent: 'flex-start', paddingLeft: '17px', textAlign: 'justify', color: 'black' }}>
                  A frame TV features a sleek, minimalistic design that integrates seamlessly with your décor. Its ultra-thin bezel provides a near-borderless look, making the screen appear as a work of art. The design enhances the viewing experience by blending into your living space and focusing attention solely on the content
                </div>
              </div>

            </div>


            <div className='subprocardfeature'>
              <h3 style={{ borderRadius: '5px', paddingLeft: "15px", alignSelf: "baseline", margin: '0', color: 'black' }}>
                Features</h3>

              <ul style={{ margin: '0', fontSize: "0.7em" }}>
                <li>4K Ultra HD Resolution</li>

                <li>HDR (High Dynamic Range) Support</li>
                <li>LED or OLED Screen Technology</li>

                <li>Multiple HDMI and USB Ports</li>

                <li>Screen Mirroring and Casting</li>
                <li>High Refresh Rate (e.g., 120Hz)</li>

                <li>Customizable Picture and Sound Settings</li>
                <li>Built-in Speakers with Surround Sound</li>
                <li>Energy-Efficient Design</li>

              </ul>


            </div>

            <div className='subprocardprice'>
              <h3 style={{ borderRadius: '5px', paddingLeft: "15px", alignSelf: "baseline", margin: '0', color: 'black' }}>
                Price</h3>

              <p style={{ width: '100%', fontWeight: 'bold', paddingLeft: "15px", display: 'flex', margin: '0', justifyContent: 'flex-start', textAlign: 'left', color: 'red' }}>
                was  <span style={{ textDecoration: 'line-through' }}> &nbsp; GHc 729.00</span>
              </p>
              <p style={{ width: '100%', paddingLeft: "15px", display: 'flex', margin: '0', justifyContent: 'flex-start', textAlign: 'left', color: 'black' }}>
                NOW  <span style={{}}> &nbsp;GHc 729.00</span>
              </p>

            </div>


            <div className='subprocardbutton'>
              <button
                onClick={() => {


                  setSelselectplanebox(true)

                  // setproductselected("DW SMART MIFI – MT10")

                  settvframe(
                    "Frame"
                  )



                }}



              >ADD TO CART</button>
            </div>

          </div>





          <h3 style={{ borderRadius: '5px', paddingLeft: "15px", alignSelf: "baseline", margin: '0', color: 'black' }}>
            Features</h3>

          <table style={{ width: '100%', borderCollapse: 'collapse', fontSize: '0.7em' }}>
            <thead>
              <tr>
                <th style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'left' }}>Feature</th>
                <th style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'left' }}>Specification</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style={{ border: '1px solid #ddd', padding: '8px' }}>System</td>
                <td style={{ border: '1px solid #ddd', padding: '8px' }}>Android</td>
              </tr>
              <tr>
                <td style={{ border: '1px solid #ddd', padding: '8px' }}>Resolution</td>
                <td style={{ border: '1px solid #ddd', padding: '8px' }}>Default (3840 * 2160) 4K UHD</td>
              </tr>
              <tr>
                <td style={{ border: '1px solid #ddd', padding: '8px' }}>Type</td>
                <td style={{ border: '1px solid #ddd', padding: '8px' }}>Smart</td>
              </tr>
              <tr>
                <td style={{ border: '1px solid #ddd', padding: '8px' }}>Screen</td>
                <td style={{ border: '1px solid #ddd', padding: '8px' }}>New A+ Screen</td>
              </tr>
              <tr>
                <td style={{ border: '1px solid #ddd', padding: '8px' }}>Border</td>
                <td style={{ border: '1px solid #ddd', padding: '8px' }}>Narrow Border</td>
              </tr>
              <tr>
                <td style={{ border: '1px solid #ddd', padding: '8px' }}>Screen Type</td>
                <td style={{ border: '1px solid #ddd', padding: '8px' }}>LED</td>
              </tr>
              <tr>
                <td style={{ border: '1px solid #ddd', padding: '8px' }}>Voltage</td>
                <td style={{ border: '1px solid #ddd', padding: '8px' }}>130-145W less then 600mA*2 Voltage</td>
              </tr>
              <tr>
                <td style={{ border: '1px solid #ddd', padding: '8px' }}>Power</td>
                <td style={{ border: '1px solid #ddd', padding: '8px' }}>180W</td>
              </tr>
              <tr>
                <td style={{ border: '1px solid #ddd', padding: '8px' }}>Aspect Ratio</td>
                <td style={{ border: '1px solid #ddd', padding: '8px' }}>16:9</td>
              </tr>
              <tr>
                <td style={{ border: '1px solid #ddd', padding: '8px' }}>Refresh Frequency</td>
                <td style={{ border: '1px solid #ddd', padding: '8px' }}>60Hz</td>
              </tr>
              <tr>
                <td style={{ border: '1px solid #ddd', padding: '8px' }}>Contrast</td>
                <td style={{ border: '1px solid #ddd', padding: '8px' }}>4000:1</td>
              </tr>
              <tr>
                <td style={{ border: '1px solid #ddd', padding: '8px' }}>Speakers</td>
                <td style={{ border: '1px solid #ddd', padding: '8px' }}>2Ω 10W *2</td>
              </tr>
              <tr>
                <td style={{ border: '1px solid #ddd', padding: '8px' }}>Memory</td>
                <td style={{ border: '1px solid #ddd', padding: '8px' }}>2GB x 16GB</td>
              </tr>
              <tr>
                <td style={{ border: '1px solid #ddd', padding: '8px' }}>Ports</td>
                <td style={{ border: '1px solid #ddd', padding: '8px' }}>RJ45/HDMI X3/RF/AV/WIFI/VGA/USB/BLUETOOTH 5.0/Built-in Headphone Socket</td>
              </tr>
              <tr>
                <td style={{ border: '1px solid #ddd', padding: '8px' }}>Packing</td>
                <td style={{ border: '1px solid #ddd', padding: '8px' }}>2445*289*1478MM</td>
              </tr>
              <tr>
                <td style={{ border: '1px solid #ddd', padding: '8px' }}>Gross Weight</td>
                <td style={{ border: '1px solid #ddd', padding: '8px' }}>42kg</td>
              </tr>
            </tbody>
          </table>



          <div style={{ marginTop: '50px' }}></div>
          <h4 style={{ borderRadius: '5px', paddingLeft: "15px", alignSelf: "baseline", margin: '0', color: 'black' }}>
            Financing Option 1
          </h4>

          <div style={{ width: '90%', display: 'flex', justifyContent: 'flex-start', paddingLeft: '17px', textAlign: 'justify', color: 'black', marginBottom: '50px' }}>
            You pays 50 or 60 % - balance is finance for up to a period of 24months with DWH in-house financing


          </div>


          <h4 style={{ borderRadius: '5px', paddingLeft: "15px", alignSelf: "baseline", margin: '0', color: 'black' }}>
            Financing Option 2
          </h4>


          <div style={{ width: '90%', display: 'flex', justifyContent: 'flex-start', paddingLeft: '17px', textAlign: 'justify', color: 'black', marginBottom: '50px' }}>
            20% deposit and the balance is financed via bank for upto 36months
            (mainly for recognised and approved institutional workers)


          </div>



          <Collapse style={{ margin: "15px", width: '100%', color: '#rgb(240, 230, 230)', backgroundColor: "#rgb(240, 230, 230)", marginBottom: '50px' }} accordion>




            <Panel style={{ color: '#rgb(240, 230, 230)' }} header={<><div>How does a smart TV work?</div></>} key="0">
              <div style={{ width: '100%', display: 'flex', flexWrap: 'wrap', justifyContent: 'center', gap: '10px' }}>

                <div style={{ display: "flex", flexDirection: 'column', justifyContent: 'flex-start', padding: '0 20px', alignItems: 'baseline', width: '100%' }}>

                  Wondering how to use your smart TV? You can connect a smart TV directly to your WiFi network or via an ethernet cable so that it can access the internet. Either log in or set up an account and start personalising your smart TV.
                  <br /> Then, choose from a multitude of different apps (much like you would on your smartphone) and you can also download more apps by searching for them. Smart TVs are easy to use, simply click on the app you want, and you will be taken through to that page.


                </div>

              </div>


            </Panel>



            <Panel style={{ color: '#rgb(240, 230, 230)' }} header={<><div>What does a smart TV do?</div></>} key="1">
              <div style={{ width: '100%', display: 'flex', flexWrap: 'wrap', justifyContent: 'center', gap: '10px' }}>

                <div style={{ display: "flex", flexDirection: 'column', justifyContent: 'flex-start', padding: '0 20px', alignItems: 'baseline', width: '100%' }}>

                  You might have guessed that smart TVs can do MUCH more than your humble regular TV. For us at Data Warehouse Gh, the main advantage of a smart TV is the interconnectivity it brings. Seamlessly sync your smartphone, Skype, Facebook, Netflix and much more at the touch of a button. You don’t have to faff around connecting other devices or wires, as everything is already built-in – result.
                  <br></br>  There are so many great things to do with a smart TV - the possibilities are endless. Here are some of our favourite smart TV benefits, apps, games and features:
                  <ul>
                    <li> Enhanced Picture and Sound Quality</li>
                    <li>    Seamless Integration with Other Devices</li>
                    <li>    App Ecosystem and Smart Features</li>
                    <li>   Interactive and User-Friendly Interface, Internet Connectivity and Streaming
                    </li>
                  </ul>
                </div>

              </div>


            </Panel>


            <Panel style={{ color: '#rgb(240, 230, 230)' }} header={<><div>Stream on-demand content, instantly              </div></>} key="2">
              <div style={{ width: '100%', display: 'flex', flexWrap: 'wrap', justifyContent: 'center', gap: '10px' }}>

                <div style={{ display: "flex", flexDirection: 'column', justifyContent: 'flex-start', padding: '0 20px', alignItems: 'baseline', width: '100%' }}>

                  On-demand is one of the best features of a smart TV – allowing you to catch up on your favourite day-to-day shows so you never have to miss an episode again. You can stream anything you want in an instant. Choose from a selection of specialty channels to watch sports and films.
                  <br></br>      And just when you thought things couldn’t get any better, you can also cast content from your phone, tablet, or laptop directly onto your smart TV. Through the use of Apple Airplay or by using the incredibly handy Google Chromecast.

                </div>

              </div>


            </Panel>


            <Panel style={{ color: '#rgb(240, 230, 230)' }} header={<><div>Browse the web</div></>} key="3">
              <div style={{ width: '100%', display: 'flex', flexWrap: 'wrap', justifyContent: 'center', gap: '10px' }}>

                <div style={{ display: "flex", flexDirection: 'column', justifyContent: 'flex-start', padding: '0 20px', alignItems: 'baseline', width: '100%' }}>

                  The built-in web browser in a smart TV enables you to access the internet, view web pages and watch videos – all from the comfort of your living room (or wherever your TV is). No need to go and dig out your laptop to catch up on today’s news headlines or research some trivia for your next family quiz.
                </div>

              </div>


            </Panel>



            <Panel style={{ color: '#rgb(240, 230, 230)' }} header={<><div>Watch videos on YouTube</div></>} key="4">
              <div style={{ width: '100%', display: 'flex', flexWrap: 'wrap', justifyContent: 'center', gap: '10px' }}>

                <div style={{ display: "flex", flexDirection: 'column', justifyContent: 'flex-start', padding: '0 20px', alignItems: 'baseline', width: '100%' }}>

                  Whether you want to boogie to your favourite tunes or follow a step-by-step cooking tutorial on the big screen, having access to YouTube through your television can be invaluable. You’ll be able to log into your account too, so you can watch all your favourite creators on the big screen. It’s no surprise that with all this free content YouTube has become one of the key apps to have on your smart TV.
                </div>

              </div>


            </Panel>



            <Panel style={{ color: '#rgb(240, 230, 230)' }} header={<><div>Listen to music</div></>} key="5">
              <div style={{ width: '100%', display: 'flex', flexWrap: 'wrap', justifyContent: 'center', gap: '10px' }}>

                <div style={{ display: "flex", flexDirection: 'column', justifyContent: 'flex-start', padding: '0 20px', alignItems: 'baseline', width: '100%' }}>

                  Listen to your favourite singles and albums from up-and-coming popstars and musical legends alike by connecting your Spotify account with your smart TV. Access millions of songs, with more added every day, and take charge of the soundtrack of your daily life. It’s a fantastic smart TV feature that you won’t know how you ever lived without. But don’t worry if you’re not subscribed to Spotify – in recent years Apple Music and Amazon Music have been expanding the number of Smart TVs that their apps are on too.
                </div>

              </div>


            </Panel>



            <Panel style={{ color: '#rgb(240, 230, 230)' }} header={<><div>Scroll on social media</div></>} key="6">
              <div style={{ width: '100%', display: 'flex', flexWrap: 'wrap', justifyContent: 'center', gap: '10px' }}>

                <div style={{ display: "flex", flexDirection: 'column', justifyContent: 'flex-start', padding: '0 20px', alignItems: 'baseline', width: '100%' }}>

                  Can’t be bothered to move rooms and switch on the computer? Smartphone out of reach? Well then, you’ll be glad to know that you can still catch up with your friends and stay connected on Facebook, Twitter and TikTok via your smart television – handy, huh? Let the scrolling commence…
                </div>

              </div>


            </Panel>




          </Collapse>

        </>}




      </div>




      {selselectplanebox === true && <>

        <div className='mifimifiselectplanbagmain'>
          <div className='mifiselectplanbag'
            onClick={() => {
            setSelselectplanebox(false)
            
            setmode("1")
            }}>


        </div>

        {mode === "1" && <>
          <div className='mifiselectplanbag2'>

            <h3 style={{ borderRadius: '5px', backgroundColor: '#0587EF', padding: "5px", alignSelf: "baseline", margin: '0', color: 'White' }}>
              Kindly select the BRAND to proceed further.                     </h3>



            <div style={{ marginBottom: '50px', marginTop: '50px', display: "flex", flexWrap: 'wrap', justifyContent: "center", gap: '10px' }}>
              {/* <table border="1">
                <thead>
                  <tr>
                    <th>Brand</th>
                    <th>Logo</th>
                  </tr>
                </thead>
                <tbody>
                  {brands.map((brand, index) => (
                    <tr
                      key={index}
                      style={{ backgroundColor: activeBrand === brand.name ? '#d3d3d3' : 'white' }}
                      onClick={() => handleCellClick(brand.name)}
                    >
                      <td>{brand.name}</td>
                      <td>
                        <img src={brand.logo} alt={`${brand.name} logo`} style={{ width: '50px' }} />
                      </td>
                    </tr>
                  ))}
                </tbody>

              

              </table> */}
              {brands.map((brand, index) => (
                <div style={{ backgroundColor: activeBrand === brand.name ? '#d3d3d3' : 'white', width: "200px", display: 'flex', justifyContent: "center", alignItems: 'center', height: 'fit-contant',padding:'20px', border: '1px solid gray' }} onClick={() => handleCellClick(brand.name)}>

                  {brand.name}
                  {/* <img src={brand.logo} alt={`${brand.name} logo`} style={{ width: '50px' }} /> */}

                </div>
              ))}
              {/* {activeBrand && <p>Selected Brand: {activeBrand}</p>} */}
            </div>








            <div className='buttoonn'>
              <button onClick={() => {

                setSelselectplanebox(false)
                setActiveBrand(null);
                setmode("1")

              }}>Cancel</button>
              <button
                onClick={async (e) => {

                  setmode("2")
                }}


              >Continue</button>
            </div>



          </div>

        </>}


        {mode === "2" && <>
          <div className='mifiselectplanbag2'>

            <h3 style={{ borderRadius: '5px', backgroundColor: '#0587EF', padding: "5px", alignSelf: "baseline", margin: '0', color: 'White' }}>
              Kindly select Buying Options proceed further.                     </h3>

            <div style={{ width: '90%', display: 'flex', justifyContent: 'flex-start', textAlign: 'justify', color: 'black' }}>
              Buy now receive 1 Free Data Warehouse Gh Smart Dongle and 15GB of data

            </div>

            <div style={{ width: '90%', display: 'flex', justifyContent: 'flex-start', textAlign: 'justify', color: 'black' }}>
              Protect your product today with DWH Care

            </div>

            <div style={{ width: '90%', display: 'flex', justifyContent: 'flex-start', textAlign: 'justify', color: 'black' }}>
              2yrs Accidental Damage and Extended Warranty

            </div>

            <table border="1" cellPadding={"2px"} cellSpacing={0} width={"100%"}>
              <thead>
                <tr>
                  <th>TV Size</th>
                  <th>Was</th>
                  <th>Now</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>32in</td>
                  <td>3,040</td>
                  <td>2,889</td>
                </tr>
                <tr>
                  <td>43in</td>
                  <td>5,280</td>
                  <td>4,799</td>
                </tr>
                <tr>
                  <td>50in</td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td>55in</td>
                  <td>8,448</td>
                  <td>7,680</td>
                </tr>
                <tr>
                  <td>65in</td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td>70in</td>
                  <td>10,389</td>
                  <td>9,449</td>
                </tr>
                <tr>
                  <td>75in</td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td>85in</td>
                  <td>21,474</td>
                  <td>19,520</td>
                </tr>
                <tr>
                  <td>100in</td>
                  <td></td>
                  <td></td>
                </tr>
              </tbody>
            </table>


            <h3 style={{ borderRadius: '5px', marginTop: '50px', backgroundColor: '#0587EF', padding: "5px", alignSelf: "baseline", margin: '0', color: 'White' }}>
              Kindly select Fill the Form to proceed further.                     </h3>

            <select onChange={(e) => { setsize(e.target.value) }} style={{ marginTop: '10px', width: '100%', height: '60px', border: '1px solid gray' }}>
              <option disabled value="" selected>Select TV Size</option>
              {tvData.map((tv, index) => (
                <option key={index} value={tv.size}>
                  {tv.size}
                </option>
              ))}
            </select>


            <select onChange={(e) => { setoption(e.target.value) }} style={{ marginTop: '10px', width: '100%', height: '60px', border: '1px solid gray' }}>
              <option disabled value="" selected>Select Buying Options</option>
              <option value="TVs + Data" >TVs + Data </option>
              <option value="TV only" >TV only   </option>
              <option value="Buying with finance" >Buying with finance  </option>

            </select>



            <div className='buttoonn'>
              <button onClick={() => {

                setSelselectplanebox(false)
                setActiveBrand(null);
                setmode("1")
              }}>Cancel</button>
              <button
                onClick={async (e) => {

                  if (mode === "2") {


                    // await  console.log(productselected.productname);
                    var price
                    if (size === "32in") {
                      price = "2889"
                    } else if (size === "43in") {
                      price = "4,799"
                    } else if (size === "50in") {
                      price = "To be assign"
                    } else if (size === "55in") {
                      price = "7,680"
                    } else if (size === "65in") {
                      price = "To be assign"
                    } else if (size === "70in") {
                      price = "9,449"
                    } else if (size === "75in") {
                      price = "To be assign"
                    } else if (size === "85in") {
                      price = "19520"
                    } else if (size === "100in") {
                      price = "To be assign"
                    }



                    const newProduct = {
                      "productname": tvframe + " " + size + " " + "(" + activeBrand + ")",
                      "productprice": price,
                      "productimg": "https://media.istockphoto.com/id/1395191574/photo/black-led-tv-television-screen-blank-isolated.jpg?s=612x612&w=0&k=20&c=ps14JZJh0ebkINcbQyHFsR1J5EC7ozkj_WO7Fh_9IOI=",
                      "dataplane": option
                    };




                    const newupdateddata = [...cookieproduct, newProduct]



                    // await console.log(newProduct)


                    const updatedProductJSON = JSON.stringify(newupdateddata);

                    await Cookies.set('Product', updatedProductJSON, { expires: 7 });

                    await navigate("/cart")


                  } else {
                    setmode("2")
                  }
                }}


              >Continue</button>
            </div>



          </div>

        </>}

      </div>

    </>}
    </div >
  )
}

export default SmartTvswithInternet