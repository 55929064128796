import React from 'react'
import { NavLink } from 'react-router-dom';
import Navbar from '../components/navbar';


function support() {
  return (
    <div>


<div style={{ marginTop: '110px' }}></div>







<Navbar/>



    </div>
  )
}

export default support