import React, { useState } from 'react'
import Navbar from '../components/navbar';
import Comingsoon from '../components/comingsoon';
import { Image } from 'antd';
import { Collapse } from 'antd';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const { Panel } = Collapse;

function Business() {

const navigate = useNavigate();










  const [firstname, setfirstname] = useState();
  const [lastname, setlastname] = useState();
  
  const [ShreetAddress, setShreetAddress] = useState();

 
  const [PhoneNumber, setPhoneNumber] = useState();
  const [Email, setEmail] = useState();






   const sendemail = async (e) => {
    e.preventDefault()



    const url = 'https://api.sendinblue.com/v3/smtp/email';
    const apiKey = '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'; // Replace with your actual API key






    const htmlContent = `
    <div style="  padding: 10px; background-color: #fff;">

    <h1 style=" margin: 0;">Customer Details - HIGH SPEED FIBRE BROADBAND</h1>
    <div style="padding-left: 20px;border: 1px solid gray;">
        <div style="display: flex;">
            <h3 style=" width: 200px;"> business entity</h3>
            <h3>: ${firstname} </h3>
        </div>
       
        <div style="display: flex;">
            <h3 style="width: 200px;"> contact person</h3>
            <h3>: ${lastname}</h3>
        </div>
      
        <div style="display: flex;">
        <h3 style="width: 200px;"> Street Address</h3>
        <h3>: ${ShreetAddress}</h3>
    </div>

        <div style="display: flex;">
            <h3 style="width: 200px;"> contact number</h3>
            <h3>: ${PhoneNumber}</h3>
        </div>
        <div style="display: flex;">
            <h3 style="width: 200px;"> Email</h3>
            <h3>: ${Email}</h3>
        </div>
      
    </div>


   

</div>

`;





    const data = {
      sender: {
        name: 'Data Warehouse Gh',
        email: 'info@datawarehousegh.com'
      },
      to: [
        {
          email: "datawarehousegh@gmail.com",
          name: "Data Warehouse Gh"
        }
      ],
      subject: 'Business',
      htmlContent
    };

    const headers = {
      'Content-Type': 'application/json',
      'api-key': apiKey
    };

    await axios.post(url, data, { headers })
      .then(response => {



        const updatedProductJSON = JSON.stringify([]);
        alert("Your Imformation has been successfully sent, and we will promptly reach out to you as soon as possible to provide further updates and assistance")

        console.log("OTP has been sent to ")
        navigate("/")
      }

      )
      .catch(error => {

        console.log(error)
      }
      );



  }









  

  return (
    <div>


      <div style={{ marginTop: '110px' }}></div>



      <Navbar />

      <div className='mainpadding'>



        <h1 style={{ borderLeft: "5px solid #0587EF", margin: '0', textAlign: 'left', borderRadius: '5px', color: "rgb(70, 70, 70)", paddingLeft: "10px", alignSelf: "baseline" }}>
          Business Solutions</h1>



        <div style={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', paddingLeft: '17px', textAlign: 'left', color: 'gray', marginBottom: '0px' }}>

          <p>   Data Warehouse Business solutions provides immediate access to internet everywhere in Ghana for businesses and NGOs. We have a basket of solutions to meet the need of most small to media size enterprises in particular and also provide value for money internet solutions for all their staff.
            <br /> It even gets better with our award-winning state of the art <strong> DW L830 android powered all-in-one fully connected Executive desk-phone. </strong></p>


        </div>







        <div>
          <Image src='/images/busniss.11.jpg' width={"20%"} alt='' />
          <Image src='/images/busniss.111.jpg' width={"20%"} alt='' />
          <Image src='/images/busniss.1.jpg' width={"20%"} alt='' />
          <Image src='/images/busniss.1111.jpg' width={"20%"} alt='' />
          <Image src='/images/busniss.11111.jpg' width={"20%"} alt='' />

        </div>

        <video width="100%" controls>
          <source src="/images/busvideo.mp4" type="video/mp4" />

        </video>


        <h2 style={{ borderRadius: '5px', width: '100%', paddingLeft: "15px", textAlign: 'left', margin: '0', color: 'gray', alignSelf: "baseline" }}>
          Device Specification</h2>

        <div style={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', paddingLeft: '17px', textAlign: 'left', color: 'gray', marginBottom: '50px' }}>


          <p><strong>Network Bands:</strong> 4G LTE Bands: 1/3/7/20/28A/38/39/40/41</p>
          <p><strong>3G WCDMA Bands:</strong> 1/2/5/8 (2100/1900/850/900MHz)</p>
          <p><strong>2G GSM Bands:</strong> 850/900/1800/1900MHz</p>
          <p><strong>Processor:</strong> Quad-core 1.5GHz</p>
          <p><strong>Operating System:</strong> Android 8.1</p>
          <p><strong>Storage:</strong> 16GB eMMC + 2GB DDR3 (Storage extension up to 64GB)</p>
          <p><strong>DSP CODEC (Audio):</strong> FR, HR, EFR, AMR, WB-AMR</p>
          <p><strong>Display:</strong> 8" IPS TFT, 1280 x 800 pixels</p>
          <p><strong>Camera:</strong> 12 million pixels</p>
          <p><strong>Wireless Connectivity:</strong> Wi-Fi 802.11 b/g/n (2.4GHz), Bluetooth 4.2LE</p>
          <p><strong>Physical Buttons:</strong> Number keys, Volume adjustment keys, Power button</p>
          <p><strong>Ports and Connectors:</strong> Micro USB, 3.5mm Audio Jack, SIM card slot (1 Standard SIM card), T-flash (microSD) card slot</p>
          <p><strong>Power:</strong> Input AC 110~220V, Output DC 5V/1.5A, Battery: 4000mAh Li-Ion</p>
        </div>

        <h1 style={{ borderRadius: '5px', width: '100%', paddingLeft: "15px", textAlign: 'left', margin: '0', color: 'gray', alignSelf: "baseline" }}>
          Our Business Offerings</h1>

        {/* <div style={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', paddingLeft: '17px', textAlign: 'left', color: 'gray', marginBottom: '50px' }}>

          <li>Go Boundless</li>
          <li>Pulse Connect</li>
          <li>Pulse Connect Plus</li>
          <li>Dedicated Internet</li>

        </div> */}




        <h2 style={{ borderRadius: '5px', width: '100%', paddingLeft: "15px", textAlign: 'left', margin: '0', color: 'gray', alignSelf: "baseline" }}>
          Go Boundless</h2>

        <h3 style={{ borderRadius: '5px', width: '100%', paddingLeft: "15px", textAlign: 'left', margin: '0', color: 'gray', alignSelf: "baseline" }}>
          Multi Staff savings</h3>


        <div style={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', paddingLeft: '17px', textAlign: 'left', color: 'gray', marginBottom: '50px' }}>

          More Staff members mean better value, even when you buy over time. Save up to 30percent per a staff per a month compared to MTN, DW GH, or AT.

        </div>




        <h3 style={{ borderRadius: '5px', width: '100%', paddingLeft: "15px", textAlign: 'left', margin: '0', color: 'gray', alignSelf: "baseline" }}>
          Flexible team and staff package</h3>



        <div style={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', paddingLeft: '17px', textAlign: 'left', color: 'gray', marginBottom: '50px' }}>

          Go boundless mobile broadband solution allows you to add and remove users/staff members and provides tailor services to each employee, and only pay for what you need.
        </div>

        <h3 style={{ borderRadius: '5px', width: '100%', paddingLeft: "15px", textAlign: 'left', margin: '0', color: 'gray', alignSelf: "baseline" }}>
          Benefits includes</h3>



        <div style={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', paddingLeft: '17px', textAlign: 'left', color: 'gray', marginBottom: '50px' }}>

          <li>Generous Internet Data </li>
          <li>Bespoke for each staff member.</li>
          <li>Cheaper data means Great Savings </li>
          <li>Dedicated professional customer service</li>

          And more
        </div>





        <h4 className='hovar' style={{ borderRadius: '5px', width: '100%', paddingLeft: "15px", textAlign: 'left', margin: '0', color: 'gray', alignSelf: "baseline" }}>
          Get in Touch</h4>



        <form onSubmit={sendemail} className='chectoutform' style={{ paddingLeft: '17px' }}>



          <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '30px' }}>
            <div style={{ display: "flex", flexDirection: 'column', textAlign: 'left', width: '48%' }}>
              <label>business entity*</label>
             <input required className='chectoutforminput' onChange={(e) => setfirstname(e.target.value)} />
            </div>


            <div style={{ display: "flex", flexDirection: 'column', textAlign: 'left', width: '48%' }}>
              <label>contact person*</label>
              <input required className='chectoutforminput' onChange={(e) => setlastname(e.target.value)} />
            </div>


          </div>







          <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '30px' }}>
            <div style={{ display: "flex", flexDirection: 'column', textAlign: 'left', width: '100%' }}>
              <label>Street Address*</label>
             <input required className='chectoutforminput' onChange={(e) => setShreetAddress(e.target.value)} />
            </div>



          </div>



          <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '30px' }}>
            <div style={{ display: "flex", flexDirection: 'column', textAlign: 'left', width: '48%' }}>
              <label>Phone Number*</label>
              <input type='number' required className='chectoutforminput' onChange={(e) => setPhoneNumber(e.target.value)} />
            </div>

            <div style={{ display: "flex", flexDirection: 'column', textAlign: 'left', width: '48%' }}>
              <label>Email*</label>
                <input type='email' required className='chectoutforminput' onChange={(e) => setEmail(e.target.value)} />
            </div>


          </div>

          <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '30px', width: '100%' }}>
            <div style={{ display: "flex", flexDirection: 'column', textAlign: 'left', width: '100%' }}>

              <button className='chectoutforminput2'  type='submit' >Sent</button>
            </div>




          </div>


        </form>





        <h4 className='hovar' style={{ borderRadius: '5px', width: '100%', paddingLeft: "15px", textAlign: 'left', margin: '0', color: 'gray', alignSelf: "baseline" }}>
          Not a business owner, and not self-employed?
          See our offers for you - <a href='/Internet&Broadband/Mifis'>Click HERE</a></h4>







        <h2 style={{ borderRadius: '5px', width: '100%', paddingLeft: "15px", textAlign: 'left', margin: '0', color: 'gray', alignSelf: "baseline", marginTop: '50px' }}>
          DW pulse connect</h2>


        <div style={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', paddingLeft: '17px', textAlign: 'left', color: 'gray', marginBottom: '50px' }}>

          Working with best-in-class partners, we’ve created a package that gives you the essential collaboration and connectivity tools needed for your business to thrive. Easy to manage, and value for money solution.
        </div>




        <h3 style={{ borderRadius: '5px', width: '100%', paddingLeft: "15px", textAlign: 'left', margin: '0', color: 'gray', alignSelf: "baseline" }}>
          Adaptable Broadband plans</h3>

        <h5 style={{ borderRadius: '5px', width: '100%', paddingLeft: "15px", textAlign: 'left', margin: '0', color: 'gray', alignSelf: "baseline" }}>
          Adjust plans at no cost</h5>

        <div style={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', paddingLeft: '17px', textAlign: 'left', color: 'gray', marginBottom: '50px' }}>

          Scale your plans up or down as your business requirements change, and don’t pay for what you don’t need
          <li>  flexible plans that grow with your business.</li>
          <li>  Full range of devices to fit your needs.</li>
          <li>   Simpler cost management</li>
          <li>    Full Fibre broadband (subject to availability)</li>
          <li>   Automatic 4G Mobile backup and WiFi</li>
          <li>    Simple set up with plug and play devices.</li>
          <li>  Manage connectivity for many locations from one place.</li>

        </div>


        <h2 style={{ borderRadius: '5px', width: '100%', paddingLeft: "15px", textAlign: 'left', margin: '0', color: 'gray', alignSelf: "baseline" }}>
          Automatic rewards</h2>

        <div style={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', paddingLeft: '17px', textAlign: 'left', color: 'gray', marginBottom: '50px' }}>

          Enjoy bigger discounts the more you purchase. Receive Bonus Applications with the full package1.

        </div>


        <Collapse style={{ margin: "15px", width: '100%', color: '#rgb(240, 230, 230)', backgroundColor: "#rgb(240, 230, 230)", marginBottom: '50px', textAlign: 'left' }} accordion>




          <Panel style={{ color: '#rgb(240, 230, 230)' }} header={<><div>Who is eligible for DW GH Pulse Connect?</div></>} key="0">
            <div style={{ width: '100%', display: 'flex', flexWrap: 'wrap', justifyContent: 'center', gap: '10px' }}>

              <div style={{ display: "flex", flexDirection: 'column', justifyContent: 'flex-start', padding: '0 20px', alignItems: 'baseline', width: '100%' }}>


                DW GH Pulse Connect has been designed for businesses with between 5 and 150 employees. Existing customers who would like to move to this service should check with their Account Manager or customer service representative regarding eligibility.
                <br /> <br />  Please note, consumer customers and business customers with a team of less than 5 people cannot purchase this service at this time

              </div>

            </div>


          </Panel>


          <Panel style={{ color: '#rgb(240, 230, 230)' }} header={<><div>How do I set up my DW GH Pulse Connect Broadband with DW GH Pulse Connect Mobile?</div></>} key="1">
            <div style={{ width: '100%', display: 'flex', flexWrap: 'wrap', justifyContent: 'center', gap: '10px' }}>


              <div style={{ display: "flex", flexDirection: 'column', justifyContent: 'flex-start', padding: '0 20px', alignItems: 'baseline', width: '100%' }}>

                DW GH Pulse Connect Broadband comes with a preconfigured DW GH modem, and a router – simply plug in and power up your DW GH branded router, and get online.
                <br /> <br />       Need more help getting started with DW GH Pulse Connect Broadband? Try our contact us
              </div>

            </div>


          </Panel>





          <Panel style={{ color: '#rgb(240, 230, 230)' }} header={<><div>Can I transfer my existing mobile number to DW GH Pulse Connect Mobile?</div></>} key="2">
            <div style={{ width: '100%', display: 'flex', flexWrap: 'wrap', justifyContent: 'center', gap: '10px' }}>


              <div style={{ display: "flex", flexDirection: 'column', justifyContent: 'flex-start', padding: '0 20px', alignItems: 'baseline', width: '100%' }}>
                Yes, you can. DW GH Pulse Connect’s mobile porting process works exactly the same as a normal switch. You’ll need a Porting Authorisation Code to start, and we will do the rest.


              </div>
            </div>


          </Panel>









        </Collapse>






        <h1 style={{ borderRadius: '5px', width: '100%', paddingLeft: "15px", textAlign: 'left', margin: '0', color: 'gray', alignSelf: "baseline" }}>
          DATA WAREHOUSE DEDICATED INTERNET</h1>


        <h2 style={{ borderRadius: '5px', width: '100%', paddingLeft: "15px", textAlign: 'left', margin: '0', color: 'gray', alignSelf: "baseline" }}>
        A reliable high-speed connection means your business is always online.</h2>


        <div style={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', paddingLeft: '17px', textAlign: 'left', color: 'gray', marginBottom: '50px' }}>

          With Dedicated Internet Access, you’ll never have to share your connection or compete with consumer traffic thanks to our uncontended service dedicated to business customers. And with service speeds from upto 150Mbps, you can choose the service that suits your needs.
          <br />   Dedicated Internet Access is ideal if your organisation depends on a reliable connection for business-critical services, whether that’s simply hosting your own website or using your connection for online sales, VoIP (Voice over IP) services, cloud-based applications, or large file transfers. We also offer a range of standard and optional security options to keep you online.

        </div>




        <h3 style={{ borderRadius: '5px', width: '100%', paddingLeft: "15px", textAlign: 'left', margin: '0', color: 'gray', alignSelf: "baseline" }}>
        An uncontended service</h3>


        <div style={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', paddingLeft: '17px', textAlign: 'left', color: 'gray', marginBottom: '50px' }}>

        You’ll get 100% of the bandwidth you’re paying for – dedicated symmetrical circuits ensure you don’t share bandwidth with anyone else, and that your download and upload speeds are the same.
        </div>


        <h3 style={{ borderRadius: '5px', width: '100%', paddingLeft: "15px", textAlign: 'left', margin: '0', color: 'gray', alignSelf: "baseline" }}>
        Unlimited access</h3>


        <div style={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', paddingLeft: '17px', textAlign: 'left', color: 'gray', marginBottom: '50px' }}>

        There are no restrictions on monthly usage or additional charges for large files, making it ideal for hosting your own website, managing email efficiency or supporting business-critical applications.
        </div>


        <h3 style={{ borderRadius: '5px', width: '100%', paddingLeft: "15px", textAlign: 'left', margin: '0', color: 'gray', alignSelf: "baseline" }}>
        The speed you need</h3>


        <div style={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', paddingLeft: '17px', textAlign: 'left', color: 'gray', marginBottom: '50px' }}>

        Choose from any of the available bandwidths for a connection speed that suits your business. A ‘burst’ option provides extra bandwidth to meet unexpected peaks in demand, while throttled ports let you prepare for planned growth.
        </div>


        <h3 style={{ borderRadius: '5px', width: '100%', paddingLeft: "15px", textAlign: 'left', margin: '0', color: 'gray', alignSelf: "baseline" }}>
        Confidently connected</h3>


        <div style={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', paddingLeft: '17px', textAlign: 'left', color: 'gray', marginBottom: '50px' }}>

        Business-critical applications stay connected thanks to 24/7 support and service monitoring spotting problems before they start, and dual access connections that keep you online.
        </div>


        <h3 style={{ borderRadius: '5px', width: '100%', paddingLeft: "15px", textAlign: 'left', margin: '0', color: 'gray', alignSelf: "baseline" }}>
        Personalise your security</h3>


        <div style={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', paddingLeft: '17px', textAlign: 'left', color: 'gray', marginBottom: '50px' }}>

        Keep your connection and your data safe with optional security features including Distributed Denial of Service (DDoS) protection, internet proxy services, email security, managed firewalls and resilient service options
        </div>







      </div>



    </div>
  )
}

export default Business