import React from 'react'
import "../css/faq.css"
import { NavLink } from 'react-router-dom';
import { Collapse } from 'antd';
import Navbar from '../components/navbar';



const { Panel } = Collapse;

function faq() {
  return (
    <div>

      <div style={{ marginTop: '110px' }}></div>


<Navbar/>

      <div className='faqmaindiv'>

        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', textAlign: 'left', height: "auto", width: '90%' }} className='uperwaladin' >
          <h1 style={{ borderLeft: "5px solid blue", borderRadius: '5px', color: "rgb(70, 70, 70)", paddingLeft: "10px", alignSelf: "baseline", marginBottom: '0' }}>
            Frequently Asked Questions (FAQs)</h1>




          <h3 style={{ borderRadius: '5px', paddingLeft: "15px", alignSelf: "baseline", margin: '0', color: 'gray' }}>
            How can you contact customer support?</h3>

          <div style={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', paddingLeft: '17px', paddingRight: '20px', textAlign: 'left', color: 'gray' }}>
            <li>  Chat with the team online – click here</li>

            <li>    Call the sales team on 0534629229 or 0534932022</li>
            <li>    You can also get in touch with us via whatsapp</li>
          </div>



          <Collapse style={{ margin: "15px", width: '100%', color: '#rgb(240, 230, 230)', backgroundColor: "#rgb(240, 230, 230)", marginBottom: '50px' }} accordion>




            <Panel style={{ color: '#rgb(240, 230, 230)' }} header={<><div>How can I subscribe to Data Warehouse Gh Internet Service</div></>} key="0">
              <div style={{ width: '100%', display: 'flex', flexWrap: 'wrap', justifyContent: 'center', gap: '10px' }}>

                <div style={{ display: "flex", flexDirection: 'column', justifyContent: 'flex-start', padding: '0 20px', alignItems: 'baseline', width: '100%' }}>

                  you can subscribe to our internet service and any of our device offerings by placing order online, with our sales representatives or from any of our partner agents.

                </div>

              </div>


            </Panel>


            <Panel style={{ color: '#rgb(240, 230, 230)' }} header={<><div>What are the payment options?</div></>} key="1">
              <div style={{ width: '100%', display: 'flex', flexWrap: 'wrap', justifyContent: 'center', gap: '10px' }}>


                <div style={{ display: "flex", flexDirection: 'column', justifyContent: 'flex-start', padding: '0 20px', alignItems: 'baseline', width: '100%' }}>
                  Data Warehouse Gh offers flexible monthly payment plan for all government and corporate workers. For the self-employed, we offer payment plans to meet their lifestyle which works for them greatly. Our students’ offerings are also available nationwide for those in recognised academic institutions.
                </div>

              </div>


            </Panel>





            <Panel style={{ color: '#rgb(240, 230, 230)' }} header={<><div>Can I replace my laptop or smartphone for Data Warehouse Gh smart devices?</div></>} key="2">
              <div style={{ width: '100%', display: 'flex', flexWrap: 'wrap', justifyContent: 'center', gap: '10px' }}>


                <div style={{ display: "flex", flexDirection: 'column', justifyContent: 'flex-start', padding: '0 20px', alignItems: 'baseline', width: '100%' }}>

                  Yes, all options are available. You can trade your smartphones and computers for our Data Warehouse Gh smart devices which are fully connected with high-speed internet.
                </div>
              </div>


            </Panel>





            <Panel style={{ color: '#rgb(240, 230, 230)' }} header={<><div>What happens when your subscription expires?</div></>} key="3">
              <div style={{ width: '100%', display: 'flex', flexWrap: 'wrap', justifyContent: 'center', gap: '10px' }}>


                <div style={{ display: "flex", flexDirection: 'column', justifyContent: 'flex-start', padding: '0 20px', alignItems: 'baseline', width: '100%' }}>

                  You can subscribe for our products for up to 60months and after the subscription period expires, you can renew it and continue enjoying our products. if you want to exchange your device for a new one also these options are available.
                </div>
              </div>


            </Panel>

            <Panel style={{ color: '#rgb(240, 230, 230)' }} header={<><div>What are your coverage areas in Ghana?</div></>} key="4">
              <div style={{ width: '100%', display: 'flex', flexWrap: 'wrap', justifyContent: 'center', gap: '10px' }}>


                <div style={{ display: "flex", flexDirection: 'column', justifyContent: 'flex-start', padding: '0 20px', alignItems: 'baseline', width: '100%' }}>

                  Data Warehouse Gh has smartly partnered the major players in the market, our coverage is therefore nationwide. We are in a position to serve and provide server and provide services anywhere in Ghana no matter your location. And the our internet connectivity is super-fast and always reliable with 98% uptime.
                </div>

              </div>



            </Panel>

            <Panel style={{ color: '#rgb(240, 230, 230)' }} header={<><div>What is Data Warehouse Gh all about?</div></>} key="5">
              <div style={{ width: '100%', display: 'flex', flexWrap: 'wrap', justifyContent: 'center', gap: '10px' }}>


                <div style={{ display: "flex", flexDirection: 'column', justifyContent: 'flex-start', padding: '0 20px', alignItems: 'baseline', width: '100%' }}>

                  Data Warehouse Gh is on a mission to provide individuals, households, institutions and communities with reliable and affordable internet/Wi-Fi connectivity, smart devices & other value-added services across Ghana.
                  Data Warehouse vision is to bring affordable internet to the doorstep of specially the unconnected communities and help reduce poverty and the deprivation of digital services to our communities.    </div>

              </div>



            </Panel>


            <Panel style={{ color: '#rgb(240, 230, 230)' }} header={<><div>What products and services are Data Warehouse offering?</div></>} key="6">
              <div style={{ width: '100%', display: 'flex', flexWrap: 'wrap', justifyContent: 'center', gap: '10px' }}>


                <div style={{ display: "flex", flexDirection: 'column', justifyContent: 'flex-start', padding: '0 20px', alignItems: 'baseline', width: '100%' }}>

                  Reliable and affordable internet to individuals, groups of persons and businesses.
                </div>

              </div>



            </Panel>

            <Panel style={{ color: '#rgb(240, 230, 230)' }} header={<><div>Are there data limits?</div></>} key="7">
              <div style={{ width: '100%', display: 'flex', flexWrap: 'wrap', justifyContent: 'center', gap: '10px' }}>


                <div style={{ display: "flex", flexDirection: 'column', justifyContent: 'flex-start', padding: '0 20px', alignItems: 'baseline', width: '100%' }}>

                  Data Warehouse Gh offers packages the meets and the individual customers’ needs and requirement up to unlimited data packages in Ghana. No data restriction, just decide on what works for you and enjoy Data Warehouse Gh network 24/7 with fast speed internet connectivity.
                </div>

              </div>



            </Panel>

            <Panel style={{ color: '#rgb(240, 230, 230)' }} header={<><div>Where is Data Warehouse Gh located?</div></>} key="8">
              <div style={{ width: '100%', display: 'flex', flexWrap: 'wrap', justifyContent: 'center', gap: '10px' }}>


                <div style={{ display: "flex", flexDirection: 'column', justifyContent: 'flex-start', padding: '0 20px', alignItems: 'baseline', width: '100%' }}>

                  Data Warehouse Gh is headquartered in Accra but has offices, sales offices, and our partner dealership shop all across the length and breath of Ghana. Please use our store locator to find the Data Warehouse point of sales nearest to you.
                </div>

              </div>



            </Panel>

            <Panel style={{ color: '#rgb(240, 230, 230)' }} header={<><div>How can I upgrade my services or account?</div></>} key="9">
              <div style={{ width: '100%', display: 'flex', flexWrap: 'wrap', justifyContent: 'center', gap: '10px' }}>


                <div style={{ display: "flex", flexDirection: 'column', justifyContent: 'flex-start', padding: '0 20px', alignItems: 'baseline', width: '100%' }}>

                  Please contact our customer service for support - <a href='/contact'>our customer service team </a>  </div>

              </div>



            </Panel>

            <Panel style={{ color: '#rgb(240, 230, 230)' }} header={<><div>How do I contact sales?</div></>} key="10">
              <div style={{ width: '100%', display: 'flex', flexWrap: 'wrap', justifyContent: 'center', gap: '10px' }}>


                <div style={{ display: "flex", flexDirection: 'column', justifyContent: 'flex-start', padding: '0 20px', alignItems: 'baseline', width: '100%' }}>

                  Call the sales team on 0534629229 or 0534932022
                  <br />    You can also get in touch with us via whatsapp
                </div>

              </div>



            </Panel>

            <Panel style={{ color: '#rgb(240, 230, 230)' }} header={<><div>How can I track my order?</div></>} key="11">
              <div style={{ width: '100%', display: 'flex', flexWrap: 'wrap', justifyContent: 'center', gap: '10px' }}>


                <div style={{ display: "flex", flexDirection: 'column', justifyContent: 'flex-start', padding: '0 20px', alignItems: 'baseline', width: '100%' }}>
                  Expecting a delivery from one of our partners!
                  <br />  Requested an installation? Want to know when you can expect your engineer? Wondering how your repair is getting on?

                  <br />   No problem! Our online trackers let you check the status of your order, when you can expect delivery, when your engineer will arrive, and what stage your repair is at, keeping you up-to-date and in the know.

                  <br />    Your confirmation email tells you who'll be delivering your order. Once you know who to expect, you can track your order below.
                  <br />   Please contact our dedicated support team for further assistance – <a href='/contact'> Click here </a> </div>

              </div>



            </Panel>

            <Panel style={{ color: '#rgb(240, 230, 230)' }} header={<><div>What is the return policy?</div></>} key="12">
              <div style={{ width: '100%', display: 'flex', flexWrap: 'wrap', justifyContent: 'center', gap: '10px' }}>


                <div style={{ display: "flex", flexDirection: 'column', justifyContent: 'flex-start', padding: '0 20px', alignItems: 'baseline', width: '100%' }}>

                  You can return something you bought from us if it doesn’t work properly, or you’ve changed your mind and don’t want it anymore.
                  <br />   Just make sure you send it back to us within the time limit:
                  <br />   Changed your mind – 3 days.
                  <br />   Faulty item – 7 days
                  <br />   Accessories – 7 days
                  <br />   We aim to refund you within 30days of receiving the item.
                  <br />   The item must be in its original form and in its original package and not tempered with in any shape of form. We will consider a refund and for items damaged by you at our exclusive discretion.
                  <br />    Please <a href='/returns&refunds'>click here</a> to initiate a refund or a return process

                </div>

              </div>



            </Panel>

            <Panel style={{ color: '#rgb(240, 230, 230)' }} header={<><div>How can I complain with an issue with my order?</div></>} key="13">
              <div style={{ width: '100%', display: 'flex', flexWrap: 'wrap', justifyContent: 'center', gap: '10px' }}>


                <div style={{ display: "flex", flexDirection: 'column', justifyContent: 'flex-start', padding: '0 20px', alignItems: 'baseline', width: '100%' }}>

                  We’re really sorry about that.
                  <br />    If you bought the item in store:
                  <br />    You can take the item to any of our stores with a valid receipt and they’ll be happy to help.
                  <br />   If you bought the item online:
                  <br />    You can take the item to any of our with a valid receipt and they’ll be happy to help.
                  <br />    If you are unable to visit one of our stores, our customer service team will be able to assist.
                </div>

              </div>



            </Panel>

            <Panel style={{ color: '#rgb(240, 230, 230)' }} header={<><div>Where can I buy or subscribe to Data Warehouse Gh products and services?</div></>} key="14">
              <div style={{ width: '100%', display: 'flex', flexWrap: 'wrap', justifyContent: 'center', gap: '10px' }}>


                <div style={{ display: "flex", flexDirection: 'column', justifyContent: 'flex-start', padding: '0 20px', alignItems: 'baseline', width: '100%' }}>

                  Right here online or get in touch with our sales team via a phone call or whatsapp on 0534629229. You may visit any our partner shops nearer to you.
                </div>

              </div>



            </Panel>

            <Panel style={{ color: '#rgb(240, 230, 230)' }} header={<><div>How does billing work?</div></>} key="15">
              <div style={{ width: '100%', display: 'flex', flexWrap: 'wrap', justifyContent: 'center', gap: '10px' }}>


                <div style={{ display: "flex", flexDirection: 'column', justifyContent: 'flex-start', padding: '0 20px', alignItems: 'baseline', width: '100%' }}>

                  Data Warehouse services are mainly on a monthly subscription basis for a contractual length of time, customers pay their subscriptions via the various payment options we offer. Please contact customer service for help. ,<a href='/contact'> our customer service team </a>
                </div>

              </div>



            </Panel>

            <Panel style={{ color: '#rgb(240, 230, 230)' }} header={<><div>How do I change my payment method associated with my account’s billing?</div></>} key="16">
              <div style={{ width: '100%', display: 'flex', flexWrap: 'wrap', justifyContent: 'center', gap: '10px' }}>


                <div style={{ display: "flex", flexDirection: 'column', justifyContent: 'flex-start', padding: '0 20px', alignItems: 'baseline', width: '100%' }}>

                  Please contact customer service assistance
                </div>

              </div>



            </Panel>

            <Panel style={{ color: '#rgb(240, 230, 230)' }} header={<><div>How do I get started to get connected with Data Warehouse Gh Services?</div></>} key="17">
              <div style={{ width: '100%', display: 'flex', flexWrap: 'wrap', justifyContent: 'center', gap: '10px' }}>


                <div style={{ display: "flex", flexDirection: 'column', justifyContent: 'flex-start', padding: '0 20px', alignItems: 'baseline', width: '100%' }}>

                  Place and order online, chat with us online, contact our dedicated customer service team or visit any of our partner shops. We also have a dedicated team of representatives across the country.
                </div>

              </div>



            </Panel>

            <Panel style={{ color: '#rgb(240, 230, 230)' }} header={<><div>Can I have items in my order delivered to different addresses?</div></>} key="18">
              <div style={{ width: '100%', display: 'flex', flexWrap: 'wrap', justifyContent: 'center', gap: '10px' }}>


                <div style={{ display: "flex", flexDirection: 'column', justifyContent: 'flex-start', padding: '0 20px', alignItems: 'baseline', width: '100%' }}>

                  Orders can only be delivered to one address. You’ll need to place separate orders for items that need to be delivered to different addresses. A proof of receipt will be required.
                </div>

              </div>



            </Panel>

            <Panel style={{ color: '#rgb(240, 230, 230)' }} header={<><div>I've received the wrong item. What should I do?</div></>} key="19">
              <div style={{ width: '100%', display: 'flex', flexWrap: 'wrap', justifyContent: 'center', gap: '10px' }}>


                <div style={{ display: "flex", flexDirection: 'column', justifyContent: 'flex-start', padding: '0 20px', alignItems: 'baseline', width: '100%' }}>

                  We’re really sorry about that. If you have received any incorrect items, you must inform us within a reasonable period of time. Where possible, you will be asked to return the products to us using one of our free returns options. If it is not possible for you to return the item, and if you are eligible for a refund or replacement, this will be discussed at the point of contact. Please contact our customer service team.
                </div>

              </div>



            </Panel>

            <Panel style={{ color: '#rgb(240, 230, 230)' }} header={<><div>How do I track my delivery?</div></>} key="20">
              <div style={{ width: '100%', display: 'flex', flexWrap: 'wrap', justifyContent: 'center', gap: '10px' }}>


                <div style={{ display: "flex", flexDirection: 'column', justifyContent: 'flex-start', padding: '0 20px', alignItems: 'baseline', width: '100%' }}>

                  You can check the status of your order and find tracking information at any time in My Account. If you placed your order as a guest, click here for tracking information.
                  <br />    We’ll keep you updated about your order via email, phone call and text messages– you’ll initially receive an order confirmation from us, and we may also send you important updates about items in your order, including when they’ve been despatched.
                  <br />    To get your order to you as quickly as possible, we may have to send some items separately and these may occasionally be delivered by different couriers. Our couriers deliver between 7am and 7pm. During busy periods, we may deliver outside of these hours.
                </div>

              </div>



            </Panel>

            <Panel style={{ color: '#rgb(240, 230, 230)' }} header={<><div>How do you I cancel my order?</div></>} key="21">
              <div style={{ width: '100%', display: 'flex', flexWrap: 'wrap', justifyContent: 'center', gap: '10px' }}>


                <div style={{ display: "flex", flexDirection: 'column', justifyContent: 'flex-start', padding: '0 20px', alignItems: 'baseline', width: '100%' }}>

                  If you placed your order within the last 15minutes, you will be able to cancel your order following the below steps. If you placed your order more than 15minutes ago, it will depend on the dispatch status. Please follow the below steps to check if your order can be cancelled. Unfortunately, if the option to cancel is not presented to you then neither you nor customer services will be able to cancel the order.
                  <br />    How to cancel an order online:
                  <br />    1. Sign into your account.
                  <br />     2. Find your order in ‘Your account’.
                  <br />     3. Click ‘View order details’.
                  <br />     4. Then click on ‘Cancel order’ next to ‘’Order placed date’.
                  <br />     5. Confirm you wish to cancel your order. You’ll then receive a confirmation email.


                  <br />       Guest orders
                  <br />       If you placed your order as a guest or via our chat and phone service, it is not possible to cancel your order online. Contact customer services within 30 minutes of placing your order and they will be able to cancel it for you. After 1hr minutes, customer services won’t be able to cancel your order.


                </div>

              </div>



            </Panel>

            <Panel style={{ color: '#rgb(240, 230, 230)' }} header={<><div>Why has my order been cancelled?</div></>} key="22">
              <div style={{ width: '100%', display: 'flex', flexWrap: 'wrap', justifyContent: 'center', gap: '10px' }}>


                <div style={{ display: "flex", flexDirection: 'column', justifyContent: 'flex-start', padding: '0 20px', alignItems: 'baseline', width: '100%' }}>

                  We’re sorry your order has been cancelled. There are a number of reasons why your order was cancelled. Please check your cancellation email sent to you for more information.
                </div>

              </div>



            </Panel>




          </Collapse>





        </div>









      </div>



      <div className='mainpadding' style={{ textAlign: 'left' }}>

        <h1 style={{ borderLeft: "5px solid blue", borderRadius: '5px', color: "rgb(70, 70, 70)", paddingLeft: "10px", alignSelf: "baseline" }}>
          About Us</h1>
        <h3 style={{ borderRadius: '5px', paddingLeft: "15px", alignSelf: "baseline", margin: '0', color: 'gray' }}>
          Who we are – Investing in people and connecting the nation</h3>
        <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-start', paddingLeft: '30px', textAlign: 'justify', color: 'gray' }}>A company that connects people to people, people to things and things to things - Data Warehouse Gh is a trading
          name for DWH Ghana limited, an Afroeuro Capital Ghana company part of Bethwell Holdings International. Data
          Warehouse has been established to bring new opportunities to people across Ghana and beyond with a focus on
          bringing together award-winning connectivity and amazing entertainment under one roof from the major ISPs in
          Ghana to our customers giving them more choice, more freedom and smart internet solutions.</div>


        <h3 style={{ borderRadius: '5px', paddingLeft: "15px", alignSelf: "baseline", margin: '0', marginTop: '10px', color: 'gray' }}>
          Sustainability and Responsible Business</h3>
        <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-start', paddingLeft: '30px', textAlign: 'justify', color: 'gray' }}>
          Part of Data Warehouse Gh’s vision is to create an inclusive, green and connected future for its customers and the
          communities. To this end, we will harness the core strengths of our partners and marry them with powerful
          technology, digital expertise, passionate people, and steadfast commitment to operate a green business creating a
          wider positive ripple effect in the fight against climate change.<br />
          We’re committed to building a business that represents the diverse communities we serve, while creating an
          environment where our people genuinely feel they can bring their whole selves to work and where our customers
          “own” our mission.

        </div>

        <h3 style={{ borderRadius: '5px', paddingLeft: "15px", alignSelf: "baseline", margin: '0', marginTop: '10px', color: 'gray' }}>
          <NavLink to='/about' style={{ textDecoration: 'none', color: 'gray' }}>Read More</NavLink></h3>





      </div>










    </div>
  )
}

export default faq