import React, { useState, useEffect } from 'react';
import './App.css';
import './index.css';
import { BrowserRouter, Routes, Route, NavLink, useLocation } from 'react-router-dom';

import { AiFillLinkedin, AiFillFacebook, AiFillTwitterCircle, AiFillInstagram } from 'react-icons/ai';
import { FaTiktok } from 'react-icons/fa';
import { FaBars } from 'react-icons/fa';
import { GrFormClose } from 'react-icons/gr';
import TawkToChat from './components/TawkToChat';


import Index from './pages';
import About from './pages/about';
import Business from './pages/business'
import Cart from './pages/cart'
import Contact from './pages/contact'
import Dealspromos from './pages/deals&promos'
import Device from './pages/devices'
import Favourite from './pages/favourite'
import Internetbroadband from './pages/internet&droadband'
import Student from './pages/student'
import Support from './pages/support'
import Worker from './pages/workers'
import Faq from './pages/faq';
import TrackOrder from './pages/TrackOrder';
import Returnsrefunds from './pages/returns&refunds';
import Termandconditions from './pages/termandconditions';
import Privacypolicy from './pages/privacypolicy';
import Shippingpolicy from './pages/shippingpolicy';
import PRICEPROMISE from './pages/PRICE PROMISE';
import Studentplans from './pages/studentplans';
import Dataplanspdf from './pages/dataplanspdf';
import AndroidBoxwithInternet from './pages/navpages/Android Box with Internet';
import Career from './pages/Career';
import Dshop from './pages/Dshop';
import Investors from './pages/investors';


// nav pages 
import FastPlugConnect from "./pages/navpages/Fast Plug & Connect";
import HighSpeedFiberBroadband from "./pages/navpages/High Speed Fiber Broadband";
import CloudSolutions from "./pages/navpages/Cloud Solutions";
import RadioSolutions from "./pages/navpages/Radio Solutions";
import Mifis from "./pages/navpages/Mifis";
import Dongles from "./pages/navpages/Dongles";
import CommercialPublicPlaces from "./pages/navpages/Commercial Public Places";
import Hostels from "./pages/navpages/Hostels";
import Churches from "./pages/navpages/Churches";
import Mosques from "./pages/navpages/Mosques";
import SmartTvswithInternet from "./pages/navpages/Smart Tv’s with Internet";
import TabletswithInternet from "./pages/navpages/Tablets with Internet";
import LaptopswithInternet from "./pages/navpages/Laptops with Internet";
import DealoftheWeek from "./pages/navpages/Deal of the Week";
import DiscountsCoupons from "./pages/navpages/Discounts & Coupons";
import FreeDataPromos from "./pages/navpages/Free Data Promos";
import CashBackDeals from "./pages/navpages/Cash Back Deals";
import LoyaltySchemes from "./pages/navpages/Loyalty Schemes";
import Connectandwin from "./pages/navpages/Connect and win";
import CampusConnect from "./pages/navpages/Campus Connect";
import HostelsWifi from "./pages/navpages/Hostels Wifi";
import GroupConnect from "./pages/navpages/Group Connect";
import SwitchingNetworks from "./pages/navpages/Switching Networks";
import StoreLocation from "./pages/navpages/Store Location";
import Checkifyoucanupgrade from "./pages/navpages/Check if you can upgrade";
import Delivery from "./pages/navpages/Delivery";
import Contactastore from "./pages/navpages/Contact a store";
import GetSupport from "./pages/navpages/Get Support";
import InsuranceInformation from "./pages/navpages/Insurance Information";

import CommunityWiFiZones from './pages/navpages/Community Wi-Fi Zones';

import Akwaabaconnect from "./pages/navpages/akwaaba";






function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}


function App() {

  const [Mobnav, setmobnav] = useState(false);



  return (
    <div className="App">




      <BrowserRouter>
        <ScrollToTop />

        <nav className="navbar">
          <ul className="nav-list">

            <a href="/" >   <img src='/trans2.png' alt='ads' loading='lazzy' width={"200px"} /></a>
            <div style={{ display: 'flex', gap: '50px' }}>
              <li className="nav-item"><NavLink exact to="/" activeClassName="active">HOME</NavLink></li>
              <li className="nav-item"><NavLink exact to="https://akwaabaconnect.com" activeClassName="active">Akwaaba Connect</NavLink></li>
              <li className="nav-item"><NavLink exact to="/Dshop" activeClassName="active">D-SHOP</NavLink></li>
            
              <li className="nav-item"><NavLink to="/about" activeClassName="active">ABOUT US</NavLink></li>
              <li className="nav-item"><NavLink to="/contact" activeClassName="active">CONTACT US</NavLink></li>
            </div>




          </ul>
        </nav>









        <TawkToChat />

        <Routes>

          <Route path='/' element={<Index />} />
          <Route path='/about' element={<About />} />

          <Route path='/akwaabaconnect' element={<Akwaabaconnect />} />

          <Route path='/business' element={<Business />} />
          <Route path='/cart' element={<Cart />} />
          <Route path='/contact' element={<Contact />} />
          <Route path='/PRICEPROMISE' element={<PRICEPROMISE />} />
          <Route path='/Studentplans' element={<Studentplans />} />
          <Route path='/Dataplanspdf' element={<Dataplanspdf />} />
          <Route path='/Career' element={<Career />} />
          <Route path='/Career/:id' element={<Career />} />
          <Route path='/Dshop' element={<Dshop />} />
          <Route path='/Investors' element={<Investors />} />
         
         
         
         
         
         
          <Route path='/deals&promos' element={
            <Dealspromos />


          } />
          <Route path='/devices' element={
            <Device />


          } />
          <Route path='/favourite' element={<Favourite />} />
          <Route path='/internet&broadband' element={
            <Internetbroadband />

          } />
          <Route path='/student' element={
            <Student />

          } />
          <Route path='/support' element={
            <Support />


          } />
          <Route path='/workers' element={<Worker />} />
          <Route path='/faq' element={<Faq />} />
          <Route path='/trackOrder' element={<TrackOrder />} />
          <Route path='/returns&refunds' element={<Returnsrefunds />} />
          <Route path='/termandtonditions' element={<Termandconditions />} />
          <Route path='/privacypolicy' element={<Privacypolicy />} />
          <Route path='/shippingpolicy' element={<Shippingpolicy />} />


        </Routes>

        {/* /internet&broadband/ */}
        <Routes>
          <Route path='/internet&broadband/FastPlugConnect' element={<FastPlugConnect />} />
          <Route path='/internet&broadband/HighSpeedFiberBroadband' element={<HighSpeedFiberBroadband />} />
          <Route path='/internet&broadband/CloudSolutions' element={<CloudSolutions />} />
          <Route path='/internet&broadband/RadioSolutions' element={<RadioSolutions />} />
          <Route path='/internet&broadband/Mifis' element={<Mifis />} />
          <Route path='/internet&broadband/Dongles' element={<Dongles />} />
          <Route path='/internet&broadband/CommercialPublicPlaces' element={<CommercialPublicPlaces />} />
          <Route path='/internet&broadband/Hostels' element={<Hostels />} />
          <Route path='/internet&broadband/Churches' element={<Churches />} />
          <Route path='/internet&broadband/Mosques' element={<Mosques />} />
          <Route path='/internet&broadband/CommunityWiFiZones' element={<CommunityWiFiZones />} />


        </Routes>


        {/* devices*/}
        <Routes>
          <Route path='/devices/SmartTvswithInternet' element={<SmartTvswithInternet />} />
          <Route path='/devices/TabletswithInternet' element={<TabletswithInternet />} />
          <Route path='/devices/LaptopswithInternet' element={<LaptopswithInternet />} />
          <Route path='/devices/AndroidBoxwithInternet' element={<AndroidBoxwithInternet />} />

        </Routes>

        {/* deals&promos */}
        <Routes>
          <Route path='/deals&promos/DealoftheWeek' element={<DealoftheWeek />} />
          <Route path='/deals&promos/DiscountsCoupons' element={<DiscountsCoupons />} />
          <Route path='/deals&promos/FreeDataPromos' element={<FreeDataPromos />} />
          <Route path='/deals&promos/CashBackDeals' element={<CashBackDeals />} />
          <Route path='/deals&promos/LoyaltySchemes' element={<LoyaltySchemes />} />
          <Route path='/deals&promos/Connectandwin' element={<Connectandwin />} />

        </Routes>

        {/* student */}
        <Routes>
          <Route path='/student/GroupConnect' element={<GroupConnect />} />
          <Route path='/student/HostelsWifi' element={<HostelsWifi />} />
          <Route path='/student/CampusConnect' element={<CampusConnect />} />

        </Routes>

        {/* /support */}
        <Routes>
          <Route path='/support/SwitchingNetworks' element={<SwitchingNetworks />} />
          <Route path='/support/StoreLocation' element={<StoreLocation />} />
          <Route path='/support/Checkifyoucanupgrade' element={<Checkifyoucanupgrade />} />
          <Route path='/support/Delivery' element={<Delivery />} />
          <Route path='/support/Contactastore' element={<Contactastore />} />
          <Route path='/support/GetSupport' element={<GetSupport />} />
          <Route path='/support/InsuranceInformation' element={<InsuranceInformation />} />

        </Routes>










        <div className='footer'>

          <div className='subfooter'>

            <h1>Need Help</h1>
            <NavLink className="footerlinks" to={"/contact"}>Contact Us</NavLink>
            <NavLink className="footerlinks" to={""}>Buying Online</NavLink>
            <NavLink className="footerlinks" to={""}>FUP (Fair Use Policy)</NavLink>
            <NavLink className="footerlinks" to={""}>News Letter</NavLink>
            <NavLink className="footerlinks" to={"/TrackOrder"}>Track Order</NavLink>
            <NavLink className="footerlinks" to={"/returns&refunds"}>Returns & Refunds</NavLink>
            <NavLink className="footerlinks" to={"faq"}>FAQ's</NavLink>
            <NavLink className="footerlinks" to={"/Career"}>Career</NavLink>
            <NavLink className="footerlinks" to={"/Investors"}>Investors</NavLink>

          </div>


          <div className='subfooter'>

            <h1>Company</h1>
            <NavLink className="footerlinks" to={"/about"}>About Us</NavLink>
            <NavLink className="footerlinks" to={"/termandtonditions"}>Term and Conditions</NavLink>
            <NavLink className="footerlinks" to={"/privacypolicy"}>Privacy Policy</NavLink>
            <NavLink className="footerlinks" to={"/shippingpolicy"}>Shipping Policy</NavLink>
            <NavLink className="footerlinks" to={""}>info@datawarehousegh.com</NavLink>
            <NavLink className="footerlinks" to={""}>(+233) 534 629 229</NavLink>
            <NavLink style={{ textAlign: 'justify' }} className="footerlinks" to={""}>P. O. Box AT 9, Achimota- Accra, Ghana</NavLink>


          </div>


          <div className='subfooter'>

            <h1>CUSTOMER SERVICES</h1>
            <NavLink className="footerlinks" to={"/PRICEPROMISE"}>Price Promise</NavLink>
            {/* <NavLink className="footerlinks" to={""}>Payment Options</NavLink> */}
            <NavLink className="footerlinks" to={""}>Trade-in</NavLink>
            <NavLink className="footerlinks" to={"/contact"}>Complaints</NavLink>
            <NavLink className="footerlinks" to={"contact"}>Enquiries</NavLink>
            <NavLink className="footerlinks" to={""}>Repair Shops</NavLink>
          </div>









          <div className='subfooter'>

            <h1>Follow US</h1>
            <NavLink className="footerlinks" to={"https://www.facebook.com/profile.php?id=100093629576293"}><AiFillFacebook />Facebook</NavLink>
            <NavLink className="footerlinks" to={"https://twitter.com/Datawarehousegh"}><AiFillTwitterCircle />Twitter</NavLink>
            <NavLink className="footerlinks" to={"https://www.instagram.com/datawarehousegh/"}><AiFillInstagram />Instagram</NavLink>
            <NavLink className="footerlinks" to={"https://www.linkedin.com/in/data-warehouse-gh-ba7817280/"}><AiFillLinkedin />Linkedin</NavLink>
            <NavLink className="footerlinks" to={"https://www.tiktok.com/@datawarehousegh?lang=en"}><FaTiktok />Tiktok</NavLink>
            {/* <NavLink className="footerlinks" to={""}><AiFillYoutube/>Youtube</NavLink> */}

          </div>


        </div>

        <div style={{ backgroundColor: '#0587EF', border: '1px solid gray', color: 'white' }}>Copyright © 2023 Data Warehouse Gh. All rights reserved</div>
      </BrowserRouter>









    </div>
  );
}

export default App;
