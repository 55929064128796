import React from 'react'

function Comingsoon() {
  return (
    <div>
        
<img src='/comming-soon.png' alt='' width={"100%"}/>


    </div>
  )
}

export default Comingsoon