import React, { useState, useEffect } from 'react'
import Navbar from "../../components/navbar"

import { useNavigate } from 'react-router-dom';
import { BiArrowBack } from "react-icons/bi"
import Cookies from 'js-cookie';
import { Image } from 'antd';




const ProductImageGallery = ({ images }) => {
  const [selectedImage, setSelectedImage] = useState(images[0]);

  const handleImageClick = (image) => {
    setSelectedImage(image);
  };

  return (
    <div className='imagessss'>
      <Image src={selectedImage} alt="Product" width={"400px"} height={"400px"} />

      <Image.PreviewGroup
        preview={{
          onChange: (current, prev) => console.log(`current index: ${current}, prev index: ${prev}`),
        }}
      >
        {/* <Image width={200} src="https://gw.alipayobjects.com/zos/rmsportal/KDpgvguMpGfqaHPjicRK.svg" />
        <Image
          width={200} src="https://gw.alipayobjects.com/zos/antfincdn/aPkFc8Sj7n/method-draw-image.svg"
        /> */}
        <div className="small-images" style={{ display: 'flex', alignItems: 'center', justifyContent: "center", gap: "10px", flexWrap: 'wrap' }}>
          {images.map((image, index) => (
            <Image style={{ border: '1px solid gray' }}
              key={index}
              src={image}
              width={"100px"}
              height={"100px"}
              alt={`Product ${index}`}
              onClick={() => handleImageClick(image)}
              // className={selectedImage === image ? 'selected' : ''}
              className='hovar'
            />

          ))}
        </div>
      </Image.PreviewGroup >




      {/* <div className="small-images" style={{ display: 'flex', alignItems: 'center', justifyContent: "center", gap: "10px", flexWrap: 'wrap' }}>
    {images.map((image, index) => (
      <img style={{ border: '1px solid gray' }}
        key={index}
        src={image}
        width={"100px"}
        height={"100px"}
        alt={`Product ${index}`}
        onClick={() => handleImageClick(image)}
        // className={selectedImage === image ? 'selected' : ''}
        className='hovar'
      />
    ))}
  </div> */}
    </div >
  );
};





function Akwaabaconnect() {
  let navigate = useNavigate();
  const [showallplans, setshowallplans] = useState(false);
  const [showcheckout, setshowcheckout] = useState(true);
  const [productdetails, setproductdetails] = useState(1);
  const [Specifications, setSpecifications] = useState(false);
  const [selectedCell, setSelectedCell] = useState(null);

  const [selselectplanebox, setSelselectplanebox] = useState(false);
  const [selselectplanebox2, setSelselectplanebox2] = useState(false);

  const [productselected, setproductselected] = useState("");




  const [cookieproduct, setcookieproduct] = useState([]);


  useEffect(() => {



    // Check if the 'Product' cookie exists
    if (Cookies.get('Product')) {
      // The 'Product' cookie exists, so you can proceed to use its value
      const productCookie = Cookies.get('Product');
      try {
        const productData = JSON.parse(productCookie);
        setcookieproduct(productData);
      } catch (error) {
        console.error('Error parsing JSON from the "Product" cookie:', error);
      }
    } else {
      // The 'Product' cookie does not exist or is empty
      console.warn('The "Product" cookie is not set or is empty.');
    }




    // setcookieproduct(JSON.parse(Cookies.get('Product')));
  }, [])







  return (
    <div>

      <div style={{ marginTop: '110px' }}></div>


      <Navbar />








      <div className='mifimain'>


        <h1 style={{ borderLeft: "5px solid #0587EF", textAlign: 'left', borderRadius: '5px', color: "rgb(70, 70, 70)", paddingLeft: "10px", alignSelf: "baseline" }}>
          RENT A HOTSPOT</h1>

        <h2 style={{ borderRadius: '5px', paddingLeft: "15px", alignSelf: "baseline", margin: '0', color: 'black' }}>
          DWH Akwaaba CONNECT.   </h2>

        <div style={{ width: '90%', display: 'flex', justifyContent: 'flex-start', paddingLeft: '17px', textAlign: 'justify', color: 'black', marginBottom: '50px' }}>
          Why pay for roaming services, buy expensive kits to get connected to the internet when you can just Rent, use and go during your short stay in Ghana?

        </div>

        {showcheckout === true && <>


          <div className='procarddd2' onClick={() => {
            setshowcheckout(false)
            setproductdetails(1)
          }}>

            <div className='subprocard'>

              {/* <div className='subprocardImg'>

                 

                </div> */}


              <div className='subprocarddescrib'>
                <h3 style={{ borderRadius: '5px', paddingLeft: "15px", alignSelf: "baseline", margin: '0', color: 'black' }}>
                  DWH Akwaaba CONNECT.  </h3>

                <div style={{ width: '90%', display: 'flex', justifyContent: 'flex-start', paddingLeft: '17px', textAlign: 'justify', color: 'black' }}>
                  Why pay for costly roaming services and invest in expensive internet kits just to stay connected? During your short stay in Ghana, you have a hassle-free alternative: rent internet services. Enjoy seamless connectivity without long-term commitments or hefty costs. Simply rent the equipment, use it during your stay, and return it when you're done. It's a smart, cost-effective, and convenient way to stay connected while traveling.
                </div>
              </div>
              <img src='/routers pictures/U901/New folder/1.jpg' alt="profile" height={"300px"} width={"300px"} />

            </div>

            <div className='subprocarddescrib2'>
              <h3 style={{ borderRadius: '5px', paddingLeft: "15px", alignSelf: "baseline", margin: '0', color: 'black' }}>
                Price And Rantal Note  </h3>

              <div style={{ width: '90%', display: 'flex', justifyContent: 'flex-start', paddingLeft: '17px', textAlign: 'justify', color: 'black',flexDirection:'column' }}>
              $15 Setup cost includes rental for the 1st  30days<br/>
                $20 refundable security deposit. That is paid back to the customer once the device is returned on departure. <br/>
               NOTE: A standard delivery charge within Accra @ $4<br/>
               We offer delivery to anywhere in Ghana (Delivery charges vary).<br/>

              </div>
            </div>

            <div className='subprocardfeature'>
              <h3 style={{ borderRadius: '5px', paddingLeft: "15px", alignSelf: "baseline", margin: '0', color: 'black' }}>
                Features</h3>

              <ul style={{ margin: '0', fontSize: "0.7em" }}>
                <li>Up to 48hrs 5000mah back-up battery </li>
                <li>Stay connected even during Light outs which are now frequent in Ghana                 </li>
                <li>Nationwide delivery </li>
                <li>Connects up to 32 devices</li>
                <li>300mbps speed </li>
                <li>Ideal for homes </li>
                <li>No Configuration required </li>


              </ul>


            </div>




          </div>




          <h2 style={{ borderRadius: '5px', paddingLeft: "15px", alignSelf: "baseline", margin: '0', color: 'black' }}>
            ADDON DATA PLANS  </h2>
          <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-start', paddingLeft: '17px', textAlign: 'justify', color: 'black' }}>
            Feel free to explore our range of products and select the one that best suits your needs. Keep in mind that our prices are flexible and tailored to the specific data packages you choose, ensuring you get the most value out of your purchase.  </div>


          <div className='routercardbox'>






            {/* 1 */}
            <div className='routercard' onClick={() => {


              setSelselectplanebox(true)

              // setproductselected("DW SMART MIFI – MT10")

              setproductselected(
                {
                  "productname": "Akwaaba connect - Bronze Package ",
                  "productPrice": "$30/-  One-time Activation+ 1st Month subscription on DW Light1 Package with inclusive 30GB data",
                  "productimg": "/routers pictures/U901/New folder/1.jpg"
                }
              )



            }}>
              <h2 style={{ margin: '0', color: 'black' }}>
                Bronze Package  </h2>

              <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-start', textAlign: 'justify', color: 'black' }}>
                <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-start', textAlign: 'justify', color: 'black', alignItems: 'center', marginBottom: '20px' }}>
                  Rating:

                  <div class="rating">
                    <span class="star">&#9733;</span>
                    <span class="star">&#9733;</span>
                    <span class="star">&#9733;</span>
                    <span class="star">&#9733;</span>
                    <span class="star">&#9733;</span>
                  </div>


                </div>
              </div>


              <p style={{ width: '100%', fontWeight: 'bold', display: 'flex', margin: '0', justifyContent: 'flex-start', textAlign: 'left', color: 'red' }}>

                <h4 style={{ borderRadius: '5px', alignSelf: "baseline", marginRight: '5px', color: 'black' }}>
                  Price</h4>
                $30/-
              </p>
              <p style={{ width: '100%', display: 'flex', margin: '0', justifyContent: 'flex-start', textAlign: 'left', color: 'black' }}>
                One-time Activation <br /> +<br /> 1st Month subscription on DW Light1 Package with inclusive 30GB data
              </p>



              <div style={{ backgroundColor: '#044ff3', padding: "5px", color: 'white', display: 'flex', justifyContent: 'center' }}>Click to Buy this Product</div>
            </div>

            {/* 2 */}

            <div className='routercard' onClick={() => {


              setSelselectplanebox(true)


              // setproductselected("DW SMART MIFI – MT10")

              setproductselected(
                {
                  "productname": " Akwaaba connect - Silver package ",
                  "productPrice": "$50/-  One-time Activation+ 1st Month subscription on DW Light1 Package with inclusive 60GB data",
                  "productimg": "/routers pictures/U901/New folder/1.jpg"
                }
              )



            }}>
              <h2 style={{ margin: '0', color: 'black' }}>
                Silver package   </h2>

              <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-start', textAlign: 'justify', color: 'black' }}>
                <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-start', textAlign: 'justify', color: 'black', alignItems: 'center', marginBottom: '20px' }}>
                  Rating:

                  <div class="rating">
                    <span class="star">&#9733;</span>
                    <span class="star">&#9733;</span>
                    <span class="star">&#9733;</span>
                    <span class="star">&#9733;</span>
                    <span class="star">&#9733;</span>
                  </div>


                </div>
              </div>


              <p style={{ width: '100%', fontWeight: 'bold', display: 'flex', margin: '0', justifyContent: 'flex-start', textAlign: 'left', color: 'red' }}>

                <h4 style={{ borderRadius: '5px', alignSelf: "baseline", marginRight: '5px', color: 'black' }}>
                  Price</h4>
                Ghc $50/-
              </p>
              <p style={{ width: '100%', display: 'flex', margin: '0', justifyContent: 'flex-start', textAlign: 'left', color: 'black' }}>
                One-time Activation <br /> +<br /> 1st Month subscription on DW Light1 Package with inclusive 60GB data
              </p>



              <div style={{ backgroundColor: '#044ff3', padding: "5px", color: 'white', display: 'flex', justifyContent: 'center' }}>Click to Buy this Product</div>
            </div>


            {/* 3 */}
            <div className='routercard' onClick={() => {


              setSelselectplanebox(true)


              // setproductselected("DW SMART MIFI – MT10")

              setproductselected(
                {
                  "productname": "Akwaaba connect - Gold package ",
                  "productPrice": "$75/-  One-time Activation+ 1st Month subscription on DW Light1 Package with inclusive 100GB data",
                  "productimg": "/routers pictures/U901/New folder/1.jpg"
                }
              )



            }}>
              <h2 style={{ margin: '0', color: 'black' }}>
                Gold package  </h2>

              <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-start', textAlign: 'justify', color: 'black' }}>
                <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-start', textAlign: 'justify', color: 'black', alignItems: 'center', marginBottom: '20px' }}>
                  Rating:

                  <div class="rating">
                    <span class="star">&#9733;</span>
                    <span class="star">&#9733;</span>
                    <span class="star">&#9733;</span>
                    <span class="star">&#9733;</span>
                    <span class="star">&#9733;</span>
                  </div>


                </div>
              </div>


              <p style={{ width: '100%', fontWeight: 'bold', display: 'flex', margin: '0', justifyContent: 'flex-start', textAlign: 'left', color: 'red' }}>

                <h4 style={{ borderRadius: '5px', alignSelf: "baseline", marginRight: '5px', color: 'black' }}>
                  Price</h4>
                $75/-
              </p>
              <p style={{ width: '100%', display: 'flex', margin: '0', justifyContent: 'flex-start', textAlign: 'left', color: 'black' }}>
                One-time Activation <br /> +<br /> 1st Month subscription on DW Light1 Package with inclusive 100GB data
              </p>



              <div style={{ backgroundColor: '#044ff3', padding: "5px", color: 'white', display: 'flex', justifyContent: 'center' }}>Click to Buy this Product</div>
            </div>


            {/* 4 */}
            <div className='routercard' onClick={() => {


              setSelselectplanebox(true)

              // setproductselected("DW SMART MIFI – MT10")

              setproductselected(
                {
                  "productname": " Akwaaba connect - Platinum Package ",
                  "productPrice": "$92/-  One-time Activation+ 1st Month subscription on DW Light1 Package with inclusive 150GB data",
                  "productimg": "/routers pictures/U901/New folder/1.jpg"
                }
              )



            }}>
              <h2 style={{ margin: '0', color: 'black' }}>
                Platinum Package   </h2>

              <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-start', textAlign: 'justify', color: 'black' }}>
                <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-start', textAlign: 'justify', color: 'black', alignItems: 'center', marginBottom: '20px' }}>
                  Rating:

                  <div class="rating">
                    <span class="star">&#9733;</span>
                    <span class="star">&#9733;</span>
                    <span class="star">&#9733;</span>
                    <span class="star">&#9733;</span>
                    <span class="star">&#9733;</span>
                  </div>


                </div>
              </div>


              <p style={{ width: '100%', fontWeight: 'bold', display: 'flex', margin: '0', justifyContent: 'flex-start', textAlign: 'left', color: 'red' }}>

                <h4 style={{ borderRadius: '5px', alignSelf: "baseline", marginRight: '5px', color: 'black' }}>
                  Price</h4>
                $92/-
              </p>
              <p style={{ width: '100%', display: 'flex', margin: '0', justifyContent: 'flex-start', textAlign: 'left', color: 'black' }}>
                One-time Activation <br /> +<br /> 1st Month subscription on DW Light1 Package with inclusive 150GB data
              </p>



              <div style={{ backgroundColor: '#044ff3', padding: "5px", color: 'white', display: 'flex', justifyContent: 'center' }}>Click to Buy this Product</div>
            </div>









          </div>



















        </>}









        {showcheckout === false && <>

          <h3 onClick={() => setshowcheckout(true)} className='hovar' style={{ paddingLeft: "15px", width: 'fit-content', alignSelf: "baseline", marginTop: '0', color: 'black', display: 'flex', alignItems: 'center', gap: '5px' }}>
            <BiArrowBack style={{ fontWeight: 'bolder' }} />    Go Back</h3>



          {productdetails === 1 && <>

            <div className='productdetails'>
              <ProductImageGallery
                images={[
                  '/routers pictures/U901/New folder/1.jpg',

                  '/routers pictures/U901/New folder/3.jpg',

                  '/routers pictures/U901/New folder/4.jpg',

                  '/routers pictures/U901/New folder/5.jpg',
                  // Add more image URLs here
                ]}
              />
              <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', textAlign: 'left' }} className='prodetailsright'>



                <h2 style={{ paddingLeft: "15px", alignSelf: "baseline", marginTop: '0', color: 'black' }}>
                  DWH Akwaaba CONNECT.</h2>




                <div style={{ width: '90%', display: 'flex', justifyContent: 'flex-start', paddingLeft: '17px', textAlign: 'justify', color: 'black' }}>
                  <div style={{ width: '90%', display: 'flex', justifyContent: 'flex-start', paddingLeft: '17px', textAlign: 'justify', color: 'black', alignItems: 'center', marginTop: '20px' }}>
                    Rating:

                    <div class="rating">
                      <span class="star">&#9733;</span>
                      <span class="star">&#9733;</span>
                      <span class="star">&#9733;</span>
                      <span class="star">&#9733;</span>
                      <span class="star">&#9733;</span>
                    </div>


                  </div>
                </div>

                <h5 style={{ width: '100%', display: 'flex', marginTop: '0', justifyContent: 'flex-start', paddingLeft: '17px', textAlign: 'justify', color: 'black' }}>
                  (Activation Cost + 1st month subscription on DW Light1 Package with inclusive data)
                </h5>





                {Specifications === true && <>
                  <div style={{ display: 'flex', gap: '30px' }}>  <h3 onClick={() => setSpecifications(false)} className='hovar' style={{ borderRadius: '5px', paddingLeft: "0px", alignSelf: "baseline", margin: '0', marginTop: '40px', color: 'black' }}>
                    Features Highlight</h3>

                    <h3 style={{ borderRadius: '5px', borderBottom: '5px solid #0587EF', paddingLeft: "0px", alignSelf: "baseline", margin: '0', marginTop: '40px', color: 'black' }}>
                      Specifications</h3></div></>}




                {Specifications === false && <>
                  <div style={{ display: 'flex', gap: '30px' }}>  <h3 style={{ borderRadius: '5px', borderBottom: '5px solid #0587EF', paddingLeft: "0px", alignSelf: "baseline", margin: '0', marginTop: '40px', color: 'black' }}>
                    Features Highlight</h3>

                    <h3 onClick={() => setSpecifications(true)} className='hovar' style={{ borderRadius: '5px', paddingLeft: "0px", alignSelf: "baseline", margin: '0', marginTop: '40px', color: 'black' }}>
                      Specifications</h3></div></>}


                {Specifications === false && <>
                  <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-start', paddingLeft: '0px', textAlign: 'justify', color: 'black' }}>

                    Why pay for costly roaming services and invest in expensive internet kits just to stay connected? During your short stay in Ghana, you have a hassle-free alternative: rent internet services. This option allows you to enjoy seamless connectivity without the burden of long-term commitments or hefty costs.
                    <br />  Simply rent the necessary equipment, use it during your stay, and return it when you're done. It's a smart, cost-effective, and convenient way to stay connected while traveling. Save money and avoid the hassle of purchasing and carrying around additional gadgets by choosing the rent, use, and return method.


                  </div></>}

                {Specifications === true && <>
                  <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-start', paddingLeft: '0px', textAlign: 'justify', color: 'black' }}>

                    <ul>
                      <li>Up to 48hrs 5000mah back-up battery </li>
                      <li>Stay connected even during Light outs which are now frequent in Ghana                 </li>
                      <li>Nationwide delivery </li>
                      <li>Connects up to 32 devices</li>
                      <li>300mbps speed </li>
                      <li>Ideal for homes </li>
                      <li>No Configuration required </li>

                    </ul>
                  </div></>}




                <button onClick={() => {



                  setSelselectplanebox(true)
                  setSelselectplanebox2(true)
                  // setproductselected("DW SMART MIFI – MT10")





                }}




                  style={{ width: '100%', height: '50px', fontSize: '2rem', fontWeight: "bold", backgroundColor: "#0587EF", color: 'white', borderRadius: '5px' }}>Order Now </button>

              </div>


            </div>




            <div className='productdetails3'>







              <h3 style={{ borderRadius: '5px', backgroundColor: '#0587EF', padding: "5px", alignSelf: "baseline", margin: '0', marginTop: '40px', color: 'White' }}>
                No fluff. Just the good stuff.</h3>

              <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-start', textAlign: 'justify', color: 'black' }}>
                All plans from Data Warehouse Gh come with great features as standard. We provide coverage that you can trust in your area. We cover 98% of the Ghana’s population. We provide competitively generous internet data up to unlimited subject to fair usage & open data policies.
              </div>



              <h3 style={{ borderRadius: '5px', backgroundColor: '#0587EF', padding: "5px", alignSelf: "baseline", margin: '0', marginTop: '40px', color: 'White' }}>
                Monthly Data Plans                        </h3>
              {/* <div style={{ width: '90%', display: 'flex', justifyContent: 'flex-start', textAlign: 'justify', color: 'black' }}>
                  Contract Duration (1-12) months.
                </div> */}


              <table className='data-plans-table'>
                <thead>
                  <tr>
                    <th>Data Plan</th>
                    <th>Price</th>

                  </tr>
                </thead>
                <tbody>

                  <tr >
                    <td>10GB Data</td>
                    <td>$10/-</td>


                  </tr>


                  <tr >
                    <td>15GB Data</td>
                    <td>$14/-</td>

                  </tr>


                  <tr >
                    <td>30GB Data</td>
                    <td>$28/-</td>


                  </tr>

                  {showallplans === true && <>

                    <tr >
                      <td>55GB Data</td>
                      <td>$48/-</td>
                    </tr>

                    <tr >
                      <td>120GB Data</td>
                      <td>$75/-</td>
                    </tr>





                  </>}

                </tbody>
              </table>

              {showallplans === false && <>


                <h5 className='readmore' onClick={() => { setshowallplans(true) }} style={{ margin: "0", width: '100%', display: 'flex', justifyContent: 'end', textAlign: 'justify', color: 'black' }}>
                  Read More.
                </h5>

              </>}
              {showallplans === true && <>

                <h5 className='readmore' onClick={() => { setshowallplans(false) }} style={{ margin: "0", width: '100%', display: 'flex', justifyContent: 'end', textAlign: 'justify', color: 'black' }}>
                  Read Less.
                </h5>
              </>}




            </div>


          </>}

          <div className='hovar' onClick={() => navigate('/dataplanspdf')} style={{ width: '100%', display: 'flex', justifyContent: 'flex-start', paddingLeft: '17px', textAlign: 'justify', color: 'black', marginBottom: '50px' }}>
            CLICK HERE to see Data Plans PDF
          </div>




        </>}





      </div>










      {selselectplanebox === true && <>

        <div className='mifimifiselectplanbagmain'>
          <div className='mifiselectplanbag' onClick={() => setSelselectplanebox(false)}></div>
          <div className='mifiselectplanbag2'>



            {selselectplanebox2 === true && <>

              <h3 style={{ borderRadius: '5px', backgroundColor: '#0587EF', padding: "5px", alignSelf: "baseline", margin: '0', color: 'White' }}>
                Kindly select  data plan to proceed further.                     </h3>
              <div style={{ width: '90%', display: 'flex', justifyContent: 'flex-start', textAlign: 'justify', color: 'black' }}>
                Feel free to explore our range of products and select the one that best suits your needs. Keep in mind that our prices are flexible and tailored to the specific data packages you choose, ensuring you get the most value out of your purchase.
              </div>



              <div className='routercardbox' style={{ padding: '0' }}>




                {/* 1 */}
                <div className='routercard' onClick={() => {


                  setSelselectplanebox2(false)

                  // setproductselected("DW SMART MIFI – MT10")

                  setproductselected(
                    {
                      "productname": " Akwaaba connect - Bronze Package ",
                      "productPrice": "$30/-  One-time Activation+ 1st Month subscription on DW Light1 Package with inclusive 30GB data",
                      "productimg": "/routers pictures/U901/New folder/1.jpg"
                    }
                  )



                }}>
                  <h2 style={{ margin: '0', color: 'black' }}>
                    Bronze Package  </h2>

                  <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-start', textAlign: 'justify', color: 'black' }}>
                    <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-start', textAlign: 'justify', color: 'black', alignItems: 'center', marginBottom: '20px' }}>
                      Rating:

                      <div class="rating">
                        <span class="star">&#9733;</span>
                        <span class="star">&#9733;</span>
                        <span class="star">&#9733;</span>
                        <span class="star">&#9733;</span>
                        <span class="star">&#9733;</span>
                      </div>


                    </div>
                  </div>


                  <p style={{ width: '100%', fontWeight: 'bold', display: 'flex', margin: '0', justifyContent: 'flex-start', textAlign: 'left', color: 'red' }}>

                    <h4 style={{ borderRadius: '5px', alignSelf: "baseline", marginRight: '5px', color: 'black' }}>
                      Price</h4>
                    $30/-
                  </p>
                  <p style={{ width: '100%', display: 'flex', margin: '0', justifyContent: 'flex-start', textAlign: 'left', color: 'black' }}>
                    One-time Activation <br /> +<br /> 1st Month subscription on DW Light1 Package with inclusive 30GB data
                  </p>



                  <div style={{ backgroundColor: '#044ff3', padding: "5px", color: 'white', display: 'flex', justifyContent: 'center' }}>Click to Buy this Product</div>
                </div>

                {/* 2 */}

                <div className='routercard' onClick={() => {


                  setSelselectplanebox2(false)


                  // setproductselected("DW SMART MIFI – MT10")

                  setproductselected(
                    {
                      "productname": " Akwaaba connect - Silver package ",
                      "productPrice": "$50/-  One-time Activation+ 1st Month subscription on DW Light1 Package with inclusive 60GB data",
                      "productimg": "/routers pictures/U901/New folder/1.jpg"
                    }
                  )



                }}>
                  <h2 style={{ margin: '0', color: 'black' }}>
                    Silver package   </h2>

                  <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-start', textAlign: 'justify', color: 'black' }}>
                    <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-start', textAlign: 'justify', color: 'black', alignItems: 'center', marginBottom: '20px' }}>
                      Rating:

                      <div class="rating">
                        <span class="star">&#9733;</span>
                        <span class="star">&#9733;</span>
                        <span class="star">&#9733;</span>
                        <span class="star">&#9733;</span>
                        <span class="star">&#9733;</span>
                      </div>


                    </div>
                  </div>


                  <p style={{ width: '100%', fontWeight: 'bold', display: 'flex', margin: '0', justifyContent: 'flex-start', textAlign: 'left', color: 'red' }}>

                    <h4 style={{ borderRadius: '5px', alignSelf: "baseline", marginRight: '5px', color: 'black' }}>
                      Price</h4>
                    Ghc $50/-
                  </p>
                  <p style={{ width: '100%', display: 'flex', margin: '0', justifyContent: 'flex-start', textAlign: 'left', color: 'black' }}>
                    One-time Activation <br /> +<br /> 1st Month subscription on DW Light1 Package with inclusive 60GB data
                  </p>



                  <div style={{ backgroundColor: '#044ff3', padding: "5px", color: 'white', display: 'flex', justifyContent: 'center' }}>Click to Buy this Product</div>
                </div>


                {/* 3 */}
                <div className='routercard' onClick={() => {


                  setSelselectplanebox2(false)


                  // setproductselected("DW SMART MIFI – MT10")

                  setproductselected(
                    {
                      "productname": "Akwaaba connect - Gold package ",
                      "productPrice": "$75/-  One-time Activation+ 1st Month subscription on DW Light1 Package with inclusive 100GB data",
                      "productimg": "/routers pictures/U901/New folder/1.jpg"
                    }
                  )



                }}>
                  <h2 style={{ margin: '0', color: 'black' }}>
                    Gold package  </h2>

                  <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-start', textAlign: 'justify', color: 'black' }}>
                    <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-start', textAlign: 'justify', color: 'black', alignItems: 'center', marginBottom: '20px' }}>
                      Rating:

                      <div class="rating">
                        <span class="star">&#9733;</span>
                        <span class="star">&#9733;</span>
                        <span class="star">&#9733;</span>
                        <span class="star">&#9733;</span>
                        <span class="star">&#9733;</span>
                      </div>


                    </div>
                  </div>


                  <p style={{ width: '100%', fontWeight: 'bold', display: 'flex', margin: '0', justifyContent: 'flex-start', textAlign: 'left', color: 'red' }}>

                    <h4 style={{ borderRadius: '5px', alignSelf: "baseline", marginRight: '5px', color: 'black' }}>
                      Price</h4>
                    $75/-
                  </p>
                  <p style={{ width: '100%', display: 'flex', margin: '0', justifyContent: 'flex-start', textAlign: 'left', color: 'black' }}>
                    One-time Activation <br /> +<br /> 1st Month subscription on DW Light1 Package with inclusive 100GB data
                  </p>



                  <div style={{ backgroundColor: '#044ff3', padding: "5px", color: 'white', display: 'flex', justifyContent: 'center' }}>Click to Buy this Product</div>
                </div>


                {/* 4 */}
                <div className='routercard' onClick={() => {


                  setSelselectplanebox2(false)

                  // setproductselected("DW SMART MIFI – MT10")

                  setproductselected(
                    {
                      "productname": "Akwaaba connect - Platinum Package ",
                      "productPrice": "$92/-  One-time Activation+ 1st Month subscription on DW Light1 Package with inclusive 150GB data",
                      "productimg": "/routers pictures/U901/New folder/1.jpg"
                    }
                  )



                }}>
                  <h2 style={{ margin: '0', color: 'black' }}>
                    Platinum Package   </h2>

                  <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-start', textAlign: 'justify', color: 'black' }}>
                    <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-start', textAlign: 'justify', color: 'black', alignItems: 'center', marginBottom: '20px' }}>
                      Rating:

                      <div class="rating">
                        <span class="star">&#9733;</span>
                        <span class="star">&#9733;</span>
                        <span class="star">&#9733;</span>
                        <span class="star">&#9733;</span>
                        <span class="star">&#9733;</span>
                      </div>


                    </div>
                  </div>


                  <p style={{ width: '100%', fontWeight: 'bold', display: 'flex', margin: '0', justifyContent: 'flex-start', textAlign: 'left', color: 'red' }}>

                    <h4 style={{ borderRadius: '5px', alignSelf: "baseline", marginRight: '5px', color: 'black' }}>
                      Price</h4>
                    $92/-
                  </p>
                  <p style={{ width: '100%', display: 'flex', margin: '0', justifyContent: 'flex-start', textAlign: 'left', color: 'black' }}>
                    One-time Activation <br /> +<br /> 1st Month subscription on DW Light1 Package with inclusive 150GB data
                  </p>



                  <div style={{ backgroundColor: '#044ff3', padding: "5px", color: 'white', display: 'flex', justifyContent: 'center' }}>Click to Buy this Product</div>
                </div>








              </div>

              <div className='buttoonn'>
                <button onClick={() => {

                  setSelselectplanebox(false)
                  setSelectedCell(null)

                }}>Cancel</button>
                {/* <button
          // onClick={async (e) => {

          //   await console.log(cookieproduct);
          //   const newProduct = {
          //     "productname": productselected.productname,
          //     "productprice": productselected.productPrice,
          //     "productimg": productselected.productimg,
          //     "dataplane": selectedCell
          //   };




          //   const newupdateddata = [...cookieproduct, newProduct]



          //   await console.log(cookieproduct)


          //   const updatedProductJSON = JSON.stringify(newupdateddata);

          //   await Cookies.set('Product', updatedProductJSON, { expires: 7 });

          //   await navigate("/cart")

          // }}


        >Continue</button> */}
              </div>


            </>}
            {selselectplanebox2 === false && <>

              <h3 style={{ borderRadius: '5px', backgroundColor: '#0587EF', padding: "5px", alignSelf: "baseline", margin: '0', color: 'White' }}>
                Kindly select a Device you want to Rent.                     </h3>
              <div style={{ width: '90%', display: 'flex', justifyContent: 'flex-start', textAlign: 'justify', color: 'black' }}>
                Contract Duration one month.
              </div>


              <table className='data-plans-table'>
                <thead>
                  <tr>

                    <th>Device</th>
                    <th>Price</th>


                  </tr>
                </thead>
                <tbody>

                  <tr >
                    <th>DW SMART MIFI – MT10</th>
                    <td
                      className={selectedCell === 'DW SMART MIFI – MT10' ? 'selected-cell' : ''}
                      onClick={async () => {
                        await setSelectedCell('DW SMART MIFI – MT10');

                        await setproductselected({ ...productselected, selectedCell })

                      }

                      }
                    >$10</td>


                  </tr>


                  <tr >
                    <th>DW SMART Mifi – MT20</th>
                    <td
                      className={selectedCell === 'DW SMART Mifi – MT20' ? 'selected-cell' : ''}
                      onClick={() => setSelectedCell('DW SMART Mifi – MT20')}
                    >$14</td>

                  </tr>


                  <tr >
                    <th>DW SMART WIFI – MT25</th>
                    <td
                      className={selectedCell === 'DW SMART WIFI – MT25' ? 'selected-cell' : ''}
                      onClick={() => setSelectedCell('DW SMART WIFI – MT25')}
                    >$28</td>

                  </tr>

                  {showallplans === true && <>

                    <tr >
                      <th>DW SMART WIFI – MT30</th>
                      <td
                        className={selectedCell === 'DW SMART WIFI – MT30' ? 'selected-cell' : ''}
                        onClick={() => setSelectedCell('DW SMART WIFI – MT30')}
                      >$48</td>

                    </tr>

                    <tr >
                      <th>Dongle DW U90</th>
                      <td
                        className={selectedCell === 'Dongle DW U90' ? 'selected-cell' : ''}
                        onClick={() => setSelectedCell('Dongle DW U90')}
                      >$75</td>

                    </tr>

                    <tr >
                      <th>DW FastNet(Battery Back-up)</th>
                      <td
                        className={selectedCell === 'DW FastNet(Battery Back-up)' ? 'selected-cell' : ''}
                        onClick={() => setSelectedCell('DW FastNet(Battery Back-up)')}
                      >$75</td>

                    </tr>

                    <tr >
                      <th>DW FastNet(No Battery)</th>
                      <td
                        className={selectedCell === 'DW FastNet(No Battery)' ? 'selected-cell' : ''}
                        onClick={() => setSelectedCell('DW FastNet(No Battery)')}
                      >$75</td>

                    </tr>






                  </>}

                </tbody>
              </table>
              {showallplans === false && <>


                <h5 className='readmore' onClick={() => { setshowallplans(true) }} style={{ margin: "0", width: '100%', display: 'flex', justifyContent: 'end', textAlign: 'justify', color: 'black' }}>
                  Read More.
                </h5>

              </>}
              {showallplans === true && <>

                <h5 className='readmore' onClick={() => { setshowallplans(false) }} style={{ margin: "0", width: '100%', display: 'flex', justifyContent: 'end', textAlign: 'justify', color: 'black' }}>
                  Read Less.
                </h5>
              </>}



              <div className='buttoonn'>
                <button onClick={() => {

                  setSelselectplanebox(false)
                  setSelectedCell(null)

                }}>Cancel</button>
                <button
                  onClick={async (e) => {

                    await console.log(cookieproduct);
                    const newProduct = {
                      "productname": productselected.productname,
                      "productprice": productselected.productPrice,
                      "productimg": productselected.productimg,
                      "dataplane": selectedCell
                    };




                    const newupdateddata = [...cookieproduct, newProduct]



                    await console.log(cookieproduct)


                    const updatedProductJSON = JSON.stringify(newupdateddata);

                    await Cookies.set('Product', updatedProductJSON, { expires: 7 });

                    await navigate("/cart")

                  }}


                >Continue</button>
              </div>
            </>}


          </div>



        </div>

      </>}





















    </div>
  )
}

export default Akwaabaconnect